import React, { useState, useEffect } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import {
  useParams,
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'
import PropTypes from "prop-types";
import axios from 'axios'

import './_dashboard.scss'

import CompanyClients from './company_clients/company_clients.js'
import Client from '../client/client'
import Users from './users/users.js'
import CompanyEdit from './company_edit/company_edit.js'
import Services from './services/serivces'
import MessagePanel from './message_panel/message_panel'
import MessageMobile from './message_panel/message_mobile/message_mobile';
import Documents from './documents/documents'
import Notifications from './notifications/notifications.js'
import Error from '../shared/error/error'
import InvoiceAll from './facturatie/invoice_all/invoice_all'
import Planning from './planning/planning'
import Timeline from './planning/timeline/timeline';
import { isLocalDataValid } from '../shared/shared_functions';
import ProjectApplication from '../project_application/project_application';
import Externe from './externe/externe';

function KlantenCompany(props) {
  let { subpage } = useParams()
  const {
    setActiveTab,
    companyId,
    setCompanyIdState,
    isView,
    setCompanyViewState,
    setCompanyCommentState,
    comment,
  } = props

  return (
    <>
      {subpage === 'list' && (
        <CompanyClients
          activeTab={setActiveTab}
          companyId={setCompanyIdState}
          companyViewState={setCompanyViewState}
          companyCommentState={setCompanyCommentState}
        />
      )}
      {subpage === 'add' && (
        <Client
          isCompany={true}
          companyId={0}
          isView={false}
          comment={''}
          isPrivate={false}
        />
      )}
      {subpage === 'edit' && (
        <Client
          isCompany={true}
          companyId={companyId}
          isView={isView}
          comment={comment}
          isPrivate={false}
        />
      )}
      {subpage === 'private' && (
        <Client
          isCompany={true}
          companyId={0}
          isView={false}
          comment={''}
          isPrivate={true}
        />
      )}
      {subpage === 'edit-private' && (
        <Client
          isCompany={true}
          companyId={companyId}
          isView={isView}
          comment={comment}
          isPrivate={true}
        />
      )}
    </>
  )
}

function KlantenUser(props) {
  let { subpage } = useParams()
  const { setActiveTab } = props

  return <>{subpage === 'list' && <Users activeTab={setActiveTab} />}</>
}

function PlanningPages(props) {
  const { getUpdatedData } = props;
  let { subpage } = useParams()

  return (
    <>
      {subpage === 'agenda' && (
        <Planning />
      )}
      {subpage === 'timeline' && (
        <Timeline getUpdatedData = {getUpdatedData} />
      )}
    </>
  )
}

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState(0)
  const [activeExpand, setActiveExpand] = useState(0)
  const [companyIdState, setCompanyIdState] = useState(0)
  const [companyViewState, setCompanyViewState] = useState(false)
  const [companyCommentState, setCompanyCommentState] = useState('')
  const [siderbarWidth, setSidebarWidth] = useState(2)
  const [data, setData] = useState([])
  const [showSettingsCompany, setShowSettingsCompany] = useState(false)
  const token = localStorage.getItem('authToken')

  const location = useLocation()
  let { page } = useParams()
  let { url } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    if(!isLocalDataValid()) {
      localStorage.clear();
      history.push("/")
    }
    else {
      // const isValid = CheckActiveURL()
      const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
      if(userDetails.role.id === 4) {
        setShowSettingsCompany(true)
      }

      if (window.screen.width < 1440) {
        setSidebarWidth(3)
      }
    }
  }, [])

  function CheckActiveURL() {
    if (location.pathname === '/dashboard') {
      setActiveTab(0)
    } 
    else if (location.pathname.includes('berichten')) {
      setActiveTab(4)
    } 
    else if (location.pathname.includes('documenten')) {
      setActiveTab(5)
    } 
    else if (location.pathname === '/planning/agenda') {
      setActiveExpand(4);
      setActiveTab(6)
    } 
    else if (location.pathname === '/planning/timeline') {
      setActiveExpand(4);
      setActiveTab(7)
    } 
    else if (location.pathname === '/settings/company') {
      setActiveTab(8)
      setActiveExpand(5)
    } 
    else if (location.pathname === '/settings/notification') {
      setActiveTab(9)
      setActiveExpand(5)
    } 
    else if (location.pathname === '/settings/externe') {
      setActiveTab(10)
      setActiveExpand(5)
    }
    else if (location.pathname === '/klanten/company/add') {
      setActiveExpand(7)
      setActiveTab(11)
    } 
    else if (location.pathname === '/klanten/company/edit') {
      setActiveExpand(7)
    } 
    else if (location.pathname === '/klanten/company/private') {
      setActiveExpand(7)
      setActiveTab(12)
    } 
    else if (location.pathname === '/klanten/company/list') {
      setActiveExpand(7)
      setActiveTab(13)
    } 
    else if (location.pathname === '/klanten/user/list') {
      setActiveExpand(7)
      setActiveTab(14)
    } 
    // else if (location.pathname === '/facturate') {
    //   setActiveExpand(10)
    //   setActiveTab(16)
    // } 
    // else if (location.pathname.includes('/invoice-draft/')) {
    // } 
    // else if (location.pathname === '/quotations-all') {
    //   setActiveExpand(10)
    //   setActiveTab(15)
    // } 
    // else if (location.pathname.includes('/quotations-draft/')) {
    // } 
    else if(location.pathname==='/project-application/:value'){

    }

    else if(location.pathname.includes('/dossiers/')){

    }

    else {
      history.push('/404')
      return false
    }

    return true
  }

  function getUpdatedData() {
    const token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/dashboard/adminData', {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setData(response.data)
      }).catch((error) => {
        if(error.response.status === 401) {
          localStorage.clear();
          history.push("/")
        }
      })
  }

  return (
    <Grid container>
      <Hidden smDown>
        <Grid container item sm={siderbarWidth}>
          <Grid
            container
            item
            sm={12}
            className="dashboard-sidebar-main-container"
          >
            <div className="dashboard-sidebar-container flex-column">
              <div className="flex-row width-auto horizontal-center margin-top-25">
                <div className="dashboard-sidebar-inset vertical-center">
                  <img
                    src={require('../../assets/svg/logo_color/7b.svg')}
                    alt="docubuild"
                    className="dashboard-sidebar-logo object-fit-contain"
                  />
                  <h1 className="main-font-bold font-size-22 font-color-navy-blue opacity-70 margin-left-10">
                    Docu
                  </h1>
                  <p className="main-font-light font-size-22 font-color-navy-blue">
                    Build
                  </p>
                </div>
              </div>
              <div
                className="dashboard-sidebar-tab-top animatable-400 ease-in-out-quart"
                style={{
                  background: activeTab === 0 && '#E5EDF3',
                }}
                onClick={() => {
                  setActiveTab(0)
                  setActiveExpand(0)
                  history.push('/dashboard',data)
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    dashboard
                  </h1>
                </div>
              </div>
              <div className="flex-row width-auto horizontal-center margin-top-5">
                <div className="dashboard-sidebar-line" />
              </div>
              <div
                className="dashboard-sidebar-tab"
                style={{
                  background: (activeTab === 4) && '#E5EDF3' ,
                }}
                onClick={() => {
                  setActiveTab(4)
                  setActiveExpand(0)
                  history.push('/berichten')
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    berichten
                  </h1>
                </div>
              </div>
              <div
                className="dashboard-sidebar-tab"
                style={{
                  background: activeTab === 5 && '#E5EDF3',
                }}
                onClick={() => {
                  setActiveTab(5)
                  setActiveExpand(0)
                  history.push('/documenten')
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    documenten
                  </h1>
                </div>
              </div>
              <div className="flex-row width-auto horizontal-center margin-top-5">
                <div className="dashboard-sidebar-line" />
              </div>
              <div
                className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
                onClick={() => {
                  activeExpand === 4 ? setActiveExpand(0) : setActiveExpand(4)
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    planning
                  </h1>
                </div>
                <img
                  src={require('../../assets/svg/icon/arrow_down_grey.svg')}
                  alt="arrow down"
                  className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
                  style={{
                    opacity: activeExpand === 4 ? 1 : 0,
                    transform:
                      activeExpand === 4
                        ? 'translateX(0px) rotate(0deg)'
                        : 'translateX(-15px) rotate(360deg)',
                  }}
                />
              </div>
              {/* planning sub tabs */}
              <div
                className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
                style={{
                  height: activeExpand === 4 ? '77.5px' : '0px',
                  opacity: activeExpand === 4 ? 1 : 0,
                }}
              >
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 6 && '#E5EDF3',
                  }}
                  onClick={() => {
                    setActiveTab(6)
                    history.push('/planning/agenda')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Agenda
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 7 && '#E5EDF3' ,
                  }}
                  onClick={() => {
                    setActiveTab(7)
                    history.push('/planning/timeline')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Timeline
                    </h1>
                  </div>
                </div>
              </div>
              <div
                className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
                onClick={() => {
                  activeExpand === 5 ? setActiveExpand(0) : setActiveExpand(5)
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center horizontal-between">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    settings
                  </h1>
                </div>
                <img
                  src={require('../../assets/svg/icon/arrow_down_grey.svg')}
                  alt="arrow down"
                  className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
                  style={{
                    opacity: activeExpand === 5 ? 1 : 0,
                    transform:
                      activeExpand === 5
                        ? 'translateX(0px) rotate(0deg)'
                        : 'translateX(-15px) rotate(360deg)',
                  }}
                />
              </div>
              {/*settings sub pages*/}
              <div
                className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
                style={{
                  height: activeExpand === 5 ? showSettingsCompany ? '135px' : '45px' : '0px',
                  opacity: activeExpand === 5 ? 1 : 0,
                }}
              >
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 8 && '#E5EDF3',
                    display: showSettingsCompany ? "flex" : "none"
                  }}
                  onClick={() => {
                    setActiveTab(8)
                    history.push('/settings/company')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Bedrijf
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 9 && '#E5EDF3',
                  }}
                  onClick={() => {
                    setActiveTab(9)
                    history.push('/settings/notification')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Notifcatie
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 10 && '#E5EDF3',
                    display: showSettingsCompany ? "flex" : "none"
                  }}
                  onClick={() => {
                    setActiveTab(10)
                    history.push('/settings/externe')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Externe
                    </h1>
                  </div>
                </div>
              </div>
              <div
                className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
                onClick={() => {
                  activeExpand === 7 ? setActiveExpand(0) : setActiveExpand(7)
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center horizontal-between">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    klanten
                  </h1>
                </div>
                <img
                  src={require('../../assets/svg/icon/arrow_down_grey.svg')}
                  alt="arrow down"
                  className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
                  style={{
                    opacity: activeExpand === 7 ? 1 : 0,
                    transform:
                      activeExpand === 7
                        ? 'translateX(0px) rotate(0deg)'
                        : 'translateX(-15px) rotate(360deg)',
                  }}
                />
              </div>
              {/* klanten sub pages */}
              <div
                className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
                style={{
                  height: activeExpand === 7 ? '155px' : '0px',
                  opacity: activeExpand === 7 ? 1 : 0,
                }}
              >
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 11 && '#E5EDF3' ,
                  }}
                  onClick={() => {
                    setActiveTab(11)
                    history.push('/klanten/company/add')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Nieuw bedrijf
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 12 && '#E5EDF3',
                  }}
                  onClick={() => {
                    setActiveTab(12)
                    history.push('/klanten/company/private')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Nieuw privé persoon
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 13 && '#E5EDF3',
                  }}
                  onClick={() => {
                    setActiveTab(13)
                    history.push('/klanten/company/list')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Bedrijfslijst
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 14 && '#E5EDF3',
                  }}
                  onClick={() => {
                    setActiveTab(14)
                    history.push('/klanten/user/list')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Gebruikerslijst
                    </h1>
                  </div>
                </div>
              </div>
              {/* <div className="flex-row width-auto horizontal-center margin-top-5">
                <div className="dashboard-sidebar-line" />
              </div> */}
              {/* <div
                className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
                onClick={() => {
                  setActiveExpand(10)
                }}
              >
                <div className="dashboard-sidebar-inset vertical-center">
                  <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
                    facturatie
                  </h1>
                </div>
                <img
                  src={require('../../assets/svg/icon/arrow_down_grey.svg')}
                  alt="arrow down"
                  className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
                  style={{
                    opacity: activeExpand === 10 ? 1 : 0,
                    transform:
                      activeExpand === 10
                        ? 'translateX(0px) rotate(0deg)'
                        : 'translateX(-15px) rotate(360deg)',
                  }}
                />
              </div>
              <div
                className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
                style={{
                  height: activeExpand === 10 ? '155px' : '0px',
                  opacity: activeExpand === 10 ? 1 : 0,
                }}
              >
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 15 ? '#E5EDF3' : 'rgba(0,0,0,0)',
                  }}
                  onClick={() => {
                    setActiveTab(15)
                    history.push('/quotations-all')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Offertes
                    </h1>
                  </div>
                </div>
                <div
                  className="dashboard-sidebar-tab"
                  style={{
                    background: activeTab === 16 ? '#E5EDF3' : 'rgba(0,0,0,0)',
                  }}
                  onClick={() => {
                    setActiveTab(16)
                    history.push('/facturate')
                  }}
                >
                  <div className="dashboard-sidebar-inset vertical-center">
                    <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
                      Facturen
                    </h1>
                  </div>
                </div>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container item md={12 - siderbarWidth} alignItems="flex-start">
        {page === 'dashboard' && <Services />}
        {page.includes('berichten') && (
          <Switch>
            <Route path = {`/berichten`}>
              <Hidden xsDown>
                <MessagePanel />
              </Hidden>
              <Hidden smUp>
                <MessageMobile />
              </Hidden>
            </Route>
            <Route path = {`/berichten&=:notif`}>
              <Hidden xsDown>
                <MessagePanel />
              </Hidden>
              <Hidden smUp>
                <MessageMobile />
              </Hidden>
            </Route>
          </Switch>
        )}
        {page.includes('documenten') && 
          <Switch>
            <Route path = {`/documenten`}>
              <Documents token={token} />
            </Route>
            <Route path = {`/documenten&=:file`}>
              <Documents token={token} />
            </Route>
          </Switch>
        }
        {page === 'settings' && (
          // <CompanyEdit />
          <Switch>
            <Route exact path={`${url}/company`}>
              <CompanyEdit />
            </Route>
            <Route exact path={`${url}/notification`}>
              <Notifications />
            </Route>
            <Route exact path = {`${url}/externe`}>
              <Externe />
            </Route>
          </Switch>
        )}
        {page === 'klanten' && (
          <Switch>
            <Route exact path={`${url}/company/:subpage`}>
              <KlantenCompany
                setActiveTab={setActiveTab}
                setCompanyIdState={setCompanyIdState}
                companyId={companyIdState}
                setCompanyViewState={setCompanyViewState}
                isView={companyViewState}
                setCompanyCommentState={setCompanyCommentState}
                comment={companyCommentState}
              />
            </Route>
            <Route exact path={`${url}/user/:subpage`}>
              <KlantenUser setActiveTab={setActiveTab} />
            </Route>
          </Switch>
        )}
        {page === 'facturate' && <InvoiceAll />}
        {/* {page === 'invoice-draft' && (
          <Switch>
            <Route exact path = {`${url}/new`}>
              <InvoiceNew />
            </Route>
            <Route exact path = {`${url}/:id`}>
              <InvoiceDraft/>
            </Route>
          </Switch>
        )}
        {page==='quotations-all' && <QuotationsAll />}
        {page==='quotations-draft' && (
          <Route exact path = {`${url}/:id`}>
            <QuotationDraft />
          </Route>
        )} */}
        {page === 'planning' && (
          <Switch>
            <Route exact path = {`${url}/:subpage`}>
              <PlanningPages getUpdatedData = {getUpdatedData} />
            </Route>
          </Switch>
        )}
        {page === 'project-application' && (
          <Route exact path='/project-application/:value'>
            <ProjectApplication/>
          </Route>
        )}
        {page === '404' && <Error />}
        {/* {activeTab === 7 && (
                <Client isCompany={true} companyId={companyIdState} isView={companyViewState} comment={companyCommentState}/>
            )} */}
      </Grid>
    </Grid>
  )
}

export function DashboardSidebarModular( props ) {
  const [activeTab, setActiveTab] = useState(-1)
  const [activeExpand, setActiveExpand] = useState(0)

  const { isVisible, closeDashboard } = props;

  const history = useHistory();

  return(
    <div className="dashboard-sidebar-container-modular flex-column absolute animatable-400 ease-in-out-quart" style = {{left: isVisible ? '0px' : '-250px'}}>
      <div className = "flex-row width-auto horizontal-end">
        <button type = "button" className = "cursor-pointer" onClick = {closeDashboard} >
          <div className = "dashbord-sidebar-inset horizontal-end">
            <img src = {require('../../assets/svg/icon/cross.svg')} alt = "close" className = "dashboard-sidebar-modular-close margin-right-15 margin-top-15" />
          </div>
        </button>
      </div>
      <div className="flex-row width-auto horizontal-center margin-top-25">
        <div className="dashboard-sidebar-inset vertical-center">
          <img
            src={require('../../assets/svg/logo_color/7b.svg')}
            alt="docubuild"
            className="dashboard-sidebar-logo object-fit-contain"
          />
          <h1 className="main-font-bold font-size-22 font-color-navy-blue opacity-70 margin-left-10">
            Docu
          </h1>
          <p className="main-font-light font-size-22 font-color-navy-blue">
            Build
          </p>
        </div>
      </div>
      <div
        className="dashboard-sidebar-tab-top animatable-400 ease-in-out-quart"
        style={{
          background: activeTab === 0 ? '#E5EDF3' : 'rgba(0,0,0,0)',
        }}
        onClick={() => {
          setActiveTab(0)
          setActiveExpand(0)
          history.push('/dashboard')
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center">
          <h1 className={`main-font-semi-bold font-size-14 font-color-grey uppercase`}>
            dashboard
          </h1>
        </div>
      </div>
      <div className="flex-row width-auto horizontal-center margin-top-5">
        <div className="dashboard-sidebar-line" />
      </div>
      <div
        className="dashboard-sidebar-tab"
        // style={{
        //   background: activeTab === 4 ? '#E5EDF3' : 'rgba(0,0,0,0)',
        // }}
        onClick={() => {
          setActiveTab(4)
          setActiveExpand(0)
          history.push('/berichten')
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center">
          <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
            berichten
          </h1>
        </div>
      </div>
      <div
        className="dashboard-sidebar-tab"
        style={{
          background: activeTab === 5 ? '#E5EDF3' : 'rgba(0,0,0,0)',
        }}
        onClick={() => {
          setActiveTab(5)
          setActiveExpand(0)
          history.push('/documenten')
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center">
          <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
            documenten
          </h1>
        </div>
      </div>
      <div className="flex-row width-auto horizontal-center margin-top-5">
        <div className="dashboard-sidebar-line" />
      </div>
      <div
        className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
        onClick={() => {
          activeExpand === 4 ? setActiveExpand(0) : setActiveExpand(4)
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center">
          <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
            planning
          </h1>
        </div>
        <img
          src={require('../../assets/svg/icon/arrow_down_grey.svg')}
          alt="arrow down"
          className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
          style={{
            opacity: activeExpand === 4 ? 1 : 0,
            transform:
              activeExpand === 4
                ? 'translateX(0px) rotate(0deg)'
                : 'translateX(-15px) rotate(360deg)',
          }}
        />
      </div>
      {/* planning sub tabs */}
      <div
        className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
        style={{
          height: activeExpand === 4 ? '77.5px' : '0px',
          opacity: activeExpand === 4 ? 1 : 0,
        }}
      >
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 6 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(6)
            history.push('/planning/agenda')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Agenda
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 7 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(7)
            history.push('/planning/timeline')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Timeline
            </h1>
          </div>
        </div>
      </div>
      <div
        className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
        onClick={() => {
          activeExpand === 5 ? setActiveExpand(0) : setActiveExpand(5)
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center horizontal-between">
          <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
            settings
          </h1>
        </div>
        <img
          src={require('../../assets/svg/icon/arrow_down_grey.svg')}
          alt="arrow down"
          className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
          style={{
            opacity: activeExpand === 5 ? 1 : 0,
            transform:
              activeExpand === 5
                ? 'translateX(0px) rotate(0deg)'
                : 'translateX(-15px) rotate(360deg)',
          }}
        />
      </div>
      {/*settings sub pages*/}
      <div
        className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
        style={{
          height: activeExpand === 5 ? '135px' : '0px',
          opacity: activeExpand === 5 ? 1 : 0,
        }}
      >
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 8 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(8)
            history.push('/settings/company')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Bedrijf
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 9 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(9)
            history.push('/settings/notification')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Notifcatie
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 10 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(10)
            history.push('/settings/externe')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Externe
            </h1>
          </div>
        </div>
      </div>
      <div
        className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
        onClick={() => {
          activeExpand === 7 ? setActiveExpand(0) : setActiveExpand(7)
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center horizontal-between">
          <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
            klanten
          </h1>
        </div>
        <img
          src={require('../../assets/svg/icon/arrow_down_grey.svg')}
          alt="arrow down"
          className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
          style={{
            opacity: activeExpand === 7 ? 1 : 0,
            transform:
              activeExpand === 7
                ? 'translateX(0px) rotate(0deg)'
                : 'translateX(-15px) rotate(360deg)',
          }}
        />
      </div>
      {/* klanten sub pages */}
      <div
        className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
        style={{
          height: activeExpand === 7 ? '155px' : '0px',
          opacity: activeExpand === 7 ? 1 : 0,
        }}
      >
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 11 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(11)
            history.push('/klanten/company/add')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Nieuw bedrijf
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 12 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(12)
            history.push('/klanten/company/private')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Nieuw privé persoon
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 13 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(13)
            history.push('/klanten/company/list')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Bedrijfslijst
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 14 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(14)
            history.push('/klanten/user/list')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Gebruikerslijst
            </h1>
          </div>
        </div>
      </div>
      {/* <div className="flex-row width-auto horizontal-center margin-top-5">
        <div className="dashboard-sidebar-line" />
      </div> */}
      {/* <div
        className="dashboard-sidebar-tab animatable-400 ease-in-out-quart flex-row vertical-center relative"
        onClick={() => {
          setActiveExpand(10)
        }}
      >
        <div className="dashboard-sidebar-inset vertical-center">
          <h1 className="main-font-semi-bold font-size-14 font-color-grey uppercase">
            facturatie
          </h1>
        </div>
        <img
          src={require('../../assets/svg/icon/arrow_down_grey.svg')}
          alt="arrow down"
          className="dashboard-sidebar-expandable-arrow animatable-400 ease-in-out-quart"
          style={{
            opacity: activeExpand === 10 ? 1 : 0,
            transform:
              activeExpand === 10
                ? 'translateX(0px) rotate(0deg)'
                : 'translateX(-15px) rotate(360deg)',
          }}
        />
      </div>
      <div
        className="flex-column width-auto animatable-400 ease-in-out-quart overflow-hidden"
        style={{
          height: activeExpand === 10 ? '155px' : '0px',
          opacity: activeExpand === 10 ? 1 : 0,
        }}
      >
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 15 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(15)
            history.push('/quotations-all')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Offertes
            </h1>
          </div>
        </div>
        <div
          className="dashboard-sidebar-tab"
          style={{
            background: activeTab === 16 ? '#E5EDF3' : 'rgba(0,0,0,0)',
          }}
          onClick={() => {
            setActiveTab(16)
            history.push('/facturate')
          }}
        >
          <div className="dashboard-sidebar-inset vertical-center">
            <h1 className="main-font-light font-size-14 font-color-grey margin-left-15">
              Facturen
            </h1>
          </div>
        </div>
      </div> */}
    </div>
  )
}

DashboardSidebarModular.propTypes = {
  isVisible: PropTypes.bool,
  closeDashboard: PropTypes.func,
}

DashboardSidebarModular.defaultProps = {
  isVisible: false,
  closeDashboard: () => {},
}
