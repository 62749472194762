// @format
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

// [NOTE]: Use this file for like your global.css file

const GlobalStyle = createGlobalStyle`

  // normalizes all css and html attributes
  ${normalize}

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  :root {
    --scaleHeight: 1;
    --scale: 1;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

export default GlobalStyle
