import React, { useState, useEffect } from 'react';
import { Grid, Hidden, Dialog, makeStyles } from '@material-ui/core';
import _ from "lodash";
import axios from 'axios';
import PropTypes from 'prop-types';

import MobileView from "../mobile_view/mobile_view";
import Header from '../header/header.js';
import ProfileView from '../profile_view/profile_view'
import { notificationHeaders } from '../../shared/sample_data';
import SearchIcon from '../../../assets/png/icon/search_icon.png';
import ViewIcon from '../../../assets/png/icon/view.png';
import ArrowDown from '../../../assets/svg/icon/arrow_down_grey.svg';
import ArrowUp from '../../../assets/svg/icon/arrow_up_grey.svg';
import CheckIcon from '../../../assets/svg/icon/check_grey.svg';
import TrashIcon from '../../../assets/svg/icon/trash_grey.svg';
import SwitchIcon from '../../../assets/svg/button_switch.svg';
import CrossIcon from '../../../assets/svg/icon/cross.svg';

import './_notifications.scss';

export default function Notifications() {
  const [originalDataState, setOriginalDataState] = useState([])
  const [filteredDataState, setFilteredDataState] = useState([])
  const [filterByState, setFilterByState] = useState(1)
  const [ headerState, setHeaderState ] = useState([])
  const [ sortByState, setSortByState ] = useState({id: 1, title: 'Notificatie', asc: true})
  const [ forceUpdateState,setForceUpdateState ] = useState(false)
  const [ viewNotificationState, setViewNotificationState ] = useState(false)
  const [ viewDataState, setViewDataState ] = useState(null)

  useEffect(() => {
    setHeaderState(notificationHeaders)
    getNotifications()
  }, [])

  useEffect (() => {
    if(originalDataState.length > 0) {
      getFilteredData()
    }
  }, [filterByState, sortByState, forceUpdateState])
  
  useEffect(() => {
    console.log(filteredDataState)
  }, [filteredDataState])

  function getNotifications() {
    const token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/dashboard/all_notification', {}, {
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then(response => {
      setFilteredDataState(response.data)
      setOriginalDataState(response.data)
    }).catch(error => {
    })
  }

  const getFilteredData = () => {
    const originalData = JSON.parse(JSON.stringify(originalDataState))
    let filteredData = []
    if (originalData) {
      if (filterByState === 2) {
        filteredData = originalData.filter(a => (a.adminCheck !== 1 && a.userCheck !== 1));
      }
      else if (filterByState === 3) {
        filteredData = originalData.filter(a => (a.adminCheck === 1 && a.userCheck === 1));
      }
      else {
        filteredData = originalData
      }
      setFilteredDataState(filteredData)
    }
  }

  const filterData = _.debounce((param) => {
    const originalData = JSON.parse(JSON.stringify(originalDataState))
    let filteredDataCate = [];
    if (filterByState === 2) {
      filteredDataCate = originalData.filter(a => a.isArchive === false);
    }
    else if (filterByState === 3) {
      filteredDataCate = originalData.filter(a => a.isArchive === true);
    }
    else {
      filteredDataCate = originalData
    }
    if(param === ""){
      getSortedList(filteredDataCate)
    }
    else {
      const originalDataFiltered = JSON.parse(JSON.stringify(filteredDataCate));
      let filteredData = [];

      originalDataFiltered.forEach(notif => {
        if(notif.buildingName.toLowerCase().includes(param.toLowerCase()) || notif.companyName.toLowerCase().includes(param.toLowerCase()) || (notif.fileNumber && notif.fileNumber.toLowerCase().includes(param.toLowerCase())) || notif.email.toLowerCase().includes(param.toLowerCase()) || notif.date.toLowerCase().includes(param.toLowerCase())) {
          filteredData.push(notif)
        }
      })
      getSortedList(filteredData)
    }
  }, 500)

  const onSearchChange = (event) => {
    filterData(event.target.value)
  }
  const handleFilterByClick = (value) => {
      if (value !== filterByState) {
          setFilterByState(value)
      }
  }
  const handleHeaderClick = (item) => {
    if (item !== null && item !== undefined) {
      const prevState = {...sortByState}
      if (prevState.id !== item.id){
        setSortByState({id: item.id, title: item.title, asc: true})
      }
      else {
        if (prevState.asc === true)
          setSortByState({id: item.id, title: item.title, asc: false})
        else
          setSortByState({id: item.id, title: item.title, asc: true})
      }
    }
    else {
      setSortByState({id: 1, title: 'Notificatie', asc: true})
    }
  }
  
  const getSortedList = (filteredData) => {
      const sortBy = {...sortByState}
      let sortedData = []
      if (filteredData !== undefined && filteredData.length > 0) {
      if (sortBy !== {} && sortBy !== null) {
        if (sortBy.title.includes('Notificatie')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a,b) => a.buildingName !== null ?
              a.buildingName.localeCompare(b.buildingName !== null ? b.buildingName : '') : '')
          else
            sortedData = filteredData.sort((a,b) => b.buildingName !== null ?
              b.buildingName.localeCompare(a.buildingName !== null ? a.buildingName : '') : '')
        }
        else if (sortBy.title.includes('Bedrijf')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a,b) => a.companyName !== null ?
              a.companyName.localeCompare(b.companyName !== null ? b.companyName : '') : '')
          else
            sortedData = filteredData.sort((a,b) => b.companyName !== null ?
              b.companyName.localeCompare(a.companyName !== null ? a.companyName : '') : '')
        }
        else if (sortBy.title.includes('Dossier')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a,b) => a.fileNumber !== null ?
              a.fileNumber.localeCompare(b.fileNumber !== null ? b.fileNumber : '') : '')
          else
            sortedData = filteredData.sort((a,b) => b.fileNumber !== null ?
              b.fileNumber.localeCompare(a.fileNumber !== null ? a.fileNumber : '') : '')
        }
        else if (sortBy.title.includes('Email')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a,b) => a.email !== null ?
              a.email.localeCompare(b.email !== null ? b.email : '') : '')
          else
            sortedData = filteredData.sort((a,b) => b.email !== null ?
              b.email.localeCompare(a.email !== null ? a.email : '') : '')
        }
        else if (sortBy.title.includes('Datum')) {
          if(sortBy.asc)
            sortedData = filteredData.sort((a,b) => a.date !== null ?
              a.date.localeCompare(b.date !== null ? b.date : '') : '')
          else
            sortedData = filteredData.sort((a,b) => b.date !== null ?
              b.date.localeCompare(a.date !== null ? a.date : '') : '')
        }
      }
      else {
        sortedData = filteredData.sort((a, b) => a.title)
      }
    }
    setFilteredDataState(sortedData)
  }

  const handleSwitchClick = (id) => {
    if (id > 0) {
      const prevDataState = [...filteredDataState]
      if (prevDataState !== null && prevDataState !== undefined) {
        const dataObj = prevDataState.find(a => a.id === id)
        if (dataObj !== null && dataObj !== undefined) {
          dataObj.isArchive = !dataObj.isArchive
          setFilteredDataState(prevDataState)
          setTimeout(() => {
            setForceUpdateState(!forceUpdateState)
          }, 500)
        }
      }
    }
  }

  const handleViewClick = (id) => {
    if (id > 0) {
      const prevDataState = [...filteredDataState]
      if (prevDataState !== null && prevDataState !== undefined) {
        const dataObj = prevDataState.find(a => a.id === id)
        if (dataObj !== null && dataObj !== undefined) {
          setViewDataState(dataObj)
          setViewNotificationState(true)
        }
      }
    }
  }

  return(
      <Grid container>
          <Grid container>
            <Hidden smDown>
                <Header />
            </Hidden>
            <Hidden smUp>
                <Header toShowHamburger />
            </Hidden>
          </Grid>
          <Hidden smUp>
            <MobileView />
          </Hidden>
          <Hidden xsDown>
            <div className="notifications-main-div">
                <Grid container item xs={12} className="height-disable fade-in-right">
                    <Grid container item xs={12} alignItems="center">
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <div className="notifications-search-bar">
                                <img src={SearchIcon} alt="" className="notifications-search-icon"/>
                                <input
                                    type="text"
                                    placeholder="Zoeken"
                                    className="width-auto font-color-black margin-left-5"
                                    onChange={(event) => onSearchChange(event)}
                                    />
                            </div>
                        </Grid>
                        <Hidden smDown>
                            <div className="notifications-vertical-div">
                                <span className="notifications-vertical-line-blue"></span>
                            </div>
                        </Hidden>
                        <div className="notifications-filter-div main-font-regular font-size-14">
                            <button className={filterByState === 1 ? "notifications-filter-active" : "notifications-filter-inactive"}
                                onClick={() => handleFilterByClick(1)}>
                                Alles
                            </button>
                        </div>
                        <div className="notifications-filter-div main-font-regular font-size-14">
                            <button className={filterByState === 2 ? "notifications-filter-active" : "notifications-filter-inactive"}
                                onClick={() => handleFilterByClick(2)}>
                                Nieuw
                            </button>
                        </div>
                        <div className="notifications-filter-div main-font-regular font-size-14">
                            <button className={filterByState === 3 ? "notifications-filter-active" : "notifications-filter-inactive"}
                                onClick={() => handleFilterByClick(3)}>
                                Archief
                            </button>
                        </div>
                    </Grid>
                    <Grid container item xs={12}>
                        <div className="notifications-header-main vertical-center">
                            {
                                headerState !== null && headerState !== undefined ?
                                headerState.map(header => {
                                    return(
                                        <div key={header.id} onClick={header.title !== '' ? () => handleHeaderClick(header) : null}
                                            className={(header.id === 6) ? "notifications-header-item-lg" :
                                                (header.id === 2 || header.id === 3) ? "notifications-header-item-sm" :
                                                "notifications-header-item"}>
                                            <span className="main-font-light font-size-14 font-color-grey">
                                            {header.title}
                                            </span>
                                            {
                                            sortByState !== null ?
                                                (sortByState.id === header.id && sortByState.asc === true) ?
                                                <span className="font-color-grey">
                                                    <img src={ArrowDown} alt="" className="notifications-arrow-icon margin-top-2 margin-left-5"/>
                                                </span>
                                                : (sortByState.id === header.id && sortByState.asc === false) ?
                                                <span className="font-color-grey">
                                                    <img src={ArrowUp} alt="" className="notifications-arrow-icon margin-top-2 margin-left-5"/>
                                                </span>
                                                : null
                                            :
                                            null
                                            }
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div>
                    </Grid>
                    <Grid container>
                        <div className="notifications-card-container flex-column width-auto">
                          {filteredDataState.length > 0 && filteredDataState.map((item, index) => {console.log(filteredDataState)
                            return(
                              index % 2 === 0 ?
                              <Grid container key={item.id}
                                className={`notifications-card-main background-color-white`}>
                                <CardDetail data={item} handleSwitchClick={handleSwitchClick} handleViewClick={handleViewClick}/>
                              </Grid>
                              :
                              <Grid container key={item.id}
                                className={`notifications-card-main background-color-transparent`}>
                                <CardDetail data={item} handleSwitchClick={handleSwitchClick} handleViewClick={handleViewClick}/>
                              </Grid>
                            )
                          })}
                        </div>
                    </Grid>
                </Grid>
                {
                    viewNotificationState === true ?
                    // <NotificationView data={viewDataState} setOpenState={setViewNotificationState}/>
                    <ProfileView
                      modalState={viewNotificationState}
                      setModalState={setViewNotificationState}
                      data={viewDataState}
                    />
                    : null
                }
            </div>
          </Hidden>
      </Grid>
  )
}

const CardDetail = (props) => {
    const { data, handleSwitchClick, handleViewClick } = props
    const [ animateOutState, setAnimateOutState ] = useState(false)
    const [ animateInState, setAnimateInState ] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setAnimateInState(false)
        }, 500)
    }, [])

    const handleSwitch = () => {
        setAnimateOutState(true)
        handleSwitchClick(data.id)
        setTimeout(() => {
            setAnimateOutState(false)
        }, 500)
    }

    return(
        <div className={`height-auto width-auto vertical-center ${animateInState === true ? 'slide-in-right': ''} ${animateOutState === true ? 'slide-out-left' : ''}`}>
          <div className="notifications-card-item">
            <p className="main-font-regular font-size-14 font-color-black notifications-word-break">
              {(data.buildingName !== null && data.buildingName !== undefined) ? data.buildingName : ''}
            </p>
          </div>
          <div className="notifications-card-item-sm">
            <p className="main-font-regular font-size-14 font-color-black notifications-word-break">
              {(data.companyName !== null && data.companyName !== undefined) ? data.companyName : ''}
            </p>
          </div>
          <div className="notifications-card-item-sm">
            <p className="main-font-regular font-size-14 font-color-black notifications-word-break">
              {(data.fileNumber !== null && data.fileNumber !== undefined) ? data.fileNumber : ''}
            </p>
          </div>
          <div className="notifications-card-item">
            <p className="main-font-regular font-size-14 font-color-black notifications-word-break">
              {(data.email !== null && data.email !== undefined) ? data.email : ''}
            </p>
          </div>
          <div className="notifications-card-item">
            <p className="main-font-regular font-size-14 font-color-black notifications-word-break">
              {(data.date !== null && data.date !== undefined) ? data.date : ''}
            </p>
          </div>
          <div className="notifications-card-item-lg">
            <div className="height-auto margin-left-10">
              <span className="notifications-vertical-line-blue"></span>
            </div>
            {data.adminCheck && data.userCheck && data.userCheck === 1 && data.adminCheck === 1 ?
              <div className="notifications-status-div font-color-grey margin-left-15">
                <span className="main-font-regular font-size-14 notifications-status-label">Archief</span>
                <img src={TrashIcon} alt="" className="notifications-symbol-icon margin-top-2 margin-left-5"/>
              </div>
              :
              <div className="notifications-status-div font-color-grey margin-left-15">
                <span className="main-font-regular font-size-14 notifications-status-label">Nieuw</span>
                <img src={CheckIcon} alt="" className="notifications-symbol-icon margin-top-2 margin-left-5"/>
              </div>
            }
            <button className="cursor-pointer margin-left-5" onClick={handleSwitch}>
              <img src={SwitchIcon} alt="" className="notifications-button-icon"/>
            </button>
            <button className="cursor-pointer" onClick={() => handleViewClick(data.id)}>
              <img src={ViewIcon} alt="" className="notifications-button-icon"/>
            </button>
          </div>
        </div>
    )
}

const useStyles = makeStyles({
    paper: {
      width: '60%',
      maxHeight: '80%',
      borderRadius: '10px'
    },
});

const NotificationView = (props) => {
    const { data, setOpenState } = props
  const classes = useStyles();
  const [ popupState, setPopupState ] = useState(true)

    const handleButtonClick = () => {
        setPopupState(false);
        if(setOpenState !== undefined && setOpenState !== null) {
            setOpenState(false)
        }
    }
  return (
    <React.Fragment>
    {
      data !== null && data !== {} ?
      <Dialog
        open={popupState}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='sm'
        scroll="paper"
        classes={{paper: classes.paper}}
    >
      <div className="horizontal-end">
        <div className="margin-top-10 margin-right-10">
          <button onClick={handleButtonClick} className="cursor-pointer">
            <img src={CrossIcon} alt="" className="notifications-symbol-icon margin-top-2 margin-left-5"/>
          </button>
        </div>
      </div>
        <div className="width-auto height-auto flex-column padding-left-30 padding-bottom-25 padding-right-15">    
          <div className="flex-column font-size-14 margin-top-20">
            <span className="main-font-light font-color-dark-grey">
              Notificatie
            </span>
            <span className="main-font-semi-bold font-color-black margin-top-5">
              {data.title !== null ? data.title : ''}
            </span>
          </div>
          <div className="flex-column font-size-14 margin-top-25">
            <span className="main-font-light font-color-dark-grey">
              Bedrijf
            </span>
            <span className="main-font-semi-bold font-color-black margin-top-5">
              {data.companyName !== null ? data.companyName : ''}
            </span>
          </div>
          <div className="flex-column font-size-14 margin-top-25">
            <span className="main-font-light font-color-dark-grey">
              E-mail
            </span>
            <span className="main-font-semi-bold font-color-black margin-top-5">
              {data.email !== null ? data.email : ''}
            </span>
          </div>
          <div className="flex-column font-size-14 margin-top-25">
            <span className="main-font-light font-color-dark-grey">
              Datum
            </span>
            <span className="main-font-semi-bold font-color-black margin-top-5">
              {data.date !== null ? data.date : ''}
            </span>
          </div>
          <div className="flex-column font-size-14 margin-top-25">
            <span className="main-font-light font-color-dark-grey">
              Bericht
            </span>
            <span className="main-font-semi-bold font-color-black margin-top-5">
              {data.message !== null ? data.message : ''}
            </span>
          </div>                
        </div>
  </Dialog>
  : null
    }
    </React.Fragment>
  )
}


CardDetail.propTypes = {
    data: PropTypes.shape({}),
    handleSwitchClick: PropTypes.func,
    handleViewClick: PropTypes.func
}
CardDetail.defaultProps = {
    data: {},
    handleSwitchClick: function() {},
    handleViewClick: function() {}
}

NotificationView.propTypes = {
    data: PropTypes.shape({}),
    setOpenState: PropTypes.func
}

NotificationView.defaultProps = {
    data: {},
    setOpenState: function() {}
}
