import React, { useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import './_step_3.scss'
import PropTypes from 'prop-types'
import { StatementDetails } from '../../../epb_forms/epb_form_admin/step_three/step_three'

export default function Step3(props) {
  const { workFile, qualityFile, setWorkFile, setQualityFile, stepThreeData, } = props

   useEffect(()=>{
    if(stepThreeData.service_document_categories.length>0 && stepThreeData.service_document_categories[0].service_documents.length>0){
      setWorkFile({id:stepThreeData.service_document_categories[0].service_documents[0].id,viewName:stepThreeData.service_document_categories[0].service_documents[0].fileName, name:stepThreeData.service_document_categories[0].service_documents[0].documentTitle, date: stepThreeData.service_document_categories[0].service_documents[0].createdAt})
    }
    if(stepThreeData.service_document_categories.length>1 && stepThreeData.service_document_categories[1].service_documents.length>0){
      setQualityFile({id:stepThreeData.service_document_categories[1].service_documents[0].id,viewName:stepThreeData.service_document_categories[1].service_documents[0].fileName, name:stepThreeData.service_document_categories[1].service_documents[0].documentTitle, date: stepThreeData.service_document_categories[1].service_documents[0].createdAt})
    }
  },[stepThreeData])

  return (
    <div className="height-auto width-auto animatable-400 fade-in-translate">
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <div className="vertical-center">
              <span className="main-font-regular font-color-black font-size-16 margin-left-10">
                Ventilatieprestatieverslag
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      {
        <Grid container>
          <Grid xs={1} sm={1} md={1} />
          <Grid xs={11} sm={11} md={11}>
            <StatementDetails backendFileName={workFile} selectDate />
          </Grid>
        </Grid>
      }
     <div className="ventilation-service-blue-strip margin-top-30 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <div className="vertical-center">
              <span className="main-font-regular font-color-black font-size-16 margin-left-10">
                Attest van het kwaliteitskader
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid xs={11} sm={11} md={11}>
          <StatementDetails backendFileName={qualityFile} selectDate />
        </Grid>
      </Grid>
    </div>
  )
}

Step3.propTypes = {
  workFile: PropTypes.object,
  qualityFile: PropTypes.object,
  setWorkFile: PropTypes.func,
  setQualityFile: PropTypes.func,
}

Step3.defaultProps = {
  workFile: {},
  qualityFile: {},
  setWorkFile: function () {},
  setQualityFile: function () {},
}
