import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import { DocumentDropzone } from '../../../epb_forms/epb_form_admin/step_two/step_two'
import PropTypes from 'prop-types'
import './_step_2.scss'

export default function Step2(props) {
  const { token, stepTwoData, projectServiceId } = props
  const [workFileNames, setWorkFileNames] = useState([])
  const [qualityFileNames, setQualityFileNames] = useState([])
  const [selectedFiles, setFiles] = useState([])
  const [workFileFormData,setWorkFileFormData]=useState(new FormData())
  const [qualityFileFormData,setQualityFileFormData]=useState(new FormData())
  
  useEffect(()=>{
    if (JSON.stringify(stepTwoData) !== '{}' && stepTwoData !== undefined) {
      const tempFormData1 = new FormData()
      const tempFormData2 = new FormData()

      let serviceDocumentCategoryId =
        stepTwoData.service_document_categories.length > 0 
            ? stepTwoData.service_document_categories[0].id
          : 0
      let serviceDocumentId =
        stepTwoData.service_document_categories.length > 0
          ? stepTwoData.service_document_categories[0].service_documents
              .length > 0
            ? stepTwoData.service_document_categories[0].service_documents[0].id
            : 0
          : 0
      tempFormData1.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData1.append('projectServiceId', projectServiceId)
      tempFormData1.append('serviceDocumentId', serviceDocumentId)
      
       serviceDocumentCategoryId =
        stepTwoData.service_document_categories.length > 0 
            ? stepTwoData.service_document_categories[1].id
          : 0
       serviceDocumentId =
        stepTwoData.service_document_categories.length > 0
          ? stepTwoData.service_document_categories[1].service_documents
              .length > 0
            ? stepTwoData.service_document_categories[1].service_documents[0].id
            : 0
          : 0
      tempFormData2.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData2.append('projectServiceId', projectServiceId)
      tempFormData2.append('serviceDocumentId', serviceDocumentId)
      setWorkFileFormData(tempFormData1)
      setQualityFileFormData(tempFormData2)
      if (stepTwoData.service_document_categories.length > 0) {
        setWorkFileNames(
          stepTwoData.service_document_categories[0].service_documents,
        )
        setQualityFileNames(
          stepTwoData.service_document_categories[1].service_documents,
        )
      }
    }
  }, [stepTwoData])

  useEffect(()=>{},[workFileNames,qualityFileNames])

  // useEffect(()=>{
  //   if(workFiles.name!==undefined && workFiles.name!==null){      
  //     setWorkFileNames([...workFileNames,workFiles])
  //   }
    
  // },[workFiles])

  // useEffect(()=>{
  //   if(qualityFiles.name!==undefined && qualityFiles.name!==null){
  //     setQualityFileNames([...qualityFileNames,qualityFiles])
  //   }
  // },[qualityFiles])

  return (
    <div className="height-auto width-auto animatable-400 fade-in-translate">
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <div className="vertical-center">
              <p className="main-font-regular font-color-black font-size-16">
                Ventilatievoorontwerp
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      {
        <div className="flex-row width-auto margin-top-10">
          <DocumentDropzone
            fileNames={workFileNames}
            setFileNames={setWorkFileNames}
            selectedFiles={selectedFiles}
            setFiles={setFiles}
            path={`https://backend.develop.bip.flykube.agifly.cloud/service_document/upload_document`}
            formData={workFileFormData}
            token={token}
          />
        </div>
      }
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <span className="main-font-regular font-color-black font-size-16">
              Attest van het kwaliteitskader
            </span>
          </Grid>
        </Grid>
      </div>
      {
        <div className="flex-row width-auto margin-top-10">
          <DocumentDropzone
            fileNames={qualityFileNames}
            setFileNames={setQualityFileNames}
            selectedFiles={selectedFiles}
            setFiles={setFiles}
            path={`https://backend.develop.bip.flykube.agifly.cloud/service_document/upload_document`}
            formData={qualityFileFormData}
            token={token}
          />
        </div>
      }
    </div>
  )
}

Step2.propTypes = {
  workFiles: PropTypes.array,
  qualityFiles: PropTypes.array,
}

Step2.defaultProps = {
  workFiles: [],
  qualityFiles: [],
}
