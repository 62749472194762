import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import './_invoice_new.scss'

export default function InvoiceNew() {
  const [ showAdminList, setShowAdminList ] = useState(false)
  const [ adminList, setAdminList ] = useState([]);
  const [ selectedAdmin, setSelectedAdmin ] = useState(0)
  const [ showUserList, setShowUserList ] = useState(false)
  const [ userList, setUserList ] = useState([])
  const [ selectedUser, setSelectedUser ] = useState(0)
  const [ showServiceList, setShowServiceList ] = useState(false)
  const [ serviceList, setServiceList ] = useState([])
  const [ selectedService, setSelectedService ] = useState(0)
  const [ isCreated, setIsCreated ] = useState(0)

  const history = useHistory()
  const token = localStorage.getItem('authToken')

  useEffect(() => {
    getAdminList();
    getUserList();
    getServiceList();
  }, []);

  function getAdminList() {
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/user/all_admin', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      let newAdminList = response.data.data.filter(admin => admin.roleId === 4);
      setAdminList(newAdminList)
    }).catch(error => {
    })
  }

  function getUserList() {
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/user/all_users', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      let newUserList = response.data.filter(user => user.roleId < 3);
      setUserList(newUserList)
    }).catch(error => {
    })
  }

  function getServiceList() {
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/service/all', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      setServiceList(response.data)
    }).catch(error => {
    })
  }

  function createNewFacturate() {
    if(selectedUser !== 0 && selectedAdmin !== 0 && selectedService !== 0){
      axios.post('https://backend.develop.bip.flykube.agifly.cloud/invoice/ceate_from_facturate', {
        buildingData: {
          userId: selectedUser.id,
          companyId: selectedUser.company.id,
          title: `${selectedUser.company.companyAddress} ${selectedUser.company.no} ${selectedUser.company.bus !== null && "/" + selectedUser.company.bus}`,
          address: selectedUser.company.companyAddress,
          city: selectedUser.company.city, 
          zip: selectedUser.company.postalCode,
          fileName: `${selectedUser.company.companyAddress} ${selectedUser.company.no} ${selectedUser.company.bus !== null && "/" + selectedUser.company.bus}`,
          shift: '',
          typeOfProperty: '',
          caseNumber: '',
          performer: '',
          phoneNumber: selectedUser.company.generalTelephone
        },
        serviceId: selectedService.id,
        isbilling: 1,
        assigned_admin_id: selectedAdmin.id,
        assigned_user_id: selectedUser.id
      }, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        setIsCreated(1)
        setTimeout(() => {
          setIsCreated(0)
          history.go(0)
        }, 2000)
      }).catch(error => {
      })
    }
    else {
      setIsCreated(2)
      setTimeout(() => {
        setIsCreated(0)
      }, 2000)
    }
  }

  function handleClientChange(selectedUser){
    setIsCreated(0)
    setSelectedUser(selectedUser)
  }

  function handleCompanyChange(selectedUser){
    setIsCreated(0)
    setSelectedAdmin(selectedUser)
  }

  function handleServiceChange(selectedService){
    setIsCreated(0)
    setSelectedService(selectedService)
  }
  
  return (
    {},
    (
      <div className = "height-auto width-auto padding-15">
        <Grid container item sm = {12}>
          <Grid container item sm = {12}>
            <p className = "main-font-semi-bold font-size-18 font-color-black">Nieuwe Factuur</p>
          </Grid>
          <Grid container item sm = {12}>
            <div className = "margin-top-15" />
          </Grid>
          <Grid
            container
            xs={6}
            sm={6}
            md={6}
            justify="center"
            alignItems="flex-start"
            className = "cursor-pointer relative"
            onClick = {() => { setShowAdminList(!showAdminList); }}
          >
            <Grid container >
              <p className="main-font-bold font-size-14 font-color-black width-auto margin-left-3 line-height-16">
                {selectedAdmin !== 0 ? selectedAdmin.company.name : 'Pick a company'}
              </p>
              <p className="main-font-regular font-color-black font-size-14 width-auto margin-left-3 line-height-16">
                {`BTW nummer : ${selectedAdmin !== 0 ? selectedAdmin.company.BTW : 'XXXXX'}`}
              </p>
              <p className="main-font-regular font-size-14 font-color-black width-auto margin-left-3 line-height-16">
                {`${selectedAdmin === 0 ? 'Sample Address Here' : `${selectedAdmin.company.companyAddress} ${selectedAdmin.company.no !== null && selectedAdmin.company.no} ${selectedAdmin.company.bus !== null && `/ ${selectedAdmin.company.bus}`}`}`}
              </p>
              <div className='flex-row width-auto'>
                <p className="main-font-regular font-size-14 font-color-black margin-left-3 line-height-16">
                  {selectedAdmin !== 0 ? selectedAdmin.company.postalCode : 'XXXX'}
                </p>
                <p className="main-font-regular font-size-14 font-color-black margin-left-3 line-height-16">
                  {selectedAdmin !== 0 ? selectedAdmin.company.city : 'City'}
                </p>
              </div>
            </Grid>
            <div className = "admin-picker-container animatable-400 ease-in-out-quart" style = {{opacity: showAdminList ? 1 : 0}}>
              <div className = "admin-picker-modal animatable-400 ease-in-out-quart" style = {{height: showAdminList ? `${adminList.length < 6 ? adminList.length * 90 : 450}px` : '0px'}}>
                {adminList.length > 0 && adminList.map((item, index) => (
                  <div className = "admin-picker-item vertical-center" onClick = {() => { handleCompanyChange(adminList[index]) }} >
                    <div className = "admin-picker-profile">
                      {item.company.profile_picture !== null && (
                        <img alt="profile" className="height-auto width-auto object-fit-cover" src={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${item.company.profile_picture}`} />
                      )}
                    </div>
                    <div className = "flex-column">
                      <p className = "main-font-semi-bold font-size-14 font-color-navy-blue">{item.company.Name}</p>
                      <p className = "main-font-regular font-size-14 font-color-black">{`${item.firstName} ${item.lastName}`}</p>
                      <p className = "main-font-regular font-size-14 font-color-light-grey">{item.email}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid
            container
            xs={6}
            sm={6}
            md={6}
            justify="center"
            alignItems="flex-start"
            className = "cursor-pointer relative"
            onClick = {() => { setShowUserList(!showUserList); }}
          >
            <Grid container>
              <p className="main-font-bold font-size-14 font-color-black width-auto margin-left-3 line-height-16">
                {selectedUser !== 0 ? selectedUser.company.name : 'Pick a company'}
              </p>
              <p className="main-font-regular font-size-14 font-color-black width-auto margin-left-3 line-height-16">
                {`BTW nummer : ${selectedUser !== 0 ? selectedUser.company.BTW : 'XXXXXXX'}`}
              </p>
              <p className="main-font-regular font-size-14 font-color-black width-auto margin-left-3 line-height-16">
                {`${selectedUser === 0 ? 'Sample Address Here' : `${selectedUser.company.companyAddress} ${selectedUser.company.no !== null && selectedUser.company.no} ${selectedUser.company.bus !== null && ` / ${selectedUser.company.bus}`}`}`}
              </p>
              <div className='flex-row width-auto'>
                <p className="main-font-regular font-size-14 font-color-black margin-left-3 line-height-16">
                  {selectedUser !== 0 ? selectedUser.company.postalCode : 'XXXX'}
                </p>
                <p className="main-font-regular font-size-14 font-color-black margin-left-3 line-height-16">
                  {selectedUser !== 0 ? selectedUser.company.city : 'City'}
                </p>
              </div>
            </Grid>
            <div className = "admin-picker-container animatable-400 ease-in-out-quart" style = {{opacity: showUserList ? 1 : 0}}>
              <div className = "admin-picker-modal animatable-400 ease-in-out-quart" style = {{height: showUserList ? `${userList.length < 6 ? userList.length * 90 : 450}px` : '0px'}}>
                {userList.length > 0 && userList.map((item, index) => (
                  <div className = "admin-picker-item vertical-center" onClick = {() => { handleClientChange(userList[index]) }} >
                    <div className = "admin-picker-profile">
                      {item.company !== null && item.company.profile_picture !== null && (
                        <img alt="profile" className="height-auto width-auto object-fit-cover" src={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${item.company.profile_picture}`} />
                      )}
                    </div>
                    <div className = "flex-column">
                      <p className = "main-font-semi-bold font-size-14 font-color-navy-blue">{item.company !== null && item.company.Name}</p>
                      <p className = "main-font-regular font-size-14 font-color-black">{`${item.company !== null && item.firstName} ${item.company !== null && item.lastName}`}</p>
                      <p className = "main-font-regular font-size-14 font-color-light-grey">{item.company !== null && item.email}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid container item sm = {12}>
            <div className = "margin-top-25" />
          </Grid>
          <Grid container item sm = {6}>
            <div className = "invoice-new-service-container animatable-400 ease-in-out-quart margin-right-5" onClick = {() => { setShowServiceList(!showServiceList); }} >
                <div className = "invoice-new-service flex-row vertical-center horizontal-between">
                  <p className = "main-font-semi-bold font-size-14 font-color-black">{selectedService !== 0 ? selectedService.title : 'select service'}</p>
                  <img src = {require('../../../../assets/svg/icon/arrow_down_grey.svg')} alt = "arrow" className = "invoice-new-service-arrow"/>
                </div>
                <div className = "width-auto animatable-400 ease-in-out-quart overflow-y-scroll background-color-white" style = {{opacity: showServiceList ? 1 : 0, height: showServiceList ? `${serviceList.length < 6 ? serviceList.length * 65 : 210}px` : '0px'}}>
                  {serviceList.length > 0 && serviceList.map((item, index) => (
                    <div className = "admin-picker-item vertical-center" style = {{height: '35px', border: 'none'}} onClick = {() => { handleServiceChange(serviceList[index]) }} >
                      <div className = "flex-column">
                        <p className = "main-font-semi-bold font-size-14 font-color-navy-blue">{item.title !== null && item.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
          </Grid>
          <Grid container item sm = {6} className = "relative">
              <div className = "invoice-new-success center-content margin-left-15 animatable-400 ease-in-out-quart absolute" style = {{opacity: isCreated === 1 ? 1 : 0, transform: isCreated === 1 ? 'translateX(0px)' : 'translateX(-15px)'}}>
                <p className = "main-font-semi-bold font-size-18" style = {{color: '#A5C27D'}}>Success</p>
              </div>
              <div className = "invoice-new-success center-content margin-left-15 animatable-400 ease-in-out-quart absolute" style = {{opacity: isCreated === 2 ? 1 : 0, transform: isCreated === 2 ? 'translateX(0px)' : 'translateX(-15px)'}}>
                <p className = "main-font-semi-bold font-size-18" style = {{color: '#D03A2F'}}>Select the required fields</p>
              </div>
              <button type = "button" className = "invoice-new-create center-content main-font-semi-bold font-size-14 font-color-white margin-left-5 animatable-400 ease-in-out-quart" style = {{opacity: isCreated === 0 ? 1 : 0, transform: isCreated === 0 ? 'translateX(0px)' : 'translateX(-15px)'}} onClick = {() => { createNewFacturate() }}>Creëren</button>
          </Grid>
        </Grid>
      </div>
    )
  )
}
