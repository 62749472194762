import React, { useState,useCallback, useRef, useEffect } from "react";
import { Grid, debounce, Hidden } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { delay } from "lodash";
import axios from "axios";
import { useDropzone } from 'react-dropzone'
import Picker from 'emoji-picker-react';

import "./_messagepanel.scss";
import Header from '../header/header';
import { isLocalDataValid } from '../../shared/shared_functions';

export default function MessagePanel() {
    const [ search, setSearch ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ selectedContact, setSelectedContact ] = useState(0);
    const [ newMessage, setNewMessage ] = useState(false);
    const [ messageConversations, setMessageConversations ] = useState([]);
    const [ messageConversationsPool, setMessageConversationsPool ] = useState([])
    const [ messageList, setMessageList ] = useState([]);
    const [ newContactList, setNewContactList ] = useState([]);
    const [ newSelectedContact, setNewSelectedContact ] = useState(0);
    const [ buildingList, setBuildingList ] = useState([])
    const [ buildingDropdownState, setBuildingDropdownState ] = useState(false)
    const [ selectedBuildingFilter, setSelectedBuildingFilter ] = useState(0);
    const [ currentUserId, setCurrentUserId ] = useState(2); // default value of 2 so that we don't get exceptions
    const [ updateConversation, setUpdateConversation ] = useState(false);
    const [ selectedFiles, setFiles ] = useState([]);
    const [ viewAttachFile, setViewAttachFile ] = useState(false);
    const [ isMessageUpdated, setIsMessageUpdated ] = useState(false); // we use this to manually change the selected index to the last 
    const [ urlParamTriggered, setUrlParamTriggered ] = useState(false)
    const [ urlParamOld, setUrlParamOld ] = useState(0)
    const [buttonDisableState, setButtonDisableState] = useState(false)

    const activeUser = JSON.parse(localStorage.getItem("loggedInUserDetails"));
    const newContactSearchRef = useRef(0);
    const messageLogRef = useRef(0);
    const history = useHistory()
    const { notif } = useParams();

    const [showChosenEmoji, setShowChosenEmoji] = useState(false);
    const [emojiClicked, setEmojiClicked] = useState(false);
    
    const onEmojiClick = (event, emojiObject) => {
        setButtonDisableState(false)
        setMessage(message+""+emojiObject.emoji);
    };

    const handleEmoji=()=>{
        setButtonDisableState(false)
        setShowChosenEmoji(!showChosenEmoji)
    }

    useEffect(() => {
        if(!isLocalDataValid()) {
            localStorage.clear();
            history.push("/")
        }
        else {
            const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
            setCurrentUserId(userId.id);
            getBuildingList()
        }
    }, [])

    useEffect(() => {
        if(isLocalDataValid()) {
            if(selectedContact !== 0){
                getAllConversations();
                if(isMessageUpdated) {
                    setSelectedContact(messageConversationsPool.length);
                    isMessageUpdated && setIsMessageUpdated(false)
                    setTimeout(() => {
                        getAllMessages(messageConversationsPool[selectedContact - 1].id, selectedContact - 1)
                    }, 100);
                } 
                else {
                    getAllMessages(messageConversationsPool[selectedContact - 1].id, selectedContact - 1)
                }
            }
            else {
                getAllConversations();
            }
            delay(() => {
                setUpdateConversation(!updateConversation);
            }, 5000)
        }
    }, [updateConversation])

    useEffect(() => {
        // we only want this to run once, not on every single messageConversation update so we introduce a new variable to disable this condition after the first initial run
        if(messageConversations.length > 0) {
            if(notif && (!urlParamTriggered || notif != urlParamOld)) {
                let selectedContactIndex = null;
                messageConversations.forEach((messageConversation, index) => {
                    if(messageConversation.id == notif) {
                        selectedContactIndex = index + 1;
                    }
                })
                setSelectedContact(selectedContactIndex)
                setUrlParamTriggered(true)
                setUrlParamOld(0);
            }
        }
    }, [messageConversations, notif])

    useEffect(() => {
        if(messageConversations !== messageConversationsPool) {
            // to see if that data has changed or not, then we apply the filter 
            filterMessageConversationPool()
        }
    }, [messageConversations])

    useEffect(() => {
        // this filter applies when a user selects/unselects a filter, it's different from the one above which is designed
        // to filter the data as soon as it updates from the backend 
        setSelectedContact(0)
        setNewSelectedContact(0)
        setNewMessage(false)
        filterMessageConversationPool()
    }, [selectedBuildingFilter, search])

    const handleSearch = (event) => {
        event.preventDefault();
        setSearch(event.target.value);
    }

    const handleMessage = (event) => {
        event.preventDefault();
        setButtonDisableState(false)
        setMessage(event.target.value);
    }

    const handleKeyPress = ({keyCode}) => {
        if(selectedContact !== 0) {
            if(keyCode === 13){
                if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                    pushMessage();
                }
                else{
                    setMessage("")
                }
            }
        }
        
        else if(newSelectedContact !== 0) {
            if(keyCode === 13) {
                if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                    sendNewMessage();
                }
                else{
                    setMessage("")
                }
            }
        }
    }

    const pushMessage = () => {
        sendMessage();
        setViewAttachFile(false)
        let attachmentObj =[]
        let date = new Date();
        for (let i = 0; i < selectedFiles.length; i++) {
            const temp ={
               fileName: selectedFiles[i].name,
               file:selectedFiles[i],
               createdAt:null,
            }
            attachmentObj.push(temp)
        }

        const messageObject = {
            body: message,
            messageAttachment:attachmentObj,
            sender_id: currentUserId,
            sent: true,
            date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}` // insert converted date object to string here
        }
        messageList.push(messageObject)
        setMessage('');
        setIsMessageUpdated(true)
        setTimeout(() => {
            messageLogRef.current.scrollTo({
                top: messageLogRef.current.scrollHeight,
                left: 0,
                behavior: 'smooth'
            })
        }, 100);
    }
    
    const handleAttachment=()=>{
        setButtonDisableState(false)
    }

    const handleCancelAttachment=(index)=>{
        let temp=[]

        selectedFiles.filter((item,ind)=>{
            if(ind !== index){
                temp.push(item)
            }
        })
        if(temp.length == 0){
            setViewAttachFile(false)
        }

        setFiles(temp)
    }

    const onDrop = useCallback((files) => {
        if(selectedFiles.length > 0){
            let temp = {...selectedFiles , files}
            setFiles(temp)
        }else{
            setFiles(files)
        }
        setViewAttachFile(true)
      }, [])
      const accept =
        'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
      const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })
    
    const handleSendButton = () => {
        setButtonDisableState(true)
        if(newSelectedContact === 0) {
            if(selectedContact !== 0) {
                if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                    pushMessage();
                }
                else{
                    setMessage("")
                }
            }
        }
        else {
            if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                sendNewMessage();
            }
            else{
                setMessage("")
            }
        }
    }

    const handleNewMessage = () => {
        setMessageList([])
        setSelectedContact(0);
        setNewMessage(!newMessage);
        setFiles([]);
        setViewAttachFile(false)
    }

    function searchMessageConversation(filteredData) {
        const param = search.toLowerCase();
        if(param === "") {
            setMessageConversationsPool(filteredData)
        }
        else {
            let newMessageConversations = JSON.parse( JSON.stringify(filteredData) )
            const newFilteredMessageConversations = [];
            newMessageConversations.forEach(convo => {
                if(convo.to_user) {
                    const contactName = convo.to_user ? convo.to_user.firstName + " " + convo.to_user.lastName : 'no name';
                    if(convo.projectServices.service.title.toLowerCase().includes(param) || convo.projectServices.project.building.title.toLowerCase().includes(param) || contactName.toLowerCase().includes(param) || convo.to_user?.email.toLowerCase().includes(param) || convo.to_user.company.name.toLowerCase().includes(param)) {
                        newFilteredMessageConversations.push(convo)
                    }
                }
            })
            setMessageConversationsPool(newFilteredMessageConversations)            
        }
    }

    function filterMessageConversationPool() {
        if(selectedBuildingFilter === 0) {
            searchMessageConversation(messageConversations)
        }
        else {
            const newConversations = JSON.parse( JSON.stringify(messageConversations))
            const newList = [];
            newConversations.forEach(item => {
                if(item.projectServices.project.building.id === buildingList[selectedBuildingFilter - 1].id) {
                    newList.push(item);
                }
            })
            searchMessageConversation(newList)
        }
    }

    function getBuildingList() {
        const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/get_conversations_building", {
            from_userId: userId.id
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setBuildingList(response.data.building)
        }).catch(error => {
        })
    }

    function getAllConversations() {
        const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/get_conversations", {
            from_userId: userId.id
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setMessageConversations(response.data)
        }).catch(error => {
        })
    }

    function getAllMessages(conversationId, index) {
        const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/get_messages", {
            message_conversation_id: conversationId,
            sender_id: userId.id,
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setMessageList(response.data);
        }).catch(error => {
        })
    }

    function sendMessage() {
        const formData = new FormData()
        const token = localStorage.getItem('authToken')

        formData.append('message_conversation_id',messageConversationsPool[selectedContact - 1].id)
        formData.append('subject','sample')
        formData.append('text',message)
        formData.append('sender_id',currentUserId)
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('docsInput', selectedFiles[i])
          }
        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/send_email", 
            formData
        , {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setFiles([])
        }).catch(error => {
        })
    }

    function sendNewMessage() {

        const formData = new FormData()
        formData.append('subject','sample')
        formData.append('text',message)
        formData.append('project_service_id',newSelectedContact.buildings.projects.projectServices.id)
        formData.append('from_userId',currentUserId)
        formData.append('to_userId',newSelectedContact.id)
        formData.append('sender_id',currentUserId)
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('docsInput', selectedFiles[i])
          }
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/send_new_email", 
        formData
        , {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setNewSelectedContact(0);
            setMessage("");
            setFiles([])
            setNewMessage(false);
        }).catch(error => {
        })
    }

    const removeNewSelectedContact = () => {
        setNewSelectedContact(0);
    }   

    const handleNewContactSearch = (event) => {
        event.preventDefault();
        setFiles([])
        setViewAttachFile(false)
        searchByEmail(event.target.value)
    }

    const searchByEmail = debounce((email) => {
        if(newSelectedContact === 0) {
            if(email !== "") {
                let token = localStorage.getItem('authToken')
                axios.post('https://backend.develop.bip.flykube.agifly.cloud/message/search_by_smtp_email', {
                    email: email
                }, {
                    headers: {
                        authorization: `bearer ${token}`
                    }
                }).then(response => {
                    setNewContactList(response.data)
                }).catch(error => {
                })
            }
            else {
                setNewContactList([]);
            }
        }
    }, 1000)

    function selectContact(userIndex, buildingIndex, projectIndex, serviceIndex) {
        const contactList = JSON.parse( JSON.stringify(newContactList) );
        let singleContact = contactList[userIndex];
        singleContact.buildings = singleContact.buildings[buildingIndex];
        singleContact.buildings.projects = singleContact.buildings.projects[projectIndex];
        singleContact.buildings.projects.projectServices = singleContact.buildings.projects.projectServices[serviceIndex];

        newContactSearchRef.current.value = "";
        setNewContactList([]);
        setNewSelectedContact(singleContact)
    }

    function cleanTimeString(dateTime) {
        let newDateTime = dateTime.split(" ");
        newDateTime = {
            date: newDateTime[0].split("-"),
            time: newDateTime[1].split(":")
        }

        newDateTime.date = `${newDateTime.date[2]}/${newDateTime.date[1]}/${newDateTime.date[0]}`;
        newDateTime.time = `${newDateTime.time[0]}:${newDateTime.time[1]}`

        return `${newDateTime.date} - ${newDateTime.time}`;
    }

    const handleBuildingButton = () => {
        setBuildingDropdownState(!buildingDropdownState)
    }

    const handleBuildingButtonBlur = () => {
        setBuildingDropdownState(false)
    }

    return(
        <Grid container>
            <Grid container>
                <Hidden smDown>
                    <Header />
                </Hidden>
                <Hidden smUp>
                    <Header toShowHamburger />
                </Hidden>
            </Grid>
            <div className = "message-panel-container">
                <div className = "width-auto flex-row horizontal-center fade-in-right">
                    <Grid container item sm = {11}>
                        <Grid container item sm = {12}>
                            <div className = "margin-top-25" />
                        </Grid>
                        <Grid container item sm = {12}>
                            <div className = "message-panel-search-container vertical-center">
                                <img className = "message-panel-search-icon margin-left-15" alt = "search" src = {require('../../../assets/svg/icon/glass_grey.svg')} />
                                <input className = "message-panel-search main-font-regular font-size-14 font-color-standard-gray margin-left-15" placeholder = "Zoeken" onChange = {handleSearch} />
                            </div>
                            <button type = "button" className = "message-panel-building-button relative center-content" tabIndex = "1" onBlur = {handleBuildingButtonBlur} >
                                <div className = "height-auto width-auto center-content" onClick = {handleBuildingButton}>
                                    <img src = {require('../../../assets/svg/icon/business-and-trade-gray.svg')} alt = "building gray" className = "message-panel-building" />
                                </div>
                                <div className = "message-panel-building-dropdown-container animatable-400 ease-in-out-quart" style = {{height: buildingDropdownState ? `${((buildingList.length + 1) * 35) + 10}px` : '0px'}} >
                                    <div className = "width-auto horizontal-center">
                                        <img className = "message-panel-building-dropdown-arrow" src = {require('../../../assets/svg/icon/corner.png')} alt = "corner" />
                                    </div>
                                    <div className = "message-panel-building-dropdown ">
                                        {buildingList.length > 0 && buildingList.map((item, index) => (
                                            <div className = "width-auto vertical-center horizontal-end message-panel-building-dropdown-item message-panel-building-dropdown-line" onClick = {() => { selectedBuildingFilter !== (index + 1) ? setSelectedBuildingFilter(index + 1) : setSelectedBuildingFilter(0) }} >
                                                <p className = "main-font-regular font-size-14 font-color-black text-align-end">{item !== null && item.title}</p>
                                                <img src = {require('../../../assets/svg/icon/correct.svg')} alt = "check" className = "message-panel-building-dropdown-check margin-left-10 animatable-400 ease-in-out-quart" style = {{opacity: selectedBuildingFilter === index + 1 ? 1 : 0}} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </button>
                            <button type = "button" className = "message-panel-new-message center-content cursor-pointer margin-left-15" onClick = {handleNewMessage} >
                                <h1 className = "main-font-semi-bold font-size-14 font-color-white">Nieuwe bericht</h1>
                            </button>
                        </Grid>
                        <Grid container item sm = {12}>
                            <div className = "margin-top-25" />
                        </Grid>
                        <Grid container item sm = {12}>
                            <div className = "message-panel">
                                <Grid container item sm = {12} className = "height-auto">
                                    <Grid container item sm = {4} className = "height-auto">
                                        <div className = "message-panel-contacts">
                                            {/* new message card */}
                                            <div className = "message-panel-contacts-details overflow-hidden animatable-400 ease-in-out-quart" style = {{height: newMessage ? '87px' : '0px', opacity: newMessage ? 1 : 0, background: newMessage ? '#E5EDF3' : 'rgba(0,0,0,0)'}}>
                                                <div className = "message-panel-contacts-details vertical-center" >
                                                    <div className = "message-panel-contacts-details-notif" style = {{opacity: 0}} />
                                                    <div className = "message-panel-contacts-details-profile" />
                                                    <div className = "flex-column margin-left-15">
                                                        <h1 className = "main-font-bold font-size-14 font-color-navy-blue line-height-14">Nieuwe bericht</h1>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* card data that is rendered dynamically */}
                                            {messageConversationsPool.length > 0 && messageConversationsPool.map((item, index) => (
                                                <div className = "message-panel-contacts-details vertical-center animatable-400" style = {{background: selectedContact === index + 1 ? '#E5EDF3' : 'rgba(0,0,0,0)'}} onClick = {() => { setSelectedContact(index + 1); setNewSelectedContact(0); setNewMessage(false); getAllMessages(item.id, index) }} >
                                                    <div className = "message-panel-contacts-details-notif" style = {{background: item.admin_notification === true ? '#7DA5C2' : 'rgba(0,0,0,0)'}} />
                                                    <div className = "message-panel-contacts-details-profile overflow-hidden">
                                                        {item.to_user !== null && item.to_user.company !== null && (
                                                            <img src = {item.to_user.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${item.to_user.company.profile_picture}` : require('../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-contain" />
                                                        )}
                                                    </div>
                                                    <div className = "flex-column margin-left-15">
                                                        <h1 className = "main-font-bold font-size-14 font-color-navy-blue margin-bottom-1 line-height-14">{item.projectServices !== null && item.projectServices.project.building !== null && item.projectServices.project.building.title}</h1>
                                                        <p className = "main-font-regular font-size-14 font-color-black line-height-14">{item.projectServices !== null && item.projectServices.service.title}</p>
                                                        <p className = "main-font-regular font-size-14 font-color-grey line-height-14">{item.last_updated !== null ? cleanTimeString(item.last_updated) : ''}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                    <Grid container item sm = {8} direction = "column" className = "height-auto">
                                        <div className = "message-panel-log" style = {{height: viewAttachFile === true ? '80%' : '90%'}} ref = {messageLogRef}>
                                            <div className = "flex-row vertical-center message-panel-log-search-container animatable-400 ease-in-out-quart" style = {{height: newMessage ? '70px' : '0px', opacity: newMessage ? 1 : 0,}} >
                                                <div className = "message-panel-log-search margin-left-25 relative animatable-400 ease-in-out-quart" style = {{width: newSelectedContact === 0 ? '360px' : '150px'}}>
                                                    <div className = "height-auto width-auto flex-row vertical-center">
                                                        <img className = "message-panel-log-search-icon margin-left-15" alt = "search" src = {require('../../../assets/svg/icon/glass_grey.svg')} />
                                                        <input className = "width-auto main-font-regular font-size-14 font-color-grey margin-left-15" disabled = {newSelectedContact === 0 ? false : true} placeholder = "Zoek een klant of bedrijf of email schrijven" ref = {newContactSearchRef} onChange = {handleNewContactSearch} />
                                                    </div>
                                                    {newContactList.length > 0 && (
                                                        <div className = "message-panel-log-search-list fade-in-translate">
                                                            {newContactList.map((user, userIndex) => (
                                                                <>
                                                                {user.buildings.map((building, buildingIndex) => (
                                                                    <>
                                                                    {building.projects.map((project, projectIndex) => (
                                                                        <>
                                                                        {project.projectServices.map((service, serviceIndex) => (
                                                                            <div className = "message-panel-log-search-list-item">
                                                                                <div className = "flex-column margin-left-15 margin-top-5 margin-bottom-5" onClick = {() => { selectContact(userIndex, buildingIndex, projectIndex, serviceIndex) }}>
                                                                                    <h1 className = "main-font-bold font-size-14 font-color-navy-blue margin-bottom-1 line-height-14">{building.title}</h1>
                                                                                    <p className = "main-font-regular font-size-14 font-color-black line-height-14">{service.service.title}</p>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        </>
                                                                    ))}
                                                                    </>
                                                                ))}
                                                                </>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                {newSelectedContact !== 0 && (
                                                    <div className = "message-panel-log-search-selected-contact fade-in-translate">
                                                        <div className = "flex-column">
                                                            <h1 className = "main-font-bold font-size-14 font-color-navy-blue margin-disable line-height-14">{newSelectedContact.buildings.title}</h1>
                                                            <p className = "main-font-regular font-size-14 font-color-black line-height-14">{newSelectedContact.buildings.projects.projectServices.service.title}</p>
                                                        </div>
                                                        <div className = "flex-row" onClick = {removeNewSelectedContact} >
                                                            <img src = {require('../../../assets/svg/close.svg')} alt = "close" className = "message-panel-log-search-selected-remove" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {selectedContact !== 0 && messageList.map(item => (
                                                <div className = "flex-row width-auto fade-in-right">
                                                    {item.sender_id !== currentUserId ? (
                                                        <div className = "flex-row padding-25">
                                                            <div className = "message-panel-profile margin-left-15 overflow-hidden">
                                                                {messageConversationsPool[selectedContact - 1].to_user !== null && messageConversationsPool[selectedContact - 1].to_user.company !== null && (
                                                                    <img src = {messageConversationsPool[selectedContact - 1].to_user.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${messageConversationsPool[selectedContact - 1].to_user.company.profile_picture}` : require('../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-contain" />
                                                                )}
                                                            </div>
                                                            <div className = "flex-column">
                                                            {item.body !== "" && (
                                                                <div className = "message-panel-message">
                                                                    <p className = "main-font-regular font-size-12 font-color-white line-height-16">{item.body}</p>
                                                                </div>
                                                            )}
                                                                
                                                            {item.messageAttachment !== null && item.messageAttachment.length > 0 && item.messageAttachment.map((attach , index)=>(
                                                                <>
                                                                    <div className = "message-panel-chatAttachments-from" >
                                                                        <div className = "message-panel-chatAttachments-img" >
                                                                            <a
                                                                            href={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${attach.fileName}`}
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            className="main-font-semi-bold font-size-14 font-color-black"
                                                                            >
                                                                                {attach.fileName.split('.')[1] === "pdf" || attach.fileName.split('.')[1] === "doc" || attach.fileName.split('.')[1] === "docx" || attach.fileName.split('.')[1] === "xls" ? (
                                                                                    <div className = "flex-row vertical-center">
                                                                                        <img src = {attach.fileName.split('.')[1] === 'pdf'
                                                                                        ? require('../../../assets/svg/doc/pdf.svg')
                                                                                        : attach.fileName.split('.')[1].includes('doc') || attach.fileName.split('.').includes('docx')
                                                                                        ? require('../../../assets/svg/doc/doc.svg')
                                                                                        : attach.fileName.split('.')[1].includes('xls')
                                                                                        ? require('../../../assets/svg/doc/xls.svg') : null}
                                                                                        alt = "attachment pic" className = "message-panel-chatAttachments-file" /> 
                                                                                        <p className = "main-font-bold font-size-14 font-color-white margin-left-10">{attach.fileName.substring(13, attach.fileName.length)}</p>
                                                                                    </div>
                                                                                ) : (
                                                                                    <img src = {attach.fileName.split('.')[1].includes('jpg')
                                                                                        ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                                        : attach.fileName.split('.')[1].includes('png') || attach.fileName.split('.')[1].includes('svg')
                                                                                        ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                                        : null}
                                                                                        alt = "attachment pic" className = "height-auto width-auto object-fit-cover" />
                                                                                )}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    </>
                                                                ))}
                                                                {messageConversationsPool[selectedContact - 1].to_user !== null && (
                                                                    <p className = "main-font-regular font-size-9 font-color-grey margin-left-25 margin-top-5">{`${messageConversationsPool[selectedContact - 1].to_user.firstName} ${messageConversationsPool[selectedContact - 1].to_user.lastName}`} - {cleanTimeString(item.date)}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className = "flex-row width-auto horizontal-end padding-25 fade-in-left">
                                                            <div className = "flex-column">
                                                               {item.body !== "" && (
                                                                <div className = "message-panel-message-alt flex-row horizontal-end">
                                                                    <p className = "main-font-regular font-size-12 font-color-white line-height-16">{item.body}</p>
                                                                </div>
                                                               )}
                                                                { item.messageAttachment !== null && item.messageAttachment.length > 0 && item.messageAttachment.map((attach , index)=>(
                                                                    <>
                                                                        <div className = "message-panel-chatAttachments-to" >
                                                                            <div className = "message-panel-chatAttachments-img" >
                                                                            <a
                                                                                href={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${attach.fileName}`}
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                className="main-font-semi-bold font-size-14 font-color-black"
                                                                            >
                                                                                {attach.fileName.split('.')[1] === "pdf" || attach.fileName.split('.')[1] === "doc" || attach.fileName.split('.')[1] === "docx" || attach.fileName.split('.')[1] === "xls" ? (
                                                                                    <div className = "flex-row vertical-center">
                                                                                        <img src = {attach.fileName.split('.')[1] === 'pdf'
                                                                                        ? require('../../../assets/svg/doc/pdf.svg')
                                                                                        : attach.fileName.split('.')[1].includes('doc') || attach.fileName.split('.').includes('docx')
                                                                                        ? require('../../../assets/svg/doc/doc.svg')
                                                                                        : attach.fileName.split('.')[1].includes('xls')
                                                                                        ? require('../../../assets/svg/doc/xls.svg') : null}
                                                                                        alt = "attachment pic" className = "message-panel-chatAttachments-file" /> 
                                                                                        <p className = "main-font-bold font-size-14 font-color-white margin-left-10">{attach.fileName.substring(13, attach.fileName.length)}</p>
                                                                                    </div>
                                                                                ) : (
                                                                                    <img src = {attach.fileName.split('.')[1].includes('jpg')
                                                                                        ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                                        : attach.fileName.split('.')[1].includes('png') || attach.fileName.split('.')[1].includes('svg')
                                                                                        ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                                        : null}
                                                                                        alt = "attachment pic" className = "height-auto width-auto object-fit-cover" />
                                                                                )}
                                                                            </a>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                 {messageConversationsPool[selectedContact - 1].from_user !== null && (
                                                                    <div className = "flex-row width-auto horizontal-between vertical-center margin-top-5">
                                                                        <div className = "flex-row">
                                                                            {item.sent ? (
                                                                                <img src = {require('../../../assets/svg/icon/loading.gif')} alt = "loading" className = "message-panel-log-status" />
                                                                            ) : (
                                                                                <img src = {require('../../../assets/svg/icon/check_blue.svg')} alt = "checked" className = "message-panel-log-status" />
                                                                            )}
                                                                        </div>
                                                                        <div className = "flex-row">
                                                                            <p className = "main-font-regular font-size-9 font-color-grey margin-right-25 margin-top-5">{`${messageConversationsPool[selectedContact - 1].from_user.firstName} ${messageConversationsPool[selectedContact - 1].from_user.lastName}`} - {cleanTimeString(item.date)}</p>
                                                                        </div>
                                                                    </div>
                                                                 )}
                                                            </div>
                                                            <div className = "message-panel-profile margin-left-15 overflow-hidden">
                                                                {messageConversationsPool[selectedContact - 1].from_user !== null && messageConversationsPool[selectedContact - 1].from_user.company !== null && (
                                                                    <img src = {`https://backend.develop.bip.flykube.agifly.cloud/uploads/${messageConversationsPool[selectedContact - 1].from_user.company.profile_picture}`} alt = "profile pic" className = "height-auto width-auto object-fit-contain" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                               
                                        </div>
                                        {viewAttachFile === true && (
                                            <div className = "message-panel-attachments margin-top-10 fade-in-translate">
                                                {selectedFiles.map((item , index)=>(
                                                    <div className="message-panel-attachments-section">
                                                        <div className = "message-panel-attachments-img" >
                                                            <a
                                                            href={URL.createObjectURL(item)}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                            className="main-font-semi-bold font-size-14 font-color-black"
                                                            >
                                                                <img src = {item.path.split('.')[1] === 'pdf'
                                                                            ? require('../../../assets/svg/doc/pdf.svg')
                                                                            : item.path.split('.')[1].includes('doc')
                                                                            ? require('../../../assets/svg/doc/doc.svg')
                                                                            : item.path.split('.')[1].includes('xls')
                                                                            ? require('../../../assets/svg/doc/xls.svg')
                                                                            : item.path.split('.')[1].includes('jpg')
                                                                            ? URL.createObjectURL(item)
                                                                            : item.path.split('.')[1].includes('png')
                                                                            ? URL.createObjectURL(item)
                                                                            : require('../../../assets/svg/doc/other.svg')}
                                                                            alt = "attachment pic" className = "height-auto width-auto object-fit-cover" />
                                                            </a>
                                                        </div>
                                                        <div className = "message-panel-attachments-cross">
                                                            <img src={require('../../../assets/svg/close.svg')} alt="close" onClick = {()=>handleCancelAttachment(index)}/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {(newMessage || selectedContact !== 0) && (
                                            <div className = "message-panel-input-container flex-row vertical-center flex-shrink-disable fade-in-bottom ">
                                                <div className = "message-panel-input-profile margin-left-25 margin-right-15 flex-shrink-disable overflow-hidden">
                                                    {activeUser !== null && activeUser.company !== null && activeUser.company.profile_picture !== null && (
                                                            <img src = {activeUser.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${activeUser.company.profile_picture}` : require('../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-contain" />
                                                     )}
                                                </div>
                                                <div className = "message-panel-input vertical-center flex-row">
                                                    <div className = "message-panel-input-emoji flex-shrink-disable outline-none" tabIndex = "1" onBlur={()=>{if(emojiClicked === false){setShowChosenEmoji(false)}}}>
                                                        <img src = {require('../../../assets/svg/icon/emoji.svg')} alt = "emoji" className = "height-auto width-auto object-fit-contain" onClick={handleEmoji}/>
                                                        <div className="message-panel-emoji" onMouseEnter={()=>{setEmojiClicked(true)}} onMouseLeave={()=>{setEmojiClicked(false)}}  style = {{display: showChosenEmoji ? 'flex' : 'none'}}>
                                                            <Picker onEmojiClick={onEmojiClick} />
                                                        </div>
                                                    </div>
                                                    <input className = "width-auto main-font-regular font-size-12 font-color-grey line-height-16 italic" placeholder = "schrijf hier iets" onChange = {handleMessage} onKeyDown = {handleKeyPress} value = {message} />
                                                    <div {...getRootProps({ style: { outline: 'none' } })}>
                                                        <img src = {require('../../../assets/svg/icon/attachment.svg')} className = "message-panel-input-attachment" alt = "attachment flex-shrink-disable" onClick = {handleAttachment} />
                                                        <input className="height-auto width-auto" {...getInputProps()} />
                                                    </div>
                                                </div>
                                                <button type = "button" className = "message-panel-input-button margin-right-25 margin-left-15 flex-shrink-disable center-content" onClick = {handleSendButton} disabled = {buttonDisableState} >
                                                    <img src = {require('../../../assets/svg/icon/send.svg')} alt = "paper plane" className = "message-panel-input-aero" />
                                                    <h1 className = "main-font-semi-bold font-size-12 font-color-white margin-left-10">Verzenden</h1>
                                                </button>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Grid>
    )
}