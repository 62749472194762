import React, { useState, useEffect } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import AddClient from './add_client/add_client'
import ViewClient from './view_client/view_client.js';
import ViewPrivateClient from "./view_private_client/view_private_client";
import PrivateClient from './private_client/private_client.js';
import AddContactPerson from './add_contact_person/add_contact_person.js';
import './_client.scss';
import EditIcon from '../../assets/svg/icon_edit_grey.svg'
import CheckIcon from '../../assets/svg/icon_check_white.svg'
import { addClientServiceForm } from '../shared/sample_data'
import axios from 'axios'
import PropTypes from 'prop-types'
import Popup from '../shared/popup/custom_popup'
import Header from "../dashboard/header/header";

export default function Client(props) {
    const { isCompany, companyId, isView, comment, isPrivate } = props;
    const [tabActiveState, setTabActiveState] = useState(1)
    const [companyIdState, setCompanyIdState] = useState(0)
    const [contactIdState, setContactIdState] = useState(0)
    const [showResponseState, setShowResponseState] = useState(false)
    const [responseMessageState, setResponseMessageState] = useState('')
    const [openServiceFormState, setOpenServiceFormState] = useState(false)
    const [serviceListState, setServiceListState] = useState([])
    const [viewState, setViewState] = useState(isView)

    const token = localStorage.getItem('authToken')

    useEffect(() => {
        if (companyId !== undefined) {
            setCompanyIdState(companyId)
        }
        else {
            setCompanyIdState(0)
        }
        axios.post(
            `https://backend.develop.bip.flykube.agifly.cloud/service/all`, {},
            { headers: { authorization: `bearer ${token}` } },
          )
          .then((response) => {
              const responseData = response.data
              if (responseData !== null && responseData !== undefined) {
                  if (responseData.length > 0) {
                      const services = responseData.map(item => {
                          return {id: item.id, title: item.title}
                      })
                      if (services !== null) {
                        setServiceListState(services)
                      }
                  }
              }
          })
          .catch((error) => {

          })
    }, [])

    const handleTabClick = (tabValue) => {
        setTabActiveState(tabValue)
    }
    

    return(
        <Grid container>
            <Grid container>
                <Hidden smDown>
                    <Header />
                </Hidden>
                <Hidden smUp>
                    <Header toShowHamburger />
                </Hidden>
            </Grid>
            <div className="client-main-div">
                {
                    isCompany === true ?
                    null
                    :
                    <Grid container alignItems="center" justify="center" className = "fade-in-right">
                        <div className="client-tab-main">
                            <div className={`client-tab-add
                                ${tabActiveState === 1 ? 'client-tab-active' : 'client-tab-inactive'}`}
                                onClick={() => handleTabClick(1)}>
                                Add
                            </div>
                            <div className= {`client-tab-import
                                ${tabActiveState === 2 ? 'client-tab-active' : 'client-tab-inactive'}`}
                                onClick={() => handleTabClick(2)}>
                                Import
                            </div>
                        </div>
                    </Grid>
                }
                
                {
                    tabActiveState === 1 ?
                    <Grid container className="margin-top-20 fade-in-right">
                        {
                            isPrivate === false ?
                                <Grid container item xs={12} sm={12} md={7} lg={7}>
                                    <div className="height-auto width-auto margin-top-10">
                                {
                                    viewState === true ?
                                    <ViewClient companyId={companyIdState} viewState={setViewState}/>
                                    :
                                    <AddClient setCompanyId={setCompanyIdState} setContactId={setContactIdState} companyId={companyIdState}
                                        setShowService={setOpenServiceFormState}/>
                                }
                                    </div>
                                </Grid>
                            :
                            <Grid container item xs={12} sm={12} md={4} lg={4}>
                                <div className="height-auto width-auto margin-top-10">
                                {viewState === true ? 
                                    <ViewPrivateClient companyId={companyIdState} setViewState = {setViewState} />
                                : 
                                    <PrivateClient setCompanyId={setCompanyIdState} setContactId={setContactIdState} companyId={companyIdState}
                                        setPopupState={setShowResponseState} setShowService={setOpenServiceFormState}
                                        setPopupMessageState={setResponseMessageState}/>
                                }
                                
                                </div>
                            </Grid>
                        }
                        <Grid container item xs={12} sm={12} md={4} lg={4}>
                            <div className="height-auto width-auto margin-top-10 margin-left-15">
                                <Grid container item xs={12}>
                                    <AddContactPerson contactId={contactIdState} companyId = {companyIdState} setShowPopup={setShowResponseState}
                                        setPopupMessage={setResponseMessageState}/>
                                </Grid>
                                <Grid container item xs={12}>
                                    <CompanyComment companyId={companyIdState} isView={isView} comment={comment} setShowPopup={setShowResponseState}
                                            setPopupMessage={setResponseMessageState}/>
                                </Grid>
                                {
                                    openServiceFormState === true ?
                                    <Grid container item xs={12}>
                                        <CompanyService services={serviceListState}/>
                                    </Grid>
                                    :
                                    null
                                }
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <Grid container className="margin-top-20">
                        <Grid container item xs={12} sm={12} md={6} lg={6}>
                            <Grid container>
                                <div>
                                    <button className="client-button-blue cursor-pointer">
                                        Download template
                                    </button>
                                </div>
                            </Grid>
                            <Grid container className="margin-top-20">
                                <div>
                                    <div className="main-font-bold font-color-black">
                                        Upload klant listing
                                    </div>
                                </div>
                            </Grid>
                            <Grid container className="margin-top-20">
                                <div className="width-auto height-15">
                                    <div className="client-child-div">
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                
                {
                    showResponseState === true ?
                    <Popup data={responseMessageState}/>
                    : null
                }
            </div>
        </Grid>        
    )
}

Client.propTypes = {
    isCompany: PropTypes.bool,
    companyId: PropTypes.number,
    isView: PropTypes.bool,
    comment: PropTypes.string,
    isPrivate: PropTypes.bool
}
  
Client.defaultProps = {
    isCompany: true,
    companyId: 0,
    isView: false,
    comment: '',
    isPrivate: false
}

const CompanyComment = (props) => {
    const { companyId, isView, comment, setShowPopup, setPopupMessage } = props
    const [commentOpenState, setCommentOpenState] = useState(false)
    const [commentValueState, setCommentValueState] = useState({value: '', error: ''})
    const [ animateState, setAnimateState] = useState(false)
    const [viewState, setViewState] = useState(isView)

    const token = localStorage.getItem('authToken')

    useEffect(() => {
        if (viewState === true) {
            setCommentOpenState(true)
        }
        const prevCommentState = {...commentValueState}
        if(prevCommentState !== undefined && prevCommentState !== null) {
            prevCommentState.value = comment
            setCommentValueState(prevCommentState)
        }
    }, [])

    const handleCommentChange = (event) => {
        const commentState = {...commentValueState}
        if (event !== undefined) {
            const eventValue = event.target.value 
            commentState.value = eventValue
        }
        else {
            commentState.value = ''
        }
        setCommentValueState(commentState)
    }
    const handleAddCommentClick = () => {
        setCommentOpenState(true)
    }
    const handleSubmitComment = () => {
        setAnimateState(true)
        let commentState = {...commentValueState}
        if (commentState !== undefined) {
            if (commentState.value.trim() !== '') {
                if (commentState.value.length > 140) {
                    commentState.error = 'Field only have 140 characters'
                }
                else {
                    const apiData = {
                        id: companyId,
                        comments: commentState.value
                    }
                    commentState.error = ''
                    axios.post(
                        `https://backend.develop.bip.flykube.agifly.cloud/admin/company/add_comments`,
                        apiData,
                        { headers: { authorization: `bearer ${token}` } },
                      )
                      .then((response) => {
                          const responseData = response.data
                        if (responseData.success === true) {
                            const messageData = {
                                isSuccess: true,
                                heading: 'Comment added',
                                message: 'data saved successfully',
                                popupState: setShowPopup
                            }
                            setPopupMessage(messageData)
                            setShowPopup(true)
                        }
                        else {
                            const messageData = {
                                isSuccess: false,
                                heading: 'Comment not saved',
                                message: 'something went wrong',
                                popupState: setShowPopup
                            }
                            setPopupMessage(messageData)
                            setShowPopup(true)
                        }
                      })
                      .catch((error) => {
                        const messageData = {
                            isSuccess: false,
                            heading: 'Comment not saved',
                            message: 'error saving record',
                            popupState: setShowPopup
                        }
                        setPopupMessage(messageData)
                        setShowPopup(true)
                      })
                }
            }
            else {
                commentState.error = 'Comment should not be empty'
            }
            setCommentValueState(commentState)
        }
        setTimeout(() => {
            setAnimateState(false)
        }, 2000)
    }
    const handleEditCommentClick = () => {
        setViewState(false)
    }

    return(
        <div className="width-auto height-auto">
        <div className="client-child-div margin-top-20">
            <div className="main-font-semi-bold font-color-black font-size-18">
                Notities
            </div>
            {
                (commentOpenState === false) ?
                <div className="center-content margin-top-20 font-size-14">
                    <button className="client-add-button font-color-grey" onClick={handleAddCommentClick}>
                        <img src={EditIcon} className="client-button-icon" alt=""/>
                        Add comment
                    </button>
                </div>
                :
                <React.Fragment>
                    {
                        (viewState === true) ?
                            <div className="font-size-14">
                                <span className="main-font-regular font-color-grey margin-top-20">
                                    Breschrijving
                                </span>
                                <p className="main-font-semi-bold font-color-black margin-top-10">
                                    {commentValueState.value}
                                </p>
                            </div>
                        :
                    <div className="font-size-14">
                        <span className="main-font-regular font-color-grey margin-top-20">
                            Breschrijving
                            {(commentValueState.error.length > 0 && animateState === true) ? (
                                <span className="font-color-red">*</span>
                            ) : null}
                            </span>
                            <textarea
                            placeholder=""
                            value={commentValueState.value}
                            onChange={(event) => handleCommentChange(event)}
                            className={`client-textarea margin-top-10 
                                                    ${
                                                        (commentValueState.error.length > 0 && animateState === true)
                                                        ? 'input-error-border'
                                                        : ''
                                                    } `}
                            />
                            {commentValueState.error.length > 0 && animateState === true ? (
                                <span
                                className= 'font-color-red font-size-14 margin-left-10 margin-top-5'
                                >
                                {commentValueState.error}
                                </span>
                            ) : null}
                        </div>
                        }   
                        {
                            (companyId === 0 && animateState === true) ?
                            <div>
                                <span className= 'font-color-red font-size-14 margin-left-10 margin-top-5'>
                                    Please add company first
                                </span>
                            </div>
                            :
                            null
                        }
                        {
                            (viewState === true) ?
                            <div className="horizontal-end margin-top-20">
                                    <button
                                    className="client-edit-button font-color-grey"
                                    onClick={() => handleEditCommentClick()}
                                    >
                                    <img src={EditIcon} className="client-button-icon" alt="" />
                                    Edit
                                    </button>
                            </div>
                            :
                        <div className="margin-top-10">
                            <div className="add-client-div-save font-size-14">
                                <button className="add-client-button-save" onClick={handleSubmitComment}>
                                    <img src={CheckIcon} className="add-client-check-icon" alt=""/>
                                    Save
                                </button>
                            </div>
                        </div>
                        }
                </React.Fragment>
            }
            
        </div>
    </div>
    )
}

CompanyComment.propTypes = {
    companyId: PropTypes.number,
    isView: PropTypes.bool,
    comment: PropTypes.string,
    setShowPopup: PropTypes.func,
    setPopupMessage: PropTypes.func
}
  
CompanyComment.defaultProps = {
    companyId: 0,
    isView: false,
    comment: '',
    setShowPopup: function() {},
    setPopupMessage: function() {}
}

const CompanyService = (props) => {
    const { services } = props
    const [animateState, setAnimateState] = useState(false)
    const [serviceFormState, setServiceFormState] = useState(addClientServiceForm)

    useEffect(() => {
        const prevServiceFormState = [...serviceFormState]
        if (prevServiceFormState !== null && prevServiceFormState !== undefined) {
            const serviceControl = prevServiceFormState.find(a => a.name === 'serviceId')
            if (serviceControl !== null && serviceControl !== undefined) {
                serviceControl.options = JSON.parse(JSON.stringify(services))
                setServiceFormState(prevServiceFormState)
            }
        }
    }, [services])

    const handleServiceInputChange = (item, event) => {
        const prevState = [...serviceFormState]
        let formControlObj = prevState.find(a => a.id === item.id)
        if (formControlObj !== null && formControlObj !== undefined) {
            if (event !== undefined) {
                const eventValue = event.target.value
                formControlObj.value = eventValue;
            }
            else {
                formControlObj.value = ''
            }
        }
        setServiceFormState(prevState)
    }

    const handleServiceSubmit = () => {
        const prevFormState = [...serviceFormState]
        let isValid = true
        setAnimateState(true)
        prevFormState.forEach(item => {
            if (item.value.toString().trim() !== '' && item.value.toString().trim() !== '0') {
                item.errorValue = ''
            }
            else {
                isValid = false
                item.errorValue = 'Field required'
            }
        })
        if (isValid === true) {
            
        }
        else {
            setServiceFormState(prevFormState)
        }
        setTimeout(() => {
            setAnimateState(false)
        }, 2000)
    }

    return (
      <div className="client-child-div margin-top-20">
        <div className="main-font-semi-bold font-color-black font-size-14">
          Dienst
        </div>
        {serviceFormState !== null && serviceFormState !== undefined
          ? serviceFormState.map((item) => {
              return item.type === 'select' ? (
                <Grid container key={item.id} className="font-size-14">
                  <span className="main-font-regular font-color-grey margin-top-10 font-size-14">
                    {item.label}
                    {item.errorValue.length > 0 &&
                    animateState === true ? (
                      <span className="font-color-red">*</span>
                    ) : null}
                  </span>
                  <select
                    className={`input-custom-control margin-top-10 
                    ${
                        item.errorValue.length > 0 && animateState === true
                        ? 'input-error-border' : ''
                    } `}
                    value={item.value}
                    onChange={(event) => handleServiceInputChange(item, event)}
                  >
                    <option selected disabled hidden value=""></option>
                    {item.options.map((option) => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      )
                    })}
                  </select>
                  {item.errorValue.length > 0 &&
                  animateState === true ? (
                    <span className="font-color-red font-size-14 margin-left-10 margin-top-5">
                      {item.errorValue}
                    </span>
                  ) : null}
                </Grid>
              ) : (
                <Grid container key={item.id} className="font-size-14">
                  <span className="main-font-regular font-color-grey margin-top-10">
                    {item.label}
                    {item.errorValue.length > 0 &&
                    animateState === true ? (
                      <span className="font-color-red">*</span>
                    ) : null}
                  </span>
                  <input
                    placeholder={item.placeholder}
                    type={item.type}
                    value={item.value}
                    onChange={(event) => handleServiceInputChange(item, event)}
                    className={`input-custom-control margin-top-10 
                    ${
                        item.errorValue.length > 0 &&
                        animateState === true
                        ? 'input-error-border'
                        : ''
                    } `}
                  />
                  {item.errorValue.length > 0 &&
                  animateState === true ? (
                    <span className="font-color-red font-size-14 margin-left-10 margin-top-5">
                      {item.errorValue}
                    </span>
                  ) : null}
                </Grid>
              )
            })
          : null}
        <div className="margin-top-10">
          <div className="add-client-div-save font-size-14">
            <button
              className="add-client-button-save"
              onClick={handleServiceSubmit}
            >
              <img src={CheckIcon} className="add-client-check-icon" alt="" />
              Save
            </button>
          </div>
        </div>
      </div>
    )
}

CompanyService.propTypes = {
    services: PropTypes.array
}
CompanyService.defaultProps = {
    services: []
}
