import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react'
import { Grid } from '@material-ui/core'
import './_custom_select.scss'

export const CustomSelect = forwardRef((props, ref) => {
  const { label, data, name, value ,setParentSelectedValue, disabled, alignTextRight, handleChange, style, gridStyle} = props
  const [selectedValue, setValue] = useState(0)
  useEffect(() => {
    if (value !== undefined && value!==null) {
      if (/[^$,.\d]/.test(value)){
        if(typeof(value)==='boolean'){
          if(value){
            setValue(2)
            if(handleChange!==undefined){
              handleChange({name:name,value:2})
            }  
          }
          else{
            setValue(1)
            if(handleChange!==undefined){
              handleChange({name:name,value:1})
            }
          }
        }
        else{
          if(typeof value === 'string'){
            let id=data.find(item=>item.title===value).id
            setValue(id)
            if(handleChange!==undefined){
              handleChange({name:name,value:id.toString()})
            }
          }
          else if(value > 0){
            setValue(value)
          }
        }
      }
      else{
        setValue(value)
      }
    }
  }, [value,handleChange])

  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValue
    },
  }))
  const handleChangeSelect = (event) => {
    setValue(event.target.value)
    if(setParentSelectedValue!==undefined){
      setParentSelectedValue(parseInt(event.target.value))
    }
  }
  return (
    <Grid container alignItems = {alignTextRight && 'flex-end'} style = {gridStyle} >
      <div className="flex-row">
        <p className="custom-select-label">{label}</p>
      </div>
      <select
        disabled={disabled}
        value={selectedValue}
        onChange={handleChangeSelect}
        className={`custom-input-box
                                ${
                                  selectedValue === 0
                                    ? //   &&submitState === true
                                      'input-error-border'
                                    : ''
                                } `}
        style = {style}
      >
        <option disabled hidden value={0}></option>
        {data !== undefined && data.length > 0 && data[0].length>0
          ? data[0].map((item) => (
              // return (
              <option value={item.id} key={item.id}>
                {item.title}
              </option>
              // )
            ))
          : data.map((item) => (
              // return (
              <option value={item.id} key={item.id}>
                {item.title}
              </option>
              // )
            ))}
      </select>
    </Grid>
  )
})
