import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import { debounce } from "lodash";
import { Grid, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './_services.scss'
import './calendar.css'

import Header from '../header/header'
import { serviceFilterManifestDefaultStructure } from '../../shared/sample_data'
import DeleteConfirmation from '../../shared/delete_confirm/delete_confirm';
import ServiceMobile from './services_mobile/services_mobile';
import ServicesTab from './services_tab/services_tab';

function CheckBox( props ) {
  const [buttonState, setButtonState] = useState(false)
  const { type, index, changeFilter } = props;

  const handleButton = () => {
    changeFilter(type, index);
    setButtonState(!buttonState)
  }

  return (
    <div className="services-checkbox" onClick={handleButton}>
      <div
        className="services-checkbox-empty center-content animatable-400 ease-in-out-quart"
        style={{ background: buttonState ? '#7DA5C2' : 'rgba(0,0,0,0)' }}
      >
        <img
          src={require('../../../assets/svg/icon/check_white.svg')}
          alt="check white"
          className="height-80-p width-80-p object-fit-contain"
        />
      </div>
    </div>
  )
}

const StatusDisplay = forwardRef((props, ref) => {
  const [ showStatus, setShowStatus ] = useState(false)
  const [ type, setType ] = useState(0)

  useEffect(() => {
    if(type !== 0) {
      setShowStatus(true);
      handleTypeChange();
    }
  }, [type])

  const handleTypeChange = debounce(() => {
    setShowStatus(false)
    setType(0)
  }, 3000);

  useImperativeHandle(ref, () => ({
    setValue: (value) => {
      setShowStatus(value)
    },
    showDatum: () => {
      setType(1)
    },
    showCustomer: () => {
      setType(2)
    },
    showCategory: () => {
      setType(3)
    }
  }))

  return (
    <div className = "services-status-display-container vertical-center animatable-400 ease-in-out-quart" style = {{opacity: showStatus ? 1 : 0, transform: showStatus ? 'translateX(0px)' : 'translateX(100%)'}}>
      <p className = "main-font-semi-bold font-size-14 services-status-display">{type === 1 ? 'Bijgewerkt Inspectie Datum' : type === 2 ? 'Bijgewerkt Verantw.' : 'Bijgewerkt Status'}</p>
    </div>
  )
})

function FilterDropdown(props) {
  const [ filterState, setFilterState ] = useState(false)
  const [ controlBlur, setControlBlur ] = useState(false)

  const { servicesData, serviceCategoryData, changeFilter } = props;
  const filterRef = useRef(0)

  const handleFilter = () => {
    if (!controlBlur) {
      if (!filterState) {
        filterRef.current.focus()
      }
      setControlBlur(true)
      setFilterState(!filterState)
    } else {
      setControlBlur(false)
    }
  }

  const handleFilterBlur = () => {
    setFilterState(false)
  }

  return(
    <div className="services-filter-container">
      <div
        className="services-filter center-content"
        onClick={handleFilter}
      >
        <p className="main-font-regular font-size-14 font-color-grey">
          Filters
        </p>
        <img
          src={require('../../../assets/svg/icon/down_arrow.svg')}
          alt="arrow down"
          className="services-filter-arrow margin-left-10"
        />
      </div>
      <div
        className="services-filter-selection-container animatable-400 ease-in-out-quart overflow-hidden"
        tabIndex="1"
        onBlur={handleFilterBlur}
        style={{
          height: filterState ? `${(((parseInt(servicesData.length/3) + (servicesData.length % 3)) * 35) + ((parseInt(serviceCategoryData.length/3) + (serviceCategoryData.length % 3)) * 35)) + 150}px` : '0px',
          opacity: filterState ? 1 : 0,
          left: window.screen.width < 1500 ? '-300px' : '0px'
        }}
        ref={filterRef}
      >
        <div className = "flex-row width-auto" style = {{justifyContent: window.screen.width < 1500 ? 'center' : 'flex-start'}}>
          <div className="services-filter-selection-arrow">
            <img
              src={require('../../../assets/svg/icon/corner.png')}
              alt="triangle"
              className="height-auto width-auto object-fit-contain"
            />
          </div>
        </div>
        <div className="services-filter-selection">
          <Grid container item sm={12}>
            <Grid container item sm={4}>
              <div className="flex-row vertical-center">
                <CheckBox type = {1} index = {0} changeFilter = {changeFilter} />
                <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                  Alle dossiers
                </p>
              </div>
            </Grid>
            <Grid container item sm={4}>
              <div className="flex-row vertical-center">
                <CheckBox type = {1} index = {1} changeFilter = {changeFilter} />
                <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                  Mijn dossiers
                </p>
              </div>
            </Grid>
            <Grid container item sm={12}>
              <div className="margin-top-25" />
            </Grid>
            <Grid container item sm={12}>
              <div className="services-filter-selection-line" />
            </Grid>
            <Grid container item sm={12}>
              <div className="margin-top-25" />
            </Grid>
            <Grid container item sm={12}>
              {serviceCategoryData.length > 0 && serviceCategoryData.map((item, index) => (
                <Grid container item sm = {4}>
                  <div className="flex-row vertical-center margin-bottom-15">
                    <CheckBox type = {3} index = {index} changeFilter = {changeFilter} />
                    <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                      {item.title}
                    </p>
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid container item sm={12}>
              <div className="services-filter-selection-line" />
            </Grid>
            <Grid container item sm={12}>
              <div className="margin-top-25" />
            </Grid>
            <Grid container item sm={12}>
              {servicesData.length > 0 && servicesData.map((item, index) => (
                <Grid container item sm = {4}>
                  <div className="flex-row vertical-center margin-bottom-15">
                    <CheckBox type = {4} index = {index} changeFilter = {changeFilter} />
                    <p className="main-font-regular font-size-14 font-color-black margin-left-15">
                      {item.title}
                    </p>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

const PageNavigator = forwardRef((props, ref) => {
  const [ scrollButton, setScrollButton] = useState(false)

  const { scrollAreaRef } = props;
  
  useImperativeHandle(ref, () => ({
    setValue: (value) => {
      setScrollButton(value)
    },
    getValue: () => {
      return scrollButton;
    }
  }))

  const handlePageUp = () => {
    if (scrollButton) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return(
    <button
      type="button"
      onClick={handlePageUp}
      className="services-scrolltop animatable-400 ease-in-out-quart"
      style={{
        opacity: scrollButton ? 1 : 0,
        transform: scrollButton
          ? 'translateY(0px)'
          : 'translateY(-15px)',
      }}
    >
      <img
        src={require('../../../assets/svg/icon/arrow_up.svg')}
        alt="arrow up"
        className="services-scrolltop-arrow"
      />
    </button>
  )
})

function ArrowLeft(props) {
  const { rotate } = props
  return (
    <img
      src={require('../../../assets/svg/icon/arrow_left.svg')}
      alt="arrow left"
      style={{
        height: '15px',
        width: '15px',
        transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      className="object-fit-contain"
    />
  )
}

function Service(props) {
  // state vars for modal control
  const [statusFilter, setStatusFilter] = useState(false)
  const [statusControlBlur, setStatusControlBlur] = useState(false)
  const [responsibleFilter, setResponsibleFilter] = useState(false)
  const [responsibleControlBlur, setResponsibleConrtrolBlur] = useState(false)
  const [dateFilter, setDateFilter] = useState(false)
  const [dateControlBlur, setDateControlBlur] = useState(false)

  // state vars for date/time picker
  const [date, setDate] = useState(new Date())
  const [startTimeHour, setStartTimeHour] = useState('')
  const [startTimeMinute, setStartTimeMinute] = useState('')
  const [endTimeHour, setEndTimeHour] = useState('')
  const [endTimeMinute, setEndTimeMinute] = useState('')

  // state vars for dropdown fields that change intial selected value
  const [responsible, setResponsible] = useState('-')

  // standard state vars
  const [mouseActive, setMouseActive] = useState(false)
  const [instancedBuildingData, setInstancedBuildingData] = useState({})
  const [renderDom, setRenderDom] = useState(false)
  const [unmountState, setUnmountState] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [buttonDisableState, setButtonDisableState] = useState(false)
  // refs for modal dom control
  const nameFilterRef = useRef(0)
  const responsibleFilterRef = useRef(0)
  const dateFilterRef = useRef(0)

  // data from parent
  const { buildingData, changeCategory, deleteService, buildingIndex, projectIndex, serviceIndex, responsibleList, zIndex, statusRef, searchDataPool, setSearchDataPool } = props
  const history = useHistory();

  useEffect(() => {
    if(buildingData.projects !== undefined && buildingData.projects.length > 0) {
      if(buildingData.projects[projectIndex].projectServices[serviceIndex].billing_start_time !== null) {
        let billingStartTime = buildingData.projects[projectIndex].projectServices[serviceIndex].billing_start_time.split(":")
        setStartTimeHour(billingStartTime[0]);
        setStartTimeMinute(billingStartTime[1])
      }
      
      if(buildingData.projects[projectIndex].projectServices[serviceIndex].billing_end_time !== null) {
        let billingEndTime = buildingData.projects[projectIndex].projectServices[serviceIndex].billing_end_time.split(":")
        setEndTimeHour(billingEndTime[0]);
        setEndTimeMinute(billingEndTime[1]);
      }

      setInstancedBuildingData(buildingData)
      setRenderDom(true)
    }
  }, [buildingData])

  useEffect(() => {
    if(buildingData.projects[projectIndex].projectServices[serviceIndex].assigned_admin_id !== null && responsibleList.length > 0) {
      const userName = responsibleList.filter( admin => admin.id === buildingData.projects[projectIndex].projectServices[serviceIndex].assigned_admin_id)
      setResponsible(userName[0] && userName[0].firstName)
    }
  }, [buildingData, responsibleList])

  // functions for animating and controlling the filters
  const handleStatusFilter = () => {
    if (!statusControlBlur) {
      if (!statusFilter) {
        nameFilterRef.current.focus()
      }
      setStatusControlBlur(true)
      setStatusFilter(!statusFilter)
    } else {
      setStatusControlBlur(false)
    }
  }

  const handleStatusFilterBlur = () => {
    setStatusFilter(false)
  }

  const handleResponsibleFilter = () => {
    if (!responsibleControlBlur) {
      if (!responsibleFilter) {
        responsibleFilterRef.current.focus()
      }
      setResponsibleConrtrolBlur(true)
      setResponsibleFilter(!responsibleFilter)
    } else {
      setResponsibleConrtrolBlur(false)
    }
  }

  const handleResponsibleFilterBlur = () => {
    setResponsibleFilter(false)
  }

  const handleDateFilter = () => {
    if (!dateControlBlur) {
      if (!dateFilter) {
        dateFilterRef.current.focus()
      }
      setDateControlBlur(true)
      setDateFilter(!responsibleFilter)
    } else {
      setDateControlBlur(false)
    }
  }

  const handleDateFilterBlur = () => {
    if (!mouseActive) {
      setDateFilter(false)
    }
  }

  // functions for input times and date mechanics
  const handleStartTimeHour = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedBuildingData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 23) {
        newTime = '23'
      }
      newData.projects[projectIndex].projectServices[serviceIndex].billing_start_time = `${newTime}:${startTimeMinute}`;
      setInstancedBuildingData(newData)
      setStartTimeHour(newTime)
    } else {
      setStartTimeHour(startTimeHour)
    }
  }

  const handleStartTimeMinute = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedBuildingData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 59) {
        newTime = '59'
      }
      newData.projects[projectIndex].projectServices[serviceIndex].billing_start_time = `${startTimeHour}:${newTime}`;
      setInstancedBuildingData(newData)
      setStartTimeMinute(newTime)
    } else {
      setStartTimeMinute(startTimeMinute)
    }
  }

  const handleEndTimeHour = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedBuildingData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 23) {
        newTime = '23'
      }
      newData.projects[projectIndex].projectServices[serviceIndex].billing_end_time = `${newTime}:${endTimeMinute}`;
      setInstancedBuildingData(newData)
      setEndTimeHour(newTime)
    } else {
      setEndTimeHour(endTimeHour)
    }
  }

  const handleEndTimeMinute = (event) => {
    let newData = JSON.parse(JSON.stringify(instancedBuildingData))
    setButtonDisableState(false)
    if (event.target.validity.valid) {
      let newTime = event.target.value
      if (parseInt(newTime) > 59) {
        newTime = '59'
      }
      newData.projects[projectIndex].projectServices[serviceIndex].billing_end_time = `${endTimeHour}:${newTime}`;
      setInstancedBuildingData(newData)
      setEndTimeMinute(newTime)
    } else {
      setEndTimeMinute(endTimeMinute)
    }
  }

  function updateDate(value) {
    let newData = JSON.parse(JSON.stringify(instancedBuildingData))
    newData.projects[projectIndex].projectServices[serviceIndex].billing_date = value
    setInstancedBuildingData(newData)
    setDate(value)  
  }

  function deleteServiceData() {
    deleteService(buildingIndex, projectIndex, serviceIndex, setUnmountState)
  }

  function setAdmin(id){
    let token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/project_services/update_assigned_admin_id', {
      id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].id,
      assigned_admin_id: id
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      statusRef.current.showCustomer();
      // succcess
    }).catch(error => {
    })
  }

  function updateDateTime(eventId) {
    let token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/project_services/update_service_date', {
      id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].id,
      billing_date: new Date(date.split('-')[0],(date.split('-')[1]-1),date.split('-')[2],12),
      eventId:eventId
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      let newData = JSON.parse(JSON.stringify(searchDataPool))
      const currentDate = new Date(date.split('-')[0],(date.split('-')[1]-1),date.split('-')[2],12);
      newData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].billing_date = `${currentDate.getFullYear()} / ${currentDate.getMonth() + 1} / ${currentDate.getDate()}`
      setSearchDataPool(newData)
      // success
    }).catch(error => {
    })
  }

  function updateStartTime(eventId) {
    let token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/project_services/update_service_start_time', {
      id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].id,
      billing_start_time: `${startTimeHour}:${startTimeMinute}:00`,
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      // success
    }).catch(error => {
    })
  }

  function updateEndTime(eventId) {
    let token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/project_services/update_service_end_time', {
      id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].id,
      billing_end_time: `${endTimeHour}:${endTimeMinute}:00`,
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      // success
    }).catch(error => {
    })
  }

  function saveEvent() {
    const token = localStorage.getItem('authToken')
    setButtonDisableState(true)
    if(instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].eventId===null){
      const userDetails = JSON.parse( localStorage.getItem('loggedInUserDetails' ))
      let data = {
        user_id: userDetails.id,
        service_id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service.id,
        message: "inspectie datum",
        email: instancedBuildingData.user.email,
        start_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
        end_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
        start_time: `${startTimeHour}:${startTimeMinute}:00`,
        end_time: `${endTimeHour}:${endTimeMinute}:00`,
        is_full_day: false,
        company_name: instancedBuildingData.fileName,
        service_name: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service.title,
        skip_email: false
      }
      axios.post('https://backend.develop.bip.flykube.agifly.cloud/events/add', 
        data, 
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      }).then((response) => {
        if(response.status===200){
          updateDateTime(response.data.id);
          updateStartTime()
          updateEndTime()
          setDateControlBlur(false)
          setDateFilter(false)
          statusRef.current.showDatum();
        }
      }).catch((error) => {
        setButtonDisableState(false)
      })
    }
    else{
      const userDetails = JSON.parse( localStorage.getItem('loggedInUserDetails' ))
      let data = {
        id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].eventId,
        user_id: userDetails.id,
        service_id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service.id,
        message: "inspectie datum",
        email: instancedBuildingData.user.email,
        start_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
        end_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
        start_time: `${startTimeHour}:${startTimeMinute}:00`,
        end_time: `${endTimeHour}:${endTimeMinute}:00`,
        is_full_day: false,
        company_name: instancedBuildingData.fileName,
        service_name: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service.title,
      }
      axios.post('https://backend.develop.bip.flykube.agifly.cloud/events/update', 
        data, 
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      }).then((response) => {
        if(response.status===200){
          updateDateTime(instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].eventId);
          updateStartTime()
          updateEndTime()
          setDateControlBlur(false)
          setDateFilter(false)
          statusRef.current.showDatum();
        }
      }).catch((error) => {
        setButtonDisableState(false)
      })
    }
  }

  // function goToInvoice() {
  //   // first we check if an invoice status is 2 or above, which means it's created 
  //   if(instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].invoice_statuses_id === 1) {
  //     const token = localStorage.getItem("authToken")
  //     axios.post(`https://backend.develop.bip.flykube.agifly.cloud/invoice/update_invoice_status`, {
  //           facturate_id: instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id,
  //           invoice_statuses_id: 2,
  //           is_cancelled: false,
  //       }, {
  //         headers: {
  //           authorization: `bearer ${token}`,
  //         },
  //       }).then((response) => {
  //       if(response.data.success){
  //         // invoice has been succesfully created so we now route user to the invoice 
  //         if(instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].is_billing){
  //           history.push(`/invoice-draft/${instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //         }
  //         else{
  //           history.push(`/quotations-draft/${instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //         }        
  //       }
  //     }).catch(error => {

  //     })
  //   }
  //   else {
  //     // invoice already exists so just route to invoice 
  //     if(instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].is_billing){
  //       history.push(`/invoice-draft/${instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //     }
  //     else{
  //       history.push(`/quotations-draft/${instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
  //     }

  // }

  // }

  function generateFileNumber() {
    let buildingId = instancedBuildingData.id.toString();
    let serviceId = instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].id.toString();
    let newBuildingDigits = '0000'
    let newServiceDigits = '0000'
    newBuildingDigits = newBuildingDigits.slice(0, 4 - buildingId.length)
    let newbuildingId = newBuildingDigits.concat(buildingId)
    newServiceDigits = newServiceDigits.slice(0, 4 - serviceId.length)
    let newserviceId = newServiceDigits.concat(serviceId)

    return `${newbuildingId}-${newserviceId}`
  }

  const classes = useStyles();


  return (
    <Grid container>
      {/* we use renderDom here to control when to render, we don't want to pass anything other than a complete object since objects are volatile and create exceptions */}
      {renderDom && (
            <div
            className={`fade-in-right flex-row width-auto vertical-center margin-bottom-10 margin-right-10 margin-left-10 ${
              unmountState && 'fade-out-right'
            }`}
            style={{ animationTimingFunction: 'cubic-bezier(0.87, 0, 0.13, 1)' }}
          >
            <div className="services-details-name-box flex-row relative">
              <div
                className={`absolute services-radio${
                  instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0]?.is_billing
                    ? '-filled'
                    : '-outline'
                }`}
              />
              <div
                className="height-auto width-auto flex-row cursor-pointer vertical-center"
                onClick={handleStatusFilter}
              >
                <div
                  className="services-details-name-color flex-shrink-disable"
                  style={{
                    background:
                      instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 0
                        ? '#B2D5FF'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 1
                        ? '#FFEBAA'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 2
                        ? '#CEF1B2'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 3
                        ? '#686886'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 4
                        ? '#FBA1AC'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 5
                        ? '#F2BBAD'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 6
                        ? '#FBDCCE'
                        : instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service_status_id === 7
                        ? '#AFDFDB'
                        : '#8cc9ba'
                  }}
                />
                <p className="main-font-medium font-size-12 font-color-black text-ellipsis width-85-p">
                  {instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service !== undefined && instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].service.title}
                </p>
              </div>
              <div
                className="services-details-name-filter-container animatable-400 ease-in-out-quart"
                ref={nameFilterRef}
                tabIndex="2"
                onBlur={handleStatusFilterBlur}
                style={{
                  height: statusFilter ? (!instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0]?.is_billing ? '400px' :'300px') : '10px',
                  opacity: statusFilter ? 1 : 0,
                }}
              >
                <div className="flex-row width-auto horizontal-start">
                  <div className="services-details-name-filter-arrow">
                    <img
                      src={require('../../../assets/svg/icon/corner.png')}
                      alt="triangle"
                      className="height-auto width-auto object-fit-contain"
                    />
                  </div>
                </div>
                <div className="services-details-name-filter vertical-center">
                  <Grid
                    container
                    item
                    sm={12}
                    direction="column"
                    alignItems="flex-end"
                  >
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 0, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        Ontvangen
                      </p>
                      <div className="services-details-name-filter-bubble background-color-faded-blue" />
                    </div>
                    <div className="flex-row">
                      <div className="services-details-name-filter-line" />
                    </div>
                    {!instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].invoices[0]?.is_billing &&
                      <React.Fragment>
                        <div
                          className="flex-row vertical-center cursor-pointer"
                          onClick={() => {
                            setStatusFilter(false)
                            changeCategory(buildingIndex, projectIndex, serviceIndex, 5, setUnmountState)
                          }}
                        >
                          <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                            Offerte
                          </p>
                          <div className="services-details-name-filter-bubble" style = {{background: 'rgb(242, 187, 173)'}} />
                        </div>
                        <div className="flex-row">
                          <div className="services-details-name-filter-line" />
                        </div>
                        <div
                          className="flex-row vertical-center cursor-pointer"
                          onClick={() => {
                            setStatusFilter(false)
                            changeCategory(buildingIndex, projectIndex, serviceIndex, 6, setUnmountState)
                          }}
                        >
                          <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                            Offerte aanvaard
                          </p>
                          <div className="services-details-name-filter-bubble " style = {{background: 'rgb(251, 220, 206)'}} />
                        </div>
                        <div className="flex-row">
                          <div className="services-details-name-filter-line" />
                        </div>
                      </React.Fragment>
                    }
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 1, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        In behandeling
                      </p>
                      <div className="services-details-name-filter-bubble background-color-faded-tan" />
                    </div>
                    <div className="flex-row">
                      <div className="services-details-name-filter-line" />
                    </div>
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 2, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        Afgewerkt
                      </p>
                      <div className="services-details-name-filter-bubble background-color-faded-green" />
                    </div>
                    <div className="flex-row">
                      <div className="services-details-name-filter-line" />
                    </div>
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 7, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        Gefactureerd
                      </p>
                      <div className="services-details-name-filter-bubble " style = {{background: 'rgb(175, 223, 219)'}} />
                    </div>
                    <div className="flex-row">
                      <div className="services-details-name-filter-line" />
                    </div>
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 8, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        Betaald
                      </p>
                      <div className="services-details-name-filter-bubble" style = {{background: 'rgb(140, 201, 186)'}} />
                    </div>
                    <div className="flex-row">
                      <div className="services-details-name-filter-line" />
                    </div>
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 3, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        On hold
                      </p>
                      <div className="services-details-name-filter-bubble background-color-faded-purple" />
                    </div>
                    <div className="flex-row">
                      <div className="services-details-name-filter-line" />
                    </div>
                    <div
                      className="flex-row vertical-center cursor-pointer"
                      onClick={() => {
                        setStatusFilter(false)
                        changeCategory(buildingIndex, projectIndex, serviceIndex, 4, setUnmountState)
                      }}
                    >
                      <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                        Geannuleerd
                      </p>
                      <div className="services-details-name-filter-bubble background-color-faded-pink" />
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="services-details-file-box center-content">
              <p className="main-font-medium font-size-12 font-color-black">
                #{instancedBuildingData && generateFileNumber()}
              </p>
            </div>
            <div className="services-details-file-name-box center-content">
              <p className="main-font-medium font-size-12 font-color-black">
                {instancedBuildingData.fileName !== null && instancedBuildingData.fileName}
              </p>
            </div>
            <div className="services-details-customer-box center-content relative">
              <div
                className="height-auto width-auto center-content"
              >
                <p className="main-font-medium font-size-12 font-color-black">
                  {instancedBuildingData.user !== undefined && instancedBuildingData.user !== null && instancedBuildingData.user.company !== undefined && instancedBuildingData.user.company !== null && instancedBuildingData.user.company.name}
                </p>
              </div>
            </div>
            <div className="services-details-responsible-box center-content relative">
              <div
                className="height-auto width-auto center-content cursor-pointer"
                onClick={handleResponsibleFilter}
              >
                <p className="main-font-medium font-size-12 font-color-black">
                  {responsible}
                </p>
              </div>
              <div
                className="services-details-responsible-filter-container animatable-400 ease-in-out-quart"
                ref={responsibleFilterRef}
                tabIndex="2"
                onBlur={handleResponsibleFilterBlur}
                style={{
                  transform: responsibleFilter ? 'scaleY(1)' : 'scaleY(0)',
                  opacity: responsibleFilter ? 1 : 0,
                }}
              >
                <div className="flex-row width-auto">
                  <div className="services-details-responsible-filter-arrow">
                    <img
                      src={require('../../../assets/svg/icon/corner.png')}
                      alt="corner"
                      className="height-auto width-auto object-fit-contain"
                    />
                  </div>
                </div>
                <div className="services-details-responsible-filter">
                  <Grid
                    container
                    item
                    sm={12}
                    direction="column"
                    alignItems="flex-end"
                  >
                    {responsibleList.map((item, index) => (
                      <>
                        <div
                          className="flex-row vertical-center cursor-pointer"
                          onClick={() => {
                            setAdmin(item.id)
                            setResponsible(
                            item.firstName,
                            )
                            setResponsibleFilter(false)
                          }}
                        >
                          <p className="main-font-semi-bold font-size-14 font-color-black">
                            {item.firstName}
                          </p>
                        </div>
                        {index !== responsibleList.length - 1 && (
                          <div className="flex-row">
                            <div className="services-details-name-filter-line" />
                          </div>
                        )}
                      </>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
            <div className="services-details-doc-box center-content">
              <img
                src={require('../../../assets/svg/icon/icon_folder_darkgrey.svg')}
                alt="folder icon"
                className="services-details-icon"
              />
              <p className="main-font-medium font-size-12 font-color-black">
                {instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].servicesDocuments !== undefined && instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].no_of_documents}
              </p>
            </div>
            <div className="services-details-message-box center-content">
              <img
                src={require('../../../assets/svg/icon/icon_chat_darkgrey.svg')}
                alt="chat icon"
                className="services-details-icon"
              />
              <p className="main-font-medium font-size-12 font-color-black">
                {instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].servicesDocuments !== undefined && instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].no_of_messages}
              </p>
            </div>
            <div className="services-details-date-box relative" style = {{zIndex: zIndex || 0}}>
              <div
                className="height-auto width-auto center-content cursor-pointer"
                onClick={handleDateFilter}
              >
                <p className="main-font-medium font-size-12 font-color-black">
                  {instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].billing_date !== null ? typeof instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].billing_date === 'string'
                    ? instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].billing_date.includes("-") ? 
                    instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].billing_date.substring(0, 10).replace(/-/g, " / " ) : 
                    instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].billing_date.substring(0, 13).replace(/-/g, " / " )
                    : date.getDate() +
                      ' / ' +
                      (date.getMonth() + 1) +
                      ' / ' +
                      date.getFullYear()
                    : 'Tijd'
                    }
                </p>
              </div>
              <div
                className="services-details-date-filter-container animatable-400 ease-in-out-quart cursor-pointer"
                ref={dateFilterRef}
                tabIndex="3"
                onBlur={handleDateFilterBlur}
                style={{
                  height: dateFilter ? '215px' : '10px',
                  opacity: dateFilter ? 1 : 0,
                }}
                onMouseOver={() => {
                  setMouseActive(true)
                }}
                onMouseLeave={() => {
                  setMouseActive(false)
                }}
              >
                <div className="flex-row width-auto">
                  <div className="services-details-date-filter-arrow">
                    <img
                      src={require('../../../assets/svg/icon/corner.png')}
                      alt="corner"
                      className="height-auto width-auto object-fit-contain"
                    />
                  </div>
                </div>
                <div className="services-details-date-filter flex-column vertical-center">
                  {dateFilter && (
                    
                    <form className={classes.container} noValidate >
                      <Grid container justify="center">
                        <TextField
                          id="date"
                          type="date"
                          value={date}
                          onChange={(e)=>{updateDate(e.target.value); setButtonDisableState(false)}}
                          
                          defaultValue={date}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </form>
                  
                  )
                  }
                  <Grid container justify='center'>
                    <div className="width-auto flex-row margin-top-15 margin-left-25 horizontal-center">
                    <div className="width-auto flex-column">
                      <p className="main-font-light font-size-14 font-color-grey">
                        Van
                      </p>
                      <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5">
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder = "12"
                          maxLength={2}
                          value={startTimeHour}
                          onChange={handleStartTimeHour}
                        />
                        <p className="main-font-medium font-color-black font-size-14">
                          :
                        </p>
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder = "30"
                          maxLength={2}serviceFilterManifestDefaultStructure
                          value={startTimeMinute}
                          onChange={handleStartTimeMinute}
                        />
                      </div>
                    </div>
                    <div className="width-auto flex-column">
                      <p className="main-font-light font-size-14 font-color-grey">
                        Tot
                      </p>
                      <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5">
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder = "14"
                          maxLength={2}
                          value={endTimeHour}
                          onChange={handleEndTimeHour}
                        />
                        <p className="main-font-medium font-color-black font-size-14">
                          :
                        </p>
                        <input
                          className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                          type="text"
                          pattern="[0-9]*"
                          placeholder = "30"
                          maxLength={2}
                          value={endTimeMinute}
                          onChange={handleEndTimeMinute}
                        />
                      </div>
                    </div>
                  </div>
                  </Grid>
                  <div className = "services-details-date-filter-save center-content cursor-pointer" onClick = {() => { saveEvent() }} disabled = {buttonDisableState}>
                    <p className = "main-font-semi-bold font-size-14 font-color-white">Save</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="services-details-timeline-box center-content">
              <p className="main-font-medium font-size-12 font-color-black">
                {instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].form_progress ? instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].form_progress : '-'}
              </p>
            </div>
            <div className="services-details-icons cursor-pointer" onClick = {() => {history.push(`/details/${instancedBuildingData.projects[projectIndex].projectServices[serviceIndex].id}-${instancedBuildingData.id}`, instancedBuildingData.projects[projectIndex].projectServices[serviceIndex]) }}>
              <img
                src={require('../../../assets/svg/icon/view.svg')}
                alt="view"
                className="height-auto width-auto object-fit-contain"
              />
            </div>
            {/* <div className="services-details-icons cursor-pointer" onClick = {() => { goToInvoice() }}>
              <img
                src={require('../../../assets/svg/icon/factuur.svg')}
                alt="factuur"
                className="height-auto width-auto object-fit-contain"
              />
            </div> */}
            <div className="services-details-icons cursor-pointer" onClick = {() => { setDeleteModal(true) }}>
              <img
                src={require('../../../assets/svg/icon/trash.svg')}
                alt="trash"
                className="height-auto width-auto object-fit-contain"
              />
            </div>
          </div>
      )}
      {deleteModal && (
        <DeleteConfirmation id = {1} setOpenState = {setDeleteModal} handleDeleteClick = {deleteServiceData} />
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function Services(props) {
  const [scrollButton, setScrollButton] = useState(false)
  const [dataPool, setDataPool] = useState([])
  const [searchDataPool, setSearchDataPool] = useState([])
  const [originalServiceData, setOriginalServiceData ] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [serviceCategoryData, setServiceCategoryData] = useState([])
  const [filterSearchManifest, setFilterSearchManifest] = useState(serviceFilterManifestDefaultStructure);
  const [ loader, setLoader ] = useState(false)
  const [ selectedTab, setSelectedTab ] = useState(1)

  const pageNavRef = useRef(0)
  const statusRef = useRef(0)
  const scrollAreaRef = useRef(0)
  const searchRef = useRef(0)
  const history = useHistory()

  useEffect(() => { 
    const token = localStorage.getItem('authToken')
    axios
        .post(
          'https://backend.develop.bip.flykube.agifly.cloud/dashboard/adminData',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          setOriginalServiceData(response.data)
          setDataPool(response.data)
          setSearchDataPool(response.data)
        })
        .catch((error) => {
            localStorage.clear();
            history.push("/")
        })
  }, [])

  useEffect(() => {
    getAdminData();
    getAllServices();
  }, [])

  useEffect(() => {
    if(filterSearchManifest.servicesCategory) {
      filterDataPool(filterSearchManifest)
    }
  }, [selectedTab])

  const handleSearch = (event) => {
    debouncedSearch(event.target.value);
  }
  
  const debouncedSearch = debounce((value) => {
    const searchPromise = new Promise((resolve, reject) => {
      const resolved = true;
      if(resolved) {
        setLoader(true)
        resolve('success')
      }
      else {
        reject('failed')
      }
    })
    searchPromise.then(() => {
      handleSearchFilter(value)
    })
  }, 500);

  function handleSearchFilter(string) {
    const searchParams = string.toLowerCase()
    const newDataPool = []
    let projectList = [];
    let serviceList = [];
    let dataExists = false
    const originalData = JSON.parse(JSON.stringify(dataPool))

    originalData.forEach((building) => {
      // if title matches with search param, don't loop further
      if(building.title.toLowerCase().includes(searchParams) || building.user.company.name.toLowerCase().includes(searchParams)) {
        dataExists = true;
        newDataPool.push(building);
      }
      if(!dataExists) {
        building.projects.forEach((project, projectIndex) => {
          // if title matches with search param, don't loop further
          if(project.title !== null && project.title.toLowerCase().includes(searchParams)) {
            dataExists = true;
            projectList.push(project);
          }
          if(!dataExists) {
            project.projectServices.forEach((service, serviceIndex) => {
              let serviceEventDate = service.billing_date;
              if(serviceEventDate) {
                serviceEventDate = serviceEventDate.split(" ")[0]
                serviceEventDate = serviceEventDate.replace(/-/g, '/');
              }
              if(service.service.title.toLowerCase().includes(searchParams) || (service.fileNumber && generateFileNumber(building.id, service.id).includes(searchParams)) || (serviceEventDate && serviceEventDate.includes(searchParams))) {
                serviceList.push(service);
              }
            })

            if(serviceList.length > 0) {
              projectList.push(project);
              projectList[projectList.length - 1].projectServices = serviceList;
              serviceList = [];
            }
          }

          dataExists = false;
        })
      }

      if(projectList.length > 0) {
        newDataPool.push(building);
        newDataPool[ newDataPool.length - 1].projects = projectList;
        projectList = [];
      }
      dataExists = false;
    })
    setTimeout(() => {
      setLoader(false)
    }, 100);
    setSearchDataPool(newDataPool)
  }

  function generateFileNumber(originBuildingId, originServiceId) {
    let buildingId = originBuildingId.toString();
    let serviceId = originServiceId.toString();
    let newBuildingDigits = '0000'
    let newServiceDigits = '0000'
    newBuildingDigits = newBuildingDigits.slice(0, 4 - buildingId.length)
    let newbuildingId = newBuildingDigits.concat(buildingId)
    newServiceDigits = newServiceDigits.slice(0, 4 - serviceId.length)
    let newserviceId = newServiceDigits.concat(serviceId)

    return `${newbuildingId}-${newserviceId}`
  }

  function changeCategory(buildingIndex, projectIndex, serviceIndex, type) {
    let singleData = JSON.parse(JSON.stringify(searchDataPool))
    let newDataPool = JSON.parse(JSON.stringify(dataPool))
    let newOriginalData = JSON.parse(JSON.stringify(originalServiceData))
    const projectServiceId = singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].id;

    if (singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].service_status_id === type) {
    } else {
      setTimeout(() => {
        singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].service_status_id = type;
        if(type === 6) {
          singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].invoices.forEach(invoice => {
            invoice.is_billing = true
          })
        }
        newDataPool.forEach(building => {
          building.projects.forEach(project => {
            project.projectServices.forEach(service => {
              if(service.id === projectServiceId) {
                service.service_status_id = type;
                if(type === 6) {
                  service.invoices.forEach(invoice => {
                    invoice.is_billing = true;
                  })
                }
              }
            })
          })
        })
    
        newOriginalData.forEach(building => {
          building.projects.forEach(project => {
            project.projectServices.forEach(service => {
              if(service.id === projectServiceId) {
                service.service_status_id = type;
                if(type === 6) {
                  service.invoices.forEach(invoice => {
                    invoice.is_billing = true;
                  })
                }
              }
            })
          })
        })

        setSearchDataPool(singleData)
        setDataPool(newDataPool)
        setOriginalServiceData(newOriginalData);
        let token = localStorage.getItem('authToken')
        axios.post('https://backend.develop.bip.flykube.agifly.cloud/project_services/update_service_status', {
          id: singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].id,
          service_status_id: singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].service_status_id,  
        }, {
          headers: {
            authorization: `bearer ${token}`
          }
        }).then(response => {

          if(singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].service_status_id === 6){
            singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].invoices.map(invoice => {
              axios
                .post(
                  `https://backend.develop.bip.flykube.agifly.cloud/invoice/update_billing_state`,
                  {
                      facturate_id:parseInt(invoice.id),
                      is_billing: true
                  },
                  {
                    headers: {
                      authorization: `bearer ${token}`,
                    },
                  },
                )
                .then((response) => {
                  if(response.data.success){
                    statusRef.current.showCategory();
                  }
                })
                .catch(error=>{})
            })
        }

        else{
          statusRef.current.showCategory();
        }
          // success
        }).catch(error => {
        })
      }, 500)
    }
  }

  function deleteService(buildingIndex, projectIndex, serviceIndex, setAnimation) {
    let singleData = JSON.parse(JSON.stringify(searchDataPool))
    let newDataPool = JSON.parse(JSON.stringify(dataPool))
    let newOriginalData = JSON.parse(JSON.stringify(originalServiceData))
    const projectServiceId = singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].id;

    let token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/project_services/delete', {
      projectId: singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].projectId,
      serviceId: singleData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].serviceId,  
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      // success
      newDataPool.forEach(building => {
        building.projects.forEach(project => {
          const dataPoolServiceIndex = project.projectServices.findIndex(service => service.id === projectServiceId)
          project.projectServices.splice(dataPoolServiceIndex, 1)
        })
      })
  
      newOriginalData.forEach(building => {
        building.projects.forEach(project => {
          const dataPoolServiceIndex = project.projectServices.findIndex(service => service.id === projectServiceId)
          project.projectServices.splice(dataPoolServiceIndex, 1)
        })
      })
      singleData[buildingIndex].projects[projectIndex].projectServices.splice(serviceIndex, 1)

      setSearchDataPool(singleData)
      setDataPool(newDataPool)
      setOriginalServiceData(newOriginalData);
    }).catch(error => {
    })
  } 

  function getAdminData() {
    let token = localStorage.getItem('authToken');
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/user/all_admin', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      const superAdmin = response.data.data.filter(admin => admin.roleId === 4);
      setAdminData(superAdmin)
    }).catch(error => {
    })
  }

  function getAllServices() {
    let serviceFilterManifestData = {...filterSearchManifest}

    let token = localStorage.getItem('authToken');
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/service/all', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      let serviceManifestData = [];
      let responseData = response.data
      responseData.sort(function (a, b) {
        return (a.id - b.id || b.name.localeCompare(a.name))
      })
      responseData.forEach((_, index) => {
        serviceManifestData.push(
          {
            id: index,
            enabled: false,
          }
        )
      })
      
      serviceFilterManifestData.services = serviceManifestData;
      setServicesData(responseData);
    }).then(() => { 
      // we do another request to make sure that the service data has been obtained and succesfully stored
      axios.post('https://backend.develop.bip.flykube.agifly.cloud/serviceCategory/get/category_has_service', {}, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        let serviceManifestData = [];
        response.data.forEach((item) => {
          serviceManifestData.push(
            {
              id: item.id,
              enabled: false,
            }
          )
        })

        serviceFilterManifestData.servicesCategory = serviceManifestData;
        setFilterSearchManifest(serviceFilterManifestData);
        setServiceCategoryData(response.data)
      }).catch(error => {
      })
    }).catch(error => {
    })
  }
  
  const handleScroll = (event) => {
    if (event.target.scrollTop > 0) {
      if (!scrollButton) {
        pageNavRef.current.setValue(true)
      }
    } else {
      pageNavRef.current.setValue(false)
    }
  }

  function changeFilter(type, index) {
    // type param is used to diffrentiate between each property, basically each array category
    // index is to change the value of the respective index within that array category
    let updatedFilterManifest = JSON.parse( JSON.stringify(filterSearchManifest) );

    if(type === 1) {
      updatedFilterManifest.files[index].enabled = !updatedFilterManifest.files[index].enabled;
    }

    else if(type === 3) {
      updatedFilterManifest.servicesCategory[index].enabled = !updatedFilterManifest.servicesCategory[index].enabled;
    }

    else if(type === 4) {
      updatedFilterManifest.services[index].enabled = !updatedFilterManifest.services[index].enabled;
    }
    
    const filterDataPromise = new Promise((resolve, reject) => {
      const resolved = true;
      if(resolved) {
        setLoader(true);
        resolve('success')
      }
      else {
        reject('promise is rejected')
      }
    })

    filterDataPromise.then((resolved) => {
      filterDataPool(updatedFilterManifest);
      setFilterSearchManifest(updatedFilterManifest);
    }).catch(rejection => { 
    })
  }

  function filterDataPool( updatedFilterManifest ) {
    // we use the manifest to update the datapool 
    const newDataPool = JSON.parse( JSON.stringify(originalServiceData) );
    let updatedDataPool = [];
    const userData = JSON.parse( localStorage.getItem('loggedInUserDetails') );
    let matchFound = false;

    if(updatedFilterManifest.files[1].enabled) {
      // first we empty previous service data 
      newDataPool.forEach(item => {
        // we keep buildings and projects but not the services
        item.projects.forEach(item => {
          item.projectServices = [];
        })
      })

      matchFound = true;
      // if my files is selected then apply filter, regardless if previous checkbox is selected
      originalServiceData.forEach((building, buildingIndex) => {
        building.projects.forEach((project, projectIndex) => {
          project.projectServices.forEach((service, serviceIndex) => {
            if(service.assigned_admin_id === userData.id) {
              newDataPool[buildingIndex].projects[projectIndex].projectServices.push(service)
            }
          })
        })
      })
    }

    matchFound = false;
    updatedDataPool = JSON.parse( JSON.stringify(newDataPool) )
    // first we empty previous service data 
    newDataPool.forEach(item => {
      // we keep buildings and projects but not the services
      item.projects.forEach(item => {
        item.projectServices = [];
      })
    })

    updatedFilterManifest.status.forEach((item, index) => {
      if(item.enabled) {
        matchFound = true;
        updatedDataPool.forEach((building, buildingIndex) => {
          building.projects.forEach((project, projectIndex) => {
            project.projectServices.forEach((service, serviceIndex) => {
              if(service.service_status_id === item.id) {
                newDataPool[buildingIndex].projects[projectIndex].projectServices.push(service)
              }
            })
          })
        })
      }
    })

    if(matchFound) {
      updatedDataPool = JSON.parse( JSON.stringify(newDataPool) )
      matchFound = false;
    }

    // first we empty previous service data 
    newDataPool.forEach(item => {
      // we keep buildings and projects but not the services
      item.projects.forEach(item => {
        item.projectServices = [];
      })
    })

    updatedFilterManifest.servicesCategory.forEach(item => {
      if(item.enabled) {
        matchFound = true;
        updatedDataPool.forEach((building, buildingIndex) => {
          building.projects.forEach((project, projectIndex) => {
            project.projectServices.forEach((service, serviceIndex) => {
              if(service.service.serviceCategoryId === item.id) {
                newDataPool[buildingIndex].projects[projectIndex].projectServices.push(service)
              }
            })
          })
        })
      }
    })

    if(matchFound) {
      updatedDataPool = JSON.parse( JSON.stringify(newDataPool) )
      matchFound = false;
    }

    // first we empty previous service data 
    newDataPool.forEach(item => {
      // we keep buildings and projects but not the services
      item.projects.forEach(item => {
        item.projectServices = [];
      })
    })

    updatedFilterManifest.services.forEach(item => {
      if(item.enabled) {
        matchFound = true;
        updatedDataPool.forEach((building, buildingIndex) => {
          building.projects.forEach((project, projectIndex) => {
            project.projectServices.forEach((service, serviceIndex) => {
              if(service.serviceId === item.id + 1) {
                newDataPool[buildingIndex].projects[projectIndex].projectServices.push(service)
              }
            })
          })
        })
      }
    })

    if(matchFound) {
      updatedDataPool = JSON.parse( JSON.stringify(newDataPool) )
      matchFound = false;
    }

    if(selectedTab !== 1) {
      updatedDataPool.forEach(building => {
        building.projects.forEach(project => {
          project.projectServices = project.projectServices.filter(service => service.service_status_id === (selectedTab - 2))
        })
      })
    }

    setTimeout(() => {
      setLoader(false)
    }, 100);
    searchRef.current.value = "";
    setDataPool(updatedDataPool)
    setSearchDataPool(updatedDataPool)
  }

  function changeTabs(tab) {
    if(selectedTab !== tab) {
      if(tab === 1) {
        const changeTabPromise = new Promise((resolved, rejected) => {
          const success = true;
          if(success) {
            setLoader(true)
            resolved(true)
          }
          else {
            rejected('failed')
          }
        })
        changeTabPromise.then(resolved => {
          setTimeout(() => {
            setLoader(false)
          }, 100);
          setSelectedTab(tab)
        })
      }
      else {
        const changeTabPromise = new Promise((resolved, rejected) => {
          const success = true;
          if(success) {
            setLoader(true)
            resolved(true)
          }
          else {
            rejected('failed')
          }
        })
        
        changeTabPromise.then(resolved => {
          const newServiceData = JSON.parse(JSON.stringify(originalServiceData))
          newServiceData.forEach(building => {
            building.projects.forEach(project => {
              project.projectServices = project.projectServices.filter(service => service.service_status_id === (tab - 2))
            })
          })

          setTimeout(() => {
            setLoader(false)
          }, 100);
          setSelectedTab(tab)
        })
      }
    }
  }

  return (
    <Grid container>
      <Grid container>
          <Hidden smDown>
              <Header />
          </Hidden>
          <Hidden mdUp>
              <Header toShowHamburger isLogoEnabled />
          </Hidden>
      </Grid>
      <Grid container item sm={12}>
        <div className="services-container">
          {originalServiceData.length > 0 && originalServiceData ? (
            <Grid container item sm={12} className="height-disable">
              <Grid container item sm={12}>
                <div
                  className="margin-left-15 flex-column services-details-container fade-in-right"
                >
                  <div className="margin-top-15 width-auto" />
                  <div className="width-auto flex-row horizontal-start">
                    <div className="services-search-container vertical-center margin-left-30 margin-right-30">
                      <div className="services-search-icon margin-left-15">
                        <img
                          src={require('../../../assets/svg/icon/search.svg')}
                          alt="search"
                          className="height-auto width-auto object-fit-contain"
                        />
                      </div>
                      <input
                        className="width-auto margin-left-15 margin-right-15 main-font-regular font-size-14 font-color-grey"
                        placeholder="Zoeken"
                        onChange={handleSearch}
                        ref = {searchRef}
                      />
                    </div>
                    <Hidden smDown>
                      <FilterDropdown servicesData = {servicesData} serviceCategoryData = {serviceCategoryData} changeFilter = {changeFilter} />
                      <button type = "button" className = "services-form center-content cursor-pointer margin-left-15" onClick={()=>{history.push('/project-application/true')}}>
                        <h1 className = "main-font-semi-bold font-size-14 font-color-white margin-disable">Nieuwe aanvraag</h1>
                      </button>
                    </Hidden>
                  </div>
                  {/* tabs here */}
                  <ServicesTab selectedTab = {selectedTab} changeTabs = {changeTabs} />
                  <div className="margin-top-25 width-auto" />
                  <Hidden smDown>
                    <div className="flex-row vertical-end margin-left-10 margin-right-25">
                      <div className="services-details-name flex-row vertical-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Dienst
                        </p>
                      </div>
                      <div className="services-details-file horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Dossier
                        </p>
                      </div>
                      <div className="services-details-file-name horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Dossiernaam
                        </p>
                      </div>
                      <div className="services-details-customer horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Klant
                        </p>
                      </div>
                      <div className="services-details-responsible horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Verantw.
                        </p>
                      </div>
                      <div className="services-details-doc horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Doc.
                        </p>
                      </div>
                      <div className="services-details-message horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Bericht
                        </p>
                      </div>
                      <div className="services-details-date horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12 center-text">
                          Inspectie Datum
                        </p>
                      </div>
                      <div className="services-details-timeline horizontal-center">
                        <p className="font-color-grey main-font-regular font-size-12">
                          Tijdslijn
                        </p>
                      </div>
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <div className="flex-row vertical-end">
                      <div className="services-details-name flex-row vertical-center">
                        <img
                          src={
                            selectedTab === 1 ? require('../../../assets/svg/icon/all_icon_color.svg') :
                            selectedTab === 2 ? require('../../../assets/svg/icon/loader.svg') :
                            selectedTab === 3 ? require('../../../assets/svg/icon/load.svg') : 
                            selectedTab === 4 ? require('../../../assets/svg/icon/check_green.svg') :
                            selectedTab === 5 ? require('../../../assets/svg/icon/onhold.svg') :
                            selectedTab === 6 ? require('../../../assets/svg/icon/cancel.svg') :
                            selectedTab === 7 ? require('../../../assets/svg/icon/offerte_color.svg') :
                            selectedTab === 8 ? require('../../../assets/svg/icon/offerteaanvaard_color.svg') :
                            selectedTab === 9 ? require('../../../assets/svg/icon/gefactureerd_color.svg') :
                            require('../../../assets/svg/icon/betaald_color.svg')
                          }
                          alt="title icons"
                          className="services-title-icon margin-top-4 margin-right-10"
                        />
                        <h1 className="main-font-semi-bold font-size-18 font-color-blue" style = {{color: selectedTab === 1 ? '#7da5c2' : selectedTab === 2 ? '#508CD0' : selectedTab === 3 ? '#9A8A55' : selectedTab === 4 ? '#7BB84D' : selectedTab === 5 ? '#686886' : selectedTab === 6 ? '#B30419' : selectedTab === 7 ? '#A7796D' : selectedTab === 8 ? '#AC9489' : selectedTab === 9 ? '#698886' : '#325758' }}>
                          {`${selectedTab === 1 ? 'Alle' :  selectedTab === 2 ? 'Ontvangen' : selectedTab === 3 ? 'In behandeling' : selectedTab === 4 ? 'Afgewerkt' : selectedTab === 5 ? 'On hold' : selectedTab === 6 ? 'Geannuleerd' : selectedTab === 7 ? 'Offerte' : selectedTab === 8 ? 'Offerte aanvaard' : selectedTab === 9 ? 'Gefactureerd' : 'Betaald'} `}
                        </h1>
                      </div>
                      <div className='width-auto horizontal-end'>
                        <button type = "button" className = "services-form center-content cursor-pointer margin-left-5 margin-right-15 " onClick={()=>{history.push('/project-application/true')}} style = {{background: selectedTab === 1 ? '#7da5c2' : selectedTab === 2 ? '#508CD0' : selectedTab === 3 ? '#9A8A55' : selectedTab === 4 ? '#7BB84D' : selectedTab === 5 ? '#686886' : selectedTab === 6 ? '#B30419' : selectedTab === 7 ? '#A7796D' : selectedTab === 8 ? '#AC9489' : selectedTab === 9 ? '#698886' : '#325758' }}>
                          <img alt = 'add' src={require('../../../assets/svg/icon/Add.svg')} className='services-details-icon margin-left-5' />
                        </button>
                      </div>
                    </div>
                  </Hidden>
                  <div className="flex-row width-auto">
                    <div className="margin-top-15" />
                  </div>
                  
                  {!loader ? (
                    <>
                    <div className = "services-category-item-container" onScroll={handleScroll} ref={scrollAreaRef}>
                      {searchDataPool.length > 0 && searchDataPool.map((building, buildingIndex) => {
                        return building.projects.map((project, projectIndex) => {
                          return project.projectServices !== undefined && project.projectServices.length > 0 && project.projectServices.map((service, serviceIndex) => {
                            return service.service !== null && service.service.title !== undefined ? (
                              <Grid container>
                                <Hidden smDown>
                                  <Service 
                                    statusRef = {statusRef}
                                    buildingData = {building}
                                    changeCategory={changeCategory}
                                    deleteService = {deleteService}
                                    buildingIndex = {buildingIndex}
                                    searchDataPool = {searchDataPool}
                                    setSearchDataPool = {setSearchDataPool}
                                    projectIndex = {projectIndex}
                                    serviceIndex = {serviceIndex}
                                    responsibleList = {adminData}
                                    zIndex = {((searchDataPool.length - buildingIndex) * 10) + (project.projectServices.length - serviceIndex)}
                                  />
                                </Hidden>
                                <Hidden mdUp>
                                  <ServiceMobile 
                                  buildingData = {building}
                                  changeCategory={changeCategory}
                                  deleteService = {deleteService}
                                  buildingIndex = {buildingIndex}
                                  searchDataPool = {searchDataPool}
                                  setSearchDataPool = {setSearchDataPool}
                                  projectIndex = {projectIndex}
                                  serviceIndex = {serviceIndex}
                                  responsibleList = {adminData}
                                />
                                </Hidden>
                              </Grid>
                            ) : null
                          })
                        })
                      })}
                    </div>

                    <div className="flex-row width-auto">
                      <div className="margin-top-20" />
                    </div>
                    </>
                  ) : (
                    <div className = "height-auto width-auto center-content">
                      <img src = {require('../../../assets/svg/icon/loading.gif')} className = "services-loader" alt = "loader" />
                    </div>
                  )}
                </div>
                <StatusDisplay ref = {statusRef} />
                <PageNavigator ref = {pageNavRef} scrollAreaRef = {scrollAreaRef} />
              </Grid>
            </Grid>
          ) : (
            <div className = "height-auto width-auto center-content">
              <img src = {require('../../../assets/svg/icon/loading.gif')} className = "services-loader" alt = "loader" />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

Service.propTypes = {
  data: PropTypes.object,
}

Service.defaultProps = {
  data: {
    id: 0,
    name: 'EPB-verslaggeving',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 'Bedrijfsnaam',
    responsible: '-',
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Startverklaring',
    type: 1,
  },
}
ArrowLeft.propTypes = {
  rotate: PropTypes.bool,
}

ArrowLeft.defaultProps = {
  rotate: false,
}

Service.propTypes = {
  data: PropTypes.object,
  responsibleList: PropTypes.array,
}

Service.defaultProps = {
  data: {
    id: 0,
    name: 'EPB-verslaggeving',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 'Bedrijfsnaam',
    responsible: null,
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Startverklaring',
    type: 1,
    responsible_list: [
      {
        id: 0,
        name: 'Cristine',
      },
      {
        id: 1,
        name: 'Albert',
      },
    ],
  },
  responsibleList: []
}

Services.propTypes = {
  services: PropTypes.array,
}

Services.defaultProps = {
  services: []
}

CheckBox.propTypes = {
  type: PropTypes.number,
  index: PropTypes.number,
  changeFilter: PropTypes.func,
}

CheckBox.defaultProps = {
  type: 1,
  index: 0,
  changeFilter: () => {},
}
