          import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
          import { Grid } from '@material-ui/core'
          import './_custom_checkbox.scss'

          export const CustomCheckBox = forwardRef((props, ref) => {
            const { label, value, disabled } = props
            const [isChecked, setValue] = useState(false)

            useEffect(()=>{
              if(value!==undefined){
                setValue(value)
              }
            },[value])

            useImperativeHandle(ref, () => ({
              getValue() {
                return isChecked
              },
            }))

            const handleChange = () => {
              setValue(!isChecked)
            }
            return (
              <Grid direction="column">
                <div className="flex center-content cursor-pointer">
                  <div
                  
                    className={`epb-service-checkbox-${
                      isChecked ? 'filled' : 'outline'
                    } center-content`}
                    onClick={() => {
                      !disabled && handleChange()
                    }}
                  >
                    {isChecked && (
                      <img
                        alt = 'button_check'  
                        className="epb-service-checkbox-filled-image"
                        src={require('../../../assets/svg/icon/button_check.svg')}
                      />
                    )}
                  </div>
                  <p className="font-color-black margin-left-10 font-size-14 vietnam-regular">
                    {label}
                  </p>
                </div>
              </Grid>
            )
          })
