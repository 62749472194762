import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

import './_calendar.scss';

function WeeklyCard( props ) {
    const [ dropdownState, setDropdownState ] = useState(false);
    const [ cardHover, setCardHover ] = useState(false)
    const [ rendered, setRendered ] = useState(false)

    const { cardProps, data, getDayName, cleanDateTimeString, cleanTimeString, index, editEvent, setVariant, color } = props;
    const dropdownRef = useRef(0);

    useEffect(() => {
        setTimeout(() => {
            setRendered(true)
        }, 1000);
    }, [])

    const handleDropdown = () => {
        setDropdownState( !dropdownState );
    }

    const closeDropdown = () => {
        setDropdownState(false);
    }   

    const handleEdit = () => {
        editEvent(data[index]);
        setVariant(2);
    }

    const handleCardHoverOver = () => {
        setCardHover(true)
    }

    const handleCardHoverLeave = () => { 
        setCardHover(false)
    }

    return(
        <div className = {`calendar-week-graph-card padding-left-10 padding-top-5 cursor-pointer animatable-400 ease-in-out-quart ${!rendered && 'fade-in-translate'} relative`} onMouseOver = {handleCardHoverOver} onMouseLeave = {handleCardHoverLeave} tabIndex = {index.toString()} ref = {dropdownRef} onClick = {handleDropdown} onBlur = {closeDropdown} style = {{width: `calc(calc(100% - 66px) / 7)`, height: cardHover ? `100px` : `${cardProps.cardHeight}%`, top: `${cardProps.cardOffsetY}%`, left: `calc(calc(calc(calc(100% - 66px) / 7) * ${cardProps.cardOffsetX}) + 66px)`, zIndex: cardHover ? 9999 : index, background: cardHover ? '#193E5D' : color || null, transform: cardHover ? 'scale(1.2)' : 'scale(1)' }}> 
            <div className = "height-auto width-auto">
                <p className = "main-font-regular font-size-12 font-color-black animatable-400 ease-in-out-quart" style = {{color: cardHover ? 'white' : 'black'}}>{`${cardProps.start_time} - ${cardProps.end_time}`}</p>
                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable animatable-400 ease-in-out-quart" style = {{color: cardHover ? 'white' : 'black'}}>{cardProps.building_name}</h1>
                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable animatable-400 ease-in-out-quart" style = {{color: cardHover ? 'white' : 'black'}}>{cardProps.service}</h1>
            </div>
            <div className = "calendar-week-info-container animatable-400 ease-in-out-quart" style = {{height: dropdownState ? '145px' : '0px', opacity: dropdownState ? 1 : 0}} >
                <div className = "flex-row width-auto horizontal-end">
                    <img src = {require('../../../../assets/svg/icon/corner.png')} alt = "corner" className = "calendar-week-info-arrow" />
                </div>
                <div className = "calendar-week-info">
                    <div className = "flex-column width-auto horizontal-between height-auto">
                        <div className = "flex-column width-auto">
                            <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{data[index] && data[index].service_name}</h1>
                            <p className = "main-font-regular font-size-12 font-color-black margin-top-5">{data[index] && data[index].company_name}</p>
                            <p className = "main-font-regular font-size-12 font-color-black margin-top-5">{data[index] && data[index].user.firstName}</p>
                        </div>
                        <div className = "flex-row width-auto horizontal-between vertical-center">
                            <div className = "flex-column width-auto">
                                <p className = "main-font-regular font-size-12 font-color-grey">{getDayName(cardProps.cardOffsetX)} {cleanDateTimeString(data[index].start_date)}</p>
                                <p className = "main-font-regular font-size-12 font-color-grey margin-top-5">{data[index].is_full_day ? '08:00 - 18:00' : `${cleanTimeString(data[index].start_time)} - ${cleanTimeString(data[index].end_time)}`}</p>
                            </div>
                            <img alt = 'button_edit' src = {require('../../../../assets/svg/icon/button_edit.svg')} className = "calendar-week-info-edit cursor-pointer" onClick = {handleEdit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Calendar( props ) {
    // month calendar state variables
    const [ startDay, setStartDay ] = useState( new Date() );
    const [ endDay, setEndDay ] = useState( new Date() )
    const [ month, setMonth ] = useState(9)
    const [ year, setYear ]= useState(2021);
    const [ lastMonthEndDay, setLastMonthEndDay ] = useState( new Date() );

    // keep these in case the client wants to track month event activity
    const [ monthDataPool, setMonthDataPool ] = useState([]);

    // week calendar state variables 
    const [ weekStartDate, setWeekStartDate ] = useState( new Date() );
    const [ weekEndDate, setWeekEndDate ] = useState( new Date() );
    const [ weeklyCards, setWeeklyCards ] = useState([]);
    const [ isWeeklyStart, setIsWeeklyStart ] = useState(false)

    // props
    const { selectedResponsible, setSelectedResponsible, variant, setVariant, data, setMonthServiceDataPool, getMonthData, responsibleList, selectedDate, setSelectedDate, setNewEventState, setStartTime, setEndTime, editEvent } = props;
    
    // animated state variables here
    const [ monthAnimationState, setMonthAnimationState ] = useState(0)
    const [ weekAnimationState, setWeekAnimationState ] = useState(true);

    const calendarRef = useRef(0);

    useEffect(() => {
        if(variant === 0) {
            isWeeklyStart && setIsWeeklyStart(false);
            startingPoint();
        }   
        else if(variant === 1) {
            if(!isWeeklyStart) {
                getCurrentWeek();
            }
        }
    }, [variant])

    useEffect(() => {
        if(variant !== 0){
            generateNewServiceList()
        }
        else {
            selectedEventDate()
        }
    }, [selectedDate, data, selectedResponsible])

    function startingPoint() {
        // in this function we grab the current date to get the month and check the day of the starting date, and the day of ending date
        let currentDate = new Date();
        const startDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // we get 
        const endDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const lastMonthEndDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

        getMonthData(startDay,endDay)
        setStartDay( startDay );
        setEndDay( endDay );
        setMonth( currentDate.getMonth() );
        setYear( currentDate.getFullYear() )
        setLastMonthEndDay( lastMonthEndDay );
    }

    const previousMonth = () => {
        let prevStartDay = new Date( startDay.getFullYear(), startDay.getMonth() - 1, 1);
        let prevEndDay = new Date( endDay.getFullYear(), endDay.getMonth(), 0)
        let prevCurrentMonth;
        let prevYear = year;

        if((month - 1) >= 0) {
            prevCurrentMonth = month - 1;
        }   
        else {
            prevCurrentMonth = 11;
            prevYear = year - 1;
        }
        let prevLastMonthEndDay = new Date( lastMonthEndDay.getFullYear(), lastMonthEndDay.getMonth(), 0);

        getMonthData(prevStartDay, prevEndDay)
        setMonthAnimationState(1);
        setStartDay( prevStartDay );
        setEndDay( prevEndDay );
        setYear( prevYear );
        setLastMonthEndDay( prevLastMonthEndDay );
        
        setTimeout(() => {
            setMonth( prevCurrentMonth );
            setMonthAnimationState(0);
        }, 400)
    }

    const nextMonth = () => {
        let nextStartDay = new Date( startDay.getFullYear(), startDay.getMonth() + 1, 1);
        let nextEndDay = new Date( endDay.getFullYear(), endDay.getMonth() + 2, 0)
        let nextCurrentMonth;
        let nextYear = year;

        if((month + 1) <= 11) {
            nextCurrentMonth = month + 1;
        }   
        else {
            nextCurrentMonth = 0;
            nextYear = year + 1;
        }
        let nextLastMonthEndDay = new Date( lastMonthEndDay.getFullYear(), lastMonthEndDay.getMonth() + 2, 0);

        getMonthData(nextStartDay, nextEndDay)
        setMonthAnimationState(2)
        setStartDay( nextStartDay );
        setEndDay( nextEndDay );
        setYear( nextYear );
        setLastMonthEndDay( nextLastMonthEndDay );

        setTimeout(() => {
            setMonth( nextCurrentMonth );
            setMonthAnimationState(0);
        }, 400)
    }

    function selectedEventDate() {
        // this functions takes the data source and filters it via the selected data
        // this only loads the events for that specific days
        const originalData = JSON.parse( JSON.stringify(data) );
        const newDataPool = [];

        originalData.forEach(event => {
            // break the string format
            let date = event.start_date.split(" ");
            date = date[0].split("-");

            if(parseInt(date[0]) === selectedDate.getFullYear() && parseInt(date[1]) === (selectedDate.getMonth() + 1) && parseInt(date[2]) === selectedDate.getDate()) {
                newDataPool.push(event);
            }
        })
        setMonthServiceDataPool(newDataPool);
    }

    function generateEventActivity() {
        // this functions generates an array, the array is essentially synced to each day of the month where it contains the value of the activity from 1 - 3
        const newEventActivity = [];
        const originalData = JSON.parse( JSON.stringify(monthDataPool) );

        originalData.forEach(user => {
            user.assigned_services.forEach(service => {
                if(newEventActivity.length === 0) {
                    const activityObject = {
                        date: parseInt(service.date.split("/")[0]),
                        events: 1
                    }

                    newEventActivity.push(activityObject);
                }
                else {
                    let eventActivityExists = false
                    newEventActivity.forEach(item => {
                        if(item.date === parseInt(service.date.split("/")[0])) {
                            item.events = item.events + 1;
                            eventActivityExists = true;
                        }
                    })

                    if(!eventActivityExists) {
                        const activityObject = {
                            date: parseInt(service.date.split("/")[0]),
                            events: 1
                        }

                        newEventActivity.push(activityObject)
                    }
                }
            })
        })

        const cleanedNewEventActivity = []
        // now we sort it and and fill in the gaps
        let dataExists = false;
        // due to the semi async nature of state variables instead of using endDay state, we use a new date since endDay's value is being calculated and set within the same time span
        const lastDayOfMonth = endDay
        Array( lastDayOfMonth.getDate() ).fill().map( (_, index) => {
            newEventActivity.forEach(item => {
                // when a match is found for the current date
                if(index + 1 === item.date) {
                    const activityObject = {
                        date: item.date,
                        events: item.events,
                        status: item.events < 5 ? item.events < 3 ? 1 : 2 : 3
                    }

                    cleanedNewEventActivity.push(activityObject);
                    dataExists = true;
                }
            })
            // this means a match wasn't found
            if(!dataExists) {
                const activityObject = {
                    date: index + 1,
                    events: 0,
                    status: 1
                }

                cleanedNewEventActivity.push(activityObject);
            }
            // we reset this value for the next date
            dataExists = false;
        })
    }

    function getStringMonth( month ) {
        if(month === 0) return "januari";
        else if(month === 1) return "februari";
        else if(month === 2) return "maart";
        else if(month === 3) return "april";
        else if(month === 4) return "mei";
        else if(month === 5) return "juni";
        else if(month === 6) return "juli";
        else if(month === 7) return "augustus";
        else if(month === 8) return "sepetember";
        else if(month === 9) return "oktober";
        else if(month === 10) return "november";
        else if(month === 11) return "december";
    }

    function getStringMonthShort( month ) {
        if(month === 0) return "jan";
        else if(month === 1) return "febr";
        else if(month === 2) return "mrt";
        else if(month === 3) return "apr";
        else if(month === 4) return "mei";
        else if(month === 5) return "juni";
        else if(month === 6) return "juli";
        else if(month === 7) return "aug";
        else if(month === 8) return "sep";
        else if(month === 9) return "okt";
        else if(month === 10) return "nov";
        else if(month === 11) return "dec";
    }
    
    function getCurrentWeek() {
        // the week for this calendar starts on the monday, which has the value of 1
        const currentDate = new Date();
        let currentDay = currentDate.getDay();
        let weekStart, weekEnd;
        
        // three scenarios, if the day is already monday, then day is greater than monday, and the day is Sunday
        if(currentDay === 0) {
            // this is the last day of the week
            weekStart = new Date( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6);
            weekEnd = currentDate;
        }
        else if(currentDay > 1) {
            weekStart = new Date( currentDate.getFullYear(), currentDate.getMonth(), (currentDate.getDate() - currentDate.getDay()) + 1)
            // if we subtract the current date from current day we basically end up on the first day of that week, however since by default the first day of the week is Sunday, we manually add 1
            weekEnd = new Date( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (7 - currentDate.getDay()));
        }
        else {
            weekStart = currentDate;
            weekEnd = new Date( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 6)
        }

        getMonthData(weekStart, weekEnd)
        setYear(weekEnd.getFullYear())
        setWeekStartDate(weekStart);
        setWeekEndDate(weekEnd);
    }

    const previousWeek = () => {
        const weekStart = new Date( weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() - 7);
        const weekEnd = new Date( weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate() - 7);
        const year = weekStart.getFullYear();
        setWeekAnimationState(false);

        setTimeout(() => {
            !isWeeklyStart && setIsWeeklyStart(true);
            getMonthData(weekStart, weekEnd)
            setYear(year);
            setWeekStartDate(weekStart);
            setWeekEndDate(weekEnd);
            setWeekAnimationState(true);
        }, 400);
    }

    const nextWeek = () => {
        const weekStart = new Date( weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 7);
        const weekEnd = new Date( weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate() + 7);
        const year = weekStart.getFullYear();
        setWeekAnimationState(false);

        setTimeout(() => {
            !isWeeklyStart && setIsWeeklyStart(true);
            getMonthData(weekStart, weekEnd)
            setYear(year);
            setWeekStartDate(weekStart);
            setWeekEndDate(weekEnd);
            setWeekAnimationState(true);
        }, 400);
    }

    function generateCards( event ) {
        // time graph starts at 08:00 and ends at 18:00, so there is a 10 hour difference 
        // we convert everything to minutes, then we divide it from 10 hours and we get the height %, then we calculate the offset by subtracting the start time and 08:00
        let offsetY;
        let height;
        
        const totalMinutes = 11 * 60;
        if(event.is_full_day) {
            offsetY = 0;
            height = 100; 
        }
        else {
            let startTimeMinutes = event.start_time.split(":");
            startTimeMinutes[0] = parseInt(startTimeMinutes[0]);
            startTimeMinutes[0] = (startTimeMinutes[0] - 8) * 60; 
            startTimeMinutes = startTimeMinutes[0] + parseInt(startTimeMinutes[1]);
            
            let endTimeMinutes = event.end_time.split(":");
            endTimeMinutes[0] = parseInt(endTimeMinutes[0]);
            endTimeMinutes[0] = (endTimeMinutes[0] - 8) * 60; 
            endTimeMinutes = endTimeMinutes[0] + parseInt(endTimeMinutes[1]);
    
            offsetY = (startTimeMinutes/totalMinutes) * 100;
            height = ((endTimeMinutes - startTimeMinutes)/totalMinutes) * 100; 
        }
        
        let date = event.start_date.split(" ")[0].split("-");
        date = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]));
        const offsetX = date.getDay() === 0 ? 6 : date.getDay() - 1;

        return {
            cardOffsetY: offsetY,
            cardHeight: height + offsetY <= 100 ? (height < 5 ? 5 : height) : 100 - offsetY,
            cardOffsetX: offsetX,
            start_time: event.start_time.substring(0, 5),
            end_time: event.end_time.substring(0, 5),
            service: event.service_name,
            building_name: event.company_name,
            admin_id: event.user.id
        }
    }   

    const renderMonthCalendar = () => {
        setVariant(0)
    }

    const renderWeekCalendar = () => {
        setVariant(1)
    }

    function generateNewServiceList() {
        // we first create a data pool for the weekly planner, instead of going through all the data regardless if it's in the current week or not
        let dataPool = JSON.parse( JSON.stringify(data));
        let serviceList = [];
        
        if(selectedResponsible !== 0) {
            dataPool = dataPool.filter(event => event.user_id === selectedResponsible.id);
        }
        dataPool.forEach(event => {
            serviceList.push(generateCards(event))
        })
        serviceList.sort((a, b) => {
            return b.cardHeight - a.cardHeight;
        })
        
        // add a little delay so that the card list renders after the width of the calendar has changed
        setTimeout(() => {
            setWeeklyCards(serviceList)
        }, 500);
    }

    function newWeekData(day, time){
        setSelectedDate( new Date( weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + day, 12) )
        setStartTime( `${8 + time}:00`)
        setEndTime( `${9 + time}:00` )
        setNewEventState(true)
        setVariant(2);
    }   

    function getDayName( offsetX ) {
        if(offsetX === 0) return 'Ma.'
        else if(offsetX === 1) return 'Di.'
        else if(offsetX === 2) return 'Wo.'
        else if(offsetX === 3) return 'Do.'
        else if(offsetX === 4) return 'Vr.'
        else if(offsetX === 5) return 'Za.'
        else if(offsetX === 6) return 'Zo.'
    }

    function cleanDateTimeString(dateTime) {
        let newDateTime = dateTime.split(" ");
        newDateTime = {
            date: newDateTime[0].split("-"),
            time: newDateTime[1].split(":")
        }

        newDateTime.date = `${newDateTime.date[2]}/${newDateTime.date[1]}/${newDateTime.date[0]}`;
        newDateTime.time = `${newDateTime.time[0]}:${newDateTime.time[1]}`

        return `${newDateTime.date}`;
    }

    function cleanTimeString(time) {
        let newTime = time.split(":");

        return `${newTime[0]}:${newTime[1]}`
    }

    function getAdminColor(item) {
        let color = '#CCC'
        responsibleList.forEach((admin) => {
            if(item.admin_id === admin.id) {
                color = admin.color
            }
        })

        return color
    }

    return(
        <Grid container>
            <div className = "calendar-container animatable-400 ease-in-out-quart" ref = {calendarRef}>
                <Grid container item sm = {12} className = "height-disable">
                    <div className = "width-auto flex-row calendar-container-padding">
                        <Grid container item sm = {12}>
                            <Grid container item sm = {3}>
                                <div className = "flex-row">
                                    <button type = "button" className = "calendar-type-button center-content margin-right-5 animatable-400 ease-in-out-quart" style = {{border: variant !== 0 ? 'none' : 'solid 1px #7DA5C2'}} onClick = {renderMonthCalendar}>
                                        <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart" style = {{color: variant ? '#6F6F6F' : 'black'}}>Maand</p>
                                    </button>
                                    <button type = "button" className = "calendar-type-button-disabled center-content animatable-400 ease-in-out-quart" style = {{border: variant !== 0 ? 'solid 1px #7DA5C2' : 'none'}} onClick = {renderWeekCalendar} >
                                        <p className = "main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart" style = {{color: variant !== 0 ? 'black' : '#6F6F6F'}}>Week</p>
                                    </button>
                                </div>
                            </Grid>
                            <Grid container item sm = {9}>
                                <div className = "flex-row width-auto horizontal-end flex-wrap">
                                    <button type = "button" className = "calendar-admin-button-disabled center-content margin-right-5 animatable-400 ease-in-out-quart" style = {{border: selectedResponsible === 0 ? 'solid 1px #7DA5C2' : 'none'}} onClick = {() => { setSelectedResponsible(0) }} >
                                        <p className = "main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart" style = {{fontWeight: selectedResponsible === 0 ? '600' : '400', color: selectedResponsible === 0 ? 'black' : '#6F6F6F'}} >Iedereen</p>
                                    </button>
                                    {responsibleList.map(item => (
                                        <button type = "button" className = "calendar-admin-button center-content margin-right-5 animatable-400 ease-in-out-quart" style = {{border: selectedResponsible.id === item.id ? 'solid 1px #7DA5C2' : 'none'}} onClick = {() => { setSelectedResponsible(item) }}>
                                            <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart" style = {{fontWeight: selectedResponsible.id === item.id ? '600' : '400', color: selectedResponsible === item.id ? 'black' : '#6F6F6F'}}>{item.firstName}</p>
                                        </button>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {/* month calendar ui */}
                    {variant === 0 && (
                        <Grid container item sm = {12} className = "fade-in-translate padding-left-25 padding-right-25">
                            <Grid container item sm = {12} justify = "center">
                                <div className = "flex-column vertical-center">
                                    <div className = "flex-row vertical-center">
                                        <img src = {require('../../../../assets/svg/icon/arrow_left.svg')} alt = "arrow left" className = "calendar-month-arrow margin-right-10" onClick = {previousMonth} />
                                        <div className = "calendar-month-container center-content relative">
                                            <h1 className = "main-font-semi-bold font-size-18 font-color-black uppercase animatable-400 ease-in-out-quart absolute margin-disable" style = {{opacity: monthAnimationState === 1 ? 1 : 0, transform: monthAnimationState === 1 ? 'translateY(0px)' : 'translateY(-15px)', transitionDuration: monthAnimationState === 0 ? '0s' : '0.4s'}}>{getStringMonth(month - 1)}</h1>
                                            <h1 className = "main-font-semi-bold font-size-18 font-color-black uppercase animatable-400 ease-in-out-quart absolute margin-disable" style = {{opacity: monthAnimationState === 2 ? 1 : 0, transform: monthAnimationState === 2 ? 'translateY(0px)' : 'translateY(-15px)', transitionDuration: monthAnimationState === 0 ? '0s' : '0.4s'}}>{getStringMonth(month + 1)}</h1>
                                            <h1 className = "main-font-semi-bold font-size-18 font-color-black uppercase animatable-400 ease-in-out-quart absolute margin-disable" style = {{opacity: monthAnimationState === 0 ? 1 : 0, transform: monthAnimationState === 0 ? 'translateY(0px)' : 'translateY(15px)', transitionDuration: monthAnimationState === 0 ? '0s' : '0.4s'}}>{getStringMonth(month)}</h1>
                                        </div>
                                        <img src = {require('../../../../assets/svg/icon/arrow_left.svg')} alt = "arrow left" className = "calendar-month-arrow-reverse margin-left-10" onClick = {nextMonth} />
                                    </div>
                                    <div className = "flex-row">
                                        <h1 className = "main-font-semi-bold font-size-12 font-color-grey margin-top-1">{year}</h1>
                                    </div>
                                </div>
                            </Grid>
                            <Grid container item sm = {12} justify = "center">
                                <Grid container item sm = {12}>
                                    <div className = "margin-top-25" />
                                </Grid>
                                <div className = "calendar-date-container flex-wrap">
                                    <div className = "flex-row width-auto">
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Ma</p>
                                        </div>
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Di</p>
                                        </div>
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Wo</p>
                                        </div>
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Do</p>
                                        </div>
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Vr</p>
                                        </div>
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Za</p>
                                        </div>
                                        <div className = "calendar-date-column-days">
                                            <p className = "calendar-date main-font-regular">Zo</p>
                                        </div>
                                    </div>
                                    <div className = "flex-row width-auto flex-wrap">
                                        {/* render days that are from the previous month */}
                                        {startDay.getDay() === 0 ? 
                                            Array(6).fill().map((_, index) => (
                                                <div className = "calendar-date-column-one margin-top-15">
                                                    <p className = "main-font-semi-bold font-size-14 font-color-grey">{lastMonthEndDay.getDate() - (5 - index)}</p>
                                                </div>
                                        )) :  
                                            Array(startDay.getDay() - 1).fill().map((_, index) => (
                                                <div className = "calendar-date-column-one margin-top-15">
                                                <p className = "main-font-semi-bold font-size-14 font-color-grey">{lastMonthEndDay.getDate() - ((startDay.getDay() - 2) -  index)}</p>
                                                </div>
                                        ))}

                                        {/* render days that are from the current month */}
                                        {Array(endDay.getDate()).fill().map((item, index) => (
                                            <div className = "calendar-date-column-one relative center-content margin-top-15 cursor-pointer" onClick = {() => { setSelectedDate( new Date(year, month, index + 1, 12) )}}> {/* we define hours in front of days because of the timezone difference */}
                                                <div className = "calendar-date-selected animatable-400 ease-in-out-quart" style = {{opacity: index + 1 === selectedDate.getDate() ? 1 : 0}} />
                                                <p className = "main-font-semi-bold font-size-14 font-color-black animatable-400 ease-in-out-quart relative" style = {{color: index + 1 === selectedDate.getDate() ? 'white' : 'black'}}>{index + 1}</p>
                                                {/* <div className = "calendar-date-state" style = {{background: item.status === 1 ? '#8DD5BE' : item.status === 2 ? '#DCA64C' : '#D70000'}} /> */}
                                            </div>
                                        ))}

                                        {/* render days that are from the next month */}
                                        {endDay.getDay() === 0 ? 
                                            Array(6).fill().map((_, index) => (
                                                <>
                                                </>
                                        )) :  
                                            Array(7 - endDay.getDay()).fill().map((_, index) => (
                                                <div className = "calendar-date-column-one margin-top-15">
                                                    <p className = "main-font-semi-bold font-size-14 font-color-grey">{index + 1}</p>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        </Grid> 
                    )}
                    {variant !== 0 && (
                        <Grid container item sm = {12} className = "fade-in-translate">
                            <Grid container item sm = {12} justify = "center">
                                <div className = "flex-column vertical-center">
                                    <div className = "flex-row vertical-center">
                                        <img src = {require('../../../../assets/svg/icon/arrow_left.svg')} alt = "arrow left" className = "calendar-month-arrow margin-right-10" onClick = {previousWeek} />
                                        <div className = "calendar-week-container center-content relative">
                                            <h1 className = "main-font-semi-bold font-size-18 font-color-black uppercase animatable-400 ease-in-out-quart margin-disable" style = {{opacity: weekAnimationState ? 1 : 0, transform: weekAnimationState ? 'translateY(0px)' : 'translateY(15px)'}} >{`${getStringMonthShort(weekStartDate.getMonth())} ${weekStartDate.getDate()} - ${getStringMonthShort(weekEndDate.getMonth())} ${weekEndDate.getDate()}`}</h1> 
                                        </div>
                                        <img src = {require('../../../../assets/svg/icon/arrow_left.svg')} alt = "arrow left" className = "calendar-month-arrow-reverse margin-left-10" onClick = {nextWeek} />
                                    </div>
                                    <div className = "flex-row">
                                        <h1 className = "main-font-semi-bold font-size-12 font-color-grey margin-top-1">{year}</h1>
                                    </div>
                                </div>
                            </Grid>
                            <Grid container item sm = {12}>
                                <div className = "calendar-week-strip margin-top-10">
                                    <Grid container item sm = {12} className = "height-auto">
                                        <div className = "calendar-week-time-container" />
                                        <div className = "calendar-week-day-container">
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Ma.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate()).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate()).getMonth() + 1}`}</h1>
                                            </div>
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Di.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 1).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 1).getMonth() + 1}`}</h1>
                                            </div>
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Wo.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 2).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 2).getMonth() + 1}`}</h1>
                                            </div>
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Do.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 3).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 3).getMonth() + 1}`}</h1>
                                            </div>
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Vr.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 4).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 4).getMonth() + 1}`}</h1>
                                            </div>
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Za.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 5).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 5).getMonth() + 1}`}</h1>
                                            </div>
                                            <div className = "calendar-week-day center-content">
                                                <p className = "main-font-regular font-size-12 font-color-black margin-bottom-5">Zo.</p>
                                                <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{`${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 6).getDate()}/${new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate() + 6).getMonth() + 1}`}</h1>
                                            </div>
                                        </div>
                                    </Grid>
                                </div>
                                <div className = "calendar-week-graph-container width-auto relative">
                                    {Array(11).fill().map((_, index) => (
                                        <Grid container item sm = {12}>
                                            <div className = {`calendar-week-time-container center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`}>
                                                <p className = "main-font-regular font-size-12 font-color-black">{`${index < 2 ? '0' : ''}${8 + index}:00`}</p>
                                            </div>
                                            <div className = "calendar-week-day-container">
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(0, index) }} >

                                                </div>
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(1, index) }} >

                                                </div>
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(2, index) }} >

                                                </div>
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(3, index) }} >

                                                </div>
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(4, index) }} >

                                                </div>
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(5, index) }} >

                                                </div>
                                                <div className = {`calendar-week-day center-content ${index === 0 ? 'calendar-week-graph-border-quarter' : 'calendar-week-graph-border-half'}`} onClick = {() => { newWeekData(6, index) }} >

                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                    {/* the width is set dynamically through a ref, the left offset is handled through ref as well, top offset is controlled through index and fixed px, 
                                        is calculated with respect to total time */}
                                    {data.length > 0 && weeklyCards.length > 0 && data.length === weeklyCards.length && weeklyCards.map((item, index) => (
                                        <WeeklyCard cardProps = {item} calendarRef = {calendarRef} data = {data} index = {index} color = {getAdminColor(item)} getDayName = {getDayName} cleanDateTimeString = {cleanDateTimeString} cleanTimeString = {cleanTimeString} editEvent = {editEvent} setVariant = {setVariant} />
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

Calendar.propTypes = {
    selectedResponsible: PropTypes.number,
    setSelectedResponsible: PropTypes.func,
    variant: PropTypes.bool,
    setVariant: PropTypes.func,
}

Calendar.defaultProps = {
    selectedResponsible: 0,
    setSelectedResponsible: () => {},
    variant: false,
    setVariant: () => {}
}