import React, { useState, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import axios from "axios";
import PropTypes from "prop-types";
import './_row_details.scss'
import OnHoldStatusIcon from '../../../../../../assets/svg/Status/onhold.svg'
import FinishedStatusIcon from '../../../../../../assets/svg/Status/afgewerkt.svg'
import AttentiveStatusIcon from '../../../../../../assets/svg/Status/ontvangen.svg'
import ContinueStatusIcon from '../../../../../../assets/svg/Status/behandeling.svg'
import CanceledStatusIcon from '../../../../../../assets/svg/Status/geannuleerd.svg'
import OfferteStatusIcon from '../../../../../../assets/svg/Status/offerte.svg'
import OfferteAanvardStatusIcon from '../../../../../../assets/svg/Status/offerteaanvaard.svg'
import GefacturedStatusIcon from '../../../../../../assets/svg/Status/gefactureerd.svg'
import BetaaldStatusIcon from '../../../../../../assets/svg/Status/betaald.svg'

function PIDButton( props ) {
  const [ toggleState, setToggleState ] = useState(false)
  const { value, updatePid } = props;
  
  useEffect(() => {
    setToggleState(value || false)
  }, [value])

  const handleClick = () => {
    updatePid(!toggleState)
    setToggleState(!toggleState)
  }

  return(
    <button type = "button"  onClick = {handleClick} >
      <div className = "PID-button center-content animatable-400 ease-in-out-quart" style = {{background: toggleState ? '#7DA5C2' : 'rgba(0, 0, 0, 0)'}} >
        <img src = {require('../../../../../../assets/svg/icon_check_white.svg')} alt = "check white" className = "PID-button-check" />
      </div>
    </button>
  )
}

export default function RowDetails(props) {
  const { file, userDetails, index, deleteFile, isSafety, token, updateDocumentList } = props
  const [isImageClicked, setImageState] = useState(false)
  const [fileHover, setFileHover] = useState(0)
  const [showMenu, setShowMenu] = useState(0)
  const [ renderItem, setRenderItem ] = useState(false)
  const [statusFilter, setStatusFilter] = useState(false)
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const [isMouseOver, setMouseOverState] = useState(false)

  const nameFilterRef = useRef(0)
  const menuFilterRef = useRef(0)

  useEffect(() => {
    if(typeof file === "object" && file.file !== undefined){
      setRenderItem(true)
    }
  }, [file])
  const handleFileDelete = () => {
    let data = {
      index: index,
      documentId: file.id,
    }
    deleteFile(data)
  }

  function updatePid(value) {
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/service/updatePid', {
      document_id: file.id,
      pid: value,
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).catch(error => {
    })
  }

  // const handleStatusFilter = () => {
  //   if(statusFilter === index + 1){
  //     setStatusFilter(0)
  //   }
  //   else{
  //     if (!statusControlBlur) {
  //       nameFilterRef.current.focus()
  //       setStatusControlBlur(true)
  //       setStatusFilter(index + 1)
  //     } else {
  //       setStatusControlBlur(false)
  //     }
  //   }
  // }

  // const handleStatusFilterBlur = () => {

  //   setStatusControlBlur(false)
  //   // setStatusFilter(0)
  // }

  function changeCategory(id, status_id) {
    let token = localStorage.getItem('authToken')
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/documents/update_status', {
      // axios.post('http://localhost:9000/documents/update_status', {
      id: id,
      status_id: status_id,  
    }, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      setStatusFilter(0)
      setForceUpdateState(!forceUpdateState)
      updateDocumentList(!forceUpdateState)
    }).catch(error => {
    })
  }

  function showPID(file) {
    let extension = file.file.split('.');
    extension = extension[extension.length - 1];
    if(extension === "pdf" || extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "bmp") {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    renderItem && (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      alignItems="center"
      justify = 'center'
      
      onMouseOver={() => {
        setFileHover(index + 1)
      }}
      onMouseLeave={() => {
        setFileHover(0)
      }}
    >
        <Grid container xs = {11} sm = {11} md = {11} className = 'row-detail-card'>

          <div className = 'flex-row width-auto height-auto vertical-center horizontal-center'>
          <div className = 'flex-row width-auto height-auto vertical-center'>
            <div className = 'flex-row vertical-center horizontal-end width-10-p'>
                {!file.adminRead && <div className="documents-panel-file-notif margin-right-10" />}
              
                {file.path !== undefined && file.path !== null ? (
                  <img
                    alt=""
                    className="document-details-image margin-right-10"
                    src={
                      file.path.split('.')[1] === 'pdf'
                        ? require('../../../../../../assets/svg/doc/pdf.svg')
                        : file.path.split('.')[1].includes('doc')
                        ? require('../../../../../../assets/svg/doc/doc.svg')
                        : file.path.split('.')[1].includes('xls')
                        ? require('../../../../../../assets/svg/doc/xls.svg')
                        : file.path.split('.')[1].includes('jpg')
                        ? require('../../../../../../assets/svg/doc/jpg.svg')
                        : require('../../../../../../assets/svg/doc/other.svg')
                    }
                  />
                ) : (
                  <>
                    {file.file !== null && (
                      <img
                        alt=""
                        className="document-details-image margin-right-10"
                        src={
                          file.file.split('.')[1] === 'pdf'
                            ? require('../../../../../../assets/svg/doc/pdf.svg')
                            : file.file.split('.')[1].includes('doc')
                            ? require('../../../../../../assets/svg/doc/doc.svg')
                            : file.file.split('.')[1].includes('xls')
                            ? require('../../../../../../assets/svg/doc/xls.svg')
                            : file.file.split('.')[1].includes('jpg')
                            ? require('../../../../../../assets/svg/doc/jpg.svg')
                            : require('../../../../../../assets/svg/doc/other.svg')
                        }
                      />
                    )}
                  </>
                )}
              </div>
              <p className="document-details-file-name vietnam-regular ">
                {file.title !== null ? file.title : '-'}
              </p>
          </div>
            <Hidden xsDown>
              <div className = 'flex-column width-auto vertical-end'>
                <div className = 'flex-row'>
                  <div className=" flex-row relative width-auto height-auto" onClick={() => {
                        if (statusFilter === index + 1) {
                          setStatusFilter(0)
                        } else {
                          nameFilterRef.current.focus()
                          setStatusFilter(index + 1)
                        }
                      }} >
                      {file.status_id === 1 ? (
                        <img
                          src={ContinueStatusIcon}
                          alt="Continued"
                          className=" status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ) : file.status_id === 0 ? (
                        <img
                          src={AttentiveStatusIcon}
                          alt="Attentive"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ) : file.status_id === 2 ? (
                        <img
                          src={FinishedStatusIcon}
                          alt="Finished"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ) : file.status_id === 3 ? (
                        <img
                          src={OnHoldStatusIcon}
                          alt="Hold"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ) : file.status_id === 4 ?  (
                        <img
                          src={CanceledStatusIcon}
                          alt="Canceled"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ) : file.status_id === 5 ? (
                        <img
                          src={OfferteStatusIcon}
                          alt="offerte"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ): file.status_id === 6 ? (
                        <img
                          src={OfferteAanvardStatusIcon}
                          alt="offert aanvard"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ): file.status_id === 7 ? (
                        <img
                          src={GefacturedStatusIcon}
                          alt="gefactureed"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      ): (
                        <img
                          src={BetaaldStatusIcon}
                          alt="betaald"
                          className="status-icon margin-right-5 cursor-pointer"
                          
                        />
                      )}
                      
                        <div
                          className="status-details-name-filter-container animatable-400 ease-in-out-quart"
                          ref={nameFilterRef}
                          tabIndex="1"
                          onBlur={() => {setStatusFilter(0)}}
                          style={{
                            height:
                              statusFilter === index + 1 
                                ? '250px'
                                : '1px',
                            opacity: statusFilter === index + 1  ? 1 : 0,
                          }}
                        >
                          { statusFilter === index + 1 && (
                          <>
                            <div className="flex-row width-auto horizontal-end">
                              <img
                                src={require('../../../../../../assets/svg/icon/corner.png')}
                                alt="corner"
                                className="status-details-name-filter-arrow"
                              />
                            </div>
                            <div className="status-details-name-filter vertical-center">
                            <Grid
                              container
                              item
                              sm={12}
                              direction="column"
                              alignItems="flex-end"
                            >
                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 0)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  Ontvangen
                                </p>
                                <div className="status-details-name-filter-bubble background-color-faded-blue" />
                              </div>
                              <div className="flex-row">
                                <div className="status-details-name-filter-line" />
                              </div>

                              {
                                <React.Fragment>
                                  <div
                                    className="flex-row vertical-center cursor-pointer"
                                    onClick={() => {
                                      changeCategory(file.id, 5)
                                    }}
                                  >
                                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                      Offerte
                                    </p>
                                    <div className="services-details-name-filter-bubble" style = {{background: 'rgb(242, 187, 173)'}} />
                                  </div>
                                  <div className="flex-row">
                                    <div className="services-details-name-filter-line" />
                                  </div>
                                  <div
                                    className="flex-row vertical-center cursor-pointer"
                                    onClick={() => {
                                      changeCategory(file.id, 6)
                                    }}
                                  >
                                    <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                      Offerte aanvaard
                                    </p>
                                    <div className="services-details-name-filter-bubble " style = {{background: 'rgb(251, 220, 206)'}} />
                                  </div>
                                  <div className="flex-row">
                                    <div className="services-details-name-filter-line" />
                                  </div>
                                </React.Fragment>
                              }

                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 1)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  In behandeling
                                </p>
                                <div className="status-details-name-filter-bubble background-color-faded-tan" />
                              </div>
                              <div className="flex-row">
                                <div className="status-details-name-filter-line" />
                              </div>
                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 2)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  Afgewerkt
                                </p>
                                <div className="status-details-name-filter-bubble background-color-faded-green" />
                              </div>
                              <div className="flex-row">
                                <div className="status-details-name-filter-line" />
                              </div>
                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 7)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  Gefactureerd
                                </p>
                                <div className="services-details-name-filter-bubble " style = {{background: 'rgb(175, 223, 219)'}} />
                              </div>
                              <div className="flex-row">
                                <div className="services-details-name-filter-line" />
                              </div>
                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 8)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  Betaald
                                </p>
                                <div className="services-details-name-filter-bubble" style = {{background: 'rgb(140, 201, 186)'}} />
                              </div>
                              <div className="flex-row">
                                <div className="services-details-name-filter-line" />
                              </div>
                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 3)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  On hold
                                </p>
                                <div className="status-details-name-filter-bubble background-color-faded-purple" />
                              </div>
                              <div className="flex-row">
                                <div className="status-details-name-filter-line" />
                              </div>
                              <div
                                className="flex-row vertical-center cursor-pointer"
                                onClick={() => {
                                  changeCategory(file.id, 4)
                                }}
                              >
                                <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                                  Geannuleerd
                                </p>
                                <div className="status-details-name-filter-bubble background-color-faded-pink" />
                              </div>
                            </Grid>
                          </div>
                          </>
                        )}
                        </div>
                  </div>
                  <div
                    className="documents-file-menu flex-column vertical-center animatable-400 ease-in-out-quart"
                    style={{
                      opacity: fileHover === index + 1 || showMenu === index + 1 ? 1 : 0,
                      transform:
                        fileHover === index + 1 || showMenu === index + 1
                          ? 'translateX(0px)'
                          : 'translateX(15px)',
                    }}
                    onBlur={ !isMouseOver ?  ()=>{setShowMenu(0);} : null}
                  >
                    <button
                      type="button"
                      className="flex-column cursor-pointer"
                      onClick={() => {
                        if (showMenu === index + 1) {
                          setShowMenu(0)
                        } else {
                          setShowMenu(index + 1)
                        }
                      }}
                    >
                      <div className="documents-file-menu-dot" />
                      <div className="documents-file-menu-dot" />
                      <div className="documents-file-menu-dot" />
                    </button>
                      <div
                        className="documents-file-menu-modal-container flex-column animatable-400 ease-in-out-quart"
                        ref = {menuFilterRef}
                        style={{
                          height:
                          showMenu === index + 1 || showMenu === index + 1
                          ? '160px'
                          : '0px',
                          opacity: showMenu === index + 1 || showMenu === index + 1 ? 1 : 0,
                        }}
                        onMouseOver = {() => setMouseOverState(true)}
                        onMouseLeave = {() => setMouseOverState(false)}
                        >
                        {showMenu === index + 1 && (
                          <>
                          <div className="flex-row width-auto horizontal-end">
                            <img
                              src={require('../../../../../../assets/svg/icon/corner.png')}
                              alt="corner"
                              className="documents-file-menu-modal-arrow"
                            />
                          </div>
                          <div className="documents-file-menu-modal flex-column vertical-center">
                            <button
                              type="button"
                              className="documents-file-menu-modal-button documents-file-menu-modal-divider center-content"
                            >
                              <a
                                href={`https://backend.develop.bip.flykube.agifly.cloud/documents/download_file/${file.file}`}
                                download
                                className="main-font-semi-bold font-size-14 font-color-black"
                              >
                                Download
                              </a>
                            </button>
                            <button
                              type="button"
                              className="documents-file-menu-modal-button documents-file-menu-modal-divider center-content"
                            >
                              <a
                                href={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${file.file}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="main-font-semi-bold font-size-14 font-color-black"
                              >
                                View
                              </a>
                            </button>
                            <button
                              type="button"
                              className="documents-file-menu-modal-button center-content"
                              onClick={handleFileDelete}
                            >
                              <h1
                                className="main-font-semi-bold font-size-14 font-color-black"
                              >
                                Delete
                              </h1>
                            </button>
                          </div>
                          </>
                        )}
                      </div>
                  </div>
                </div>
              </div>
          </Hidden>
          </div>
          <div className = 'flex-row width-auto vertical-start horizontal-between margin-top-10'>
            {isSafety === 4 && (
              <div className = 'flex-row  margin-right-10'>
                <div className = 'row-detail-card-verticleLine margin-right-10'/>
                <div className = 'flex-column  vertical-start horizontal-start'>
                  <p className="actions-information-document-headers vietnam-regular margin-left-5">
                    PID
                  </p>
                  <p className="document-details-file-name vietnam-regular margin-top-5">
                    {file && showPID(file) && (
                      <PIDButton value = {file.pid} updatePid = {updatePid} />
                    )}
                  </p>
                </div>
              </div>
            )}
            <Hidden xsDown>
              <>
                <div className = 'flex-row width-30-p height-100-p margin-right-10'> 
                  <div className = 'row-detail-card-verticleLine margin-right-10'/>
                  <div className = 'flex-column height-100-p width-90-p '>
                    <p className="actions-information-document-headers vietnam-regular">
                      Persoon
                    </p>
                    <div className = 'flex-row flex-wrap'>
                      <p className="document-details-person-name vietnam-regular">
                        {file.person ? `${file.person.split(" ")[0]}` : userDetails.firstName}
                      </p>
                      {file.person && file.person.split(" ")[1] && (
                        <p className="document-details-person-name vietnam-regular">
                          {file.person.split(" ")[1]}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className = 'flex-row width-30-p margin-right-10'>
                  <div className = 'row-detail-card-verticleLine margin-right-10'/>
                  <div className = 'flex-column width-90-p '>
                    <p className="actions-information-document-headers vietnam-regular">
                      label
                    </p>
                    <p className="document-details-label vietnam-regular">
                      {file.label !== null ? file.label : '-'}
                    </p>
                  </div>
                </div>
              </>
          </Hidden>
          <div className = 'flex-row width-30-p margin-right-10'>
            <div className = 'row-detail-card-verticleLine margin-right-10'/>
              <div className = 'flex-column width-90-p'>
                <p className="actions-information-document-headers vietnam-regular margin-right-10">
                  Gepubliceerd
                </p>
                <p className="document-details-date-time vietnam-regular margin-right-10 ">
                  {file.createdAt !== null ? file.createdAt.split(' ')[0] : '-'}
                </p>
              </div>
            </div>
          </div>
        </Grid>
    </Grid>
    )
  )
}

RowDetails.propTypes = {
  file: PropTypes.object,
}

RowDetails.defaultProps = {
  file: {
    file: "example.png"
  },
  detailsPage: false,
}