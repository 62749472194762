import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  createRef,
} from 'react'
import { Grid } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import './_step_two.scss'
import Popup from '../../../shared/popup/custom_popup'
import { CustomCheckBox } from '../../../shared_components/custom_checkbox/custom_checkbox'

export default function StepTwo(props) {
  const { token, stepTwoData, projectId, ventilatievoorontwerp, projectServiceId } = props
  const [fileNames, setFileNames] = useState([])
  const [ventilationPreliminaryDesign, setVentilationPreliminaryDesign]=useState([])
  const [ventilationDesignRef, setVentilationDesignRef]=useState([])
  const [formData, setFormData] = useState(new FormData())
  const [showMore, setShowMore] = useState(true)
  
  useEffect(() => {
    if (JSON.stringify(stepTwoData) !== '{}' && stepTwoData !== undefined) {
      const tempFormData = new FormData()
      let serviceDocumentCategoryId =
        stepTwoData.service_document_categories.length > 0 
            ? stepTwoData.service_document_categories[0].id
          : 0
      let serviceDocumentId =
        stepTwoData.service_document_categories.length > 0
          ? stepTwoData.service_document_categories[0].service_documents
              .length > 0
            ? stepTwoData.service_document_categories[0].service_documents[0].id
            : 0
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setFormData(tempFormData)
      if (stepTwoData.service_document_categories.length > 0) {
        setFileNames(
          stepTwoData.service_document_categories[0].service_documents,
        )
      }
    }
  }, [stepTwoData])

  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setVentilationDesignRef(tempArray)
  }, [])

  function disableAllButtons() {
    ventilationDesignRef[0].current.disableButton()
    ventilationDesignRef[1].current.disableButton()
    ventilationDesignRef[2].current.disableButton()
  }

  return (
    <Grid container className="fade-in-translate animatable-100">
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          <Grid container justify="flex-end" md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11}  md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              EPB adviesrapport
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <div className="flex-row width-auto">
        <DocumentDropzone
          fileNames={fileNames}
          setFileNames={setFileNames}
          path="https://backend.develop.bip.flykube.agifly.cloud/service_document/upload_document"
          projectId={projectId}
          formData={formData}
          token={token}
          serviceId={2}
        />
      </div>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1}/>
        <Grid container xs={11} sm={11} md={11}>
          <p className="epb-service-Category-heading-label vietnam-regular">
            Ventilatievoorontwerp
            <img
              alt = 'arrow'
              className="margin-left-5 epb-service-Category-heading-arrow-size"
              src={require(`../../../../assets/svg/icon/${
                showMore ? 'arrow_up_grey.svg' : 'down_arrow.svg'
              }`)}
              onClick={() => {
                setShowMore(!showMore)
              }}
            />
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {showMore && (
        <Grid container >
          <Grid container>
            <Grid xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <p className = "main-font-regular font-size-14 font-color-black">Mag het ventilatievoorontwerp opgemaakt worden a.d.h.v. bovenstaande EPB adviesrapport?</p>
            </Grid>
          </Grid>
          <Grid container>
            <div className = "margin-bottom-10" />
          </Grid>
          <Grid container alignItems="center">
            <Grid xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <Grid>
                <CustomCheckBox
                  label="Ja"
                  ref={ventilationDesignRef[0]}
                  disableAllButtons={disableAllButtons}
                  value={ventilatievoorontwerp!==undefined && ventilatievoorontwerp!== null && ventilatievoorontwerp===1 && ventilatievoorontwerp}
                  disabled={true}
                />
              </Grid>
              <Grid>
                <div className="margin-right-20" />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Nee, ander ventilatiesysteem"
                  ref={ventilationDesignRef[1]}
                  disableAllButtons={disableAllButtons}
                  value={ventilatievoorontwerp!==undefined && ventilatievoorontwerp!== null && ventilatievoorontwerp===2 && ventilatievoorontwerp}
                  disabled={true}
                />
              </Grid>
              <Grid>
                <div className="margin-right-20" />
              </Grid>
              <Grid>
                <CustomCheckBox
                  label="Nee, graag nog wachten tot definitief ventilatiesysteem gekend is"
                  ref={ventilationDesignRef[2]}
                  disableAllButtons={disableAllButtons}
                  value={ventilatievoorontwerp!==undefined && ventilatievoorontwerp!== null && ventilatievoorontwerp===3 && ventilatievoorontwerp}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      </Grid>
  )
}
const FileDetails = (props) => {
  const {
    setWorkFile,
    setQualityFile,
    index,
    file,
    deleteFile,
  } = props

  const handleFileDelete = () => {
    let data = {
      index: index,
      id: file.id,
    }
    deleteFile(data)
  }
  return (
    <Grid container sm={10} xs={9} justify='flex-start' alignItems="center">
      <Grid>
        <img
          alt=""
          className="step-three-file-icon"
          src={
            file.name !== undefined
              ? file.name.split('.').includes('pdf')
                ? require('../../../../assets/svg/doc/pdf.svg')
                : file.name.split('.').includes('doc')
                ? require('../../../../assets/svg/doc/doc.svg')
                : file.name.split('.').includes('xls')
                ? require('../../../../assets/svg/doc/xls.svg')
                : file.name.split('.').includes('jpg')
                ? require('../../../../assets/svg/doc/jpg.svg')
                : require('../../../../assets/svg/doc/other.svg')
              : file.documentTitle.split('.').includes('pdf')
              ? require('../../../../assets/svg/doc/pdf.svg')
              : file.documentTitle.split('.').includes('doc')
              ? require('../../../../assets/svg/doc/doc.svg')
              : file.documentTitle.split('.').includes('xls')
              ? require('../../../../assets/svg/doc/xls.svg')
              : file.documentTitle.split('.').includes('jpg')
              ? require('../../../../assets/svg/doc/jpg.svg')
              : require('../../../../assets/svg/doc/other.svg')
          }
        />
      </Grid>
      <Grid>
        <p className="step-three-file-name vietnam-regular margin-left-20">
          {file.name !== undefined ? file.name : file.documentTitle}
        </p>
      </Grid>
      <Grid>
      <div className="margin-left-20" />
    </Grid>
    <Grid>
      <button
        type="button"
        className={'step-three-delete-image cursor-pointer'}
        onClick={() => handleFileDelete()}
      >
        <img
          alt=""
          src={require('../../../../assets/svg/icon/trash.svg')}
          className=""
        />
      </button>
    </Grid>
    </Grid>
  )
}
export const DocumentDropzone = forwardRef((props, ref) => {
  const { fileNames, setFileNames, token, buttonLabel, path, formData, projectId, serviceId, deletePath } = props
  const [forceUpdateState,setForceUpdateState]=useState(false)
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  const [ fileUploadProgress, setFileUploadProgress ] = useState(0)

  const onDrop = useCallback(
    (files) => {
      if (files[0] !== null && files[0] !== undefined) {
        if( fileNames.length>0){
          let responseMessage = {
            isSuccess: false,
            heading: 'Bestand kan niet worden toegevoegd',
            message: 'Verwijder eerst het vorige bestand',
            popupState: setShowPopupState
          }
          setShowPopupState(true)
          setPopupMessageState(responseMessage)
          // formData.delete('docsInput')
        }
        else{
          if(formData.has('docsInput')){
            formData.delete('docsInput')
          }
          formData.append('docsInput', files[0])
          axios
            .post(path, formData, {
              headers: {
                authorization: `bearer ${token}`,
              },
              onUploadProgress: progress => {
                const { total, loaded } = progress;
                const totalSize = total/1000000;
                const totalLoaded = loaded/1000000;
                const percentage = (totalLoaded/totalSize) * 100;
                setFileUploadProgress(percentage.toFixed(2));
              },
            })
            .then((response) => {
              setFileUploadProgress(0)
              if (response.data.success) {
                setFileNames([{id:response.data.serviceDocumentId,name:response.data.fileName}])
                setForceUpdateState(!forceUpdateState)
              }
              else if(!response.data.success){
                handleErrorPopupState()
              }
            })
            .catch((error) => {
              handleErrorPopupState()
              formData.delete('docsInput')
            })
        }
      }
    },
    [formData],
  )

  const handleErrorPopupState=()=>{
    let responseMessage = {
      isSuccess: false,
      heading: 'Bestand kon niet worden geselecteerd',
      message: 'Verwijder eerst het vorige bestand',
      popupState: setShowPopupState
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)  
  }

  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    noClick: true,
  })

  useImperativeHandle(ref, () => ({
    filesToReturn() {
      return fileNames
    },
  }))

  const deleteFile = (data) => {
    if(data.id>0){
      axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/service_document/delete`,
        { serviceDocumentId: data.id },
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        if (response.data.success) {
          setFileNames(response.data.data)
        }
      })
      .catch((error) => {})
    }
    else if(deletePath!==undefined){
      axios
      .post(
       deletePath,formData,
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        if (response.data.success) {
          setFileNames(response.data.data)
        }
      })
      .catch((error) => {})
    }
  }

  return (
    <Grid container>
      <Grid container md={1} xs={1} sm={1}/>
      <Grid container xs={11} sm={11} md={11} >
        <Grid md={3} sm={4} justify="flex-end">
          <Grid container>
            <DocumentButtonDropzone
              fileNames={fileNames}
              setFileNames={setFileNames}
              token={token}
              buttonLabel={buttonLabel}
              formData={formData}
              path={path}
              projectId={projectId}
              serviceId={serviceId}
              fileUploadProgress = {fileUploadProgress}
              setFileUploadProgress = {setFileUploadProgress}
            />
          </Grid>

          <div className="flex-row margin-top-20">
            <div className="flex-column">
              <p className="vietnam-regular font-size-14 font-color-semi-dark-grey text-direction-rtl">
                Upload document : Drag & drop
                <br />
                het document
              </p>
            </div>
            <div>
              <img
                src={require('../../../../assets/svg/arrow_curve.svg')}
                alt="curved arrow"
                className="object-fit-contain margin-left-10"
                style={{ transform: 'matrix(1, 0, 0, -1, 0, 0)' }}
              />
            </div>
          </div>
        </Grid>
      
        <Grid container xs={11} sm={8} md={8} >
          {fileNames !== undefined &&
            fileNames !== null &&
              fileNames.length > 0 &&(
              <div
                onClick={()=>{fileNames.length>0 && handleErrorPopupState()}}
                className="height-auto width-auto relative"
                {...getRootProps({ style: { outline: 'none' } })}
              >
                {fileNames.length>0 ?
                showPopupState && (
                  <Popup data={popupMessageState}/>
                )
                :
                <input className="height-auto width-auto" {...getInputProps()} />
              }
                {fileNames.map((item, index) => (
                    // <div className="flex-row width-auto">
                    <Grid container>  
                      <FileDetails
                        file={item}
                        index={index}
                        deleteFile={deleteFile}
                        buttonLabel={buttonLabel}
                      />
                    </Grid>
                  ))}
              </div>
            )}
          {fileUploadProgress !== 0 && (
            <Grid container alignItems = 'center'>
              <p className = "main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">Upload</p>
              <div className = 'slider'>
                <div className = 'slider-thumb' style = {{width: fileUploadProgress*2}} />
              </div>
              <p className = "main-font-semi-bold font-size-12 font-color-sky-blue">{fileUploadProgress}%</p>
            </Grid>
          )}
        </Grid>
      </Grid>
      {
        showPopupState === true ?
            <Popup data={popupMessageState}/>
        : null
      }
    </Grid>
  )
})
function DocumentButtonDropzone(props) {
  const { fileNames, setFileNames, token, buttonLabel, formData, path, setFileUploadProgress } = props
  const [forceUpdateState,setForceUpdateState]=useState(false)
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})

  const handleErrorPopupState=()=>{
    let responseMessage = {
      isSuccess: false,
      heading: 'Bestand kon niet worden geselecteerd',
      message: 'Verwijder eerst het vorige bestand',
      popupState: setShowPopupState
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)  
  }

  const onDrop = useCallback(
    (files) => {
      if (files[0] !== null && files[0] !== undefined) {
        if(formData.has('docsInput')){
          formData.delete('docsInput')
        }
        formData.append('docsInput', files[0])
        axios
          .post(path, formData, {
            headers: {
              authorization: `bearer ${token}`,
            },
            onUploadProgress: progress => {
              const { total, loaded } = progress;
              const totalSize = total/1000000;
              const totalLoaded = loaded/1000000;
              const percentage = (totalLoaded/totalSize) * 100;
              setFileUploadProgress(percentage.toFixed(2));
            },
          })
          .then((response) => {
            setFileUploadProgress(0)
            if (response.data.success === true) {
              setFileNames([{id:response.data.serviceDocumentId,name:response.data.fileName}])
              setForceUpdateState(!forceUpdateState)
            }
            else{
              handleErrorPopupState()
            }
          })
          .catch((error) => {
            handleErrorPopupState()
            formData.delete('docsInput')
          })
        }
    },
    [formData],
  )
  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })

  return (
    <div {...getRootProps({ style: { outline: 'none' } })}>
      <button type="button" className="ventilation-service-button-upload" onClick={()=>{fileNames.length>0 && handleErrorPopupState()}}>
        <img
          src={require('../../../../assets/svg/icon/upload.svg')}
          alt=""
          className="ventilation-service-button-icon"
        />
        {fileNames.length>0 ?
          showPopupState && (
            <Popup data={popupMessageState}/>
          )
          :
          <input className="height-auto width-auto" {...getInputProps()} />
        }
        <p className="center-text margin-left-10 margin-right-10 main-font-semi-bold font-size-14">
          {buttonLabel !== undefined ? buttonLabel : 'Upload'}
        </p>
      </button>
      {
        showPopupState === true ?
            <Popup data={popupMessageState}/>
        : null
      }
    </div>
  )
}
