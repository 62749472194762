import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import './_custom_input.scss'

export const CustomInput = forwardRef((props, ref) => {
  const { label, value, name, showInfoIcon,type, disabled, alignTextRight, style, gridStyle } = props
  const [text, setText] = useState('')

  useEffect(()=>{
    if(value!==undefined){

      setText(value)
    }
  },[value])

  useImperativeHandle(ref, () => ({
    getValue() {
      if(type==='number' && text===''){
        return 0
      }
      return text
    },
    setValueToEmpty() {
      setText('')
    },
  }))

  const handleChange = (event) => {
    setText(event.target.value)
  }
  return (
    <Grid container direction="column" alignItems = {alignTextRight && 'flex-end'} style = {gridStyle} >
      <div className="flex-row">
        <p className= {`width-auto margin-top-10 custom-input-label ${alignTextRight ? 'right-text' : ''}`}>{label}</p>
        {(showInfoIcon !== undefined || showInfoIcon) && (
          <img
            alt = 'info'
            className="margin-left-20 epb-service-Category-heading-image-size"
            src={require('../../../assets/svg/icon/info.svg')}
          />
        )}
      </div>
      {label === 'Dikte' ? (
        <div className = {`${disabled!==undefined && disabled ? 'custom-thickness-disable-container' : 'custom-thickness-container'} flex-row center-content`}>
          <input
            name={name}
            disabled = {disabled !== undefined && disabled}
            placeholder = {name ? `voer ${name} in` : '-'}
            value={text}
            type={type!==undefined?type:"text"}
            onChange={handleChange}
            className={`custom-thickness-input ${alignTextRight ? 'right-text' : ''}`}
          />
          <p className="font-color-black margin-left-10 vietnam-semi-bold">
            cm
          </p>
        </div>
      ) : label === 'Glas' ? (
        <div className = {`${disabled!==undefined && disabled ? 'custom-thickness-disable-container' : 'custom-thickness-container'} flex-row center-content`}>
          <input
            disabled = {disabled !== undefined && disabled}
            placeholder = {name ? `voer ${name} in` : '-'}
            name={name}
            value={text}
            type={type!==undefined?type:"text"}
            onChange={handleChange}
            className={`custom-thickness-input ${alignTextRight ? 'right-text' : ''}`}
          />
          <p className="font-color-black margin-left-10 vietnam-semi-bold">k</p>
        </div>
      ) : label === 'Hoeveel panelen' ? (
        <div className = {`${disabled!==undefined && disabled ? 'custom-thickness-disable-container' : 'custom-thickness-container'} flex-row center-content`}>
          <input
            disabled = {disabled !== undefined && disabled}
            placeholder = {name ? `voer ${name} in` : '-'}
            name={name}
            value={text}
            type={type!==undefined?type:"text"}
            onChange={handleChange}
            className={`custom-thickness-input ${alignTextRight ? 'right-text' : ''}`}
          />
          <p className="font-color-black margin-left-10 vietnam-semi-bold">
            panelen
          </p>
        </div>
      ) : (
        <input
          disabled = {disabled !== undefined && disabled}  
          name={name}
          value={text}
          placeholder = {name ? `voer ${name} in` : '-'}
          type={type!==undefined?type:"text"}
          onChange={handleChange}
          className={`custom-input-box ${alignTextRight ? 'right-text' : ''}`}
          style = {style}
        />
      )}
    </Grid>
  )
})
