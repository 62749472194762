import React, { useState } from 'react'

export default function ServicesTab (props) {
    const {selectedTab, changeTabs} = props
    const [tabButtonObjList, setTabButtonObjList] = useState([
        {id: 1, tabIndex: 2, name: 'Ontvangen', background: '#508CD0', color: '#508CD0', colorImageSrc: require('../../../../assets/svg/icon/loader.svg'), whiteImageSrc: require('../../../../assets/svg/icon/loader_white.svg') },
        {id: 2, tabIndex: 7, name: 'Offerte', background: '#F2BBAD', color: '#A7796D', colorImageSrc: require('../../../../assets/svg/icon/offerte_color.svg'), whiteImageSrc: require('../../../../assets/svg/icon/offerte_white.svg') },
        {id: 3, tabIndex: 8, name: 'Offerte aanvaard', background: '#FBDCCE', color: '#AC9489', colorImageSrc: require('../../../../assets/svg/icon/offerteaanvaard_color.svg'), whiteImageSrc: require('../../../../assets/svg/icon/offerteaanvaard_white.svg') },
        {id: 4, tabIndex: 3, name: 'In behandeling', background: '#9A8A55', color: '#9A8A55', colorImageSrc: require('../../../../assets/svg/icon/load.svg'), whiteImageSrc: require('../../../../assets/svg/icon/load_white.svg') },
        {id: 5, tabIndex: 4, name: 'Afgewerkt', background: '#7BB84D', color: '#7BB84D', colorImageSrc: require('../../../../assets/svg/icon/check_green.svg'), whiteImageSrc: require('../../../../assets/svg/icon/check_white.svg') },
        {id: 6, tabIndex: 9, name: 'Gefactureerd', background: '#AFDFDB', color: '#698886', colorImageSrc: require('../../../../assets/svg/icon/gefactureerd_color.svg'), whiteImageSrc: require('../../../../assets/svg/icon/gefactureerd_white.svg') },
        {id: 7, tabIndex: 10, name: 'Betaald', background: '#8cc9ba', color: '#325758', colorImageSrc: require('../../../../assets/svg/icon/betaald_color.svg'), whiteImageSrc: require('../../../../assets/svg/icon/betaald_white.svg') },
        {id: 8, tabIndex: 5, name: 'On hold', background: '#686886', color: '#686886', colorImageSrc: require('../../../../assets/svg/icon/onhold.svg'), whiteImageSrc: require('../../../../assets/svg/icon/onhold_white.svg') },
        {id: 9, tabIndex: 6, name: 'Geannuleerd', background: '#B30419', color: '#B30419', colorImageSrc: require('../../../../assets/svg/icon/cancel.svg'), whiteImageSrc: require('../../../../assets/svg/icon/cancel_white.svg') },
    ])

    const handleLeftArrowClick = () => {
        let tempTabList = JSON.parse(JSON.stringify(tabButtonObjList))
        let firstTab = tempTabList.shift()
        tempTabList.push(firstTab)
        setTabButtonObjList(tempTabList)
    }

    const handleRightArrowClick = () => {
        let tempTabList = JSON.parse(JSON.stringify(tabButtonObjList))
        let lastTab = tempTabList.pop()
        tempTabList.unshift(lastTab)
        setTabButtonObjList(tempTabList)
    }

    return (
        <div className = "services-tab-container animatable-400 ease-in-out-quart flex-shrink-disable" style = {{borderBottomColor: selectedTab === 1 ? '#7DA5C2' : selectedTab === 2 ? '#508CD0' : selectedTab === 3 ? '#9A8A55' : selectedTab === 4  ? '#7BB84D' : selectedTab === 5 ? '#686886' : selectedTab === 6 ? '#B30419' : selectedTab === 7 ? '#F2BBAD' : selectedTab === 8 ? '#FBDCCE' : selectedTab === 9 ? '#AFDFDB' :  '#8cc9ba'}}>
            <div className = 'flex-shrink-disable'>
                <button type = "button" className = "services-tab animatable-400 ease-in-out-quart " style = {{background: selectedTab === 1 ? '#7DA5C2' : 'rgba(0, 0, 0, 0)'}} onClick = {() => { changeTabs(1) }} >
                    <p className = "main-font-semi-bold font-size-14 font-color-white animatable-400 ease-in-out-quart" style = {{color: selectedTab === 1 ? 'white' : ' #7DA5C2'}}>Alle</p>
                    <img alt = 'tab name' src = {require('../../../../assets/svg/icon/all_icon_color.svg')} className = "services-tab-icon" style = {{display: selectedTab === 1 ? 'none' : 'flex'}} />
                    <img alt = 'image' src = {require('../../../../assets/svg/icon/all_icon_white.svg')} className = "services-tab-icon" style = {{display: selectedTab === 1 ? 'flex' : 'none'}} />
                </button>
            </div>
            <div className = 'flex-row width-auto overflow-hidden'>
                { tabButtonObjList.map((button, index) => (
                    <button type = "button" className = "services-tab animatable-400 ease-in-out-quart vertical-center flex-shrink-disable fade-in-translate" style = {{background: selectedTab === button.tabIndex ? button.background : 'rgba(0, 0, 0, 0)'}} onClick = {() => { changeTabs(button.tabIndex) }}>
                        <p className = "main-font-semi-bold font-size-14 font-color-white animatable-400 ease-in-out-quart" style = {{color: selectedTab === button.tabIndex ? 'white' : button.color}}>{button.name}</p>
                        <img alt = 'tab name' src = {button.colorImageSrc} className = "services-tab-icon" style = {{display: selectedTab === button.tabIndex ? 'none' : 'flex'}} />
                        <img alt = 'image' src = {button.whiteImageSrc} className = "services-tab-icon" style = {{display: selectedTab === button.tabIndex ? 'flex' : 'none'}} />
                    </button>
                ))}
            </div>
            <div className = 'flex-row flex-shrink-disable background-color-white'>
                <button type = 'button' onClick = {handleLeftArrowClick}><img alt = 'arrow-left' src = {require('../../../../assets/svg/icon/arrow_left_blue.svg')} className = 'cursor-pointer' /></button>
                <button type = 'button' onClick = {handleRightArrowClick}><img alt = 'arrow-right' src = {require('../../../../assets/svg/icon/arrow_left_blue.svg')} style = {{transform: 'rotate(180deg)', cursor: 'pointer'}} /></button>
            </div>
        </div>
    )
}