import React, { useState, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import {useParams, useHistory} from 'react-router-dom'
import './_safety_service_client.scss'
import SafetyStepOne from './step_one/step_one'
import StepTwo from './step_two/step_two'
import StepThree from './step_three/step_three'
import StepFour from './step_four/step_four'
import { CustomSelect } from '../../shared_components/custom_select/custom_select'
import { isTokenValid } from "../../shared/shared_functions"

export default function SafetyServiceClient(props) {
  const { setBuildingData} = props
  const token = localStorage.getItem('authToken')
  const [stepNumber, setStepNumber] = useState(1)
  const [isButtonClick, setButtonClick] = useState(false)
  const [projectId,setProjectId]=useState(0)
  const [projectServiceId, setProjectServiceId] = useState(0)
  const [safetyPdfFile,setSafetyPdfFile]=useState('')
  const [safetyFormId,setSafetyFormId]=useState(0)
  const [stepOneData, setStepOneData] = useState({
    projectId: 0,
    startDate: '',
    endDate: '',
    safety_services_form_planning: [],
    safety_services_form_resources: [],
  })
  const [ showMenuState, setShowMenuState ] = useState(false)
  const [generatedPdfFile, setGeneratedPdfFile] = useState('')
  const [backendFileName, setBackendFileName] = useState('')
  const dashboardRef = useRef(0)
  const parameters = useParams()
  const history = useHistory()
  const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails')) 
  const userRole = loggedInUserDetails.role.title

  const dropDownData=[
    {id:1,title:'1. Startgegevens'},
    {id:2,title:'2. Veiligheids-en gezondheidsplan'},
    {id:3,title:'3. Uitvoering van werken'},
    {id:4,title:'4. Postinterventiedossier'},
   ]
  
  useEffect(() => {
    isTokenValid(history)
  }, [])

  useEffect(()=>{
    let newId = parameters.id.split("-")
    setProjectId(newId[1])
    setProjectServiceId(newId[0])
  },[parameters.id])

  useEffect(()=>{},[stepOneData,projectId])

  return (
    <Grid container>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Hidden smDown> 
      <Grid container>
        <Grid md={1}></Grid>
        <Grid container md={8} alignItems="center">
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 1 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(1)
              }}
            >
              Startgegevens
            </span>
          </Grid>
          <Grid>
            <div className="margin-left-30" />
          </Grid>
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 2 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(2)
              }}
            >
              Veiligheids-en gezondheidsplan
            </span>
          </Grid>
          <Grid>
            <div className="margin-left-30" />
          </Grid>
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 3 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(3)
              }}
            >
              Uitvoering van werken
            </span>
          </Grid>
          <Grid>
            <div className="margin-left-30" />
          </Grid>
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 4 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(4)
              }}
            >
              Postinterventiedossier
            </span>
          </Grid>
        </Grid>
        <Grid container md={2} justify="flex-end" alignItems="center">
          <button
            className="safety-service-button"
            onClick={() => {
              setButtonClick(!isButtonClick)
            }}
          >
            Bewaren
          </button>
        </Grid>
      </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container justify='center' alignItems='center'>
           <Grid container xs={5} sm={5}>
              <CustomSelect data={dropDownData} setParentSelectedValue={setStepNumber} value={stepNumber}/>
           </Grid>
           <Grid container xs={5} sm={5} justify='flex-end'>
              <button className="epb-service-button" onClick={()=>{setButtonClick(!isButtonClick)}}>Bewaren</button>
           </Grid>
        </Grid>     
      </Hidden>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>

      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {stepNumber === 1 ? (
        <SafetyStepOne
          token={token}
          stepOneData={stepOneData}
          setStepOneData={setStepOneData}
          isButtonClick={stepNumber === 1 && isButtonClick}
          projectId={projectId}
          projectServiceId = {projectServiceId}
          setSafetyFormId={setSafetyFormId}
          safetyFormId={safetyFormId}
          setSafetyPdfFile={setSafetyPdfFile}
          setBuildingData={setBuildingData}
          projectServiceId={projectServiceId}
          setGeneratedPdfFile = {setGeneratedPdfFile}
          setBackendFileName = {setBackendFileName}
          userRole = {userRole}
        />
      ) : stepNumber === 2 ? (
        <StepTwo token={token} setStepNumber={setStepNumber} stepOneData={stepOneData} projectId={projectId} projectServiceId={projectServiceId} userRole = {userRole} />
      ) : stepNumber === 3 ? (
        <StepThree token={token} projectId={projectId} projectServiceId={projectServiceId} userRole = {userRole} formId = {safetyFormId} />
      ) : stepNumber === 4 ? (
        <StepFour token={token} projectId={projectId} 
        safetyPdfFile={safetyPdfFile}
        safetyPdfFileBackendName = {backendFileName}
        setSafetyPdfBackendFileName = {setBackendFileName}
        setSafetyPdfFile={setSafetyPdfFile}
        generatedPdfFile = {generatedPdfFile}
        setGeneratedPdfFile = {setGeneratedPdfFile}
        safetyFormId={safetyFormId}
        projectServiceId={projectServiceId}
        userRole = {userRole}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Grid>
  )
}
