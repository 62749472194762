import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios"
import { debounce, delay } from "lodash";
import { useDropzone } from 'react-dropzone';
import { useHistory, useParams } from "react-router-dom";
import Picker from 'emoji-picker-react';

import './_message_mobile.scss';
import Header from '../../header/header';
import { isLocalDataValid } from '../../../shared/shared_functions';

export default function MessageMobile() {
    const [ search, setSearch ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ selectedContact, setSelectedContact ] = useState(0);
    const [ newMessage, setNewMessage ] = useState(false);
    const [ messageConversations, setMessageConversations ] = useState([]);
    const [ messageConversationsPool, setMessageConversationsPool ] = useState([])
    const [ messageList, setMessageList ] = useState([]);
    const [ newContactList, setNewContactList ] = useState([]);
    const [ newSelectedContact, setNewSelectedContact ] = useState(0);
    const [ buildingList, setBuildingList ] = useState([])
    const [ buildingDropdownState, setBuildingDropdownState ] = useState(false)
    const [ selectedBuildingFilter, setSelectedBuildingFilter ] = useState(0);
    const [ currentUserId, setCurrentUserId ] = useState(2); // default value of 2 so that we don't get exceptions
    const [ updateConversation, setUpdateConversation ] = useState(false);
    const [ selectedFiles, setFiles ] = useState([]);
    const [ viewAttachFile, setViewAttachFile ] = useState(false);
    const [ isMessageUpdated, setIsMessageUpdated ] = useState(false); // we use this to manually change the selected index to the last 
    const [showChosenEmoji, setShowChosenEmoji] = useState(false);
    const [buttonDisableState, setButtonDisableState] = useState(false)
    const [emojiClicked, setEmojiClicked] = useState(false);
    const [ urlParamTriggered, setUrlParamTriggered ] = useState(false)
    const [ urlParamOld, setUrlParamOld ] = useState(0)

    const activeUser = JSON.parse(localStorage.getItem("loggedInUserDetails"));
    const newContactSearchRef = useRef(0);
    const history = useHistory()
    const { notif } = useParams();

    const onEmojiClick = (event, emojiObject) => {
        setButtonDisableState(false)
        setMessage(message+""+emojiObject.emoji);
    };

    const handleEmoji=()=>{
        setShowChosenEmoji(!showChosenEmoji)
    }

    useEffect(() => {
        if(!isLocalDataValid()) {
            localStorage.clear();
            history.push("/")
        }
        else {
            const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
            setCurrentUserId(userId.id);
            getBuildingList()
        }
    }, [])

    useEffect(() => {
        // we only want this to run once, not on every single messageConversation update so we introduce a new variable to disable this condition after the first initial run
        if(messageConversations.length > 0) {
            if(notif && (!urlParamTriggered || notif != urlParamOld)) {
                let selectedContactIndex = null;
                messageConversations.forEach((messageConversation, index) => {
                    if(messageConversation.id == notif) {
                        selectedContactIndex = index + 1;
                    }
                })
                setSelectedContact(selectedContactIndex)
                setUrlParamTriggered(true)
                setUrlParamOld(0);
            }
        }
    }, [messageConversations, notif])

    useEffect(() => {
        if(isLocalDataValid()) {
            if(selectedContact !== 0){
                getAllConversations();
                if(isMessageUpdated) {
                    setSelectedContact(messageConversationsPool.length);
                    isMessageUpdated && setIsMessageUpdated(false)
                    setTimeout(() => {
                        getAllMessages(messageConversationsPool[selectedContact - 1].id, selectedContact - 1)
                    }, 100);
                } 
                else {
                    getAllMessages(messageConversationsPool[selectedContact - 1].id, selectedContact - 1)
                }
            }
            else {
                getAllConversations();
            }
            delay(() => {
                setUpdateConversation(!updateConversation);
            }, 5000)
        }
    }, [updateConversation])


    useEffect(() => {
        if(messageConversations !== messageConversationsPool) {
            // to see if that data has changed or not, then we apply the filter 
            filterMessageConversationPool()
        }
    }, [messageConversations])

    useEffect(() => {
        // this filter applies when a user selects/unselects a filter, it's different from the one above which is designed
        // to filter the data as soon as it updates from the backend 
        setSelectedContact(0)
        setNewSelectedContact(0)
        setNewMessage(false)
        filterMessageConversationPool()
    }, [selectedBuildingFilter, search])

    const handleSearch = (event) => {
        event.preventDefault();
        setSearch(event.target.value);
    }

    const handleMessage = (event) => {
        event.preventDefault();
        setButtonDisableState(false)
        setMessage(event.target.value);
    }

    const handleKeyPress = ({keyCode}) => {
        if(selectedContact !== 0) {
            if(keyCode === 13){
                if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                    pushMessage();
                }
                else{
                    setMessage("")
                }
            }
        }
        
        else if(newSelectedContact !== 0) {
            if(keyCode === 13) {
                if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                    sendNewMessage();
                }
                else{
                    setMessage("")
                }
            }
        }
    }

    const pushMessage = () => {
        sendMessage();
        setViewAttachFile(false)
        let attachmentObj =[]
        let date = new Date();
        for (let i = 0; i < selectedFiles.length; i++) {
            const temp ={
               fileName: selectedFiles[i].name,
               file:selectedFiles[i],
               createdAt:null,
            }
            attachmentObj.push(temp)
        }

        const messageObject = {
            body: message,
            messageAttachment:attachmentObj,
            sender_id: currentUserId,
            sent: true,
            date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}` // insert converted date object to string here
        }
        messageList.push(messageObject)
        setMessage('');
        setIsMessageUpdated(true)
    }
    
    const handleAttachment=()=>{
        setButtonDisableState(false)
    }

    const onDrop = useCallback((files) => {
        if(selectedFiles.length > 0){
            let temp = {...selectedFiles , files}
            setFiles(temp)
        }else{
            setFiles(files)
        }
        setViewAttachFile(true)
      }, [])
      const accept =
        'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
      const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })
    
    const handleSendButton = () => {
        setButtonDisableState(false)
        if(newSelectedContact === 0) {
            if(selectedContact !== 0) {
                if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                    pushMessage();
                }
                else{
                    setMessage("")
                }
            }
        }
        else {
            if(message != "" && message.trim() !== "" || selectedFiles.length !== 0){
                sendNewMessage();
            }
            else{
                setMessage("")
            }
        }
    }

    function searchMessageConversation(filteredData) {
        const param = search.toLowerCase();
        if(param === "") {
            setMessageConversationsPool(filteredData)
        }
        else {
            let newMessageConversations = JSON.parse( JSON.stringify(filteredData) )
            const newFilteredMessageConversations = [];
            newMessageConversations.forEach(convo => {
                if(convo.to_user) {
                    const contactName = convo.to_user ? convo.to_user.firstName + " " + convo.to_user.lastName : 'no name';
                    if(convo.projectServices.service.title.toLowerCase().includes(param) || convo.projectServices.project.building.title.toLowerCase().includes(param) || contactName.toLowerCase().includes(param) || convo.to_user?.email.toLowerCase().includes(param) || convo.to_user.company.name.toLowerCase().includes(param)) {
                        newFilteredMessageConversations.push(convo)
                    }
                }
            })
            setMessageConversationsPool(newFilteredMessageConversations)            
        }
    }

    function filterMessageConversationPool() {
        if(selectedBuildingFilter === 0) {
            searchMessageConversation(messageConversations)
        }
        else {
            const newConversations = JSON.parse( JSON.stringify(messageConversations))
            const newList = [];
            newConversations.forEach(item => {
                if(item.projectServices.project.building.id === buildingList[selectedBuildingFilter - 1].id) {
                    newList.push(item);
                }
            })
            searchMessageConversation(newList)
        }
    }

    function getBuildingList() {
        const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/get_conversations_building", {
            from_userId: userId.id
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setBuildingList(response.data.building)
        }).catch(error => {
        })
    }

    function getAllConversations() {
        const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/get_conversations", {
            from_userId: userId.id
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setMessageConversations(response.data)
        }).catch(error => {
        })
    }

    function getAllMessages(conversationId, index) {
        const userId = JSON.parse(localStorage.getItem("loggedInUserDetails"));
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/get_messages", {
            message_conversation_id: conversationId,
            sender_id: userId.id,
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setMessageList(response.data);
        }).catch(error => {
        })
    }

    function sendMessage() {
        const formData = new FormData()
        const token = localStorage.getItem('authToken')

        formData.append('message_conversation_id',messageConversationsPool[selectedContact - 1].id)
        formData.append('subject','sample')
        formData.append('text',message)
        formData.append('sender_id',currentUserId)
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('docsInput', selectedFiles[i])
          }
        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/send_email", 
            formData
        , {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setFiles([])
        }).catch(error => {
        })
    }

    function sendNewMessage() {

        const formData = new FormData()
        formData.append('subject','sample')
        formData.append('text',message)
        formData.append('project_service_id',newSelectedContact.buildings.projects.projectServices.id)
        formData.append('from_userId',currentUserId)
        formData.append('to_userId',newSelectedContact.id)
        formData.append('sender_id',currentUserId)
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('docsInput', selectedFiles[i])
          }
        const token = localStorage.getItem('authToken')

        axios.post("https://backend.develop.bip.flykube.agifly.cloud/message/send_new_email", 
        formData
        , {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setNewSelectedContact(0);
            setMessage("");
            setFiles([])
            setNewMessage(false);
        }).catch(error => {
        })
    }

    const searchByEmail = debounce((email) => {
        if(newSelectedContact === 0) {
            if(email !== "") {
                let token = localStorage.getItem('authToken')
                axios.post('https://backend.develop.bip.flykube.agifly.cloud/message/search_by_smtp_email', {
                    email: email
                }, {
                    headers: {
                        authorization: `bearer ${token}`
                    }
                }).then(response => {
                    setNewContactList(response.data)
                }).catch(error => {
                })
            }
            else {
                setNewContactList([]);
            }
        }
    }, 1000)

    function cleanTimeString(dateTime) {
        let newDateTime = dateTime.split(" ");
        newDateTime = {
            date: newDateTime[0].split("-"),
            time: newDateTime[1].split(":")
        }

        newDateTime.date = `${newDateTime.date[2]}/${newDateTime.date[1]}/${newDateTime.date[0]}`;
        newDateTime.time = `${newDateTime.time[0]}:${newDateTime.time[1]}`

        return `${newDateTime.date} - ${newDateTime.time}`;
    }

    const handleBuildingButton = () => {
        setBuildingDropdownState(!buildingDropdownState)
    }

    const handleBuildingButtonBlur = () => {
        setBuildingDropdownState(false)
    }

    return(
        <Grid container>
            <Header isLogoEnabled toShowHamburger  />
            <Grid container>
                <div className = "message-mobile-container">
                    <Grid container justify = "center" className = "padding-left-10 padding-right-10">
                       <div className = "flex-row width-auto margin-top-25 vertical-center">
                        <div className = "message-mobile-search-container vertical-center">
                                <img className = "message-panel-search-icon margin-left-15" alt = "search" src = {require('../../../../assets/svg/icon/glass_grey.svg')} />
                                <input className = "message-panel-search main-font-regular font-size-14 font-color-standard-gray margin-left-15" placeholder = "Zoeken" onChange = {handleSearch} />
                            </div>
                            <button type = "button" className = "message-panel-building-button relative center-content" tabIndex = "1" onBlur = {handleBuildingButtonBlur} >
                                <div className = "height-auto width-auto center-content" onClick = {handleBuildingButton}>
                                    <img src = {require('../../../../assets/svg/icon/business-and-trade-gray.svg')} alt = "building gray" className = "message-panel-building" />
                                </div>
                                <div className = "message-panel-building-dropdown-container animatable-400 ease-in-out-quart" style = {{height: buildingDropdownState ? `${((buildingList.length + 1) * 35) + 10}px` : '0px', right: '0%'}} >
                                    <div className = "message-panel-building-dropdown margin-top-10">
                                        {buildingList.length > 0 && buildingList.map((item, index) => (
                                            <div className = "width-auto vertical-center horizontal-end message-panel-building-dropdown-item message-panel-building-dropdown-line" onClick = {() => { selectedBuildingFilter !== (index + 1) ? setSelectedBuildingFilter(index + 1) : setSelectedBuildingFilter(0) }} >
                                                <p className = "main-font-regular font-size-14 font-color-black">{item !== null && item.title}</p>
                                                <img src = {require('../../../../assets/svg/icon/correct.svg')} alt = "check" className = "message-panel-building-dropdown-check margin-left-10 animatable-400 ease-in-out-quart" style = {{opacity: selectedBuildingFilter === index + 1 ? 1 : 0}} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </button>
                       </div>
                        <div className = "message-mobile-explorer">
                            <div className = "message-mobile-explorer-nav animatable-400 ease-in-out-quart" style = {{height: selectedContact === 0 ? '0px' : '87px', opacity: selectedContact === 0 ? 0 : 1}} onClick = {() => { setSelectedContact(0); setSelectedBuildingFilter(0)}} >
                                {selectedContact !== 0 && (
                                    <div className = "message-panel-contacts-details vertical-center animatable-400" >
                                        <div className = "message-panel-contacts-details-profile overflow-hidden">
                                            {messageConversationsPool[selectedContact - 1].to_user && messageConversationsPool[selectedContact - 1].to_user.company && (
                                                <img src = {messageConversationsPool[selectedContact - 1].to_user.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${messageConversationsPool[selectedContact - 1].to_user.company.profile_picture}` : require('../../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-cover" />
                                            )}
                                        </div>
                                        <div className = "flex-column margin-left-15">
                                            <h1 className = "main-font-bold font-size-14 font-color-navy-blue margin-bottom-1 line-height-14">{messageConversationsPool[selectedContact - 1].projectServices && messageConversationsPool[selectedContact - 1].projectServices.project.building && messageConversationsPool[selectedContact - 1].projectServices.project.building.title}</h1> 
                                            <p className = "main-font-regular font-size-14 font-color-black line-height-14">{messageConversationsPool[selectedContact - 1].projectServices && messageConversationsPool[selectedContact - 1].projectServices.service.title}</p> 
                                            <p className = "main-font-regular font-size-14 font-color-grey line-height-14">{messageConversationsPool[selectedContact - 1].last_updated ? cleanTimeString(messageConversationsPool[selectedContact - 1].last_updated) : ''}</p> 
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* message conversations panel */}
                            <div className = "message-mobile-explorer-body animatable-400 ease-in-out-quart"  style = {{height: selectedContact === 0 ? '100%' : '0%', opacity: selectedContact === 0 ? 1 : 0}}>
                                {messageConversationsPool.length > 0 && messageConversationsPool.map((item, index) => (
                                    <div className = "message-panel-contacts-details vertical-center animatable-400" style = {{background: selectedContact === item + 1 ? '#E5EDF3' : 'rgba(0,0,0,0)'}} onClick = {() => { setSelectedContact(index + 1); setNewSelectedContact(0); setNewMessage(false); getAllMessages(item.id, index) }} >
                                        <div className = "message-panel-contacts-details-notif" style = {{background: item.admin_notification === true ? '#7DA5C2' : 'rgba(0,0,0,0)'}} />
                                        <div className = "message-panel-contacts-details-profile overflow-hidden">
                                            {item.to_user && item.to_user.company && (
                                                <img src = {item.to_user.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${item.to_user.company.profile_picture}` : require('../../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-cover" />
                                            )}
                                        </div>
                                        <div className = "flex-column margin-left-15">
                                            <h1 className = "main-font-bold font-size-14 font-color-navy-blue margin-bottom-1 line-height-14">{item.projectServices && item.projectServices.project.building && item.projectServices.project.building.title}</h1> 
                                            <p className = "main-font-regular font-size-14 font-color-black line-height-14">{item.projectServices && item.projectServices.service.title}</p> 
                                            <p className = "main-font-regular font-size-14 font-color-grey line-height-14">{item.last_updated ? cleanTimeString(item.last_updated) : ''}</p> 
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* messages panel */}
                            <div className = "message-mobile-explorer-body animatable-400 ease-in-out-quart"  style = {{height: selectedContact === 0 ? '0%' : 'calc(100% - 152px)', opacity: selectedContact === 0 ? 0 : 1}}>
                                {selectedContact !== 0 && messageList.map((item, index) => (
                                    <div className = "flex-row width-auto fade-in-right">
                                        {item.sender_id !== currentUserId ? (
                                            <div className = "flex-row padding-top-25 padding-bottom-25">
                                                <div className = "message-panel-profile margin-left-15 overflow-hidden flex-shrink-disable">
                                                    {messageConversationsPool[selectedContact - 1].to_user !== null && messageConversationsPool[selectedContact - 1].to_user.company !== null && (
                                                        <img src = {messageConversationsPool[selectedContact - 1].to_user.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${messageConversationsPool[selectedContact - 1].to_user.company.profile_picture}` : require('../../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-cover" />
                                                    )}
                                                </div>
                                                <div className = "flex-column">
                                                {item.body !== "" && (
                                                    <div className = "message-panel-message margin-right-15" style = {{width: 'auto'}}>
                                                        <p className = "main-font-regular font-size-12 font-color-white line-height-16">{item.body}</p>
                                                    </div>
                                                )}
                                                    
                                                {item.messageAttachment !== null && item.messageAttachment.length > 0 && item.messageAttachment.map((attach , index)=>(
                                                    <>
                                                        <div className = "message-panel-chatAttachments-from margin-right-15" style = {{width: 'auto', marginRight: '15px'}} >
                                                            <div className = "message-panel-chatAttachments-img" >
                                                                <a
                                                                href={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${attach.fileName}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="main-font-semi-bold font-size-14 font-color-black"
                                                                >
                                                                    {attach.fileName.split('.')[1] === "pdf" || attach.fileName.split('.')[1] === "doc" || attach.fileName.split('.')[1] === "docx" || attach.fileName.split('.')[1] === "xls" ? (
                                                                        <div className = "flex-row vertical-center">
                                                                            <img src = {attach.fileName.split('.')[1] === 'pdf'
                                                                            ? require('../../../../assets/svg/doc/pdf.svg')
                                                                            : attach.fileName.split('.')[1].includes('doc') || attach.fileName.split('.').includes('docx')
                                                                            ? require('../../../../assets/svg/doc/doc.svg')
                                                                            : attach.fileName.split('.')[1].includes('xls')
                                                                            ? require('../../../../assets/svg/doc/xls.svg') : null}
                                                                            alt = "attachment pic" className = "message-panel-chatAttachments-file" /> 
                                                                            <p className = "main-font-bold font-size-14 font-color-white margin-left-10">{attach.fileName.substring(13, attach.fileName.length)}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <img src = {attach.fileName.split('.')[1].includes('jpg')
                                                                            ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                            : attach.fileName.split('.')[1].includes('png') || attach.fileName.split('.')[1].includes('svg')
                                                                            ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                            : null}
                                                                            alt = "attachment pic" className = "height-auto width-auto object-fit-cover" />
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ))}
                                                    {messageConversationsPool[selectedContact - 1].to_user !== null && (
                                                        <p className = "main-font-regular font-size-9 font-color-grey margin-left-25 margin-top-5">{`${messageConversationsPool[selectedContact - 1].to_user.firstName} ${messageConversationsPool[selectedContact - 1].to_user.lastName}`} - {cleanTimeString(item.date)}</p>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className = "flex-row width-auto horizontal-end padding-top-25 padding-bottom-25 fade-in-left">
                                                <div className = "flex-column">
                                                {item.body !== "" && (
                                                    <div className = "message-panel-message-alt margin-left-15 flex-row horizontal-end" style = {{width: 'auto'}}>
                                                        <p className = "main-font-regular font-size-12 font-color-white line-height-16">{item.body}</p>
                                                    </div>
                                                )}
                                                    { item.messageAttachment !== null && item.messageAttachment.length > 0 && item.messageAttachment.map((attach , index)=>(
                                                        <>
                                                            <div className = "message-panel-chatAttachments-to margin-left-15" style = {{width: 'auto', marginLeft: '15px'}} >
                                                                <div className = "message-panel-chatAttachments-img" >
                                                                <a
                                                                    href={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${attach.fileName}`}
                                                                    rel="noopener noreferrer"
                                                                    target="_blank"
                                                                    className="main-font-semi-bold font-size-14 font-color-black"
                                                                >
                                                                    {attach.fileName.split('.')[1] === "pdf" || attach.fileName.split('.')[1] === "doc" || attach.fileName.split('.')[1] === "docx" || attach.fileName.split('.')[1] === "xls" ? (
                                                                        <div className = "flex-row vertical-center">
                                                                            <img src = {attach.fileName.split('.')[1] === 'pdf'
                                                                            ? require('../../../../assets/svg/doc/pdf.svg')
                                                                            : attach.fileName.split('.')[1].includes('doc') || attach.fileName.split('.').includes('docx')
                                                                            ? require('../../../../assets/svg/doc/doc.svg')
                                                                            : attach.fileName.split('.')[1].includes('xls')
                                                                            ? require('../../../../assets/svg/doc/xls.svg') : null}
                                                                            alt = "attachment pic" className = "message-panel-chatAttachments-file" /> 
                                                                            <p className = "main-font-bold font-size-14 font-color-white margin-left-10">{attach.fileName.substring(13, attach.fileName.length)}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <img src = {attach.fileName.split('.')[1].includes('jpg')
                                                                            ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                            : attach.fileName.split('.')[1].includes('png') || attach.fileName.split('.')[1].includes('svg')
                                                                            ? 'https://backend.develop.bip.flykube.agifly.cloud/uploads/'+attach.fileName
                                                                            : null}
                                                                            alt = "attachment pic" className = "height-auto width-auto object-fit-cover" />
                                                                    )}
                                                                </a>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                    {messageConversationsPool[selectedContact - 1].from_user !== null && (
                                                        <div className = "flex-row width-auto horizontal-between vertical-center margin-top-5">
                                                            <div className = "flex-row">
                                                                {item.sent ? (
                                                                    <img src = {require('../../../../assets/svg/icon/loading.gif')} alt = "loading" className = "message-panel-log-status" />
                                                                ) : (
                                                                    <img src = {require('../../../../assets/svg/icon/check_blue.svg')} alt = "checked" className = "message-panel-log-status" />
                                                                )}
                                                            </div>
                                                            <div className = "flex-row">
                                                                <p className = "main-font-regular font-size-9 font-color-grey margin-right-25 margin-top-5">{`${messageConversationsPool[selectedContact - 1].from_user.firstName} ${messageConversationsPool[selectedContact - 1].from_user.lastName}`} - {cleanTimeString(item.date)}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className = "message-panel-profile margin-left-15 overflow-hidden flex-shrink-disable">
                                                    {messageConversationsPool[selectedContact - 1].from_user !== null && messageConversationsPool[selectedContact - 1].from_user.company !== null && (
                                                        <img src = {`https://backend.develop.bip.flykube.agifly.cloud/uploads/${messageConversationsPool[selectedContact - 1].from_user.company.profile_picture}`} alt = "profile pic" className = "height-auto width-auto object-fit-cover" />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {selectedContact !== 0 && (
                                <div className = "message-panel-input-container flex-row vertical-center flex-shrink-disable fade-in-bottom animatable-400 ease-in-out-quart" style = {{opacity: selectedContact === 0 ? 0 : 1, height: selectedContact === 0 ? "0px" : '45px'}}>
                                    <div className = "message-panel-input-profile margin-left-10 margin-right-15 flex-shrink-disable overflow-hidden">
                                        {activeUser !== null && activeUser.company !== null && activeUser.company.profile_picture !== null && (
                                                <img src = {activeUser.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${activeUser.company.profile_picture}` : require('../../../../assets/svg/user.svg')} alt = "profile pic" className = "height-auto width-auto object-fit-cover" />
                                            )}
                                    </div>
                                    <div className = "message-panel-input vertical-center flex-row">
                                        <div className = "message-panel-input-emoji flex-shrink-disable outline-none" tabIndex = "1" onBlur={()=>{if(emojiClicked === false){setShowChosenEmoji(false)}}}>
                                            <img src = {require('../../../../assets/svg/icon/emoji.svg')} alt = "emoji" className = "height-auto width-auto object-fit-contain" onClick={handleEmoji}/>
                                            <div className="message-panel-emoji" onMouseEnter={()=>{setEmojiClicked(true)}} onMouseLeave={()=>{setEmojiClicked(false)}}  style = {{display: showChosenEmoji ? 'flex' : 'none', left: '0%'}}>
                                                <Picker onEmojiClick={onEmojiClick} />
                                            </div>
                                        </div>
                                        <input className = "width-auto main-font-regular font-size-12 font-color-grey line-height-16 italic" placeholder = "schrijf hier iets" onChange = {handleMessage} onKeyDown = {handleKeyPress} value = {message} />
                                        <div {...getRootProps({ style: { outline: 'none' } })}>
                                            <img src = {require('../../../../assets/svg/icon/attachment.svg')} className = "message-panel-input-attachment" alt = "attachment flex-shrink-disable" onClick = {handleAttachment} />
                                            <input className="height-auto width-auto" {...getInputProps()} />
                                        </div>
                                    </div>
                                    <button type = "button" className = "message-panel-input-button margin-right-10 margin-left-15 flex-shrink-disable center-content" style = {{height: '45px', width: '45px'}} onClick = {handleSendButton}>
                                        <img src = {require('../../../../assets/svg/icon/send.svg')} alt = "paper plane" className = "message-panel-input-aero" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </Grid>
                </div>
            </Grid>
            {viewAttachFile && (
                <div className = "message-mobile-modal flex-column center-content fade-in-translate">
                    <div className = "message-mobile-modal-image">
                        {selectedFiles.length > 0 && (
                            <>
                            {selectedFiles[0].type.includes('image') ? (
                                <img alt = 'file' src = {URL.createObjectURL(selectedFiles[0])} className = "height-auto width-auto object-fit-contain" />
                            ) : (selectedFiles[0].type.includes('msword') || selectedFiles[0].type.includes('wordprocessingml')) ? (
                                <img alt = 'file' src = {require('../../../../assets/svg/doc/doc.svg')} className = "height-auto width-auto object-fit-contain" />
                            ) : selectedFiles[0].type.includes('pdf') ? (
                                <img alt = 'file' src = {require('../../../../assets/svg/doc/pdf.svg')} className = "height-auto width-auto object-fit-contain" />
                            ) : selectedFiles[0].type.includes('vnd.ms-excel') || selectedFiles[0].type.includes('spreadsheetml') ? (
                                <img alt = 'file' src = {require('../../../../assets/svg/doc/xls.svg')} className = "height-auto width-auto object-fit-contain" />
                            ) : (
                                <img alt = 'file' src = {require('../../../../assets/svg/doc/other.svg')} className = "height-auto width-auto object-fit-contain" />
                            )}
                            </>
                        )}
                    </div>
                    <p className = "main-font-semi-bold font-size-16 font-color-white margin-top-10">{selectedFiles[0].name}</p>
                    <div className = "message-mobile-modal-button-container">
                        <button type = "button" className = "message-mobile-modal-button-cancel center-content" onClick = {() => { setViewAttachFile(false); setFiles([])}} > 
                            <p className = "main-font-semi-bold font-size-14 font-color-white">Annuleren</p>
                        </button>
                        <button type = "button" className = "message-mobile-modal-button-submit center-content" disabled = {buttonDisableState} onClick = {() => { setMessage(""); handleSendButton(); }} >
                            <p className = "main-font-semi-bold font-size-14 font-color-white">Verzenden</p>
                        </button>
                    </div>
                </div>
            )}
        </Grid>
    )
}