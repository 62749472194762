import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Grid, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'
import './_company_clients.scss'
import axios from 'axios';
import MobileView from "../mobile_view/mobile_view";
import { companyClientsHeaders } from '../../shared/sample_data';
import EditIcon from '../../../assets/png/icon/edit.png';
import ViewIcon from '../../../assets/png/icon/view.png';
import DeleteIcon from '../../../assets/png/icon/trash.png';
import SearchIcon from '../../../assets/png/icon/search_icon.png';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Popup from '../../shared/popup/custom_popup.js';
import Header from "../header/header";
import DeleteConfirm from '../../shared/delete_confirm/delete_confirm.js';
import ProfileView from '../profile_view/profile_view'

export default function CompanyClients(props) {
  const { activeTab, companyId, companyViewState, companyCommentState } = props
  const [ originalCompanyState, setOriginalCompanyState ] = useState([])
  const [ filterCompanyState, setFilterCompanyState ] = useState([])
  const [ searchState, setSearchState ] = useState('')
  const [ sortByState, setSortByState ] = useState({id: 1, title: 'Bedrijf', asc: true})
  const [showResponseState, setShowResponseState] = useState(false)
  const [responseMessageState, setResponseMessageState] = useState({})
  const [showDeletePopupState, setShowDeletePopupState] = useState(false)
  const [deleteIdState, setDeleteIdState] = useState(0)

  const token = localStorage.getItem('authToken')
  const history = useHistory();

  useEffect(() => {
    axios
    .post(
      `https://backend.develop.bip.flykube.agifly.cloud/admin/company/get_all`, {},
      {headers: {authorization: `bearer ${token}`}}
    )
    .then((response) => {
      setOriginalCompanyState(response.data)
      setFilterCompanyState(response.data)
    })
    .catch((error) => {
      let responseMessage = {
        isSuccess: false,
        heading: 'Geen record gevonden',
        message: 'Er is iets fout gegaan',
        popupState: setShowResponseState
      }
      setShowResponseState(true)
      setResponseMessageState(responseMessage)
    })
  }, [])

  useEffect(() => {
    getFilteredData()
  }, [searchState, sortByState])
  
  const onSearchChange = (event) => {
    if (event !== undefined) {
      const value = event.target.value;
      if (value !== null) {
        setSearchState(value)
      }
      else {
        setSearchState('')
      }
    }
    else {
      setSearchState('')
    }
  }

  const getFilteredData = () => {
    const originalData = [...originalCompanyState]
    const searchData = searchState.toLowerCase()
    let filteredData = []
    originalData.forEach(company => {
      if(company.name.toLowerCase().includes(searchData) || company.generalEmail.toLowerCase().includes(searchData) || company.generalTelephone.toLowerCase().includes(searchData) || company.postalCode.toString().toLowerCase().includes(searchData)) {
        filteredData.push(company)
      }
    })
    getSortedList(filteredData)
  }

  const handleHeaderClick = (item) => {
    if (item !== null && item !== undefined) {
      const prevState = {...sortByState}
      if (prevState.id !== item.id){
        setSortByState({id: item.id, title: item.title, asc: true})
      }
      else {
        if (prevState.asc === true)
          setSortByState({id: item.id, title: item.title, asc: false})
        else
          setSortByState({id: item.id, title: item.title, asc: true})
      }
    }
    else {
      setSortByState({id: 1, title: 'Bedrijf', asc: true})
    }
  }

  const getSortedList = (filteredData) => {
    const sortBy = {...sortByState}
    let sortedData = []
    if (filteredData !== undefined && filteredData.length > 0) {
    if (sortBy !== {} && sortBy !== null) {
      if (sortBy.title.includes('Bedrijf')) {
        if (sortBy.asc)
          sortedData = filteredData.sort((a,b) => a.name !== null ?
            a.name.localeCompare(b.name !== null ? b.name : '') : '')
        else
          sortedData = filteredData.sort((a,b) => b.name !== null ?
            b.name.localeCompare(a.name !== null ? a.name : '') : '')
      }
      else if (sortBy.title.includes('E-mail')) {
        if (sortBy.asc)
          sortedData = filteredData.sort((a,b) => a.generalEmail !== null ?
            a.generalEmail.localeCompare(b.generalEmail !== null ? b.generalEmail : '') : '')
        else
          sortedData = filteredData.sort((a,b) => b.generalEmail !== null ?
            b.generalEmail.localeCompare(a.generalEmail !== null ? a.generalEmail : '') : '')
      }
      else if (sortBy.title.includes('Telefoon')) {
        if (sortBy.asc)
          sortedData = filteredData.sort((a,b) => a.generalTelephone !== null ?
            a.generalTelephone.localeCompare(b.generalTelephone !== null ? b.generalTelephone : '') : '')
        else
          sortedData = filteredData.sort((a,b) => b.generalTelephone !== null ?
            b.generalTelephone.localeCompare(a.generalTelephone !== null ? a.generalTelephone : '') : '')
      }
      else if (sortBy.title.includes('Postcode')) {
        if(sortBy.asc)
          sortedData = filteredData.sort((a,b) => a.postalCode !== null ?
            a.postalCode.localeCompare(b.postalCode !== null ? b.postalCode : '') : '')
        else
          sortedData = filteredData.sort((a,b) => b.postalCode !== null ?
            b.postalCode.localeCompare(a.postalCode !== null ? a.postalCode : '') : '')
      }
      else if (sortBy.title.includes('Dossiers')) {
        if(sortBy.asc)
          sortedData = filteredData.sort((a,b) => (a.fileNumber !== null && b.fileNumber !== null) ?
            parseInt(a.fileNumber) - parseInt(b.fileNumber) : '')
        else
          sortedData = filteredData.sort((a,b) => (a.fileNumber !== null && b.fileNumber !== null) ?
            parseInt(b.fileNumber) - parseInt(a.fileNumber) : '')
      }
    }
    else {
      sortedData = filteredData.sort((a, b) => a.name)
    }
  }
    setFilterCompanyState(sortedData)
  }

  const handleAddCompanyClick = () => {
    if (activeTab !== undefined && companyId !== undefined) {
      companyId(0)
      activeTab(6)
      companyViewState(false)
      history.push('/klanten/company/add')
    }
  }

  const handleDeleteClick = () => {
    if (deleteIdState > 0) {
       axios.put(
        `https://backend.develop.bip.flykube.agifly.cloud/admin/company/delete/${deleteIdState}`, {},
        {headers: {authorization: `bearer ${token}`}}
      )
      .then((response) => {
        const prevCompanyState = [...originalCompanyState]
        const remainingData = prevCompanyState.filter(a => a.id !== deleteIdState)
        if (remainingData !== undefined) {
          setOriginalCompanyState(remainingData)
          setSortByState({id: 1, title: 'Bedrijf', asc: true})
        }
        setDeleteIdState(0)
      })
      .catch((error) => {
        let responseMessage = {
          isSuccess: false,
          heading: 'Record niet verwijderd',
          message: 'Er is iets fout gegaan',
          popupState: setShowResponseState
        }
        setShowResponseState(true)
        setResponseMessageState(responseMessage)
      })
      
    }
  }

  const handleDeleteConfirmation = (id) => {
    if (id > 0) {
      setDeleteIdState(id)
      setShowDeletePopupState(true)
    }
    else {
      setDeleteIdState(0)
    }
  }

  return (
    <Grid container>
      <Grid container>
          <Hidden smDown>
              <Header />
          </Hidden>
          <Hidden smUp>
              <Header toShowHamburger />
          </Hidden>
      </Grid>
      <Hidden smUp>
        <MobileView />
      </Hidden>
      <Hidden xsDown>
        <div className="company-clients-main-div">
          <Grid container item xs={12} className="height-disable fade-in-right">
            <Grid container item xs={12}>
              <Grid item xs={8} sm={8} md={6} lg={4}>
                <div className="company-clients-search-bar">
                  <img src={SearchIcon} alt="" className="company-clients-search-icon"/>
                  <input
                    type="text"
                    placeholder="Zoeken"
                    className="width-auto font-color-black margin-left-5"
                    onChange={(event) => onSearchChange(event)}
                    value={searchState}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={6} lg={4}>
                <button type="button" className="custom-button-blue cursor-pointer" onClick={handleAddCompanyClick}>
                  Nieuwe bedrijf
                </button>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container className="company-clients-header-main center-content">
                {
                  companyClientsHeaders !== null ?
                  companyClientsHeaders.map((item, index) => {
                    return(
                      index > 2 ?
                      <Grid item xs={3} sm={3} md={3} lg={2} key={item.id}>
                        <div className="company-clients-header-item"
                          onClick={() => handleHeaderClick(item)}>
                          <span className="main-font-regular font-color-grey">
                            {item.title}
                          </span>
                          {
                            sortByState !== null ?
                              (sortByState.id === item.id && sortByState.asc === true) ?
                                <span className="font-color-grey">
                                  <ExpandMore />
                                </span>
                              : (sortByState.id === item.id && sortByState.asc === false) ?
                                <span className="font-color-grey">
                                  <ExpandLess />
                                </span>
                              : null
                            :
                            null
                          }
                          
                        </div>
                      </Grid>
                      :
                      <Grid item xs={2} key={item.id}>
                        <div className="company-clients-header-item"
                          onClick={() => handleHeaderClick(item)}>
                          <span className="main-font-regular font-color-grey">
                            {item.title}
                          </span>
                          {
                            sortByState !== null ?
                              (sortByState.id === item.id && sortByState.asc === true) ?
                                <span className="font-color-grey">
                                  <ExpandMore />
                                </span>
                              : (sortByState.id === item.id && sortByState.asc === false) ?
                                <span className="font-color-grey">
                                  <ExpandLess />
                                </span>
                              : null
                            :
                            null
                          }
                          
                        </div>
                      </Grid>
                    )
                  })
                  : null
                }
                <Grid item xs={2}>
                  <div></div>
                </Grid>
              </Grid>
              <Grid container>
                  <div className="company-clients-card-container flex-column width-auto">
                  {
                      filterCompanyState.length > 0 ?
                      filterCompanyState.map((item, index) => {
                          return(
                              index % 2 === 0 ?
                              <Grid container className="company-clients-card-main background-color-white" key={item.id}>
                                  <CardDetail data={item} activeTab={activeTab} companyId={companyId} handleDelete={handleDeleteConfirmation}
                                    companyViewState={companyViewState} companyCommentState={companyCommentState}/>
                              </Grid>
                              :
                              <Grid container className="company-clients-card-main background-color-transparent" key={item.id}>
                                  <CardDetail data={item} activeTab={activeTab} companyId={companyId} handleDelete={handleDeleteConfirmation}
                                    companyViewState={companyViewState} companyCommentState={companyCommentState}/>
                              </Grid>
                          )
                      })
                      :
                      <React.Fragment></React.Fragment>
                  }
                  </div>
              </Grid>
            </Grid>
          </Grid>
          {
            showResponseState === true ?
            <Popup data={responseMessageState}/>
            : null
          }
          {
            showDeletePopupState === true ?
            <DeleteConfirm id={deleteIdState} setIdState={setDeleteIdState} setOpenState={setShowDeletePopupState} handleDeleteClick={handleDeleteClick}/>
            : null
          }
        </div>
      </Hidden>
    </Grid>
  )
}
CompanyClients.propTypes = {
  activeTab: PropTypes.func,
  companyId: PropTypes.func,
  companyViewState: PropTypes.func,
  companyCommentState: PropTypes.func
}
CompanyClients.defaultProps = {
  activeTab: function() {},
  companyId: function() {},
  companyViewState: function() {},
  companyCommentState: function() {}
}

const CardDetail = (props) => {
    const { data, companyId, activeTab, companyViewState, handleDelete, companyCommentState } = props
    const history = useHistory();
    const [isProfileClick, setProfileClickState] = useState(false)
    const handleEditClick = (id, isPrivate, comment) => {
      if (id > 0) {
        if (companyId !== undefined && activeTab !== undefined) {
          companyId(id)
          companyViewState(false)
          activeTab(6)
          if(isPrivate) {
            history.push('/klanten/company/edit-private')
          }
          else {
            history.push('/klanten/company/edit')
          }
          if (comment !== null && comment !== undefined) {
            companyCommentState(comment)
          }
          else {
            companyCommentState('')
          }
        }
      }
    }

    const handleViewClick = (id, isPrivate, comment) => {
      if (id > 0) {
        if (companyId !== undefined && activeTab !== undefined) {
          companyId(id)
          companyViewState(true)
          activeTab(6)
          if(isPrivate) {
            history.push('/klanten/company/edit-private')
          }
          else {
            history.push('/klanten/company/edit')
          }
          if (comment !== null && comment !== undefined) {
            companyCommentState(comment)
          }
          else {
            companyCommentState('')
          }
        }
      }
    }

  return (
      <React.Fragment>
        {
          data !== null && data !== {} ?
          <Grid container>
            <div className="height-auto width-auto center-content">
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <div className="width-auto">
              <span className="main-font-regular font-color-grey">
                {data.name}
              </span>
            </div>
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <div className="width-auto">
              <p className="main-font-regular font-color-grey company-clients-word-break">
                  {data.generalEmail}
              </p>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <div className="width-auto">
              <p className="main-font-regular font-color-grey company-clients-word-break">
                  {data.generalTelephone}
              </p>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="width-auto">
              <span className="main-font-regular font-color-grey">
                  {data.postalCode}
              </span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="width-auto">
              <span className="main-font-regular font-color-grey">
                  {data.totalProjectService}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Grid container justify="center" alignItems="center" className="height-auto">
              <Hidden smDown>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <div className="height-auto">
                    <span className="company-clients-verticle-line"></span>
                  </div>
                </Grid>
              </Hidden>
              <Grid item xs={4} sm={3} md={3} lg={3}>
                <button className="cursor-pointer" onClick={() => handleEditClick(data.id, data.private, data.comments)}>
                    <img src={EditIcon} alt="Edit" className="company-clients-icon-button"/>
                </button>
              </Grid>
              <Grid item xs={4} sm={3} md={3} lg={3}>
                <button className="cursor-pointer" onClick={() => handleViewClick(data.id, data.private, data.comments)}>
                    <img src={ViewIcon} alt="View" className="company-clients-icon-button"/>
                </button>
              </Grid>
              <ProfileView
                modalState={isProfileClick}
                setModalState={setProfileClickState}
                userId={data.id}
              />
              <Grid item xs={4} sm={3} md={3} lg={3}>
              <button className="cursor-pointer" onClick={() => handleDelete(data.id)}>
                    <img src={DeleteIcon} alt="Delete" className="company-clients-icon-button"/>
                </button>
              </Grid>
            </Grid>
          </Grid>
          </div>
        </Grid>
        :
        null
      }
    </React.Fragment>
  )
}
CardDetail.propTypes = {
    data: PropTypes.object,
    activeTab: PropTypes.func,
    companyId: PropTypes.func,
    handleDelete: PropTypes.func,
    companyViewState: PropTypes.func,
    companyCommentState: PropTypes.func
}
CardDetail.defaultProps = {
    data: {},
    activeTab: function() {},
    companyId: function() {},
    handleDelete: function() {},
    companyViewState: function() {},
    companyCommentState: function() {}
}
