import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { restructureDate } from '../../../epb_forms/epb_form_client/step_two/step_two'
import axios from 'axios'
import './_step_two.scss'
export default function StepTwo(props) {
  const { token, setStepNumber, stepOneData, projectServiceId } = props
  const [documentDate, setDocumentDate] = useState('')
  const [documentName, setDocumentName] = useState('')
  const [documentOriginalName, setDocumentOriginalName] = useState("")

  useEffect(() => {
    let data = []
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/service_document/get`,
        { serviceId: 4, projectServiceId: projectServiceId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success === true) {
          data = response.data.data
          if (data[0].service_document_categories[0].service_documents.length > 0) {
            setDocumentDate(
              restructureDate(
                data[0].service_document_categories[0].service_documents[0]
                  .createdAt,
              ),
            )
            setDocumentName(
              data[0].service_document_categories[0].service_documents[0]
                .fileName,
            )
            setDocumentOriginalName(data[0].service_document_categories[0].service_documents[0].documentTitle)
          }
        }
      })
      .catch((error) => {})
  }, [])
  useEffect(() => {}, [documentDate])

  function downloadImage(documentName) {
    if(documentName) {
      axios.get(`https://backend.develop.bip.flykube.agifly.cloud/documents/download_file/${documentName}`, {
        responseType: 'blob'
      }).then(response => {
        const imageUrl = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = imageUrl;
        a.download = documentOriginalName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(imageUrl)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  return (
    <Grid container className="fade-in-translate animatable-400">
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center"></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Veiligheids-en gezondheidsplan
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid container xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
         <Grid container xs={6} sm={3} md={2}>
            <a
                // href={
                //   documentName !== '' &&
                //   `https://backend.develop.bip.flykube.agifly.cloud/documents/download_file/${documentName}`
                // }
                // download = "example.exe"
                className={
                  `${documentName !== '' ? 'selected-tab-button-style' : 'disabled-button-style'} cursor-pointer flex-row vertical-center`
                }
                onClick = {() => downloadImage(documentName)}
              >
                <img
                  alt=""
                  src={documentName !== '' ? require('../../../../assets/svg/icon/download.svg') : require('../../../../assets/svg/icon/download_grey.svg') }
                  className={documentName!== '' ? '' : "step-two-icon-size margin-left-10"}
                />

                <p className="vietnam-semi-bold center-text width-auto">{`${documentName !== '' ? 'Download' : ''}`}</p>
              </a>
         </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <p className="step-two-description vietnam-regular">
              {documentDate !== '' && `Opgemaakt op ${documentDate}`}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      {stepOneData.safety_services_form_resources.length === 0 && (
        <Grid container>
          <Grid
            container
            className="epb-service-Category-heading"
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center"></Grid>

            <Grid container xs={11} sm={11}  md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Contacten
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container>
            <Grid container xs={1} sm={1} md={1} />
            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="step-two-other-heading vietnam-regular">
                  Gelieve de reeds gekende aannemers aan te vullen in de
                  contactenlijst. Deze gegevens worden opgenomen in het
                  postinterventiedossier.{' '}
                </p>
              </Grid>
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
              <Grid container xs={11} sm={6} md={2}>
                <button
                  type="button"
                  className={
                    'selected-tab-button-style cursor-pointer flex-row center-content vietnam-semi-bold'
                  }
                  onClick={() => {
                    setStepNumber(1)
                  }}
                  justify="center"
                >
                  Contactenlijst aanvullen
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      )}

      {stepOneData.safety_services_form_planning.length===0&&
        <Grid>
        <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          {/* <Grid container justify="flex-end" md={10}>
            <img
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            />
          </Grid> */}
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Planning
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="step-two-other-heading vietnam-regular">
              De volgende stap is het houden van de werfbezoeken. Wij beschikken
              momenteel niet over een planning van de werken. Gelieve ons de
              planning zo snel mogelijk te bezorgen via de planningstool. In
              functie van deze data plannen wij onze werfbezoeken in.{' '}
            </p>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container xs={11} sm={6} md={2}>
            <button
              type="button"
              className={
                `selected-tab-button-style cursor-pointer flex-row center-content vietnam-semi-bold`
              }
              onClick={() => {
                setStepNumber(1)
              }}
              justify="center"
            >
              Planningstool
            </button>
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      </Grid>
      }
    </Grid>
  )
}
