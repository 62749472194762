import React, {
  useEffect,
  useState,
  createRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Grid } from '@material-ui/core'
import './_step_one.scss'
import axios from 'axios'
import { CustomInput } from '../../../shared_components/custom_input/custom_input'
import { CustomDateInput } from '../../../shared_components/custom_date_input/custom_date_input'
import { CustomSelect } from '../../../shared_components/custom_select/custom_select'
import Popup from '../../../shared_components/custom_popup/custom_popup'

export default function SafetyStepOne(props) {
  const { stepOneData, setStepOneData, token, isButtonClick,projectId, projectServiceId, safetyFormId, setSafetyFormId, setSafetyPdfFile, setBuildingData, userRole, setGeneratedPdfFile, setBackendFileName } = props
  const [dataWorks, setDataWorks] = useState([])
  const [dataWorksRef, setDataWorksRef] = useState([])
  const [planningRow, setPlanning] = useState([])
  const [planningRowRef, setPlanningRef] = useState([])
  const [contactsRow, setContacts] = useState([])
  const [contactsRowRef, setContactsRef] = useState([])
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  const [forceUpdateState,setForceUpdateState]=useState(false)
  const [isFirstTime, setFirstHitState] = useState(true)
  // const [safetyFormId,setSafetyFormId]=useState(0)

  useEffect(() => {
    let data = {}
    let tempDataWorks = {}
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/safety_service_form/get`,

        { projectServiceId: projectServiceId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.message === 'Success!') {
          data = response.data.data[0]
          if(data.id!==undefined){
            tempDataWorks['startDate'] = data.startDate
            tempDataWorks['endDate'] = data.endDate
            setDataWorks(tempDataWorks)
            tempDataWorks['safety_services_form_planning']=data.safety_services_form_planning
            tempDataWorks['safety_services_form_resources']=data.safety_services_form_resources
            setPlanning(data.safety_services_form_planning)
            setContacts(data.safety_services_form_resources)
            setSafetyFormId(data.id)
            setStepOneData(tempDataWorks)
            setSafetyPdfFile(data.safety_pdf_orignal_name)
            setBuildingData(data.building)
            setGeneratedPdfFile(data.final_pdf_filename)
            setBackendFileName(data.safety_pdf)
          }
          else{
            setBuildingData(data.building)
          }
        }
      })
      .catch((error) => {})
  }, [projectServiceId,forceUpdateState])

  useEffect(() => {
    if(!isFirstTime){
      storeForm()
    }
    else{
      setFirstHitState(false)
    }
  }, [isButtonClick])

  function storeForm(){
    if(projectId>0){
      let tempData = JSON.parse(JSON.stringify(stepOneData))
      tempData.projectId=projectId
      tempData.projectServiceId = projectServiceId
      tempData['safetyServiceFormId']=safetyFormId
      if (dataWorksRef.length > 0) {
        tempData.startDate = dataWorksRef[0].current.getValue()
        tempData.endDate = dataWorksRef[1].current.getValue()
      }
      if (planningRowRef.length > 0) {
        planningRowRef.map((item, index) => {
          tempData.safety_services_form_planning[index] = item.current.getValue()
        })
      }
      if (contactsRowRef.length > 0) {
        contactsRowRef.map((item, index) => {
          tempData.safety_services_form_resources[index] = item.current.getValue()
        })
      }
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/safety_service_form/store`,
          tempData,
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data.message === 'Inserted' || response.data.message === 'Updated!' ) {
                let responseMessage = {
                  isSuccess: true,
                  heading: 'Succesrecord opgeslagen',
                  message: '',
                  popupState: setShowPopupState
              }
              setShowPopupState(true)
              setPopupMessageState(responseMessage)
              setForceUpdateState(!forceUpdateState)
          }
        })
        .catch((error) => {})
      }
  }

  return (
    <Grid container className='animatable-400 fade-in-translate'>
      <DataWorks
        stepOneData={stepOneData}
        setStepOneData={setStepOneData}
        isButtonClick={isButtonClick}
        dataWorksRef={dataWorksRef}
        setDataWorksRef={setDataWorksRef}
        setDataWorks={setDataWorks}
        dataWorks={dataWorks}
        disabled = {userRole === 'Extern'}
      />
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Planning
        stepOneData={stepOneData}
        setStepOneData={setStepOneData}
        isButtonClick={isButtonClick}
        planningRowRef={planningRowRef}
        setPlanningRef={setPlanningRef}
        planningRow={planningRow}
        setPlanning={setPlanning}
        forceUpdateState = {forceUpdateState}
        setForceUpdateState={setForceUpdateState}
        token={token}
        disabled = {userRole === 'Extern'}
      />
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <YardContacts
        stepOneData={stepOneData}
        setStepOneData={setStepOneData}
        isButtonClick={isButtonClick}
        contactsRowRef={contactsRowRef}
        setContactsRef={setContactsRef}
        contactsRow={contactsRow}
        setContacts={setContacts}
        forceUpdateState = {forceUpdateState}
        setForceUpdateState={setForceUpdateState}
        token={token}
        disabled = {userRole === 'Extern'}
      />
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      {
        showPopupState === true ?
            <Popup data={popupMessageState}/>
        : null
      }
    </Grid>
  )
}

const DataWorks = (props) => {
  const {
    isButtonClick,
    dataWorksRef,
    setDataWorksRef,
    dataWorks,
    disabled
  } = props
  useEffect(() => {
    const tempArray = []
    Array(2)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setDataWorksRef(tempArray)
  }, [dataWorks])
  useEffect(() => {}, [isButtonClick])
  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Gegevens werken
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid container xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
          <Grid container md={3} direction="column">
            <CustomDateInput
              label="Startdatum werken"
              value={dataWorks.startDate}
              ref={dataWorksRef[0]}
              disabled = {disabled}
            />
          </Grid>
          <Grid container md={3} direction="column">
            <CustomDateInput
              label="Einddatum werken"
              value={dataWorks.endDate}
              ref={dataWorksRef[1]}
              disabled = {disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Planning = (props) => {
  const {
    stepOneData,
    setStepOneData,
    isButtonClick,
    planningRowRef,
    setPlanningRef,
    planningRow,
    setPlanning,
    forceUpdateState,
    setForceUpdateState,
    token,
    disabled
  } = props
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  useEffect(() => {
    const tempArray = []
    Array(planningRow.length)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setPlanningRef(tempArray)
  }, [planningRow])

  useEffect(() => {
    let tempData = { ...stepOneData }

    setStepOneData(tempData)
  }, [isButtonClick])

  useEffect(() => {}, [planningRow])

  function handleDeleteClick(rowIndex,id){
    if(id>0){
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/safety_service_form/remove_ssform_planning`,
          {record_id:id},
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data.message === 'Success!' ) {
            let tempRecordsRef = JSON.parse(JSON.stringify(planningRowRef))
            let tempRecords = JSON.parse(JSON.stringify(planningRow))
            tempRecordsRef.splice(rowIndex, 1)
            tempRecords.splice(rowIndex, 1)
            setPlanningRef(tempRecordsRef)
            setPlanning(tempRecords)
                let responseMessage = {
                  isSuccess: true,
                  heading: 'Succesrecord opgeslagen',
                  message: '',
                  popupState: setShowPopupState
              }
              setShowPopupState(true)
              setPopupMessageState(responseMessage)
              setForceUpdateState(!forceUpdateState)
          }
        })
        .catch((error) => {})
    }
    else{
      let tempRecordsRef = [...planningRowRef]
      let tempRecords = JSON.parse(JSON.stringify(planningRow))
      tempRecordsRef.splice(rowIndex, 1)
      tempRecords.splice(rowIndex, 1)
      setPlanningRef(tempRecordsRef)
      setPlanning(tempRecords)
    }
  }


  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center"></Grid>
        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Planning
            </p>
          </Grid>
        </Grid>
      </Grid>
      {planningRow.map((item, index) => {
        return(
          <PlanningRow data={item} allRecords={planningRow} setAllRecords={setPlanning} index={index} handleDeleteClick={handleDeleteClick} ref={planningRowRef[index]} disabled = {disabled} />
        )
      })}
      <PlanningRow
        isNewRecord={true}
        allRecords={planningRow}
        setAllRecords={setPlanning}
        allRecordsRef={planningRowRef}
        setAllRecordsRef={setPlanningRef}
        disabled = {disabled}
      />
      
      </Grid>
  )
}

const PlanningRow = forwardRef((props, ref) => {
  const {
    data,
    isNewRecord,
    allRecords,
    setAllRecords,
    allRecordsRef,
    setAllRecordsRef,
    index,
    handleDeleteClick,
    disabled
  } = props
  const [planningRowRef, setPlanningRowRef] = useState([])
  const [rowDetails, setRowDetails] = useState({
    planningId:0,
    phase: '',
    startDate: '',
    endDate: '',
  })
  
  useEffect(() => {
    const tempArray = []
    Array(3)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setPlanningRowRef(tempArray)
  }, [])

  useEffect(()=>{
    if(data!==undefined){
      if(data.id!==undefined){
        rowDetails.planningId=data.id
      }
      else{
        rowDetails.planningId=data.planningId
      }
      rowDetails.phase=data.phase
      rowDetails.startDate=data.startDate
      rowDetails.endDate=data.endDate
      setRowDetails(rowDetails)
    }
  },[data])

  useEffect(()=>{
    if(data!==undefined){
      let tempRecords=JSON.parse(JSON.stringify(allRecords))
      tempRecords[index].phase=rowDetails.phase
      tempRecords[index].startDate=rowDetails.startDate
      tempRecords[index].endDate=rowDetails.endDate
      setAllRecords(tempRecords) 
    }
  },[rowDetails])

  useImperativeHandle(ref, () => ({
    getValue() {
      let tempRowDetails = JSON.parse(JSON.stringify(rowDetails))
      tempRowDetails.phase = planningRowRef[0].current.getValue()
      tempRowDetails.startDate = planningRowRef[1].current.getValue()
      tempRowDetails.endDate = planningRowRef[2].current.getValue()

      return tempRowDetails
    },
  }))

  const handleAddClick = () => {
    let tempRowDetails =JSON.parse(JSON.stringify(rowDetails))
    let tempRecordsRef = allRecordsRef
    tempRecordsRef.push(createRef(0))
    tempRowDetails.phase = ''
    tempRowDetails.startDate = Date.now()
    tempRowDetails.endDate = (Date.now())
    setAllRecordsRef(tempRecordsRef)
    setAllRecords([...allRecords, tempRowDetails])
    // planningRowRef[0].current.setValueToEmpty()
    // planningRowRef[1].current.setValueToEmpty()
    // planningRowRef[2].current.setValueToEmpty()
  }

  const handleChange = (value) => {
    if(value!==undefined){
      setRowDetails({...rowDetails,[value.name]:value.value})
    }
  }
  
  return isNewRecord ? (
    <Grid container alignItems="flex-end">
      <Grid container xs={1} sm={1} md={1} alignItems="center">
        <Grid container xs={10} sm={10} md={10} justify="flex-end" alignItems="center">
          <button
            type="button"
            className="safety-step-one-add-image cursor-pointer center-content margin-left-5 margin-top-10"
            disabled = {disabled}
            style = {{opacity: disabled? 0.2 : 1}}
            onClick={handleAddClick}
          >
            <img
              alt=""
              src={require('../../../../assets/svg/icon/Add.svg')}
              className="safety-step-one-add-image-size"
            />
          </button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container alignItems="flex-end">
      <Grid container xs={1} sm={1} md={1}>
        <Grid container xs={10} sm={10} md={10} justify="flex-end" alignItems="center">
          <button
            type="button"
            className={'safety-step-one-delete-image cursor-pointer'}
            disabled = {disabled}
            style = {{opacity: disabled? 0.2 : 1}}
              onClick={() => handleDeleteClick(index,rowDetails.planningId)}
          >
            <img
              alt=""
              src={require('../../../../assets/svg/icon/trash.svg')}
              className=""
            />
          </button>
        </Grid>
      </Grid>
      <Grid container xs={11} sm={11} md={11}>
        <Grid container md={3} direction="column">
          <CustomInput
            label="Fase"
            name='fase'
            ref={planningRowRef[0]}
            value={rowDetails.phase}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
        <Grid container md={3} direction="column">
          <CustomDateInput
            label="Startdatum werken"
            name='startDate'
            ref={planningRowRef[1]}
            value={rowDetails.startDate}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
        <Grid container md={3} direction="column">
          <CustomDateInput
            label="Einddatum werken"
            name='endDate'
            ref={planningRowRef[2]}
            value={rowDetails.endDate}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

const YardContacts = (props) => {
  const {
    contactsRowRef,
    setContactsRef,
    contactsRow,
    setContacts,
    token,
    forceUpdateState,
    setForceUpdateState,
    disabled
  } = props
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  useEffect(() => {
    const tempArray = []
    Array(contactsRow.length)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setContactsRef(tempArray)
  }, [contactsRow])
  
  useEffect(() => {}, [contactsRow])

  function handleDeleteClick(rowIndex,id){
    if(id>0){
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/safety_service_form/remove_ssform_resources`,
          {record_id:id},
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        )
        .then((response) => {
          if (response.data.message === 'Success!' ) {
            let tempRecordsRef = [...contactsRowRef]
            let tempRecords = JSON.parse(JSON.stringify(contactsRow))
            tempRecordsRef.splice(rowIndex, 1)
            tempRecords.splice(rowIndex, 1)
            setContactsRef(tempRecordsRef)
            setContacts(tempRecords)
            let responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord opgeslagen',
              message: '',
              popupState: setShowPopupState
            }
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
            setForceUpdateState(!forceUpdateState)
          }
        })
        .catch((error) => {})
    }
    else{
    let tempRecordsRef = [...contactsRowRef]
    let tempRecords = JSON.parse(JSON.stringify(contactsRow))
    tempRecordsRef.splice(rowIndex, 1)
    tempRecords.splice(rowIndex, 1)
    setContactsRef(tempRecordsRef)
    setContacts(tempRecords)
  }
}

  return (
    <Grid container>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Contacten voor deze werf
            </p>
          </Grid>
        </Grid>
      </Grid>
      {contactsRow.map((item, index) => (
        <ContactFormRow ref={contactsRowRef[index]} data={item} allRecords={contactsRow} setAllRecords={setContacts} index={index} handleDeleteClick={handleDeleteClick} disabled = {disabled} />
      ))}
      <ContactFormRow
        isNewRecord={true}
        allRecords={contactsRow}
        setAllRecords={setContacts}
        allRecordsRef={contactsRowRef}
        setAllRecordsRef={setContactsRef}
        disabled = {disabled}
      />
    </Grid>
  )
}

const ContactFormRow = forwardRef((props, ref) => {
  const {
    data,
    isNewRecord,
    allRecords,
    setAllRecords,
    allRecordsRef,
    setAllRecordsRef,
    handleDeleteClick,
    index,
    disabled
  } = props
  const [contactFormRowRef, setContactFormRowRef] = useState([])
  const [rowDetails, setRowDetails] = useState({
    resourcesId:0,
    project: '',
    capacity: '',
    phone: '',
    email: '',
    send_email: 0,
  })
  const sendEmailData = [
    { id: 1, title: 'Nee' },
    { id: 2, title: 'Ja' },
  ]
  useEffect(() => {
    const tempArray = []
    Array(5)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setContactFormRowRef(tempArray)
  }, [])

  useEffect(()=>{
    if(data!==undefined){
      if(data.id!==undefined){
        rowDetails.resourcesId=data.id
      }
      else{
        rowDetails.resourcesId=data.resourcesId
      }
      rowDetails.project=data.project
      rowDetails.capacity=data.capacity
      rowDetails.phone=data.phone
      rowDetails.email=data.email
      rowDetails.send_email=data.send_email
      setRowDetails(rowDetails)
    }
  },[data])

  useEffect(()=>{
    if(data!==undefined){
      let tempRecords=JSON.parse(JSON.stringify(allRecords))
      tempRecords[index].resourcesId=rowDetails.resourcesId
      tempRecords[index].project=rowDetails.project
      tempRecords[index].capacity=rowDetails.capacity
      tempRecords[index].phone=rowDetails.phone
      tempRecords[index].email=rowDetails.email
      tempRecords[index].send_email=rowDetails.send_email
      setAllRecords(tempRecords) 
    }
  },[rowDetails])

  useImperativeHandle(ref, () => ({
    getValue() {
      let tempRowDetails = { ...rowDetails }
      tempRowDetails.project = contactFormRowRef[0].current.getValue()
      tempRowDetails.capacity = contactFormRowRef[1].current.getValue()
      tempRowDetails.phone = contactFormRowRef[2].current.getValue()
      tempRowDetails.email = contactFormRowRef[3].current.getValue()
      tempRowDetails.send_email = parseInt(contactFormRowRef[4].current.getValue()) - 1
      return tempRowDetails
    },
  }))

  const handleAddClick = () => {
    let tempRowDetails = { ...rowDetails }
    let tempRecordsRef = allRecordsRef
    tempRecordsRef.push(createRef(0))
    tempRowDetails.project = ''
    tempRowDetails.capacity = ''
    tempRowDetails.phone = ''
    tempRowDetails.email = ''
    tempRowDetails.send_email = 0
    setAllRecordsRef(tempRecordsRef)
    setAllRecords([...allRecords, tempRowDetails])
  }

  const handleChange = (value) => {
    if(value!==undefined){
      setRowDetails({...rowDetails,[value.name]:value.value})
    }
  }

  return isNewRecord ? (
    <Grid container alignItems="flex-end">
      <Grid container xs={1} sm={1} md={1}>
        <Grid container xs={10} sm={10} md={10} justify="flex-end" alignItems="center">
          <button
            type="button"
            className="safety-step-one-add-image cursor-pointer center-content margin-top-10"
            disabled = {disabled}
            style = {{opacity: disabled? 0.2 : 1}}
            onClick={handleAddClick}
          >
            <img
              alt=""
              src={require('../../../../assets/svg/icon/Add.svg')}
              className="safety-step-one-add-image-size"
            />
          </button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container alignItems="flex-end">
      <Grid container xs={1} sm={1} md={1}>
        <Grid container xs={10} sm={10} md={10} justify="flex-end" alignItems="center">
          <button
            type="button"
            className={'safety-step-one-delete-image cursor-pointer'}
            disabled = {disabled}
            style = {{opacity: disabled? 0.2 : 1}}
            onClick={() => handleDeleteClick(index,rowDetails.resourcesId)}
          >
            <img
              alt=""
              src={require('../../../../assets/svg/icon/trash.svg')}
              className=""
            />
          </button>
        </Grid>
      </Grid>
      <Grid container xs={11} sm={11} md={11}>
        <Grid container md={2} direction="column">
          <CustomInput
            label="Naam"
            name='naam'
            ref={contactFormRowRef[0]}
            value={rowDetails.project}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
        <Grid container md={2} direction="column">
          <CustomInput
            label="Hoedanigheid"
            name='hoedanigheid'
            ref={contactFormRowRef[1]}
            value={rowDetails.capacity}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
        <Grid container md={2} direction="column">
          <CustomInput
            label="Telefoonnummer"
            name='telefoonnummer'
            ref={contactFormRowRef[2]}
            value={rowDetails.phone}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
        <Grid container md={2} direction="column">
          <CustomInput
            label="E-mail"
            name='email'
            ref={contactFormRowRef[3]}
            value={rowDetails.email}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
        <Grid container md={2} direction="column">
          <CustomSelect
            label="Verstuur Email"
            name='send_email'
            ref={contactFormRowRef[4]}
            data={sendEmailData}
            value={rowDetails.send_email}
            handleChange={handleChange}
            disabled = {disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})
