import React, { forwardRef, useEffect, useState } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import './_step_five_a.scss'

export const StepFiveA = forwardRef((props, ref) => {
  const {
    isFacturateActive,
    FormData,
    isSubmitClick,
    facturateData,
    handleChangeCompanyForm,
    formIndex,
    setSecondFormVisibility,
    isAddButtonClick,
    setFinalData,
    finalData,
    handleButtonClick

  } = props
  const emailRegex = /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/
  const [isEmailValid, setEmailValidity] = useState(false)
  const [isCompanyForm, setCompanyFormState] = useState(true)

  useEffect(() => {
    setCompanyFormState(FormData.isCompany)
  }, [FormData])

  const handleSwitchChange = () => {
    let tempData = JSON.parse(JSON.stringify(finalData))
    let tempFormData = JSON.parse(JSON.stringify(FormData))
    tempFormData = {
      ...tempData[formIndex],
      'isCompany': !isCompanyForm,
    }
    tempData[formIndex] = tempFormData
    setFinalData(tempData)
    setCompanyFormState(!isCompanyForm)
  }
  const validEmail = () => {
    if (emailRegex.test(FormData.emailAddress)) {
      setEmailValidity(true)
      return true
    } else {
      setEmailValidity(false)
      return false
    }
  }
  useEffect(() => {
    validEmail()
  }, [FormData.emailAddress])



  return (
    <Grid container >
      {!FormData.private ? (
          <Grid container xs={7} sm={3} md={9} alignItems='center'>
              <div className="project-application-BillInfo-switch-group cursor-pointer overflow-hidden margin-top-15">
                <div
                  className={`project-application-BillInfo-switch-active vietnam-regular center-content`}
                  onClick={handleSwitchChange}
                  style = {{background: isCompanyForm ? '#7da5c2' : 'rgba(0,0,0,0)', zIndex: isCompanyForm ? 4 : 2}}
                >
                  <span style = {{color: isCompanyForm ? '#FFF' : '#000'}}>Bedrijf</span>
                </div>
                <div
                  className={`project-application-BillInfo-switch-inactive vietnam-regular center-content`}
                  onClick={handleSwitchChange}
                  style = {{background: isCompanyForm ? 'rgba(0,0,0,0)' : '#7da5c2', zIndex: isCompanyForm ? 2 : 4}}
                >
                  <span className='center-content' style = {{color: isCompanyForm ? '#000' : '#FFF'}}>Privé persoon</span>
                </div>
              </div>
          </Grid>
        ) :( 
            <Grid container xs={7} sm={3} md={3} alignItems='center' >
              <div
              className={`selected-tab-button-style vietnam-regular center-content margin-top-10`}
              style = {{background: '#7da5c2', zIndex: 4}}
              >
                <span className='center-content' style = {{color: '#FFF'}}>Privé persoon</span>
              </div>
            </Grid>
      )}
          <Hidden mdUp>
            {!isAddButtonClick &&
              <Grid container xs={5} sm={4} item justify="center" alignItems="center">
                <button
                  type="button"
                  className="project-application-billinfo-second-invoice-box flex-column center-content"
                  onClick={setSecondFormVisibility}
                >
                  <img
                    alt = 'add'
                    className="project-application-billinfo-second-invoice-box-image"
                    src={require('../../../../assets/svg/icon/Add.svg')}
                  />
                  <p className="vietnam-regular">
                    facturatie toevoegen
                  </p>
                </button>
              </Grid>
            }
          </Hidden>
          {formIndex===1 &&
            <Grid container item md={3} xs={5} sm={3} justify='flex-end' alignItems="center">
              <img
                alt = 'cross'
                className="profile-cross-icon cursor-pointer"
                src={require('../../../../assets/svg/icon/cross.svg')}
                onClick={handleButtonClick}
              />
            </Grid>
          }
      <Grid container item >
        {isCompanyForm && !FormData.private ? (
          <Grid container>
            <div className="project-application-billinfo-input-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                Bedrijf
              </p>
              <input
                name="companyName"
                value={FormData.companyName}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-input-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.companyName === null ||
                    FormData.companyName.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-input-box-error-border'
                    : ''
                }
            `}
              />
            </div>
          </Grid>
        ) : (
          <Grid container>
            <div className="project-application-billinfo-input-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                Naam
              </p>
              <input
                name="name"
                value={FormData.name}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-input-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.name === null || FormData.name.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-input-box-error-border'
                    : ''
                }
            `}
              />
            </div>
          </Grid>
        )}
        {isCompanyForm && !FormData.private ? (
          <Grid container>
            <div className="project-application-billinfo-input-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                BTW
              </p>
              <input
                name="BTW"
                value={FormData.BTW}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-input-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.BTW === null || FormData.BTW.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-input-box-error-border'
                    : ''
                }
          `}
              />
            </div>
          </Grid>
        ) : (
          <Grid container>
            <div className="project-application-billinfo-input-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                Voornaam
              </p>
              <input
                name="firstName"
                value={FormData.firstName}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-input-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.firstName === null ||
                    FormData.firstName.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-input-box-error-border'
                    : ''
                }
            `}
              />
            </div>
          </Grid>
        )}
        <Grid container>
          <div className="project-application-billinfo-input-box">
            <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
              Emailadres
            </p>
            <input
              name="emailAddress"
              value={FormData.emailAddress}
              type="email"
              onChange={handleChangeCompanyForm(formIndex)}
              className={`project-application-billinfo-input-box-input main-font-medium font-size-14 font-color-black ${
                isSubmitClick && !isEmailValid
                  ? 'project-application-billinfo-input-box-error-border'
                  : ''
              }
            `}
              required
            />
          </div>
        </Grid>
        <Grid container>
          <div className="project-application-billinfo">
            <div className="project-application-billinfo-adres-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                Adres
              </p>
              <input
                name="address"
                value={FormData.address}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-adres-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.address === null ||
                    FormData.address.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-adres-box-error-border'
                    : ''
                }
                `}
              />
            </div>
            <div className="project-application-billinfo-nr-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey margin-left-10">
                Nr.
              </p>
              <input
                name="no"
                value={FormData.no}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-nr-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.no === null || FormData.no.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-nr-box-error-border'
                    : ''
                }
                `}
              />
            </div>
            <div className="project-application-billinfo-bus-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey margin-left-10">
                Bus
              </p>
              <input
                name="bus"
                value={FormData.bus}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-bus-box-input main-font-medium font-size-14 font-color-black`}
              />
            </div>
          </div>
        </Grid>
        <Grid container>
          <div className="flex">
            <div className="project-application-billinfo-postcode-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                Postcode
              </p>
              <input
                type="number"
                name="postalCode"
                value={FormData.postalCode}
                required
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-postcode-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.postalCode === null ||
                    FormData.postalCode.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-postcode-box-error-border'
                    : ''
                }
              `}
              />
            </div>
            <div className="project-application-billinfo-gemeente-box">
              <p className="project-application-billinfo-input-box-label main-font-light font-size-14 font-color-standard-grey">
                Gemeente
              </p>
              <input
                name="city"
                value={FormData.city}
                type="text"
                onChange={handleChangeCompanyForm(formIndex)}
                className={`project-application-billinfo-gemeente-box-input main-font-medium font-size-14 font-color-black ${
                  (FormData.city === null || FormData.city.length === 0) &&
                  isSubmitClick
                    ? 'project-application-billinfo-gemeente-box-error-border'
                    : ''
                }
              `}
              />
            </div>
          </div>
        </Grid>
        {isFacturateActive && (
          <Grid container item direction="column">
            <p className="project-application-billinfo-input-box-label">
              Facturate%
            </p>
            <select
                name="billingPercent"
              value={FormData.billingPercent}
              onChange={handleChangeCompanyForm(formIndex)}
              className="project-application-billinfo-postcode-box-input vietnam-regular"
            >
              {facturateData.map((data) => {
                return <option>{data}</option>
              })}
            </select>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})
