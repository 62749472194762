import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Header from '../dashboard/header/header'
import SafetyServiceAdmin from './safety_service_admin/safety_service_admin'
import SafetyServiceClient from './safety_service_client/safety_service_client'
import build from '@date-io/date-fns'

export default function SafetyService(props) {
    const [isFormEditable, setEditFormState] = useState(false)
    const [buildingData,setBuildingData]=useState('')

    return (
        <Grid
        className="epb-service-grid-size background-color-faded-faint-grey height-auto width-auto relative animatable-400 fade-in-translate"
        alignItems="flex-start"
        >
        <Grid container>
            <Header isLogoEnabled toShowHamburger={true} />
        </Grid>
        <Grid container>
            <div className="margin-top-10" />
        </Grid>
            <Grid container justify="flex-start" alignItems="center">
                <Grid container xs={1} sm={1} md={1} justify="center"/>
                <Grid container xs={11} sm={11} md={11} justify="space-between">
                    <div className = 'flex-column'>
                        <p className="vietnam-bold font-size-18 font-color-black">
                            {(buildingData.fileName!==undefined && buildingData.fileName!==null)? buildingData.fileName : 'Residentie Amboise'}
                        </p>
                        <p className="vietnam-regular font-size-14 font-color-grey margin-top-5">
                        {(buildingData.zip!==undefined && buildingData.zip!==null)? `${buildingData.zip} ${buildingData.city}`  : 'Residentie Amboise'}
                        </p>
                        <p className="vietnam-regular font-size-14 font-color-grey margin-top-5">
                            Veiligheidscoördinatie dossierverloop
                        </p>
                    </div>
                    <img
                        alt = 'edit'
                        src={require('../../assets/svg/icon/edit_blue.svg')}
                        className = ' width-4 height-4'
                        onClick={() => {
                            setEditFormState(!isFormEditable)}}
                    />
                </Grid>
            </Grid>
            { isFormEditable ? 
                <SafetyServiceClient setBuildingData = {setBuildingData} buildingData = {buildingData} />
                    :
                        <SafetyServiceAdmin setBuildingData = {setBuildingData} buildingData = {buildingData} />
            }
        </Grid>
    )
}