import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import './_project_application_information.scss'

export default function ProjectApplicationInformation(props) {
  const {
    setStepNumber,
    appInfoData,
    handleChange,
  } = props
  const [submitState, setSubmitState] = useState(false)

  const handleInfoSubmit = () => {
    setSubmitState(true)
    if(appInfoData.addressState.length !== 0 && appInfoData.no.length !== 0 && appInfoData.zipcodeState.length !== 0 && appInfoData.cityState.length !== 0 ) {
      setStepNumber(4)
    } 
  }

  return (
    <Grid container md={12}>
      <div className="project-application-service-size">
        <React.Fragment>
          <Grid container className="margin-top-15">
          </Grid>
          <Grid container className="margin-top-15">
            <Grid item xs={8} sm={8} md={6} lg={3}>
              <span className="main-font-light font-size-14 font-color-grey">
                Adres
                {appInfoData.addressState.length === 0 &&
                submitState === true ? (
                  <span className="font-color-red">*</span>
                ) : null}
              </span>
              <input
                name="addressState"
                type="text"
                value={appInfoData.addressState}
                className={`input-custom-control margin-top-5 main-font-medium font-size-14 font-color-black
                          ${
                            appInfoData.addressState.length === 0 &&
                            submitState === true
                              ? 'input-error-border'
                              : ''
                          }`}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={1}
              className="project-application-information-padding"
            >
              <span className="main-font-light font-size-14 font-color-grey">
                Nr.
                {appInfoData.no.length === 0 &&
                submitState === true ? (
                  <span className="font-color-red">*</span>
                ) : null}
              </span>
              <input
                name="no"
                type="text"
                value={appInfoData.no}
                className={`input-custom-control margin-top-5 margin-left-5 main-font-medium font-size-14 font-color-black
                          ${
                            appInfoData.no.length === 0 &&
                            submitState === true
                              ? 'input-error-border'
                              : ''
                          }`}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={1}
              className="project-application-information-padding"
            >
              <span className="main-font-light font-size-14 font-color-grey">
                Bus
              </span>
              <input
                name="bus"
                type="text"
                value={appInfoData.bus}
                className={`input-custom-control margin-top-5 margin-left-5 main-font-medium font-size-14 font-color-black`}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container className="margin-top-15">
            <Grid item xs={4} sm={4} md={3} lg={2}>
              <span className="main-font-light font-size-14 font-color-grey">
                Postcode
                {appInfoData.zipcodeState.length === 0 &&
                submitState === true ? (
                  <span className="font-color-red">*</span>
                ) : null}
              </span>
              <input
                name="zipcodeState"
                type="number"
                value={appInfoData.zipcodeState}
                className={`input-custom-control margin-top-5 main-font-medium font-size-14 font-color-black
                          ${
                            appInfoData.zipcodeState.length === 0 &&
                            submitState === true
                              ? 'input-error-border'
                              : ''
                          } `}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={4}
              lg={3}
              className="project-application-information-padding"
            >
              <span className="main-font-light font-size-14 font-color-grey">
                Gemeente
                {appInfoData.cityState.length === 0 &&
                submitState === true ? (
                  <span className="font-color-red">*</span>
                ) : null}
              </span>
              <input
                name="cityState"
                type="text"
                value={appInfoData.cityState}
                className={`input-custom-control margin-top-5 main-font-medium font-size-14 font-color-black
                          ${
                            appInfoData.cityState.length === 0 &&
                            submitState === true
                              ? 'input-error-border'
                              : ''
                          } `}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </React.Fragment>
        <Grid container className="margin-top-15">
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <span className="main-font-light font-size-14 font-color-grey">
              Meer info over de dienst
            </span>
            <textarea
              name="moreInfoState"
              onChange={handleChange}
              value={appInfoData.moreInfoState}
              className={`project-application-information-textarea main-font-medium font-size-14 font-color-black`}
            ></textarea>
          </Grid>
        </Grid>
      </div>
      <button
        onClick={handleInfoSubmit}
        type="button"
        className={`project-application-billinfo-submit vietnam-semibold cursor-point project-application-submit`}
      >
        Volgende
        <img
          alt = 'right_arrow'
          className="width-1 height-1"
          src={require('../../../assets/svg/icon/arrow_right.svg')}
        />
      </button>
    </Grid>
  )
}
