import React, { createRef, useEffect, useState } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import './_info_tab.scss'
import Popup from '../../../../shared/popup/custom_popup.js';
import {CustomInput} from '../../../../shared_components/custom_input/custom_input'
import { CustomSelect } from '../../../../shared_components/custom_select/custom_select'

function ContactPerson(props) {
  const [ isContactEditable, setIsContactEditable ] = useState(false);
  const [ data, setData ] = useState({
    first_name: '-',
    last_name: '-',
    email: '-',
    phoneNumber: '-',
    profession: '-',
  })
  const { contactData, token, buildingId } = props;

  useEffect(() => {
    // this makes sure contact is not null, that means that the current building doesn't have an extra contact
    if(contactData) {
      setData(contactData)
    }
  }, [contactData])

  function handleChange(event, property) {
    const newData = {...data};
    newData[property] = event.target.value;
    setData(newData);
  }

  function Space() {
    return(
      <Grid container>
        <div className = "margin-top-15" />
      </Grid>
    )
  }

  const updateContact = () => {
    let finalData = {...data};
    finalData.buildingId = buildingId;
    axios.post('https://backend.develop.bip.flykube.agifly.cloud/buildlings/addNewContact', finalData, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      setIsContactEditable(false)
    }).catch(error => console.log(error))
  }

  return(
    <Grid container>
      <Space />
      <Grid container justify = "flex-end">
        <div className = "info-tab-pencil cursor-pointer margin-top-5 margin-right-10" onClick = {() => { setIsContactEditable(!isContactEditable) }} >
          <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
        </div>
      </Grid>
      <Grid container justify = "center">
        <Grid container md = {10} lg = {10}>
          <p className = "main-font-semi-bold font-size-18 font-color-black">Contactpersoon</p>
        </Grid>
      </Grid>
      <Space />
      {isContactEditable ? (
        <Grid container justify = "center">
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Naam</p>
            <p className="info-tab-details vietnam-regular">
              <input className="info-tab-input main-font-semi-bold font-size-14 font-color-black" onChange = {(event) => { handleChange(event, 'last_name')}} value = {data?.last_name} />
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Voornaam</p>
            <input className="info-tab-input main-font-semi-bold font-size-14 font-color-black" onChange = {(event) => { handleChange(event, 'first_name')}} value = {data?.first_name} />
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Email</p>
            <p className="info-tab-details vietnam-regular">
              <input className="info-tab-input main-font-semi-bold font-size-14 font-color-black" onChange = {(event) => { handleChange(event, 'email')}} value = {data?.email} />
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Telefoon</p>
            <p className="info-tab-details vietnam-regular">
              <input className="info-tab-input main-font-semi-bold font-size-14 font-color-black" onChange = {(event) => { handleChange(event, 'phoneNumber')}} value = {data?.phoneNumber} />
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Beroep</p>
            <p className="info-tab-details vietnam-regular">
              <input className="info-tab-input main-font-semi-bold font-size-14 font-color-black" onChange = {(event) => { handleChange(event, 'profession')}} value = {data?.profession} />
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10}>
            <button type = "button" className = "info-tab-save center-content" onClick = {updateContact} >
              <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
            </button>
          </Grid>
        </Grid>
      ): (
        <Grid container justify = "center">
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Naam</p>
            <p className="info-tab-details vietnam-regular">
              {data?.last_name || '-'}
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Voornaam</p>
            <p className="info-tab-details vietnam-regular">
              {data?.first_name || '-'}
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Email</p>
            <p className="info-tab-details vietnam-regular">
              {data?.email || '-'}
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Telefoon</p>
            <p className="info-tab-details vietnam-regular">
              {data?.phoneNumber || '-'}
            </p>
          </Grid>
          <Space />
          <Grid container md = {10} lg = {10} direction = "column">
            <p className="info-tab-heading vietnam-regular">Beroep</p>
            <p className="info-tab-details vietnam-regular">
              {data?.profession || '-'}
            </p>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
} 

export default function InfoTab(props) {
  const {
    token,
    buildingId,
    serviceId,
    projectId,
    buildingData,
    singleBuildingData
  } = props
  const [isFacturateEditable, setFacturateEditState] = useState(true)
  const [isCompanyEditable, setCompanyEditState] = useState(true)
  const [showResponseState, setShowResponseState] = useState(false)
  const [responseMessageState, setResponseMessageState] = useState({})
  const [companyData, setCompanyData] = useState({})
  const [data, addData] = useState([])
  const [facturateFormRefs, setFacturateFormRefs] = useState([])
  const [facturateData, setFacturateData] = useState([])
  const [ dateHoverState, setDateHoverState ] = useState(false)

  const history = useHistory()

  useEffect(() => {
    let tempArray = []
    Array(11 * data.length).fill().map(item => {
      tempArray.push(createRef(0))
    })  
    setFacturateFormRefs(tempArray)
  }, [data])
  
  useEffect(() => {
    if(singleBuildingData !== undefined && singleBuildingData !== null){
      setCompanyData(singleBuildingData.user)
    }
  } , [singleBuildingData])

  function generateFileNumber(originBuildingId, originServiceId) {
    let buildingId = originBuildingId.toString();
    let serviceId = originServiceId.toString();
    let newBuildingDigits = '0000'
    let newServiceDigits = '0000'
    newBuildingDigits = newBuildingDigits.slice(0, 4 - buildingId.length)
    let newbuildingId = newBuildingDigits.concat(buildingId)
    newServiceDigits = newServiceDigits.slice(0, 4 - serviceId.length)
    let newserviceId = newServiceDigits.concat(serviceId)

    return `${newbuildingId}-${newserviceId}`
  }

  const handleUserChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setCompanyData({...companyData, [name]:value})
  }

  const handleCompanyChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    let tempCompanyData = JSON.parse(JSON.stringify(companyData))
    tempCompanyData.company.[name] = value
    setCompanyData(tempCompanyData)
  }

  const handleSaveClick = () => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.map((item, i) => {
      if(i<2){
        item.companyName = facturateFormRefs[(i*11)+0].current !== null ? facturateFormRefs[(i*11)+0].current.getValue() : item.companyName
        item.name = facturateFormRefs[(i*11)+1].current !== null ? facturateFormRefs[(i*11)+1].current.getValue() : item.name
        item.BTW = facturateFormRefs[(i*11)+2].current !== null ? facturateFormRefs[(i*11)+2].current.getValue() : item.BTW
        item.firstName = facturateFormRefs[(i*11)+3].current !== null ? facturateFormRefs[(i*11)+3].current.getValue() : item.firstName
        item.emailAddress = facturateFormRefs[(i*11)+4].current.getValue()
        item.address = facturateFormRefs[(i*11)+5].current.getValue()
        item.no = facturateFormRefs[(i*11)+6].current.getValue()
        item.bus = facturateFormRefs[(i*11)+7].current.getValue()
        item.postalCode = facturateFormRefs[(i*11)+8].current.getValue()
        item.city = facturateFormRefs[(i*11)+9].current.getValue()
        item.billingPercent = data.length > 1 ? facturateFormRefs[(i*11)+10].current.getValue() : 100
      }
    })
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/company_billing/store_billing_by_id`,
          {
            dataArray: newData,
          },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.message === 'Billing data saved') {
            let responseMessage = {
              success: true,
              heading: '',
              message: ''
            }
            
            setShowResponseState(true)
            responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord opgeslagen',
              message: 'De facturering van het bedrijf is bijgewerkt',
              popupState: setShowResponseState
            }
            setResponseMessageState(responseMessage) 
          setFacturateEditState(!isFacturateEditable)         
        }
          })
        .catch((error) => {
         
        })  
      
  }

  useEffect(()=>{
    var arr = []
    var i = 0
    while (i < 101) {
      arr.push({id: i, title: i})
      i += 5
    }
    setFacturateData(arr)
      if(companyData!== undefined && companyData.id !== undefined){
        getCompanyDetails()
      }
  },[companyData, serviceId])

  const getCompanyDetails = () => {
    if(serviceId){
      let responseData = []
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/project_services/comp_billing_by_ps`,
          {
            projectServiceId: serviceId,
          },  
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.status === 200) {
            responseData = response.data.data
            addData(responseData)
          }
        })
        .catch((error) => {})
    }
  }

  const updateCompany = () => {
    let tempCompanyData = {
      companyInfo: companyData.company,
      contactInfo: companyData
    }
    data.map((item, i) => {
      if(i<2){
        item.companyName = facturateFormRefs[(i*11)+0].current !== null ? facturateFormRefs[(i*11)+0].current.getValue() : item.companyName
        item.name = facturateFormRefs[(i*11)+1].current !== null ? facturateFormRefs[(i*11)+1].current.getValue() : item.name
        item.BTW = facturateFormRefs[(i*11)+2].current !== null ? facturateFormRefs[(i*11)+2].current.getValue() : item.BTW
        item.firstName = facturateFormRefs[(i*11)+3].current !== null ? facturateFormRefs[(i*11)+3].current.getValue() : item.firstName
        item.emailAddress = facturateFormRefs[(i*11)+4].current.getValue()
        item.address = facturateFormRefs[(i*11)+5].current.getValue()
        item.no = facturateFormRefs[(i*11)+6].current.getValue()
        item.bus = facturateFormRefs[(i*11)+7].current.getValue()
        item.postalCode = facturateFormRefs[(i*11)+8].current.getValue()
        item.city = facturateFormRefs[(i*11)+9].current.getValue()
        item.billingPercent = data.length > 1 ? facturateFormRefs[(i*11)+10].current.getValue() : 100
      }
    })
    axios.post(
      `https://backend.develop.bip.flykube.agifly.cloud/admin/company/add`,
      tempCompanyData,
      { headers: { authorization: `bearer ${token}` } },
    )
    .then((response) => {
      let responseMessage = {
        success: true,
        heading: '',
        message: ''
      }
      
      setShowResponseState(true)
      responseMessage = {
        isSuccess: true,
        heading: 'Succesrecord opgeslagen',
        message: 'Bedrijf succesvol bijgewerkt',
        popupState: setShowResponseState
      }
      setResponseMessageState(responseMessage) 
      setCompanyEditState(!isCompanyEditable)  
    }          
    )
    .catch((error) => {
      setShowResponseState(true)
      let responseMessage = {
        isSuccess: false,
        heading: 'Record niet opgeslagen',
        message: error.response.data.message ? error.response.data.message : 'Er is iets misgegaan',
        popupState: setShowResponseState
      }
      setResponseMessageState(responseMessage)
    })
  }

  return ( 
    <Grid container justify = 'center' className = 'margin-top-20' style = {{height: '80vh', overflowY: 'auto', overflowX: 'hidden'}}>
      <Grid container item md = {10}>
        <Grid container md={8} xs={8} sm={8}>
          <div>
            <p className="info-tab-heading vietnam-regular">Status</p>
            {buildingData !== null && (
              <img
                alt = 'status'
                className = 'width-8'
                src={
                  buildingData.status === 0
                    ? require('../../../../../assets/svg/Status/ontvangen.svg')
                    : buildingData.status === 1
                    ? require('../../../../../assets/svg/Status/behandeling.svg')
                    : buildingData.status === 2
                    ? require('../../../../../assets/svg/Status/afgewerkt.svg')
                    : buildingData.status === 3
                    ? require('../../../../../assets/svg/Status/onhold.svg')
                    : buildingData.status === 4
                    ? require('../../../../../assets/svg/Status/geannuleerd.svg')
                    : buildingData.status === 5
                    ? require('../../../../../assets/svg/Status/offerte.svg')
                    : buildingData.status === 6
                    ? require('../../../../../assets/svg/Status/offerteaanvaard.svg')
                    : buildingData.status === 7
                    ? require('../../../../../assets/svg/Status/gefactureerd.svg')
                    : require('../../../../../assets/svg/Status/betaald.svg')
                }
              />
            )}
          </div>
        </Grid>
        {buildingData.shift === 'EPB - Verslaggeving' ||
        buildingData.shift === 'Ventilatieverslaggeving' ||
        buildingData.shift === 'Veiligheidscoördinatie' ? (
          <Grid container xs={4} sm={4} md={4} alignItems = 'flex-end'>
            <button
              type="button"
              className="selected-tab-button-style"
              onClick={
                buildingData.shift === 'EPB - Verslaggeving'
                  ? () => {
                      history.push(`/dossiers/epb/${serviceId}-${projectId}`)
                    }
                  : buildingData.shift === 'Ventilatieverslaggeving'
                  ? () => {
                      history.push(`/dossiers/ventilatie/${serviceId}-${projectId}`)
                    }
                  : () => {
                      history.push(`/dossiers/veiligheidc/${serviceId}-${projectId}`)
                    }
              }
            >
              Dossierverloop
            </button>
          </Grid>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Grid>
      <Grid container alignItems = 'flex-start' justify = 'flex-end'>
          <img
            alt = 'edit'
            className='margin-top-16 margin-left-10 margin-right-10' style = {{height: '24px', width: '24px'}}
            src={require('../../../../../assets/svg/icon/edit_blue.svg')}
            onClick={() => {
              setCompanyEditState(!isCompanyEditable)
              // setEditFormState(true)
            }}
          />
        </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
        <p className="info-tab-heading vietnam-regular">Dossiernaam</p>
        <p className="info-tab-details vietnam-regular">
          {buildingData === null || buildingData.fileName === null
            ? '-'
            : buildingData.fileName}
        </p>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column' justify='flex-end' >
        {isCompanyEditable?
          <>
            <p className="info-tab-heading vietnam-regular horizontal-end">Bedrijf</p>
            <p className="info-tab-details vietnam-regular horizontal-end">
              {companyData?.company?.name
                }
            </p>
          </>
          :
          <>
            <p className="info-tab-heading vietnam-regular horizontal-end">Bedrijf</p> 
            <input
              className={`info-tab-input vertical-center main-font-semi-bold font-size-14 font-color-black text-align-end`}
              name="name"
              value={ companyData?.company?.name }
              disabled={isCompanyEditable}
              onChange={handleCompanyChange}
            />
          </>
        }
        </Grid>
      </Grid>
      <Grid container item md = {10}>
        <div className = "margin-top-15" />
      </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
        <p className="info-tab-heading vietnam-regular">Dienst</p>
        <p className="info-tab-details vietnam-regular">
          {buildingData === null || buildingData.shift === null
            ? '-'
            : buildingData.shift}
        </p>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column'>
        {isCompanyEditable?
          <>
            <p className="info-tab-heading vietnam-regular horizontal-end">Contactpersoon</p>
            <p className="info-tab-details vietnam-regular horizontal-end">
              {companyData?.firstName }
            </p>
          </>
          :
          <>
            <p className="info-tab-heading vietnam-regular horizontal-end">Contactpersoon</p> 
            <input
              className={`info-tab-input vertical-center main-font-semi-bold font-size-14 font-color-black text-align-end`}
              name="firstName"
              value={companyData?.firstName }
              disabled={isCompanyEditable}
              onChange={handleUserChange}
            />
          </>
        }
        </Grid>
      </Grid>
      <Grid container item md = {10}>
        <div className = "margin-top-15" />
      </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
        <p className="info-tab-heading vietnam-regularg">Type</p>
        <p className="info-tab-details vietnam-regular">
          {buildingData === null || buildingData.typePand === null
            ? '-'
            : buildingData.typePand}
        </p>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column'>
          {isCompanyEditable?
            <>
              <p className="info-tab-heading vietnam-regular horizontal-end">Beroep</p>
              <p className="info-tab-details vietnam-regular horizontal-end">
                {companyData?.profession}
              </p>
            </>
            :
            <>
              <p className="info-tab-heading vietnam-regular horizontal-end">Beroep</p> 
              <input
                className={`info-tab-input vertical-center main-font-semi-bold font-size-14 font-color-black text-align-end`}
                name="profession"
                value={companyData?.profession}
                disabled={isCompanyEditable}
                onChange={handleUserChange}
              />
            </>
          }
        </Grid>
      </Grid>
      <Grid container item md = {10}>
        <div className = "margin-top-15" />
      </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
        <p className="info-tab-heading vietnam-regular">
          Dossiernummer
        </p>
        <p className="info-tab-details vietnam-regular">
          {buildingId && serviceId ? generateFileNumber(buildingId, serviceId) : '-'}
        </p>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column'>
        {isCompanyEditable?
          <>
            <p className="info-tab-heading vietnam-regular horizontal-end">
            Telefoon
            </p>
            <p className="info-tab-details vietnam-regular horizontal-end">
              {companyData?.phone}
            </p>
          </>
          :
          <>
            <p className="info-tab-heading vietnam-regular horizontal-end">Telefoon</p> 
            <input
              className={`info-tab-input vertical-center main-font-semi-bold font-size-14 font-color-black text-align-end`}
              name="phone"
              value={companyData?.phone}
              disabled={isCompanyEditable}
              onChange={handleUserChange}
            />
          </>
        }
        </Grid>
      </Grid>
      <Grid container item md = {10}>
        <div className = "margin-top-15" />
      </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
        <p className="info-tab-heading vietnam-regular">Uitvoerder</p>
        <p className="info-tab-details vietnam-regular">
          {buildingData === null || buildingData.performer === null
            ? '-'
            : buildingData.performer}
        </p>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column'>
          {isCompanyEditable?
            <>
              <p className="info-tab-heading vietnam-regular horizontal-end">Kantoor tel.</p>
              <p className="info-tab-details vietnam-regular horizontal-end">
                {companyData?.company?.generalTelephone}
              </p>
            </>
            :
            <>
              <p className="info-tab-heading vietnam-regular horizontal-end">Kantoor tel.</p> 
              <input
                className={`info-tab-input vertical-center main-font-semi-bold font-size-14 font-color-black text-align-end`}
                name="generalTelephone"
                value={companyData?.company?.generalTelephone}
                disabled={isCompanyEditable}
                onChange={handleCompanyChange}
              />
            </>
          }
        </Grid>
      </Grid>
      <Grid container item md = {10}>
        <div className = "margin-top-15" />
      </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
          <div className = "relative cursor-pointer" onMouseOver = {() => setDateHoverState(true) } onMouseLeave = {() => setDateHoverState(false) } >
            <div>
              <p className="info-tab-heading vietnam-regular">Inspectie Datum</p>
              <p className="info-tab-details vietnam-regular">
                {buildingData === null || buildingData.inspectionDate === null
                  ? '-'
                  : buildingData.inspectionDate.split(' ')[0]}
              </p>
            </div>
            <div className = "info-tab-datum-dropdown animatable-400 ease-in-out-quart" style = {{height: dateHoverState ? '52px' : '0px', opacity: dateHoverState ? 1 : 0}}>
              <div className = "width-auto flex-column">
                <p className = "main-font-regular font-size-14 font-color-grey">Van</p>
                <p className = "main-font-semi-bold font-size-14 font-color-black">{buildingData?.inspectionStartTime ? buildingData?.inspectionStartTime.substring(0, 5) : '-'}</p>
              </div>
              <div className = "flex-row">
                <div className = "info-tab-datum-line" />
              </div>
              <div className = "width-auto flex-column">
                <p className = "main-font-regular font-size-14 font-color-grey">Tot</p>
                <p className = "main-font-semi-bold font-size-14 font-color-black">{buildingData?.inspectionEndTime ? buildingData?.inspectionEndTime.substring(0, 5) : '-'}</p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column'>
          {isCompanyEditable?
            <>
              <p className="info-tab-heading vietnam-regular horizontal-end">E-mail</p>
              <p className="info-tab-details vietnam-regular horizontal-end">
                {companyData?.email}
              </p>
            </>
            :
            <>
              <p className="info-tab-heading vietnam-regular horizontal-end">E-mail</p> 
              <input
                className={`info-tab-input vertical-center main-font-semi-bold font-size-14 font-color-black text-align-end`}
                name="email"
                value={companyData?.email}
                disabled={isCompanyEditable}
                onChange={handleUserChange}
              />
            </>
          }
        </Grid>
      </Grid>
      <Grid container item md = {10}>
        <div className = "margin-top-15" />
      </Grid>
      <Grid container item md = {10} >
        <Grid container xs={6} sm={6} md={6} direction='column'>
        <p className="info-tab-heading vietnam-regular">Beschrijving</p>
        <p className="info-tab-details vietnam-regular">
          {buildingData === null || buildingData.description === null
            ? '-'
            : buildingData.description}
        </p>
        </Grid>
        <Grid container xs={6} sm={6} md={6} direction='column'>
          <p className="info-tab-heading vietnam-regular horizontal-end">Gebruikersgroep</p>
            <p className="info-tab-details vietnam-regular horizontal-end">
              {companyData?.roleId && companyData?.roleId === 1 ? 'Verkopen & V.' : companyData?.roleId === 2 ? 'Bouwen & V.' : companyData?.roleId === 3 ? 'Admin' : companyData?.roleId === 4 ? 'Superadmin' : companyData?.roleId === 6 ? 'Externe' : '-'}
            </p>
        </Grid>
        <Grid container item sm = {12} justify = "flex-end" className = "relative">
          <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: !isCompanyEditable ? 1 : 0, transform: !isCompanyEditable ? 'translateX(0px)' : 'translateX(15px)'}}>
            <div className = "info-tab-save center-content" onClick = {updateCompany} >
              <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
            </div>
            <div className = "height-auto width-auto absolute" style = {{display: !isCompanyEditable ? 'none' : 'flex'}} />
          </div>
        </Grid>
      </Grid>
      
      {
          showResponseState === true ?
          <Popup data={responseMessageState}/>
          : null
      }
      <Grid container item sm = {12} justify = "flex-end">
        <div className = "info-tab-pencil cursor-pointer margin-top-5 margin-right-10" onClick = {() => { setFacturateEditState(!isFacturateEditable) }} >
          <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
        </div>
      </Grid>
      <Grid container md = {10}>
        <p className="main-font-semi-bold font-size-18 font-color-black">Facturatie info</p>
      </Grid>
      <Grid container md = {10} justify = 'flex-end' >
          <Grid container >
            {data.map((item, index) =>
              index < 2 ? (
                
                <Grid container item md={6} sm={6} >
                  {item.isCompany ? (
                    
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Bedrijf' ref = {facturateFormRefs[(index*11)+0]} disabled = {isFacturateEditable} value = {item.companyName} />
                  ) : (
                    
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Naam' ref = {facturateFormRefs[(index*11)+1]} disabled = {isFacturateEditable} value = {item.name}/>
                  )}
                  {item.isCompany ? (
                    
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'BTW' ref = {facturateFormRefs[(index*11)+2]} disabled = {isFacturateEditable} value = {item.BTW} />
                  ) : (
                    
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Voornaam' ref = {facturateFormRefs[(index*11)+3]} disabled = {isFacturateEditable} value = {item.firstName} />
                  )}
                  <Grid container>
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'emailAddress' ref = {facturateFormRefs[(index*11)+4]} disabled = {isFacturateEditable} value = {item.emailAddress} />
                  </Grid>
                  <Grid container>
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Adres' ref = {facturateFormRefs[(index*11)+5]} disabled = {isFacturateEditable} value = {item.address} />
                  </Grid>
                  <Grid container>                    
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Nr.' ref = {facturateFormRefs[(index*11)+6]} disabled = {isFacturateEditable} value = {item.no} />
                  </Grid>
                  <Grid container>  
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Bus' ref = {facturateFormRefs[(index*11)+7]} disabled = {isFacturateEditable} value = {item.bus} />
                  </Grid> 
                  <Grid container >
                    <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Postcode' ref = {facturateFormRefs[(index*11)+8]} disabled = {isFacturateEditable} value = {item.postalCode} />
                  </Grid>
                  <Grid container >
                      <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Gemeente' ref = {facturateFormRefs[(index*11)+9]} disabled = {isFacturateEditable} value = {item.city} />
                  </Grid>
                  {data.length > 1 && (
                    <Grid container>
                        {!isFacturateEditable ? (
                          <CustomSelect style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlignLast: 'end', width: 'auto'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Facturate %' data = {facturateData} disabled = {isFacturateEditable} value = {item.billingPercent} ref = {facturateFormRefs[(index*11)+10]}/>
                        ) : (
                          <CustomInput style = {{height: isFacturateEditable ? '15px' : '35px', borderRadius: '7px', padding: isFacturateEditable ? '0px' : '0px 15px', textAlign: index === 1 ? 'end' : 'start'}} gridStyle = {index === 1 ? {flexDirection: 'column', alignItems: 'flex-end'} : {flexDirection: 'column', alignItems: 'flex-start'}} label = 'Facturate %' ref = {facturateFormRefs[(index*11)+10]} disabled = {isFacturateEditable} value = {item.billingPercent} />
                        )}
                    </Grid>
                  )}
                </Grid>
                
              ) : null,
            )}
            <Grid container>
              <div className = 'margin-top-10' />
            </Grid>
          </Grid>
        
      </Grid>
      <Grid container item sm = {11} justify = "flex-end" className = "relative">
          <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: !isFacturateEditable ? 1 : 0, transform: !isFacturateEditable ? 'translateX(0px)' : 'translateX(15px)'}}>
            <div className = "info-tab-save center-content" onClick = {handleSaveClick} >
              <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
            </div>
            <div className = "height-auto width-auto absolute" style = {{display: !isFacturateEditable ? 'none' : 'flex'}} />
          </div>
      </Grid>
       <ContactPerson contactData = {buildingData.contact} token = {token} buildingId = {buildingId} />
    </Grid>
  )
}
