import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Grid } from "@material-ui/core"

import './_add_contact_person.scss';

import EditIcon from '../../../assets/svg/icon_edit_grey.svg'
import CheckIcon from '../../../assets/svg/icon_check_white.svg'

export default function AddContactPerson(props) {

    const { companyId, setShowPopup, setPopupMessage } = props
    const [addContactPersonState, setAddContactPersonState] = useState(false)
    const [ animateState, setAnimateState ] = useState(false)
    const [ doesContactExist, setDoesContactExist ] = useState(false)
    const [ editEnabled, setEditEnabled ] = useState(true)
    const [ userId, setUserId ] = useState(0)

    // contact form state vars
    const [ contactName, setContactName ] = useState("")
    const [ contactLastName, setContactLastName ] = useState("");
    const [ contactTelephone, setContactTelephone ] = useState("")
    const [ contactProfession, setContactProfession ] = useState("");
    const [ contactEmail, setContactEmail ] = useState("");
    const [ contactPassword, setContactPassword ] = useState("");
    const [ contactRole, setContactRole ] = useState(2)

    const [ contactNameError, setContactNameError ] = useState(false)
    const [ contactLastNameError, setContactLastNameError ] = useState(false);
    const [ contactTelephoneError, setContactTelephoneError ] = useState(false)
    const [ contactProfessionError, setContactProfessionError ] = useState(false);
    const [ contactEmailError, setContactEmailError ] = useState(false);
    const [ contactPasswordError, setContactPasswordError ] = useState(false);

    const [buttonDisableState, setButtonDisableState] = useState(false)
    const token = localStorage.getItem('authToken')

    useEffect(() => {
      if(companyId !== 0) {
        const newToken = localStorage.getItem('authToken')
        axios.post(
          `https://backend.develop.bip.flykube.agifly.cloud/admin/company/get/${companyId}`, {},
          { headers: { authorization: `bearer ${newToken}` } },
        ).then(response => {
          if(response.data.user.length > 1) {
            // this means a second user already exists hence we just load the respective data
            const newContact = response.data.user[1];
            setUserId(newContact.id)
            setContactName(newContact.firstName);
            setContactLastName(newContact.lastName)
            setContactTelephone(newContact.phone)
            setContactProfession(newContact.profession)
            setContactEmail(newContact.email)
            setContactRole(newContact.roleId)
            setContactPassword(newContact.password)

            setDoesContactExist(true)
            setEditEnabled(false)
          }
        }).catch(error => {
        })
      }
    }, [companyId])

    const handleNewContactClick = () => {
        setButtonDisableState(false)
        setAddContactPersonState(true)
    }

    const handleSubmitContactForm = () => {
        setAnimateState(true)
        setShowPopup(false)
        const formValid = isFieldsValid()
        let data = {}
        if (formValid === true) {
            if (companyId > 0) {
              setButtonDisableState(true)
              data = {
                id: userId !== 0 ? userId : 0,
                companyId: companyId,
                firstName: contactName,
                lastName: contactLastName,
                phone: contactTelephone,
                profession: contactProfession,
                email: contactEmail,
                password: contactPassword,
                roleId: contactRole
              }
              axios.post(
                  `https://backend.develop.bip.flykube.agifly.cloud/user/create_client`,
                  data,
                  { headers: { authorization: `bearer ${token}` } },
                )
              .then((response) => {
                data.userId === 0 && setUserId(response.data.user.id);
                const messageData = {
                  isSuccess: true,
                  heading: 'Contact Persoon Added',
                  message: 'Data saved successfully',
                  popupState: setShowPopup
                }
                setPopupMessage(messageData)
                setShowPopup(true)
              })
              .catch((error) => {
                setButtonDisableState(false)
                const messageData = {
                    isSuccess: false,
                    heading: 'Contact persoon not saved',
                    message: 'Error saving record',
                    popupState: setShowPopup
                }
                setPopupMessage(messageData)
                setShowPopup(true)
              })
            }
        }
        setTimeout(() => {
            setAnimateState(false)
        }, 2000)
    }

    const handleContactName = (event) => {
      contactNameError && setContactNameError(false)
      setButtonDisableState(false)
      setContactName(event.target.value)
    }

    const handleContactLastName = (event) => {
      contactLastNameError && setContactLastNameError(false)
      setButtonDisableState(false)
      setContactLastName(event.target.value)
    }

    const handleContactTelephone = (event) => {
      if(event.target.validity.valid) {
        contactTelephoneError && setContactTelephoneError(false)
      }
      else {
        !contactTelephoneError && setContactTelephoneError(true)
      }
      setButtonDisableState(false)
      setContactTelephone(event.target.value)
    }

    const handleContactProfession = (event) => {
      contactProfessionError && setContactProfessionError(false)
      setButtonDisableState(false)
      setContactProfession(event.target.value)
    }

    const handleContactEmail = (event) => {
      if(event.target.validity.valid) {
        contactEmailError && setContactEmailError(false)
      }
      else {
        !contactEmailError && setContactEmailError(true)
      }
      setButtonDisableState(false)
      setContactEmail(event.target.value)
    }

    const handleContactPassword = (event) => {
      contactPasswordError && setContactPasswordError(false)
      setButtonDisableState(false)
      setContactPassword(event.target.value)
    }

    const handleContactRole = (event) => {
      setButtonDisableState(false)
      setContactRole(event.target.value)
    }

    function isFieldsValid() {
      let isValid = true;
      if(contactName === "") {
        isValid = false;
        setContactNameError(true)
      }
      if(contactLastName === "") {
        isValid = false;
        setContactLastNameError(true)
      }
      if(contactTelephone === "" || contactTelephoneError) {
        isValid = false;
        setContactTelephoneError(true)
      }
      if(contactProfession === "") {
        isValid = false;
        setContactProfessionError(true)
      }
      if(contactEmail === "" || contactEmailError) {
        isValid = false;
        setContactEmailError(true)
      }
      if(contactPassword === "" || contactPassword.length < 8) {
        isValid = false;
        setContactPasswordError(true)
      }
      
      return isValid;
    }

    function resetFields() {
      setContactName("");
      setContactLastName("")
      setContactTelephone("");
      setContactProfession("")
      setContactEmail("")
      setContactPassword("")
      setContactRole(2);

      setContactNameError(false)
      setContactLastNameError(false)
      setContactTelephoneError(false)
      setContactProfessionError(false)
      setContactEmailError(false)
      setContactPasswordError(false)
      setButtonDisableState(false)
    }

    const handleEditButton = () => {
      setEditEnabled(true)
    }

    const handleCancelButton = () => {
      setEditEnabled(false)
    }

    return (
      <div className="client-child-div">
        <div className = "flex-row width-auto horizontal-between">
          <div className="main-font-semi-bold font-color-black font-size-18">
            Contactpersoon toevoegen
          </div>
          {addContactPersonState && (
            <button type = "button" className = "add-contact-person-close center-content fade-in-translate" onClick = {() => { setAddContactPersonState(false); resetFields(); }} >
              <img src = {require('../../../assets/svg/close.svg')} alt = "close" className = "height-auto width-auto" />
            </button>
          )}
        </div>
        {doesContactExist ? (
          <>
            {editEnabled ? (
              <Grid container>
                <div className = "flex-row width-auto margin-top-30" />
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Naam</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactLastName} onChange = {handleContactLastName} style = {{border: contactLastNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto">
                  <p className = "main-font-light font-size-14 font-color-grey">Voornaam</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactName} onChange = {handleContactName} style = {{border: contactNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Telefoon</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$" value = {contactTelephone} onChange = {handleContactTelephone} style = {{border: contactTelephoneError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Beroep</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactProfession} onChange = {handleContactProfession} style = {{border: contactProfessionError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">E-mail</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" value = {contactEmail} onChange = {handleContactEmail} style = {{border: contactEmailError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Wachtwoord</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" type = "password" value = {contactPassword} onChange = {handleContactPassword} style = {{border: contactPasswordError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Gebruikersgroep</p>
                  <select placeholder = "" value = {contactRole} onChange = {handleContactRole} className = "add-contact-person-select main-font-semi-bold font-size-14 font-color-black" >
                    <option value = {2}>Bouwen & verbouwen</option>
                    <option value = {1}>Verkopen & verhuren</option>
                    <option value = {3}>Admin</option>
                    <option value = {4}>Superadmin</option>
                    <option value = {5}>Geen Account</option>
                  </select>
                </div>
              </Grid>
            ) : (
              <Grid container>
                <div className = "flex-row width-auto margin-top-30" />
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Naam</p>
                  <p className = "main-font-semi-bold font-size-14 font-color-black margin-top-10">{contactLastName}</p>
                </div>
                <div className = "flex-column width-auto">
                  <p className = "main-font-light font-size-14 font-color-grey">Voornaam</p>
                  <p className = "main-font-semi-bold font-size-14 font-color-black margin-top-10">{contactName}</p>
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Telefoon</p>
                  <p className = "main-font-semi-bold font-size-14 font-color-black margin-top-10">{contactTelephone}</p>
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Beroep</p>
                  <p className = "main-font-semi-bold font-size-14 font-color-black margin-top-10">{contactProfession}</p>
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">E-mail</p>
                  <p className = "main-font-semi-bold font-size-14 font-color-black margin-top-10">{contactEmail}</p>
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Wachtwoord</p>
                  <input type = "password" disabled className = "main-font-semi-bold font-size-14 font-color-black margin-top-10" value = {contactPassword} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Gebruikersgroep</p>
                  <p className = "main-font-semi-bold font-size-14 font-color-black margin-top-10">{contactRole === 1 ? 'Verkopen & verhuren' : contactRole === 2 ? 'Bouwen & verbouwen' : contactRole === 3 ? 'Admin' : contactRole === 4 ? "Superadmin" : contactRole === 5 ? 'Geen Account' : contactRole === 6 ? 'Externe' : ''}</p>
                </div>
              </Grid>
            )}
          </>
        ) : (
          <>
            {addContactPersonState === false ? (
              <div className="center-content margin-top-20 font-size-14">
                <button
                  className="client-add-button font-color-grey"
                  onClick={handleNewContactClick}
                >
                  <img src={EditIcon} className="client-button-icon" alt="" />
                  Add new user
                </button>
              </div>
            ) : (
              <Grid container>
                <div className = "flex-row width-auto margin-top-30" />
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Naam</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactLastName} onChange = {handleContactLastName} style = {{border: contactLastNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto">
                  <p className = "main-font-light font-size-14 font-color-grey">Voornaam</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactName} onChange = {handleContactName} style = {{border: contactNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Telefoon</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$" value = {contactTelephone} onChange = {handleContactTelephone} style = {{border: contactTelephoneError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Beroep</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactProfession} onChange = {handleContactProfession} style = {{border: contactProfessionError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">E-mail</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+" value = {contactEmail} onChange = {handleContactEmail} style = {{border: contactEmailError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Wachtwoord</p>
                  <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" type = "password" value = {contactPassword} onChange = {handleContactPassword} style = {{border: contactPasswordError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                  <p className = "main-font-light font-size-14 font-color-grey">Gebruikersgroep</p>
                  <select placeholder = "" value = {contactRole} onChange = {handleContactRole} className = "add-contact-person-select main-font-semi-bold font-size-14 font-color-black" >
                    <option value = {2}>Bouwen & verbouwen</option>
                    <option value = {1}>Verkopen & verhuren</option>
                    <option value = {3}>Admin</option>
                    <option value = {4}>Superadmin</option>
                    <option value = {5}>Geen Account</option>
                    <option value = {6}>Externe</option>
                  </select>
                </div>
              </Grid>
            )}
          </>
        )}
        
        {companyId === 0 && animateState === true ? (
          <div>
            <span className="font-color-red font-size-14 margin-left-10 margin-top-5">
              Please add user first
            </span>
          </div>
        ) : null}
        {doesContactExist && (
          <>
            {editEnabled ? (
              <div className="margin-top-25">
                <div className="add-client-div-save font-size-14">
                  <button type = "button" className = "add-contact-person-cancel center-content" onClick = {handleCancelButton} >
                    <p className = "main-font-semi-bold font-size-14 font-color-white">Cancel</p>
                  </button>
                  <button
                    className="add-client-button-save"
                    onClick={handleSubmitContactForm}
                  >
                    <img src={CheckIcon} className="add-client-check-icon" alt="" />
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <Grid container item xs={12}>
                <div className="width-auto horizontal-end margin-top-20">
                  <button className="view-client-edit-button font-color-grey" onClick = {handleEditButton} >
                    <img src={EditIcon} className="view-client-button-icon" alt="" />
                    Edit
                  </button>
                </div>
              </Grid>
            )}
          </>
        )}
        {addContactPersonState === true ? (
          <div className="margin-top-20">
            <div className="add-client-div-save font-size-14">
              <button
                className="add-client-button-save"
                onClick={handleSubmitContactForm}
                disabled = {buttonDisableState}
              >
                <img src={CheckIcon} className="add-client-check-icon" alt="" />
                Save
              </button>
            </div>
          </div>
        ) : null}
      </div>
  )
}

AddContactPerson.propTypes = {
    contactId: PropTypes.number,
    setShowPopup: PropTypes.func,
    setPopupMessage: PropTypes.func
}

AddContactPerson.defaultProps = {
    contactId: 0,
    setShowPopup: function() {},
    setPopupMessage: function() {}
}
