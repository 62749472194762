import React, { useState,forwardRef,useImperativeHandle,useEffect } from 'react'
import { Grid } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import './_custom_date_input.scss'
export const CustomDateInput = forwardRef((props, ref) => {
  const { label, value, name, handleChange, disabled } = props

  const [selectedDate, setSelectedDate] = useState(Date.now())

  useEffect(()=>{
    if(value!==undefined && value !=='' && value !==null){
      setSelectedDate(value)
    }
  },[value])

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    if(handleChange!==undefined){
      handleChange({name:name,value:date})
    }
  }
  useImperativeHandle(ref, () => ({
    getValue() {
      let formattedDate=new Date(selectedDate)
       let date=formattedDate.getFullYear()+'-'+(formattedDate.getMonth()+1)+'-'+formattedDate.getDate()
      return date
    },
    setValueToEmpty() {
      setSelectedDate(new Date())
    },
  }))
  return (
    <Grid container direction="column">
      <p className="custom-date-input-label">{label}</p>
      <div className = {`${disabled ? 'custom-date-disable-input-container' : 'custom-date-input-container'} center-content flex-column`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="flex-end">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              name={name}
              disabled = {disabled}
              fullWidth
              value={selectedDate}
              onChange={handleDateChange}
              SelectProps={{
                color: 'grey',
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </Grid>
  )
})
