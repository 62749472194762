import React, { useState } from 'react'
import { Grid, Dialog } from '@material-ui/core'
import './_label_select.scss'

export default function LabelSelect(props) {
  const { modalState, setModalState,data,setSelectedValue,selectedValue,handleFilesLabel,forceUpdateState,setForceUpdateState } = props
  const [showMenu, setShowMenu] = useState(0)
  
  const handleButtonClick = () => {
    setModalState(false)
  }
  const handleUploadButtonClick = () => {
    if(selectedValue>0)
    handleFilesLabel(selectedValue)
    setSelectedValue(selectedValue)
    setModalState(false)
    if(forceUpdateState !== undefined)
    setForceUpdateState(!forceUpdateState)
  }

  return (
    <Dialog open={modalState} disableBackdropClick disableEscapeKeyDown>
      <Grid container item className="label-view-size">
        <div className="label-view-size">
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            className="label-container-size"
          >
            <Grid
              container
              item
              md={11}
              xs={11}
              sm={11}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <img
                alt = 'pdf'
                className="label-user-icon"
                src={require('../../../../../../assets/svg/doc/pdf.svg')}
              />
              <p className="label-title vietnam-regular">new Document</p>
            </Grid>
            <Grid container item sm={1} md={1} xs={1} alignItems="flex-start">
              <img
                alt = 'cross'
                className="label-cross-icon"
                src={require('../../../../../../assets/svg/icon/cross.svg')}
                onClick={handleButtonClick}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} md={12} item justify="center">
            <p className="label-title vietnam-regular margin-top-20 margin-left-10 margin-right-10 center-text">
              Gelieve het juiste type documenten te selecteren
            </p>
          </Grid>
          <div className="label-data-view ">
            {data.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                key={item.id}
                alignItems="center"
                justify="center"
              >
                <div className="flex margin-top-10 ">
                  {item.info !== undefined && item.info !== null && item.info !== '' ? (
                    <React.Fragment>
                        <img
                          alt = 'info'
                          onMouseOver={() => {
                            // setFileHover(index + 1)
                            setShowMenu(index+1)
                          }}
                          onMouseLeave={() => {
                            // setFileHover(0)
                            setShowMenu(0)
                          }}
                          className= {`margin-left-10 ${window.screen.width < 600 ? 'margin-right-10' : 'margin-right-20' } epb-service-Category-heading-image-size cursor-pointer`}
                          src={require('../../../../../../assets/svg/icon/info.svg')}
                        />
                        { showMenu === index + 1 && (
                          <div className = ' flex-row'>
                            <img
                              src={require('../../../../../../assets/svg/icon/corner.png')}
                              alt="corner"
                              className="absolute label-info-arrow"
                            />
                            <div className = 'label-info-modal'>
                                <p>{item.info}</p>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ) : (
                    <div className= {`margin-left-10 ${window.screen.width < 600 ? 'margin-right-10' : 'margin-right-20' } epb-service-Category-heading-image-size cursor-pointer`}/>
                  )}
                  <div
                    className={`cursor-pointer label-radio${item.id === selectedValue ? '-filled' : '-outline'}`}
                    onClick={() => {
                      setSelectedValue(item.id)
                    }}
                  />
                  <p className="font-color-black margin-left-10 vietnam-regular word-break width-77-p">
                    {item.label}
                  </p>
                </div>
              </Grid>
            ))}
          </div>
          <Grid container justify='center'> 
            <Grid container item xs={10} sm={9} md={8} justify="center" alignItems='center'>
              <button
                type="button"
                className="selected-tab-button-style actions-information-tab-buttons-size center-content vietnam-semi-bold margin-bottom-10"
                onClick={handleUploadButtonClick}
              >
                <p>Upload</p>
              </button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Dialog>
  )
}
