import React, { useState, useEffect, useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import Popup from '../../shared/popup/custom_popup';
import './_company_edit.scss'
import axios from 'axios'
import EditIcon from '../../../assets/svg/icon_edit_grey.svg'
import CheckIcon from '../../../assets/svg/icon_check_white.svg'
import PropTypes from 'prop-types'
import Header from "../header/header";

export default function CompanyEdit() {
    const [companyState, setCompanyState] = useState([])
    const [showResponseState, setShowResponseState] = useState(false)
    const [responseMessageState, setResponseMessageState] = useState({})
    const [update, setUpdate] = useState(false)
    const [ fileUploadProgress, setFileUploadProgress ] = useState(0)

    const token = localStorage.getItem('authToken')
    const premiumFileRef = useRef(null)

    useEffect(() => {
        axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/user/all_admin`, {},
          {headers: {authorization: `bearer ${token}`}}
        )
        .then((response) => {
            const responseData = response.data.data
            if (responseData !== null && responseData !== undefined) {
                const modifiedData = responseData.filter(item => item.roleId === 4)
                setCompanyState(modifiedData)
            }
        })
        .catch((error) => {
        })
    }, [update])

    const handleAdminFileChange = (event) => {
        event.preventDefault();
        const files = event.target.files
        if (files !== null && files !== undefined) {
            if(files.length > 0) {
                    if (files[0] !== null && files[0] !== undefined) {
                        let formData = new FormData()
                        formData.append('docsInput', files[0])
                        axios.post(
                            `https://backend.develop.bip.flykube.agifly.cloud/admin/company/upload_premium`,
                            formData,
                            { 
                              headers: { 
                                authorization: `bearer ${token}` 
                              },
                              onUploadProgress: progress => {
                                const { total, loaded } = progress;
                                const totalSize = total/1000000;
                                const totalLoaded = loaded/1000000;
                                const percentage = (totalLoaded/totalSize) * 100;
                                setFileUploadProgress(percentage.toFixed(2));
                              },
                              
                            },
                        )
                        .then((response) => {
                          setFileUploadProgress(0)
                            
                          })
                          .catch((error) => {})
                    }
                    
            }
        }
    }
    
    return (
        <Grid container>
            <Grid container>
                <Hidden smDown>
                    <Header />
                </Hidden>
                <Hidden smUp>
                    <Header toShowHamburger />
                </Hidden>
            </Grid>
            <div className="company-edit-main-div">
                <div className = 'flex-row width-auto horizontal-end vertical-center padding-left-10 padding-right-10'>
                    <div className = "calendar-admin-button-disabled center-content margin-left-20 margin-right-20 animatable-400 ease-in-out-quart" style = {{border:  'solid 1px #7DA5C2' }}  >
                        <p className = "main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart" style = {{fontWeight: '600', color: 'black' }} >Premies</p>
                    </div>
                    <p className = "main-font-regular font-size-14 font-color-grey animatable-400 ease-in-out-quart" style = {{fontWeight: '600', color: 'black' }} >:</p>
                    <div className = 'flex-row'>
                        <input type="file" ref={premiumFileRef} className="ventilation-service-file-input" accept="application/pdf"
                            onChange={(event) => handleAdminFileChange(event)}/>
                        <button
                            type="button"
                            className="header-signout-button-radius background-color-sky-blue center-content margin-left-20 margin-right-20 cursor-pointer"
                            style = {{height: '40px'}}
                            onClick={() => premiumFileRef.current.click()}
                        >
                            <p className = "main-font-semi-bold font-color-white font-size-14 margin-left-15 margin-right-15">Upload Premies</p>
                        </button>
                    </div>
                    {fileUploadProgress !== 0 ? (
                      <div class="lds-roller margin-left-15">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        <span className = "height-auto width-auto center-content absolute">
                          <p className = "main-font-semi-bold font-size-12 font-color-blue">{fileUploadProgress}%</p>
                        </span>
                      </div>
                    ) :( 
                        <div className = 'flex-row center-content'>
                            <img
                                alt=""
                                className="document-details-image"
                                src={ require('../../../assets/svg/doc/pdf.svg') }
                            />
                            <p className = "main-font-regular font-size-14 margin-left-10 font-color-grey animatable-400 ease-in-out-quart" style = {{fontWeight: '600', color: 'black' }} >Premies.pdf</p>
                        </div>
                    )}
                </div>
                <Grid container className = "fade-in-right">
                {
                    (companyState !== undefined && companyState !== null) ?
                    companyState.map(company => {
                        if(company!==undefined) {
                            return (
                            <React.Fragment key={company.id}>
                                <CompanyFormEdit data ={company} setPopupState={setShowResponseState}
                                    setPopupMessage={setResponseMessageState} setUpdate = {setUpdate} update = {update} />
                            </React.Fragment>
                        )}
                    })
                    : null
                }
                {
                    showResponseState === true ?
                    <Popup data={responseMessageState}/>
                    : null
                }
                </Grid>
            </div>
        </Grid>
    )
}

const CompanyFormEdit = (props) => {
    const { data, setPopupState, setPopupMessage, setUpdate, update } = props
    const [companyDataState, setCompanyDataState] = useState(0)
    const [ companyEdit, setCompanyEdit ] = useState(false)

    // company and contact state vars
    const [ companyName, setCompanyName ] = useState("");
    const [ companyBTW, setCompanyBTW ] = useState("")
    const [ companyAddress, setCompanyAddress ] = useState("")
    const [ companyNr, setCompanyNr ] = useState("")
    const [ companyBus, setCompanyBus ] = useState("")
    const [ companyZip, setCompanyZip ] = useState("")
    const [ companyCity, setCompanyCity ] = useState("")
    const [ contactName, setContactName ] = useState("")
    const [ contactLastName, setContactLastName ] = useState("")
    const [ contactTelephone, setContactTelephone ] = useState("")
    const [ contactEmail, setContactEmail ] = useState("")

    const [ companyNameError, setCompanyNameError ] = useState(false);
    const [ companyBTWError, setCompanyBTWError ] = useState(false)
    const [ companyAddressError, setCompanyAddressError ] = useState(false)
    const [ companyNrError, setCompanyNrError ] = useState(false)
    const [ companyBusError, setCompanyBusError ] = useState(false)
    const [ companyZipError, setCompanyZipError ] = useState(false)
    const [ companyCityError, setCompanyCityError ] = useState(false)
    const [ contactNameError, setContactNameError ] = useState(false)
    const [ contactLastNameError, setContactLastNameError ] = useState(false)
    const [ contactTelephoneError, setContactTelephoneError ] = useState(false)
    const [ contactEmailError, setContactEmailError ] = useState(false)

    const token = localStorage.getItem('authToken')

    useEffect(() => {
        if (data !== undefined && data !== null) {
            setCompanyDataState(data)   
        }
        else {
            setCompanyDataState(0)
        }
    }, [data])

    useEffect(() => {
        if(data !== undefined && data !== null) {
            setData();
        }
    }, [companyEdit, data])

    function setData() {
        setCompanyName(data.company.name);
        setCompanyBTW(data.company.BTW)
        setCompanyAddress(data.company.companyAddress)
        setCompanyNr(data.company.no)
        setCompanyBus(data.company.bus)
        setCompanyZip(data.company.postalCode)
        setCompanyCity(data.company.city)
        setContactName(data.firstName)
        setContactLastName(data.lastName)
        setContactTelephone(data.phone)
        setContactEmail(data.email)
    }

    const handleCompanySubmit = () => {
        const validate = isFieldsValid()
        if (validate) {
            const apiData = {
                companyInfo: {
                    id: data.company.id,
                    name: companyName,
                    BTW: companyBTW,
                    companyAddress: companyAddress,
                    no: companyNr,
                    bus: companyBus,
                    postalCode: companyZip,
                    city: companyCity,
                },
                contactInfo: {
                    id: data.id,
                    firstName: contactName,
                    lastName: contactLastName,
                    email: contactEmail,
                    phone: contactTelephone,
                    password: data.password
                }
            }
            axios.post(`https://backend.develop.bip.flykube.agifly.cloud/admin/company/add`,
                apiData,
                { headers: { authorization: `bearer ${token}` } })
            .then((response) => {
                const data = response.data
                let responseMessage = {
                    isSuccess: false,
                    heading: '',
                    message: '',
                    popupState: false
                }
                if (data !== undefined) {
                    if (data.message !== null && data.message !== undefined) {
                        if (data.message.userId > 0) {
                            setPopupState(true)
                            responseMessage = {
                                isSuccess: true,
                                heading: 'Record Updated',
                                message: 'Data saved successfully',
                                popupState: setPopupState
                            }
                            setPopupMessage(responseMessage)
                        }
                        else {
                            setPopupState(true)
                            responseMessage = {
                                isSuccess: false,
                                heading: 'Record Not Updated',
                                message: data.message.message,
                                popupState: setPopupState
                            }
                            setPopupMessage(responseMessage)
                        }
                    }
                }
                else {
                    setPopupState(true)
                    responseMessage = {
                        isSuccess: false,
                        heading: 'Record Not Updated',
                        message: 'Something went wrong',
                        popupState: setPopupState
                    }
                    setPopupMessage(responseMessage)
                }
                setCompanyEdit(false)
                resetFields();
                setUpdate(!update)
            })
            .catch((error) => {
                setPopupState(true)
                let responseMessage = {
                    isSuccess: false,
                    heading: 'Record Not Updated',
                    message: error.response.data.message ? error.response.data.message : 'Something wents wrong',
                    popupState: setPopupState
                }
                setPopupMessage(responseMessage)
                setCompanyEdit(false)
                resetFields();
            })
        }
    }

    const handleCompanyName = (event) => {
        companyNameError && setCompanyNameError(false)
        setCompanyName(event.target.value)
    }

    const handleCompanyBTW = (event) => {
        companyBTWError && setCompanyBTWError(false)
        setCompanyBTW(event.target.value)
    }

    const handleCompanyAddress = (event) => {
        companyAddressError && setCompanyAddressError(false)
        setCompanyAddress(event.target.value);
    }

    const handleCompanyNr = (event) => {
        companyNrError && setCompanyNrError(false)
        setCompanyNr(event.target.value)
    }

    const handleCompanyBus = (event) => {
        companyBusError && setCompanyBusError(false)
        setCompanyBus(event.target.value)
    }

    const handleCompanyZip = (event) => {
        companyZipError && setCompanyZipError(false)
        setCompanyZip(event.target.value)
    }

    const handleCompanyCity = (event) => {
        companyCityError && setCompanyCityError(false)
        setCompanyCity(event.target.value)
    }

    const handleContactName = (event) => {
        contactNameError && setContactNameError(false)
        setContactName(event.target.value)
    }

    const handleContactLastName = (event) => {
        contactLastNameError && setContactLastNameError(false)
        setContactLastName(event.target.value)
    }

    const handleContactTelephone = (event) => {
        if(event.target.validity.valid) {
            contactTelephoneError && setContactTelephoneError(false)
        }
        else {
            !contactTelephoneError && setContactTelephoneError(true)
        }
        setContactTelephone(event.target.value)
    }

    const handleContactEmail = (event) => {
        if(event.target.validity.valid) {
            contactEmailError && setContactEmailError(false)
        }
        else {
            !contactEmailError && setContactEmailError(true)
        }
        setContactEmail(event.target.value)
    }

    function isFieldsValid() {
        // check to see if the fields are not empty and for the fields that have regex, to see if they have valid content
        let isValid = true;
        if(companyName === "") {
            isValid = false;
            setCompanyNameError(true);
        }
        if(companyBTW === "") {
            isValid = false;
            setCompanyBTWError(true);
        }
        if(companyAddress === "" || companyAddressError) {
            isValid = false;
            setCompanyAddressError(true)
        }
        if(companyNr === "" || companyNrError) {
            isValid = false;
            setCompanyNrError(true)
        }
        if(companyBus === "" || companyBusError) {
            isValid = false;
            setCompanyBusError(true)
        }
        if(companyZip === "" || companyZipError) {
            isValid = false;
            setCompanyZipError(true)
        }
        if(companyCity === "" ) {
            isValid = false;
            setCompanyCityError(true)
        }
        if(contactName === "") {
            isValid = false;
            setContactNameError(true)
        }
        if(contactLastName === "") {
            isValid = false;
            setContactLastNameError(true)
        }
        if(contactTelephone === "" || contactTelephoneError) {
            isValid = false;
            setContactTelephoneError(true)
        }
        if(contactEmail === "" || contactEmailError) {
            isValid = false;
            setContactEmailError(true)
        }
    
        return isValid;
    }
    
    function resetFields() {
        setCompanyName("")
        setCompanyBTW("")
        setCompanyAddress("")
        setCompanyNr("")
        setCompanyBus("")
        setCompanyZip("")
        setCompanyCity("")
        setContactName("");
        setContactLastName("")
        setContactTelephone("");
        setContactEmail("")

        setCompanyNameError(false)
        setCompanyBTWError(false)
        setCompanyAddressError(false)
        setCompanyNrError(false)
        setCompanyBusError(false)
        setCompanyZipError(false)
        setCompanyCityError(false)
        setContactNameError(false);
        setContactNameError(false)
        setContactLastNameError(false)
        setContactTelephoneError(false)
        setContactEmailError(false)
    }

    return (
            <React.Fragment>
                {typeof companyDataState === "object" ?
                    !companyEdit ? (
                        <div className="company-edit-sub-div margin-top-10" key={companyDataState.id}>
                            <div className="client-child-div">
                                <div className = "flex-column width-auto">
                                    <span className="main-font-light font-size-14 font-color-grey margin-top-10">Bedrijf</span>
                                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.name}</p>
                                </div>
                                <div className = "flex-column width-auto">
                                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">BTW</span>
                                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.BTW}</p>
                                </div>
                                <div className = "flex-row width-auto horizontal-between">
                                    <div className = "flex-column width-60-p">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-20">Adres</span>
                                        <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.companyAddress}</p>
                                    </div>
                                    <div className = "flex-column width-19-p">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-20">Nr</span>
                                        <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.no}</p>
                                    </div>
                                    <div className = "flex-column width-19-p">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-20">Bus</span>
                                        <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.bus}</p>
                                    </div>
                                </div>
                                <div className = "flex-row width-auto horizontal-between">
                                    <div className = "flex-column width-29-p">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-20">Postcode</span>
                                        <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.postalCode}</p>
                                    </div>
                                    <div className = "flex-column width-69-p">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-20">Gemeente</span>
                                        <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data.company.city}</p>
                                    </div>
                                </div>
                                <div className = "flex-column width-auto">
                                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Contact Naam</span>
                                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data !== undefined && data.lastName}</p>
                                </div>
                                <div className = "flex-column width-auto">
                                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Contact Voornaam</span>
                                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data !== undefined && data.firstName}</p>
                                </div>
                                <div className = "flex-column width-auto">
                                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Tel. contactpersoon</span>
                                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data !== undefined && data.phone}</p>
                                </div>
                                <div className = "flex-column width-auto">
                                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Contact E-mail</span>
                                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{data !== undefined && data.email}</p>
                                </div>
                                <div className="horizontal-end margin-top-20">
                                    <button className="company-edit-button-edit font-color-grey" onClick={() => setCompanyEdit(true)} >
                                        <img src={EditIcon} className="company-edit-button-icon" alt="" />
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div className="company-edit-sub-div margin-top-10" key={companyDataState.id}>
                                <div className="client-child-div">
                                    <div className = "flex-column width-auto">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-10">Bedrijf</span>
                                        <input placeholder="" value = {companyName} className= "input-custom-control margin-top-10" onChange = {handleCompanyName} style = {{border: companyNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                    </div>
                                    <div className = "flex-column width-auto">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-10">BTW</span>
                                        <input placeholder="" value = {companyBTW} className= "input-custom-control margin-top-10" onChange = {handleCompanyBTW} style = {{border: companyBTWError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                    </div>
                                    <div className = "flex-row width-auto horizontal-between">
                                        <div className = "flex-column width-60-p">
                                            <span className="main-font-light font-size-14 font-color-grey margin-top-10">Adres</span>
                                            <input placeholder="" value = {companyAddress} className= "input-custom-control margin-top-10" onChange = {handleCompanyAddress} style = {{border: companyAddressError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                        </div>
                                        <div className = "flex-column width-19-p">
                                            <span className="main-font-light font-size-14 font-color-grey margin-top-10">Nr</span>
                                            <input placeholder="" value = {companyNr} className= "input-custom-control margin-top-10" onChange = {handleCompanyNr} style = {{border: companyNrError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                        </div>
                                        <div className = "flex-column width-19-p">
                                            <span className="main-font-light font-size-14 font-color-grey margin-top-10">Bus</span>
                                            <input placeholder="" value = {companyBus} className= "input-custom-control margin-top-10" onChange = {handleCompanyBus} style = {{border: companyBusError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                        </div>
                                    </div>
                                    <div className = "flex-row width-auto horizontal-between">
                                        <div className = "flex-column width-29-p">
                                            <span className="main-font-light font-size-14 font-color-grey margin-top-10">Postcode</span>
                                            <input placeholder="" value = {companyZip} className= "input-custom-control margin-top-10" pattern = "[0-9]+" onChange = {handleCompanyZip} style = {{border: companyZipError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                        </div>
                                        <div className = "flex-column width-69-p">
                                            <span className="main-font-light font-size-14 font-color-grey margin-top-10">Gemeente</span>
                                            <input placeholder="" value = {companyCity} className= "input-custom-control margin-top-10" onChange = {handleCompanyCity} style = {{border: companyCityError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                        </div>
                                    </div>
                                    <div className = "flex-column width-auto">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-10">Contact Naam</span>
                                        <input placeholder="" value = {contactLastName} className= "input-custom-control margin-top-10" onChange = {handleContactLastName} style = {{border: contactLastNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                    </div>
                                    <div className = "flex-column width-auto">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-10">Contact Voornaam</span>
                                        <input placeholder="" value = {contactName} className= "input-custom-control margin-top-10" onChange = {handleContactName} style = {{border: contactNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                    </div>
                                    <div className = "flex-column width-auto">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-10">Tel. contactpersoon</span>
                                        <input placeholder="" value = {contactTelephone} className= "input-custom-control margin-top-10" pattern = "^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$" onChange = {handleContactTelephone} style = {{border: contactTelephoneError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                    </div>
                                    <div className = "flex-column width-auto">
                                        <span className="main-font-light font-size-14 font-color-grey margin-top-10">Contact E-mail</span>
                                        <input placeholder="" value = {contactEmail} className= "input-custom-control margin-top-10"pattern = "[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+" onChange = {handleContactEmail} style = {{border: contactEmailError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                                    </div>
                                    <div className="margin-top-20">
                                        <div className="company-edit-div-save font-size-14">
                                            <button className="company-edit-button-cancel margin-right-15" onClick={() => { setCompanyEdit(false); resetFields(); }}>
                                                Cancel
                                            </button>
                                            <button className="company-edit-button-save" onClick={() => handleCompanySubmit()}>
                                                <img src={CheckIcon} className="company-edit-button-icon" alt=""/>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
            </React.Fragment>
    )
}

CompanyFormEdit.propTypes = {
    data: PropTypes.object,
    setPopupState: PropTypes.func,
    setPopupMessage: PropTypes.func
}
CompanyFormEdit.defaultProps = {
    data: null,
    setPopupState: function() {},
    setPopupMessage: function() {}
}
