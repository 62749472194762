import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { restructureDate } from '../../../epb_forms/epb_form_client/step_two/step_two'
import './_step_1.scss';
import UploadIcon from '../../../../assets/svg/icon/upload.svg';
import PropTypes from 'prop-types';
import PDFIcon from '../../../../assets/svg/doc/pdf.svg';
import DocIcon from '../../../../assets/svg/doc/doc.svg';
import XLSIcon from '../../../../assets/svg/doc/xls.svg';
import ImageIcon from '../../../../assets/svg/doc/jpg.svg';
import OtherDocIcon from '../../../../assets/svg/doc/other.svg';
import TrashIcon from '../../../../assets/svg/icon/trash.svg';
import axios from 'axios';
import Popup from '../../../shared_components/custom_popup/custom_popup'

export default function Step1(props) {

    const { epbFileState, adviseFileState, ventType, brand, type, ventTypeOptions, error,
            setEPBFileState, setAdviseFileState, setVentType, setBrand, setType, projectServiceId, hasEPB, userRole } = props
    const [ showPopupState, setShowPopupState ] = useState(false)
    const [ popupMessageState, setPopupMessageState ] = useState({})
    const epbFileRef = useRef(null)
    const adviseFileRef = useRef(null)

    const token = localStorage.getItem('authToken')
    
    const handleEPBChange = (event) => {
        event.preventDefault();
        const files = event.target.files
        if (files !== null && files !== undefined) {
            if(files.length > 0) {
                if (setEPBFileState !== null && setEPBFileState !== undefined) {
                    if (files[0] !== null && files[0] !== undefined) {
                        let formData = new FormData()
                        formData.append('projectServiceId', projectServiceId)
                        formData.append('docsInput', files[0])
                        axios.post(
                            `https://backend.develop.bip.flykube.agifly.cloud/ventilation_service_form/ventilation_epc_software_document`,
                            formData,
                            { headers: { authorization: `bearer ${token}` } },
                        )
                        .then((response) => {
                            const responseData = response.data
                            if (responseData !== null && responseData !== undefined) {
                                if (responseData.success === true) {
                                    setEPBFileState({name:responseData.fileName})
                                }
                                else {
                                    setEPBFileState(null)
                                }
                            }
                            else {
                                setEPBFileState(null)
                            }
                          })
                          .catch((error) => {
                            let responseMessage = {
                                isSuccess: false,
                                heading: 'Record niet opgeslagen',
                                message: 'vul eerst het formulier in',
                                popupState: setShowPopupState
                              }
                            setShowPopupState(true)
                            setPopupMessageState(responseMessage)
                            setEPBFileState(null)
                        })
                    }
                    else {
                        setEPBFileState(null)
                    }
                }
            }
        }
    }

    const handleAdviseChange = (event) => {
        event.preventDefault();
        const files = event.target.files
        if (files !== null && files !== undefined) {
            if(files.length > 0) {
                if (setAdviseFileState !== null && setAdviseFileState !== undefined) {
                    if (files[0] !== null && files[0] !== undefined) {
                        let formData = new FormData()
                        formData.append('projectServiceId', projectServiceId)
                        formData.append('docsInput', files[0])
                        axios.post(
                            `https://backend.develop.bip.flykube.agifly.cloud/ventilation_service_form/ventilation_adviesrapport_document`,
                            formData,
                            { headers: { authorization: `bearer ${token}` } },
                        )
                        .then((response) => {
                            const responseData = response.data
                            if (responseData !== null && responseData !== undefined) {
                                if (responseData.success === true) {
                                    setAdviseFileState({name:responseData.fileName})
                                }
                                else {
                                    setAdviseFileState(null)
                                }
                            }
                            else {
                                setAdviseFileState(null)
                            }
                          })
                          .catch((error) => {
                            let responseMessage = {
                                isSuccess: false,
                                heading: 'Record niet opgeslagen',
                                message: 'vul eerst het formulier in',
                                popupState: setShowPopupState
                              }
                            setShowPopupState(true)
                            setPopupMessageState(responseMessage)
                            setAdviseFileState(null)
                        })
                    }
                    else {
                        setAdviseFileState(null)
                    }
                }
            }
        }
    }

    const deleteEPBFileClick = () => {
        axios
            .post(
              `https://backend.develop.bip.flykube.agifly.cloud/ventilation_service_form/epc_software_document_del`,
              { projectServiceId :projectServiceId  },
              { headers: { authorization: `bearer ${token}` } },
            )
            .then((response) => {
              if (response.data.success) {
                  setEPBFileState(null)
              }
            })
            .catch((error) => {})
    }
    const deleteAdviseFileClick = () => {
        // adviesrapport_document
            axios
            .post(
              `https://backend.develop.bip.flykube.agifly.cloud/ventilation_service_form/adviesrapport_document_del`,
              { projectServiceId :projectServiceId  },
              { headers: { authorization: `bearer ${token}` } },
            )
            .then((response) => {
              if (response.data.success) {
                  setAdviseFileState(null)
              }
            })
            .catch((error) => {})
    }
    const handleVentTypeChange = (event) => {
        event.preventDefault()
        setVentType(event.target.value)
    }
    const handleBrandChange = (event) => {
        event.preventDefault()
        setBrand(event.target.value)
    }
    const handleTypeChange = (event) => {
        event.preventDefault()
        setType(event.target.value)
    }

    return(
        <div className="height-auto width-auto animatable-400 fade-in-translate">
            <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
                <Grid container>
                    <Grid item xs={1} sm={1} md={1}></Grid>
                    <Grid item xs={11} sm={11} md={11}>
                        <span className="main-font-regular font-color-black font-size-16">
                            Algemene gegevens
                        </span>
                    </Grid>
                </Grid>
            </div>
                <Grid container className="margin-top-30">
                    <Grid container xs={1} sm={1} md={1}></Grid>
                    <Grid container xs={11} sm={11} md={11}>
                        <Grid container xs={10} sm={6} md={3}>
                            <span className="main-font-light font-color-grey font-size-14">
                                Type ventilatiesysteem
                            </span>
                            <select
                                name="typeId"
                                value={ventType}
                                onChange={handleVentTypeChange}
                                disabled = {userRole === 'Extern'}
                                className={`ventilation-service-step1-control
                                        ${error === true && ventType.length === 0 ?
                                        'input-error-border'
                                        : ''}
                                    `}
                            >
                                <option disabled hidden value=''></option>
                                {
                                    ventTypeOptions !== null && ventTypeOptions !== undefined ?
                                    ventTypeOptions.map(item => {
                                        return(
                                            <option key={item.id} value={item.title}>{item.title}</option>
                                        )
                                    })
                                    : null
                                }
                            </select>
                            {
                                error === true && ventType.length === 0 ?
                                <span className= 'font-color-red font-size-15 margin-left-10 margin-top-5'>
                                    Field required
                                </span>
                                : null
                            }
                        </Grid>
                            <Grid md={1}/>
                            <Grid container xs={10} sm={6} md={3}>
                                <span className="main-font-light font-color-grey font-size-14 width-auto">
                                    Merk
                                </span>
                                <input type="text" value={brand} onChange={handleBrandChange} disabled = {userRole === 'Extern'}
                                    className={`ventilation-service-step1-control
                                        ${error === true && brand.length === 0 ?
                                            'input-error-border'
                                            : ''}
                                    `}/>
                                {
                                    error === true && brand.length === 0 ?
                                    <span className= 'font-color-red font-size-15 margin-left-10 margin-top-5'>
                                        Field required
                                    </span>
                                    : null
                                }
                            </Grid>
                            <Grid md={1}/>
                            <Grid container xs={10} sm={6} md={3}>
                                <span className="main-font-light font-color-grey font-size-14 width-auto">
                                    Type
                                </span>
                                <input type="text" value={type} onChange={handleTypeChange} disabled = {userRole === 'Extern'}
                                    className={`ventilation-service-step1-control
                                        ${error === true && type.length === 0 ?
                                            'input-error-border'
                                            : ''}
                                    `}/>
                                    {
                                        error === true && type.length === 0 ?
                                        <span className= 'font-color-red font-size-15 margin-left-10 margin-top-5'>
                                            Field required
                                        </span>
                                        : null
                                    }
                                <span></span>
                            </Grid>
                    </Grid>
                </Grid>
                {hasEPB !== undefined && !hasEPB &&
                    <Grid container>
                        <div className="ventilation-service-blue-strip margin-top-30 vertical-center">
                            <Grid container>
                                <Grid container xs={1} sm={1} md={1}></Grid>
                                <Grid container xs={11} sm={11} md={11}>
                                    <span className="main-font-regular font-color-black font-size-16">
                                        EPB rapport / adviesrapport
                                    </span>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container className="margin-top-30">
                            <Grid container item xs={1} sm={1} md={1}></Grid>
                            <Grid container xs={11} sm={11} md={11}>
                                    <Grid container xs={10} sm={4} md={2}>
                                        <input type="file" ref={epbFileRef} className="ventilation-service-file-input"
                                            onChange={(event) => handleEPBChange(event)}/>
                                        <button className="ventilation-service-button-upload"
                                            disabled = {userRole === 'Extern'}
                                            style = {{opacity: (userRole === 'Extern') && 0.2}}
                                            onClick={() => epbFileRef.current.click()}>
                                            <img src={UploadIcon} alt="" className="ventilation-service-button-icon"/>
                                            <p className="margin-left-15 main-font-semi-bold font-size-14">
                                                EPB rapport
                                            </p>
                                        </button>
                                    </Grid>                            
                                    {
                                        epbFileState !== null && epbFileState !== undefined && epbFileState.name !== null ?
                                            <Grid container xs={10} sm={8} md={9} alignItems="center">  
                                            <img className="ventilation-service-button-icon margin-left-10" alt=""
                                                src={
                                                epbFileState.name.split('.')[1].includes('jpg') ?
                                                    ImageIcon
                                                :
                                                epbFileState.name.split('.')[1].includes('pdf') ?
                                                    PDFIcon
                                                :
                                                epbFileState.name.split('.')[1].includes('doc') ?
                                                    DocIcon
                                                :
                                                epbFileState.name.split('.')[1].includes('xls') ?
                                                    XLSIcon
                                                :
                                                    OtherDocIcon
                                                }
                                            />
                                            <span className="main-font-semi-bold font-color-black font-size-14 margin-left-10 margin-right-20 ">
                                                {epbFileState.name}
                                            </span>
                                            <button className="height-auto cursor-pointer" onClick={deleteEPBFileClick}>
                                                <img src={TrashIcon} alt="" className="ventilation-service-button-icon"/>
                                            </button>
                                            </Grid>
                                        : null
                                    }
                            </Grid>
                        </Grid>
                        <Grid container className="margin-top-30">
                    <Grid container xs={1} sm={1} md={1}></Grid>
                    <Grid container xs={11} sm={11} md={11}>
                        <Grid container xs={10} sm={4} md={2}>
                                <input type="file" ref={adviseFileRef} className="ventilation-service-file-input"
                                    onChange={(event) => handleAdviseChange(event)}/>
                                <button className="ventilation-service-button-upload"
                                    disabled = {userRole === 'Extern'}
                                    style = {{opacity: (userRole === 'Extern') && 0.2}}
                                    onClick={() => adviseFileRef.current.click()}>
                                    <img src={UploadIcon} alt="" className="ventilation-service-button-icon"/>
                                    <p className="margin-left-15 main-font-semi-bold font-size-14">
                                        Adviesrapport
                                    </p>
                                </button>
                        </Grid> 
                           {
                                adviseFileState !== null && adviseFileState !== undefined && adviseFileState.name !== null ?
                                <Grid container xs={10} sm={8} md={9} alignItems='center'>
                                <img className="ventilation-service-button-icon margin-left-10" alt=""
                                        src={
                                        adviseFileState.name.split('.')[1].includes('jpg') ?
                                            ImageIcon
                                        :
                                        adviseFileState.name.split('.')[1].includes('pdf') ?
                                            PDFIcon
                                        :
                                        adviseFileState.name.split('.')[1].includes('doc') ?
                                            DocIcon
                                        :
                                        adviseFileState.name.split('.')[1].includes('xls') ?
                                            XLSIcon
                                        :
                                            OtherDocIcon
                                        }
                                    />
                                    <span className="main-font-semi-bold font-color-black font-size-14 margin-left-10 margin-right-20 width-p-50">
                                        {adviseFileState.name}
                                    </span>
                                    <button className="height-auto cursor-pointer" onClick={deleteAdviseFileClick}>
                                        <img src={TrashIcon} alt="" className="ventilation-service-button-icon"/>
                                    </button>
                                </Grid>
                                : null
                            }
                    </Grid>
                </Grid>
                    </Grid> 
                }
                {
                    showPopupState === true ?
                        <Popup data={popupMessageState}/>
                    : null
                }
        </div>
    )
}

Step1.propTypes = {
    adviseFileState: PropTypes.object,
    epbFileState: PropTypes.object,
    ventType: PropTypes.string,
    brand: PropTypes.string,
    type: PropTypes.string,
    setEPBFileState: PropTypes.func,
    setAdviseFileState: PropTypes.func,
    setVentType: PropTypes.func,
    setBrandType: PropTypes.func,
    setType: PropTypes.func,
    ventTypeOptions: PropTypes.array,
    error: PropTypes.bool
}

Step1.defaultProps = {
    epbFileState: {},
    adviseFileState: {},
    ventType: '',
    brand: '',
    type: '',
    setEPBFileState: function() {},
    setAdviseFileState: function() {},
    setVentType: function() {},
    setBrandType: function() {},
    setType: function() {},
    ventTypeOptions: [],
    error: false
}
