import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import './_project_application_service.scss'
import axios from 'axios'

export default function ProjectApplicationService(props) {
  const {
    setStepNumber,
    serviceState,
    setServiceState,
    setUserRole,
    serviceObjectArray,
    setServiceObjectArray,
    setStepTwoVisibility
  } = props

  const [disableSubmitState, setDisableSubmitState] = useState(true)
  const token = localStorage.getItem('authToken')

  const checkSelectedServices = (services) => {
    var hasServices = false
      hasServices = services.bouwen_verbouwen.some((item) => {
        if (item.isSelected !== undefined && item.isSelected) {
          return true
        }
      })
      if (!hasServices) {
        hasServices = services.keuringen.some((item) => {
          if (item.isSelected !== undefined && item.isSelected) {
            return true
          }
        })
      }
    if (hasServices) {
      setDisableSubmitState(false)
    } else {
      setDisableSubmitState(true)
    }
  }

  const getServices = () => {
    var data = []
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/projects/step1`,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        data = response.data.data
        setServiceState(data)
        setUserRole(response.data.data.role)
      })
      .catch((error) => {
        setServiceState([])
      })
  }

  useEffect(() => {
    if (JSON.stringify(serviceState) === JSON.stringify({})) {
      getServices()
      setStepNumber(1)
    } else {
      checkSelectedServices(serviceState)
    }
  }, [])

  const handleBouwenVerbouwenServiceClick = (index) => {
    const prevServiceState = { ...serviceState }
    const selectedService = prevServiceState.bouwen_verbouwen[index]
    if (selectedService !== null) {
      if (selectedService.isSelected === true) {
        selectedService.isSelected = false
        var tempObjArray = [...serviceObjectArray]
        tempObjArray.splice(index, 1)
        setServiceObjectArray(tempObjArray)
      } else {
        selectedService.isSelected = true
        setServiceObjectArray([...serviceObjectArray, selectedService])
      }
      setServiceState(prevServiceState)
    }
    checkSelectedServices(prevServiceState)
  }
  const handleKeuringenServiceClick = (index) => {
    const prevServiceState = { ...serviceState }
    const selectedService = prevServiceState.keuringen[index]
    if (selectedService !== null) {
      if (selectedService.isSelected === true) {
        selectedService.isSelected = false
        var tempObjArray = [...serviceObjectArray]
        tempObjArray.splice(index, 1)
        setServiceObjectArray(tempObjArray)
      } else {
        selectedService.isSelected = true
        setServiceObjectArray([...serviceObjectArray, selectedService])
      }
      setServiceState(prevServiceState)
    }
    checkSelectedServices(prevServiceState)
  }

  const handleServiceSubmit = () => {
    localStorage.setItem('selectedServiceIds', null)
    localStorage.setItem('projectServiceMetaIds', null)
    var selectedServices = []
    serviceState.bouwen_verbouwen.forEach((element) => {
      if (element.isSelected) {
        selectedServices.push(element)
      }
    })
    serviceState.keuringen.forEach((element) => {
      if (element.isSelected) {
        selectedServices.push(element)
      }
    })
    
    if (selectedServices !== null) {
      const selectedServiceIds = selectedServices.map((service) => service.id)
      const hasStepTwo = serviceObjectArray.some((item) => {
        if (item.servicesMeta !== undefined && item.servicesMeta.length > 0) {
          return true
        }
      })
      localStorage.setItem(
        'selectedServiceIds',
        JSON.stringify(selectedServiceIds),
      )
      setStepTwoVisibility(hasStepTwo)
      if (hasStepTwo) {
        setStepNumber(2)
      } else {
    let selectedServicesId=JSON.parse(localStorage.getItem('selectedServiceIds'))
    let finalObjectList=[]

      selectedServicesId.forEach(id=>{
            var obj={}
            obj['serviceId']=id
            obj['serviceMetaId']=0
            obj['documents']=[]
            finalObjectList.push(obj)
      })
       localStorage.setItem('services', null)

        if (finalObjectList.length > 0) {
          localStorage.setItem(
            'services',
            JSON.stringify(finalObjectList),
          )
          setStepNumber(3)
        }
      }
    }
  }

  return (
    <Grid container className="relative">
      <div className="project-application-service-size">
          <Grid>
            <Grid item xs={12}>
              <div className="flex-column">
                <span className="vietnam-bold font-size-18 font-color-black">
                  Bouwen & verbouwen
                </span>
                <span className="vietname-regular font-color-grey font-size-14">
                  U kunt meerdere diensten aanklikken
                </span>
              </div>
            </Grid>
            <Grid
              container
              className="margin-top-15 flex-wrap"
              justify="flex-start"
            >
              {serviceState.bouwen_verbouwen !== undefined &&
                serviceState.bouwen_verbouwen !== null
                ? serviceState.bouwen_verbouwen.length > 0
                  ? serviceState.bouwen_verbouwen.map((item, index) => {
                    return (
                      <Grid container xs={12} sm={5} md={3}
                        onClick={() =>
                          handleBouwenVerbouwenServiceClick(index)
                        }
                        key={item.id}
                        className={
                          item.isSelected === true
                            ? 'project-application-service-selected'
                            : 'project-application-service-unselected'
                        }
                      >
                        <span className="vietnam-regular">{item.title}</span>
                        {item.isSelected === true ? (
                          <img
                            alt = 'button_check'
                            src={require('../../../assets/svg/icon/button_check.svg')}
                            className="project-application-service-selectedIcon"
                          />
                        ) : null}
                      </Grid>
                    )
                  })
                  : null
                : null}
            </Grid>
            <Grid item xs={12}>
              <div className="flex-column">
                <span className="vietnam-bold font-size-20 font-color-black">
                  Keuringen :
                </span>
              </div>
            </Grid>
            <Grid
              container
              className="margin-top-15 flex-wrap "
              justify="flex-start"
            >
              {serviceState.keuringen !== undefined &&
                serviceState.keuringen !== null
                ? serviceState.keuringen.length > 0
                  ? serviceState.keuringen.map((item, index) => {
                    return (
                      <Grid container  xs={12} sm={5} md={3}
                        onClick={() => handleKeuringenServiceClick(index)}
                        key={item.id}
                        className={
                          item.isSelected === true
                            ? 'project-application-service-selected margin-right-20'
                            : 'project-application-service-unselected'
                        }
                      >
                        <span className="vietnam-regular">{item.title}</span>
                        {item.isSelected === true ? (
                          <img
                            alt = 'button_check'
                            src={require('../../../assets/svg/icon/button_check.svg')}
                            className="project-application-service-selectedIcon"
                          />
                        ) : null}
                      </Grid>
                    )
                  })
                  : null
                : null}
            </Grid>
          </Grid>
        </div>
      <button
        disabled={disableSubmitState}
        onClick={handleServiceSubmit}
        type="button"
        className={`project-application-billinfo-submit vietnam-semibold project-application-submit center-content
          ${disableSubmitState === true
            ? 'cursor-disable'
            : 'cursor-pointer'
          }`}
      >
        Volgende
        <img
          alt = 'arrow_right'
          className="width-1 height-1 submit-button-image"
          src={require('../../../assets/svg/icon/arrow_right.svg')}
        />
      </button>
    </Grid>
  )
}

ProjectApplicationService.defaultProps = {
  bouwen_verbouwen: [],
}
