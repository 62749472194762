import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react'
import { Grid } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import LabelSelect from './label_select/label_select'
import RowDetails from './row_details/row_details'
import DeleteConfirm from '../../../../shared/delete_confirm/delete_confirm';

import './_document_details.scss'

function DocumentButtonDropzone(props) {
  const {
    setModalState,
    setFiles,
    } = props
  const onDrop = useCallback((files) => {
    setModalState(true)
    setFiles(files)
  }, [])
  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })

  return (
    <div {...getRootProps({ style: { outline: 'none' } })}>
      <button
        type="button"
        className="enable-button-style actions-information-button center-content vietnam-regular font-size-14"
      >
        <input className="height-auto width-auto" {...getInputProps()} />
        <img
          src={require('../../../../../assets/svg/pin.svg')}
          alt="pin svg"
          className="stepsix-import-icon"
        />
        <p className="vietnam-medium font-size-12 font-color-semi-dark-grey margin-left-5">
          Importeren
        </p>
      </button>
    </div>
  )
}

const DocumentDropzone = forwardRef((props, ref) => {
  const {
    fileNames,
    setFileNames,
    modalState,
    setModalState,
    data,
    selectedFiles,
    brokenFileList,
    setFiles,
    token,
    serviceId,
    projectId,
    isSafety,
    setFileUploadProgress,
    updateDocumentList
  } = props
  const [selectedValue, setSelectedValue] = useState(0)
  const [forceUpdateState, setForceUpdateState] = useState(true)
  const [showDeletePopupState, setShowDeletePopupState] = useState(false)
  const [deleteIdState, setDeleteIdState] = useState(0)
  const [ isBrokenFilesVisible, setIsBrokenFilesVisisble ] = useState(false)

  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))

  
  useEffect(() => {
    if(selectedValue > 0){
    const date = new Date()
    const formData = new FormData()
    formData.append('serviceId', serviceId)
    formData.append('projectId', projectId)
    formData.append('role', "admin")
    formData.append('person', `${userDetails.firstName} ${userDetails.lastName}`)
    formData.append('label', data[selectedValue-1].label)
    formData.append('status_id', 0)
    for (let i = 0; i < selectedFiles.length; i++) {
      selectedFiles[i]['date']=`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
      selectedFiles[i]['person']=`${userDetails.firstName} ${userDetails.lastName}`
      formData.append('docsInput', selectedFiles[i])
    }
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/documents/uploadDocument`,
        formData,
        {
          headers: {
            authorization: `bearer ${token}`,
          },
          onUploadProgress: progress => {
            const { total, loaded } = progress;
            const totalSize = total/1000000;
            const totalLoaded = loaded/1000000;
            const percentage = (totalLoaded/totalSize) * 100;
            setFileUploadProgress(percentage.toFixed(2));
          },
        },
      )
      .then((response) => {
        setFileUploadProgress(0)
        if (response.data.success === true) {
          setFileNames(response.data.uploadedDocuments)
          updateDocumentList(!forceUpdateState)
        }
      })
      .catch((error) => {})
    }
  }, [forceUpdateState])

  const onDrop = useCallback(
    (files) => {
      setModalState(true)
      setFiles(files)
    },
    [selectedFiles],
  )

  const handleDeleteClick = () => {
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/documents/delete`,
        { documentId: deleteIdState },
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        if (response.data.success) {
          setFileNames(response.data.uploadedDocuments)
        }
      })
      .catch((error) => {})
  }

  const accept =
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    noClick: true,
  })

  useImperativeHandle(ref, () => ({
    filesToReturn() {
      return fileNames
    },
  }))

  const handleFilesLabel = (value) => {
    const date = new Date()
    selectedFiles.forEach((item) => {
      item.label = data[value - 1].label
      item.date = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
      item.person = `${userDetails.firstName} ${userDetails.lastName}`
      // fileNames.push(item)
    })
  }
  
  const deleteFile = (data) => {
   setDeleteIdState(data.documentId)
    setShowDeletePopupState(true)
  }
  
  return (
    <div
      className="height-auto width-auto relative"
      {...getRootProps({ style: { outline: 'none' } })}
    >
      <input className="height-auto width-auto" {...getInputProps()} />
      <div className="document-details-outer-container">
        <div className="document-details-inner-container ">
          {fileNames !== undefined &&
            fileNames !== null &&
            fileNames.map((item, index) => (
              <div className="margin-top-10">
                <RowDetails
                  file={item}
                  userDetails={userDetails}
                  index={index}
                  deleteFile={deleteFile}
                  isSafety = {isSafety}
                  token = {token}
                  detailsPage = {true}
                  updateDocumentList = {updateDocumentList}
                />
             </div>
            ))}
            {brokenFileList && brokenFileList.length > 0 && (
              <>
                <Grid container justify = "center">
                  <Grid container xs = {11} justify = "flex-end">
                    <p className = "main-font-regular font-size-14 font-color-red margin-top-10 cursor-pointer" onClick = {() => setIsBrokenFilesVisisble(!isBrokenFilesVisible) } >{isBrokenFilesVisible ? 'Hieronder de corrupte bestanden: ' : 'Er zijn enkele corrupte bestanden, klik om weer te geven'}</p>
                  </Grid>
                </Grid>
                {isBrokenFilesVisible && brokenFileList && brokenFileList.length > 0 && brokenFileList.map((item, index) => (
                  <div className="margin-top-10">
                    <RowDetails
                      file={item}
                      userDetails={userDetails}
                      index={index}
                      deleteFile={deleteFile}
                      isSafety = {isSafety}
                      token = {token}
                      detailsPage = {true}
                      updateDocumentList = {updateDocumentList}
                    />
                  </div>
                ))}
              </>
            )}
        </div>
      </div>
      <LabelSelect
        modalState={modalState}
        setModalState={setModalState}
        data={data}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        handleFilesLabel={handleFilesLabel}
        forceUpdateState={forceUpdateState}
        setForceUpdateState={setForceUpdateState}
      />
      {
          showDeletePopupState === true ?
          <DeleteConfirm id={deleteIdState} setIdState={setDeleteIdState} setOpenState={setShowDeletePopupState} handleDeleteClick={handleDeleteClick}/>
          : null
        }
    </div>
  )
})
export default function DocumentDetails(props) {
  const { token, serviceId, projectId, documentSelectorRef } = props
  const [fileNames, setFileNames] = useState([])
  const [selectedFiles, setFiles] = useState([])
  const [ brokenFileList, setBrokenFileList ] = useState([])
  const [ isSafety, setIsSafety ] = useState(0)
  const documentsRef = useRef(0)
  const [modalState, setModalState] = useState(false)
  const [ fileUploadProgress, setFileUploadProgress ] = useState(0)
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const data = [
    { id: 1, label: 'Intentieverklaringen', info: 'Alle ondertekende documenten van bouwheer en aannemers' },
    { id: 2, label: 'Bouwvergunning', info: ''  },
    { id: 3, label: 'Plannen', info: 'As-built plannen, stabiliteitsplannen, leidingsplannen, …'  },
    { id: 4, label: 'Verslagen', info: 'Werfverslagen of andere meldingen.'  },
    { id: 5, label: 'Nutsleidingen – huisaansluitingen – verborgen elementen', info: 'Werfverslagen of andere meldingen.'  },
    { id: 6, label: 'Plaatsbeschrijvingen', info: ''  },
    { id: 7, label: 'Offertes/facturen', info: ''  },
    { id: 8, label: 'Foto’s', info: 'Bouwmaterialen'  },
    { id: 9, label: 'Gebruikte materialen', info: 'Bouwmaterialen, technieken + technische fiches.'  },
    { id: 10, label: 'Handleidingen', info: ''  },
    { id: 11, label: 'Keuringen', info: 'Keuringsattesten of metingen.'  },
    { id: 12, label: 'Oplevering', info: 'PV voorlopige oplevering, PV definitieve oplevering'  },
    { id: 13, label: 'Overige', info: ''  },

  ]


  useEffect(() => {
    // const data = documentsRef.current.filesToReturn();
    let data = []
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/service/get/servicedocumentAdmin`,
        { serviceId: serviceId,
           projectId: projectId,
           role:"admin"
         },
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        data = response.data
        setIsSafety(response.data.serviceId)
        // we check if the files are properly updated first by checking if file property is not null
        // we generate two different array, one for broken files and one for working files
        let fileList = [], brokenFileList = [];
        if(response.data.servicesDocuments) {
          response.data.servicesDocuments.forEach(document => {
            if(document.file) {
              fileList.push(document)
            }
            else {
              brokenFileList.push(document);
            }
          })
        }
        setBrokenFileList(brokenFileList)
        setFileNames(fileList)
      })
      .catch((error) => {
        setFileNames([])
      })
  }, [serviceId, projectId, forceUpdateState])

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      justify="center"
      alignItems="start"
      className = "height-auto"
      wrap = "nowrap"
    >
      <Grid container item sm={12} md={12} alignItems = "flex-start" className = "height-auto" direction = "column">
        <Grid
          container
          item
          sm={12}
          justify="space-around"
          alignItems="flex-end"
          direction="row"
          style = {{flex: 1}}
        >
          <div className="flex-row margin-top-20">
            <div className="flex-column">
              <p className="vietnam-regular font-size-14 font-color-semi-dark-grey text-direction-rtl">
                Upload document : Drag & drop
                <br />
                het document
              </p>
            </div>
            <div className="width-3 height-3">
              <img
                src={require('../../../../../assets/svg/arrow_curve.svg')}
                alt="curved arrow"
                className="height-auto width-auto object-fit-contain"
              />
            </div>
          </div>
          <DocumentButtonDropzone
            fileNames={fileNames}
            setFileNames={setFileNames}
            ref={documentsRef}
            modalState={modalState}
            setModalState={setModalState}
            data={data}
            selectedFiles={selectedFiles}
            setFiles={setFiles}
            token={token}
          />
        </Grid>
        <Grid container item sm={12} >
          <div className="document-details-dropzone-container padding-10">
            
            <div className="flex-column height-auto width-auto relative">
            <Grid container>
              <DocumentDropzone
                fileNames={fileNames}
                setFileNames={setFileNames}
                modalState={modalState}
                setModalState={setModalState}
                data={data}
                selectedFiles={selectedFiles}
                brokenFileList = {brokenFileList}
                setFiles={setFiles}
                token={token}
                serviceId={serviceId}
                projectId={projectId}
                isSafety = {isSafety}
                fileUploadProgress = {fileUploadProgress}
                setFileUploadProgress = {setFileUploadProgress}
                updateDocumentList = {setForceUpdateState}
              />
            </Grid>
            {fileUploadProgress !== 0 && (
              <div className = 'width-auto flex-row vertical-center'>
                <p className = "main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">Upload</p>
                <div className = 'slider'>
                  <div className = 'slider-thumb' style = {{width: fileUploadProgress*2}} />
                </div>
                <p className = "main-font-semi-bold font-size-12 font-color-sky-blue">{fileUploadProgress}%</p>
              </div>
            )}
            
              <div className = "width-auto horizontal-end">
                <button type = "button" className = "email-modal-send center-content" onClick = {() => { documentSelectorRef.current.openModal(fileNames) }}>
                  <p className = "main-font-semi-bold font-size-14 font-color-white">Verzenden</p>
                </button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
