import React, { useState, useEffect } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import './_safety_service_admin.scss'
import axios from 'axios'
import SafetyStepOne from './step_one/step_one'
import StepTwo from './step_two/step_two'
import StepThree from './step_three/step_three'
import StepFour from './step_four/step_four'
import { isLocalDataValid } from '../../shared/shared_functions';
import { CustomSelect } from '../../shared_components/custom_select/custom_select'

export default function SafetyServiceAdmin(props) {
  const { buildingData, setBuildingData} = props
  const token = localStorage.getItem('authToken')
  const [projectId, setProjectId]=useState(0)
  const [ projectServiceId, setProjectServiceId ] = useState(0)
  const [stepNumber, setStepNumber] = useState(1)
  const [isButtonClick, setButtonClick] = useState(false)
  const [stepOneData, setStepOneData] = useState({})
  const [stepTwoData, setStepTwoData] = useState({})
  const [stepThreeData, setStepThreeData] = useState({})
  const [stepFourData, setStepFourData] = useState({})
  const [postInterventionFile, setPostInterventionFile]=useState('')
  const [safetyPdfFile, setSafetyPdfFile]=useState('')
  const [safetyFormId, setSafetyFormId]=useState(0)
  const [generatedPdfFile, setGeneratedPdfFile] = useState('')
  const [backendFileName, setBackendFileName] = useState('')
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const [dataWorks, setDataWorks] = useState({
    startDate: '',
    endDate: '',
  })
  const [planningData, setPlanningData] = useState([])
  const [contactsData, setContactsData] = useState([])
  const parameters = useParams()
  const history = useHistory();

  const dropDownData=[
    {id:1,title:'1. Startgegevens'},
    {id:2,title:'2. Veiligheids-en gezondheidsplan'},
    {id:3,title:'3. Uitvoering van werken'},
    {id:4,title:'4. Postinterventiedossier'},
   ]

  useEffect(()=>{
    if(!isLocalDataValid()) {
      localStorage.clear();
      history.push("/")
  }
    let url = parameters.id.split('-')
    setProjectServiceId(url[0])
    setProjectId(url[1])
  },[parameters.id])

  useEffect(()=>{},[postInterventionFile])

  useEffect(() => {
    let data = []
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/service_document/get`,
        { serviceId: 4, projectServiceId: parseInt(projectServiceId)   },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success === true) {
          data = response.data.data
          setStepTwoData(data[0])
          setStepThreeData(data[1])
          setStepFourData(data[2])
        }
      })
      .catch((error) => {
        if(error.response.status === 401) {
          localStorage.clear();
          history.push("/")
        }
      })
  }, [projectServiceId,stepNumber])

  useEffect(() => {
    if(projectServiceId>0){
      axios
      .post(
        // `http://localhost:9000/safety_service_form/get`,
        `https://backend.develop.bip.flykube.agifly.cloud/safety_service_form/get`,
        {
          projectServiceId: projectServiceId,
        },
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        let data = response.data.data[0]
        if(data.id!==undefined){
          dataWorks.startDate = data.startDate
          dataWorks.endDate = data.endDate
          setDataWorks(dataWorks)
          setPlanningData(data.safety_services_form_planning)
          setContactsData(data.safety_services_form_resources)
          setPostInterventionFile(data.postinterventie_document)
          setSafetyPdfFile(data.safety_pdf_orignal_name)
          setSafetyFormId(data.id)
          setBuildingData(data.building)
          setStepOneData(data)
          setGeneratedPdfFile(data.final_pdf_filename)
          setBackendFileName(data.safety_pdf)
        }
        else{
          setBuildingData(data.building)
        }
       })
      .catch((error) => {
      })
    }
  }, [projectServiceId, forceUpdateState])

  return (
    <Grid container>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Hidden smDown> 
      <Grid container>
        <Grid xs={1} sm={1} md={1}></Grid>
        <Grid container md={8} alignItems="center">
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 1 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(1)
              }}
            >
              Startgegevens
            </span>
          </Grid>
          <Grid>
            <div className="margin-left-30" />
          </Grid>
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 2 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(2)
              }}
            >
              Veiligheids-en gezondheidsplan
            </span>
          </Grid>
          <Grid>
            <div className="margin-left-30" />
          </Grid>
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 3 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(3)
              }}
            >
              Uitvoering van werken
            </span>
          </Grid>
          <Grid>
            <div className="margin-left-30" />
          </Grid>
          <Grid>
            <span
              className={`animatable-400 fade-in-out-quart safety-service-tab-${
                stepNumber === 4 ? 'selected' : 'unSelected'
              } vietnam-regular cursor-pointer `}
              onClick={() => {
                setStepNumber(4)
              }}
            >
              Postinterventiedossier
            </span>
          </Grid>
        </Grid>
        <Grid container md={2} justify="flex-end" alignItems="center">
          <button
            className="safety-service-button"
            onClick={() => {
              setButtonClick(!isButtonClick)
            }}
          >
            Bewaren
          </button>
        </Grid>
      </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container justify='center' alignItems='center'>
           <Grid container xs={5} sm={5}>
              <CustomSelect data={dropDownData} setParentSelectedValue={setStepNumber} value={stepNumber}/>
           </Grid>
           <Grid container xs={5} sm={5} justify='flex-end'>
              <button className="epb-service-button" onClick={()=>{setButtonClick(!isButtonClick)}}>Bewaren</button>
           </Grid>
        </Grid>     
      </Hidden>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>

      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {stepNumber === 1 ? (
        <SafetyStepOne
          token={token}
          stepOneData={stepOneData}
          setStepOneData={setStepOneData}
          isButtonClick={stepNumber === 1 && isButtonClick}
          projectId={projectId}
          projectServiceId = {projectServiceId}
          setPostInterventionFile={setPostInterventionFile}
          setSafetyFormId={setSafetyFormId}
          setSafetyPdfFile={setSafetyPdfFile}
          setGeneratedPdfFile = {setGeneratedPdfFile}
          setBuildingData={setBuildingData}
          setBackendFileName = {setBackendFileName}
          dataWorks = {dataWorks}
          setDataWorks = {setDataWorks}
          planningData = {planningData}
          setPlanningData = {setPlanningData}
          contactsData = {contactsData}
          setContactsData = {setContactsData}
        />
      ) : stepNumber === 2 ? (
        <StepTwo token={token} 
        stepTwoData={stepTwoData}
        setStepTwoData={setStepTwoData}
        isButtonClick={stepNumber === 2 && isButtonClick}
        projectId={projectId}
        projectServiceId={projectServiceId}
        />
      ) : stepNumber === 3 ? (
        <StepThree token={token} 
        stepThreeData={stepThreeData}
        setStepThreeData={setStepThreeData}
        isButtonClick={stepNumber === 3 && isButtonClick}
        projectId={projectId}
        setPostInterventionFile={setPostInterventionFile}
        postInterventionFile={postInterventionFile}
        projectServiceId={projectServiceId}
        />
      ) : stepNumber === 4 ? (
        <StepFour token={token} 
        buildingData = {buildingData}
        stepOneData={stepOneData}
        stepTwoData={stepTwoData}
        stepThreeData={stepThreeData}
        stepFourData={stepFourData}
        safetyFormId={safetyFormId}
        safetyPdfFile={safetyPdfFile}
        safetyPdfFileBackendName = {backendFileName}
        setSafetyPdfBackendFileName = {setBackendFileName}
        setSafetyPdfFile={setSafetyPdfFile}
        generatedPdfFile = {generatedPdfFile}
        setGeneratedPdfFile = {setGeneratedPdfFile}
        setStepFourData={setStepFourData}
        isButtonClick={stepNumber === 4 && isButtonClick}
        projectId={projectId}
        projectServiceId={projectServiceId}
        setForceUpdateState = {setForceUpdateState}
        forceUpdateState = {forceUpdateState}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Grid>
  )
}
