import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./core.scss";
import "../scss/fonts.css";

import Dashboard from './dashboard/dashboard';
import Loginpage from './loginpage/loginpage';
import Residence from "./residence/residence";
import EPBService from "./epb_forms/epb_service";
import SafetyService from "./safety_service/safety_service";
import VentilationService from "./ventilation_service/ventilation_service";
import ProjectApplication from '../components/project_application/project_application'

export default function Core() {

    return(
        <Router>
            <Switch>
                <Route exact path='/project-application/:value'>
                    <ProjectApplication/>
                </Route>
                <Route exact path = "/details/:id">
                    <Residence />
                </Route>
                <Route exact path = {`/dossiers/epb/:id`}>
                    <EPBService />
                </Route>
                <Route exact path = {`/dossiers/veiligheidc/:id`}>
                    <SafetyService />
                </Route>
                <Route exact path = {`/dossiers/ventilatie/:id`}>
                    <VentilationService />
                </Route>
                <Route path = "/:page">
                    <Dashboard />
                </Route>
                <Route exact path = "/">
                    <Loginpage />
                </Route>
            </Switch>
        </Router>
    )
}