export const serviceData = [
  {
    id: 0,
    name: 'EPB-verslaggeving',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: null,
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Startverklaring',
    type: 1,
    responsible_list: [
      {
        id: 0,
        name: 'Cristine',
      },
      {
        id: 1,
        name: 'Albert',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 1,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: null,
    doc: 2,
    message: 6,
    price: 'Aanvraag',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 1,
    responsible_list: [
      {
        id: 0,
        name: 'Raymond',
      },
      {
        id: 1,
        name: 'Peck',
      },
      {
        id: 2,
        name: 'Weston',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
    ],
  },
  {
    id: 2,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: null,
    doc: 2,
    message: 6,
    price: 'Aanvraag',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 1,
    responsible_list: [
      {
        id: 0,
        name: 'Castro',
      },
    ],
    customer_list: [
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 3,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '800',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 1,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Noah',
      },
      {
        id: 2,
        name: 'Toby',
      },
      {
        id: 3,
        name: 'Korben',
      },
    ],
    customer_list: [
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 4,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '125',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 2,
    responsible_list: [
      {
        id: 0,
        name: 'Yates',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 5,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 2,
    doc: 2,
    message: 6,
    price: '100',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 2,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Amelia',
      },
      {
        id: 2,
        name: 'Ari',
      },
      {
        id: 3,
        name: 'Cayden',
      },
    ],
    customer_list: [
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 6,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '145',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 2,
    responsible_list: [
      {
        id: 0,
        name: 'Yates',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 7,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 2,
    doc: 2,
    message: 6,
    price: '150',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 2,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Noah',
      },
      {
        id: 2,
        name: 'Toby',
      },
      {
        id: 3,
        name: 'Korben',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
    ],
  },
  {
    id: 8,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 2,
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 3,
    responsible_list: [
      {
        id: 0,
        name: 'Raymond',
      },
      {
        id: 1,
        name: 'Peck',
      },
      {
        id: 2,
        name: 'Weston',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 9,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '760',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 3,
    responsible_list: [
      {
        id: 0,
        name: 'Cristine',
      },
      {
        id: 1,
        name: 'Albert',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 10,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '500',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 3,
    responsible_list: [
      {
        id: 0,
        name: 'Castro',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
    ],
  },
  {
    id: 11,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '465',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 3,
    responsible_list: [
      {
        id: 0,
        name: 'Yates',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 12,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 2,
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 4,
    responsible_list: [
      {
        id: 0,
        name: 'Raymond',
      },
      {
        id: 1,
        name: 'Peck',
      },
      {
        id: 2,
        name: 'Weston',
      },
    ],
    customer_list: [
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 13,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 1,
    doc: 2,
    message: 6,
    price: '760',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 4,
    responsible_list: [
      {
        id: 0,
        name: 'Raymond',
      },
      {
        id: 1,
        name: 'Peck',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 14,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 1,
    doc: 2,
    message: 6,
    price: '500',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 4,
    responsible_list: [
      {
        id: 0,
        name: 'Raymond',
      },
      {
        id: 1,
        name: 'Peck',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 15,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 3,
    doc: 2,
    message: 6,
    price: '465',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 4,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Noah',
      },
      {
        id: 2,
        name: 'Toby',
      },
      {
        id: 3,
        name: 'Korben',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 16,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '345',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 5,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Noah',
      },
    ],
    customer_list: [
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 17,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 2,
    doc: 2,
    message: 6,
    price: '760',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 5,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Noah',
      },
      {
        id: 2,
        name: 'Toby',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 18,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 1,
    doc: 2,
    message: 6,
    price: '500',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 5,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
      {
        id: 1,
        name: 'Noah',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
  {
    id: 19,
    name: 'Dienst',
    file: 70234,
    file_name: 'Dossiernaam',
    customer: 0,
    responsible: 0,
    doc: 2,
    message: 6,
    price: '465',
    date: 'Tijd',
    timeline: 'Tijdslijn',
    type: 5,
    responsible_list: [
      {
        id: 0,
        name: 'Manning',
      },
    ],
    customer_list: [
      {
        id: 0,
        name: 'Petricia',
      },
      {
        id: 1,
        name: 'Sensia',
      },
      {
        id: 2,
        name: 'phenom',
      },
      {
        id: 3,
        name: 'rudimentary',
      },
    ],
  },
]

export const companyClientsHeaders = [
  { id: 1, title: 'Bedrijf' },
  { id: 2, title: 'E-mail' },
  { id: 3, title: 'Telefoon' },
  { id: 4, title: 'Postcode' },
  { id: 5, title: 'Dossiers' },
]

export const userRolesData = [
  { id: 2, value: 'Architect/Builder', title: 'Bouwen & verbouwen' },
  { id: 1, value: 'Buyer/Seller', title: 'Verkopen & verhuren' },
  { id: 3, value: 'Admin', title: 'Admin' },
  { id: 4, value: 'Superadmin', title: 'Superadmin' },
  { id: 5, value: 'No Account', title: 'Geen Account' },
]

export const allCompanyClients = [
  {
    id: 1,
    name: 'Ayz 1',
    generalEmail: 'yw@ab.com',
    phone: '012 8973',
    postcode: '1287',
    dossier: '6',
  },
  {
    id: 2,
    name: 'Bedrijf 2',
    generalEmail: 'rd@example.com',
    phone: '012 7634',
    postcode: '8763',
    dossier: '3',
  },
  {
    id: 3,
    name: 'Jrt 3',
    generalEmail: 'mp@example.com',
    phone: '634 7843',
    postcode: '3476',
    dossier: '4',
  },
  {
    id: 4,
    name: 'Deq 4',
    generalEmail: 'abc@abc.com',
    phone: '382 8975',
    postcode: '4267',
    dossier: '1',
  },
  {
    id: 5,
    name: 'Dar 5',
    generalEmail: 'trq@tr.com',
    phone: '634 1257',
    postcode: '8721',
    dossier: '5',
  },
  {
    id: 6,
    name: 'Tyu 6',
    generalEmail: 'yb@yb.com',
    phone: '336 9823',
    postcode: '2865',
    dossier: '7',
  },
  {
    id: 7,
    name: 'Rqw 7',
    generalEmail: 'dt@example.com',
    phone: '768 2938',
    postcode: '3187',
    dossier: '3',
  },
  {
    id: 8,
    name: 'Bedrijf 8',
    generalEmail: 'generalEmail 8',
    phone: 'Tel. 8',
    postcode: 'Postcode 8',
    dossier: '4',
  },
  {
    id: 9,
    name: 'Bedrijf 9',
    generalEmail: 'generalEmail 9',
    phone: 'Tel. 9',
    postcode: 'Postcode 9',
    dossier: '5',
  },
  {
    id: 10,
    name: 'Bedrijf 10',
    generalEmail: 'generalEmail 10',
    phone: 'Tel. 10',
    postcode: 'Postcode 10',
    dossier: '8',
  },
]

export const addClientCompanyForm = [
  {
    id: 1,
    label: '',
    name: 'companyId',
    placeholder: '',
    value: '0',
    type: 'hidden',
    errorValue: '',
  },
  {
    id: 2,
    label: 'Bedrijf',
    name: 'companyName',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 3,
    label: 'BTW',
    name: 'btw',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 4,
    label: 'Adres & nr',
    name: 'address',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 5,
    label: 'Zip en city',
    name: 'zipcode',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 6,
    label: 'Kantoor E-mail',
    name: 'companyEmail',
    placeholder: '',
    value: '',
    type: 'email',
    errorValue: '',
  },
  {
    id: 7,
    label: 'Kantoor tel',
    name: 'companyPhone',
    placeholder: '',
    value: '',
    type: 'number',
    errorValue: '',
  },
]

export const addClientContactForm = [
  {
    id: 1,
    label: '',
    name: 'contactId',
    placeholder: '',
    value: '0',
    type: 'hidden',
    pattern: null,
    errorValue: '',
  },
  {
    id: 2,
    label: 'Naam',
    name: 'contactName',
    placeholder: '',
    value: '',
    type: 'text',
    pattern: null,
    errorValue: '',
  },
  {
    id: 3,
    label: 'Voornaam',
    name: 'contactFirstName',
    placeholder: '',
    value: '',
    type: 'text',
    pattern: null,
    errorValue: '',
  },
  {
    id: 4,
    label: 'Telefoon',
    name: 'contactPhone',
    placeholder: '',
    value: '',
    type: 'text',
    pattern: "^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$",
    errorValue: '',
  },
  {
    id: 5,
    label: 'Beroep',
    name: 'contactProfession',
    placeholder: '',
    value: '',
    type: 'text',
    pattern: null,
    errorValue: '',
  },
  {
    id: 6,
    label: 'E-mail',
    name: 'contactEmail',
    placeholder: '',
    value: '',
    type: 'email',
    pattern: null,
    errorValue: '',
  },
  {
    id: 7,
    label: 'Wachtwoord',
    name: 'contactPassword',
    placeholder: '',
    value: '',
    type: 'password',
    pattern: null,
    errorValue: '',
  },
  {
    id: 8,
    label: 'Gebruikersgroep',
    name: 'userRole',
    placeholder: '',
    value: '',
    type: 'select',
    pattern: null,
    options: userRolesData,
    errorValue: '',
  },
]

export const addContactPersonForm = [
  {
    id: 1,
    label: 'Naam',
    name: 'contactName',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 2,
    label: 'E-mail',
    name: 'contactEmail',
    placeholder: '',
    value: '',
    type: 'email',
    errorValue: '',
  },
  {
    id: 3,
    label: 'Telefoon',
    name: 'contactPhone',
    placeholder: '',
    value: '',
    type: 'number',
    errorValue: '',
  },
  {
    id: 4,
    label: 'Beroep',
    name: 'contactProfession',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  // {
  //   id: 5,
  //   label: 'Email',
  //   name: 'contactProfession',
  //   placeholder: '',
  //   value: '',
  //   type: 'text',
  //   errorValue: '',
  // },
  // {
  //   id: 6,
  //   label: 'Watchwoord',
  //   name: 'contactProfession',
  //   placeholder: '',
  //   value: '',
  //   type: 'text',
  //   errorValue: '',
  // },
]

export const addClientServiceForm = [
  {
    id: 1,
    label: 'Deinst',
    name: 'serviceId',
    placeholder: '',
    value: '',
    type: 'select',
    options: [],
    errorValue: '',
  },
  {
    id: 2,
    label: 'Straat & Nr',
    name: 'address',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 3,
    label: 'Postcode & Gemeente',
    name: 'postalCode',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
]

export const allUserHeader = [
  { id: 1, title: 'Naam' },
  { id: 2, title: 'Voornaam' },
  { id: 3, title: 'E-mail' },
  { id: 4, title: 'Bedrijf' },
  { id: 5, title: 'Beroep' },
  { id: 6, title: 'Gebruikersgroep' },
  { id: 7, title: '' },
]

export const allUsersData = [
  {
    id: 1,
    firstName: 'David',
    lastName: 'Tuck',
    email: 'david@ab.com',
    companyName: 'XYZ',
    profession: 'HR',
    roleTitle: 'Builder',
  },
  {
    id: 2,
    firstName: 'Mark',
    lastName: 'Zen',
    email: 'mark@ab.com',
    companyName: 'AB 1',
    profession: 'Manager',
    roleTitle: 'Seller',
  },
  {
    id: 3,
    firstName: 'Anderson',
    lastName: 'Shawn',
    email: 'anderson@ab.com',
    companyName: 'ABC',
    profession: 'HR',
    roleTitle: 'Builder',
  },
  {
    id: 4,
    firstName: 'Terry',
    lastName: 'Lee',
    email: 'terry@ab.com',
    companyName: 'MT',
    profession: 'Manager',
    roleTitle: 'Architect',
  },
  {
    id: 5,
    firstName: 'Jimmy',
    lastName: 'Anderson',
    email: 'jimmy@ab.com',
    companyName: 'NEW 1',
    profession: 'Salesman',
    roleTitle: 'Real estate',
  },
  {
    id: 6,
    firstName: 'Tim',
    lastName: 'Corey',
    email: 'tim@ab.com',
    companyName: 'IT World',
    profession: 'HR',
    roleTitle: 'Builder',
  },
]

export const messageData = [
  {
    id: 1,
    user_id: 1,
    building: 'Résidentie Amboise',
    service: 'Ventilatieverslaggeving',
    name: 'Jane Doe',
    message_log_id: 1,
    last_message: '23/07/20 - 15:45',
  },
  {
    id: 2,
    user_id: 2,
    building: 'Huis de Mol',
    service: 'Ventilatieverslaggeving',
    name: 'Jane Doe',
    message_log_id: 2,
    last_message: '23/07/20 - 15:45',
  },
  {
    id: 3,
    user_id: 3,
    building: 'Résidentie Amboise',
    service: 'Ventilatieverslaggeving',
    name: 'Jane Doe',
    message_log_id: 3,
    last_message: '23/07/20 - 15:45',
  },
  {
    id: 4,
    user_id: 4,
    building: 'Résidentie Amboise',
    service: 'Ventilatieverslaggeving',
    name: 'Jane Doe',
    message_log_id: 4,
    last_message: '23/07/20 - 15:45',
  },
  {
    id: 5,
    user_id: 5,
    building: 'Huis de Mol',
    service: 'Ventilatieverslaggeving',
    name: 'Jane Doe',
    message_log_id: 5,
    last_message: '23/07/20 - 15:45',
  },
]

export const messageLog = [
  {
    message_log_id: 1,
    messages: [
      {
        message:
          'Hallo! 😃 Is het mogelijk om ook een Ventilatieverslaggeving van hetzelfde residentie te hebben?',
        user_id: 1,
        name: 'Jane Doe',
        profile: './example.jpg',
        time: '12/06/20 - 13:59',
      },
      {
        message:
          'Ja helemaal! Je mag op het knop « Nieuwe aanvraag » clikken en Ventilatieverslaggeving dienst onlineaanvragen .',
        user_id: 9,
        name: 'Marijn Theunynck',
        profile: './example.jpg',
        time: '12/06/20 - 15:23',
      },
    ],
  },
  {
    message_log_id: 2,
    messages: [
      {
        message:
          'Hallo! 😃 Is het mogelijk om ook een Ventilatieverslaggeving van hetzelfde residentie te hebben?',
        user_id: 2,
        name: 'Jane Doe',
        profile: './example.jpg',
        time: '12/06/20 - 13:59',
      },
      {
        message:
          'Ja helemaal! Je mag op het knop « Nieuwe aanvraag » clikken en Ventilatieverslaggeving dienst onlineaanvragen .',
        user_id: 9,
        name: 'Marijn Theunynck',
        profile: './example.jpg',
        time: '12/06/20 - 15:23',
      },
    ],
  },
  {
    message_log_id: 3,
    messages: [
      {
        message:
          'Hallo! 😃 Is het mogelijk om ook een Ventilatieverslaggeving van hetzelfde residentie te hebben?',
        user_id: 3,
        name: 'Jane Doe',
        profile: './example.jpg',
        time: '12/06/20 - 13:59',
      },
      {
        message:
          'Ja helemaal! Je mag op het knop « Nieuwe aanvraag » clikken en Ventilatieverslaggeving dienst onlineaanvragen .',
        user_id: 9,
        name: 'Marijn Theunynck',
        profile: './example.jpg',
        time: '12/06/20 - 15:23',
      },
    ],
  },
  {
    message_log_id: 4,
    messages: [
      {
        message:
          'Hallo! 😃 Is het mogelijk om ook een Ventilatieverslaggeving van hetzelfde residentie te hebben?',
        user_id: 4,
        name: 'Jane Doe',
        profile: './example.jpg',
        time: '12/06/20 - 13:59',
      },
      {
        message:
          'Ja helemaal! Je mag op het knop « Nieuwe aanvraag » clikken en Ventilatieverslaggeving dienst onlineaanvragen .',
        user_id: 9,
        name: 'Marijn Theunynck',
        profile: './example.jpg',
        time: '12/06/20 - 15:23',
      },
    ],
  },
  {
    message_log_id: 5,
    messages: [
      {
        message:
          'Hallo! 😃 Is het mogelijk om ook een Ventilatieverslaggeving van hetzelfde residentie te hebben?',
        user_id: 5,
        name: 'Jane Doe',
        profile: './example.jpg',
        time: '12/06/20 - 13:59',
      },
      {
        message:
          'Ja helemaal! Je mag op het knop « Nieuwe aanvraag » clikken en Ventilatieverslaggeving dienst onlineaanvragen .',
        user_id: 9,
        name: 'Marijn Theunynck',
        profile: './example.jpg',
        time: '12/06/20 - 15:23',
      },
    ],
  },
]

export const documentsData = [
  {
    id: 0,
    building: 'Résidentie Amboise',
    services: [
      {
        id: 0,
        building_id: 0,
        name: 'Stabiliteit',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
          {
            id: 3,
            name: 'Analyse',
            type: 'pdf',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 1',
            date: '9/07/20 - 11:22',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 1,
        building_id: 0,
        name: 'EPB-verslaggeving',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
        ],
      },
      {
        id: 2,
        building_id: 0,
        name: 'Ventilatieverslaggeving',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
        ],
      },
      {
        id: 3,
        building_id: 0,
        name: 'Veiligheidscoördinatie',
        files: [
          {
            id: 3,
            name: 'Analyse',
            type: 'pdf',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 1',
            date: '9/07/20 - 11:22',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 4,
        building_id: 0,
        name: 'EPC',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 5,
        building_id: 0,
        name: 'Plaatsbeschrijving',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
        ],
      },
      {
        id: 6,
        building_id: 0,
        name: 'Blowerdoortest',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 3,
            name: 'Analyse',
            type: 'pdf',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 1',
            date: '9/07/20 - 11:22',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 7,
        building_id: 0,
        name: 'Keuringen',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
          {
            id: 3,
            name: 'Analyse',
            type: 'pdf',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 1',
            date: '9/07/20 - 11:22',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
    ],
  },
  {
    id: 1,
    building: 'Huis de Mol',
    services: [
      {
        id: 8,
        building_id: 1,
        name: 'Stabiliteit',
        files: [
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
          {
            id: 3,
            name: 'Analyse',
            type: 'pdf',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 1',
            date: '9/07/20 - 11:22',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 9,
        building_id: 1,
        name: 'EPB-verslaggeving',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
        ],
      },
      {
        id: 10,
        building_id: 1,
        name: 'Ventilatieverslaggeving',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 11,
        building_id: 1,
        name: 'Veiligheidscoördinatie',
        files: [
          {
            id: 3,
            name: 'Analyse',
            type: 'pdf',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 1',
            date: '9/07/20 - 11:22',
          },
        ],
      },
      {
        id: 12,
        building_id: 1,
        name: 'EPC',
        files: [
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
          {
            id: 2,
            name: 'lastenboek',
            type: 'doc',
            person: 'Jane Doe',
            person_id: 2,
            label: 'label 3',
            date: '10/07/20 - 15:55',
          },
          {
            id: 4,
            name: 'Factuure',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '8/07/20 - 10:43',
          },
        ],
      },
      {
        id: 14,
        building_id: 1,
        name: 'Plaatsbeschrijving',
        files: [
          {
            id: 0,
            name: 'sitemap',
            type: 'pdf',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 1',
            date: '12/07/20 - 12:30',
          },
          {
            id: 1,
            name: 'Overallview',
            type: 'xls',
            person: 'Marijn Theunynck',
            person_id: 1,
            label: 'label 2',
            date: '11/07/20 - 11:33',
          },
        ],
      },
    ],
  },
]

export const companyEditViewHeaders = [
  { id: 1, title: 'Bedrijf', value: '' },
  { id: 2, title: 'BTW', value: '' },
  { id: 3, title: 'Ligging', value: '' },
  { id: 4, title: 'Contact persoon', value: '' },
  { id: 5, title: 'Contact tel.', value: '' },
]

export const companyEditHeaders = [
  {
    id: 1,
    label: '',
    name: 'id',
    placeholder: '',
    value: '0',
    type: 'hidden',
    errorValue: '',
  },
  {
    id: 2,
    label: 'Company',
    name: 'name',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 3,
    label: 'BTW',
    name: 'btw',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 4,
    label: 'Adres & nr',
    name: 'address',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 5,
    label: 'Postcode & gemeente',
    name: 'postcode',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 6,
    label: 'Contact persoon',
    name: 'contactId',
    placeholder: '',
    value: '',
    type: 'select',
    errorValue: '',
    options: [],
  },
  {
    id: 7,
    label: 'Contact telefon',
    name: 'contactPhone',
    placeholder: '',
    value: '',
    type: 'number',
    errorValue: '',
  },
]

export const privateCompanyForm = [
  {
    id: 1,
    label: '',
    name: 'companyId',
    placeholder: '',
    value: '0',
    type: 'hidden',
    errorValue: '',
  },
  {
    id: 2,
    label: '',
    name: 'contactId',
    placeholder: '',
    value: '0',
    type: 'hidden',
    errorValue: '',
  },
  {
    id: 3,
    label: 'Naam',
    name: 'firstName',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 4,
    label: 'Voornaam',
    name: 'lastName',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 5,
    label: 'Adres & nr',
    name: 'address',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 6,
    label: 'Zip en city',
    name: 'postcode',
    placeholder: '',
    value: '',
    type: 'text',
    errorValue: '',
  },
  {
    id: 7,
    label: 'Telefoon',
    name: 'phone',
    placeholder: '',
    value: '',
    type: 'number',
    errorValue: '',
  },
  {
    id: 8,
    label: 'Email',
    name: 'email',
    placeholder: '',
    value: '',
    type: 'email',
    errorValue: '',
  },
  {
    id: 9,
    label: 'Watchwoord',
    name: 'password',
    placeholder: '',
    value: '',
    type: 'password',
    errorValue: '',
  },
  {
    id: 10,
    label: 'Usergroup',
    name: 'userRole',
    placeholder: '',
    value: '',
    type: 'select',
    options: userRolesData,
    errorValue: '',
  },
]

export const notificationHeaders = [
  { id: 1, title: 'Notificatie' },
  { id: 2, title: 'Bedrijf' },
  { id: 3, title: 'Dossier nr.' },
  { id: 4, title: 'E-mail' },
  { id: 5, title: 'Datum' },
  { id: 6, title: '' },
]

export const notificationData = [
  {
    id: 1,
    firstName: 'first',
    lastName: 'last',
    title: 'Notificatie 1',
    email: 'email1@example.com',
    companyName: 'Bedrijf 1',
    date: '12/07/2020 - 13:46',
    fileNo: '# 123456',
    isArchive: true,
    message: 'message 1',
  },
  {
    id: 2,
    firstName: 'first',
    lastName: 'last',
    title: 'Notificatie 2',
    email: 'email2@example.com',
    companyName: 'Bedrijf 2',
    date: '21/11/2020 - 13:26',
    fileNo: '# 123898',
    isArchive: false,
    message: 'xyz',
  },
  {
    id: 3,
    firstName: 'first',
    lastName: 'last',
    title: 'New Message',
    email: 'david@example.com',
    companyName: 'Bedrijf 3',
    date: '12/08/2020 - 13:18',
    fileNo: '# 127896',
    isArchive: false,
    message: 'Hello world',
  },
  {
    id: 4,
    firstName: 'first',
    lastName: 'last',
    title: 'Project 2',
    email: 'terry@example.com',
    companyName: 'Bedrijf 4',
    date: '13/07/2020 - 13:24',
    fileNo: '# 237964',
    isArchive: true,
    message: 'New message',
  },
  {
    id: 5,
    firstName: 'first',
    lastName: 'last',
    title: 'Building 1',
    email: 'gates@example.com',
    companyName: 'Bedrijf 5',
    date: '07/10/2020 - 13:36',
    fileNo: '# 478974',
    isArchive: true,
    message: 'comments',
  },
  {
    id: 6,
    firstName: 'first',
    lastName: 'last',
    title: 'Building contract',
    email: 'mark@example.com',
    companyName: 'Bedrijf 6',
    date: '24/09/2020 - 13:34',
    fileNo: '# 324123',
    isArchive: false,
    message:
      'Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, …',
  },
]
export const invoiceHeaders = [
  { id: 1, title: 'Dienst' },
  { id: 2, title: 'Facturate nr.' },
  { id: 3, title: 'Superadmin' },
  { id: 4, title: 'Klant' },
  { id: 5, title: 'Status' },
  { id: 6, title: 'Laatste update' },
  { id: 7, title: '' },
]
export const invoiceDataDetails = {
  firstCompany: {
    companyName: 'Mathebo Bv. ',
    vtaNumber: 'BE08.2345.324.566',
    streetName: 'Schoorbakkestraat',
    streetNumber: '11',
    postCode: '8600',
    city: 'Pervijze',
  },
  secondCompany: {
    companyName: 'We Invest real estate',
    vtaNumber: 'BE08.2345.324.566',
    streetName: 'Dunzestraat',
    streetNumber: '23',
    postCode: '1440',
    city: 'Tervuren',
  },
  billDetails: {
    billNumber: 2245,
    date: '13/08/2020',
    expireDate: '13/09/2020',
    clientVATNumber: 'BE08.6342.345.734',
    serviceName: 'EPB-verslaggeving',
    number: 1,
    unitPrice: 45000,
    amount: 45000,
    percentage: 50,
    totalExclBTW: 22500,
    btw: 4725,
    totalInclBTW: 27225,
  },
}
export const invoiceData = [
  {
    id: 1,
    shift: 'Stabiliteit',
    percentage: '0%',
    customer: 'Bedrijf 1',
    date: '-',
    fileNo: '# 123456',
    status: 'Ontvangen',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 2,
    shift: 'EPB-verslaggeving',
    percentage: '0%',
    customer: 'Bedrijf 2',
    date: '-',
    fileNo: '# 123898',
    status: 'Ontvangen',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 3,
    shift: 'Ventilatieverslaggeving',
    percentage: '0%',
    customer: 'Bedrijf 3',
    date: '-',
    fileNo: '# 127896',
    status: 'In behandeling',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 4,
    shift: 'Veiligheidscoördinatie',
    percentage: '50%',
    customer: 'Bedrijf 4',
    date: '13/07/2020',
    fileNo: '# 237964',
    status: 'In behandeling',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 5,
    shift: 'EPC',
    percentage: '50%',
    customer: 'Bedrijf 5',
    date: '07/10/2020',
    fileNo: '# 478974',
    status: 'In behandeling',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 6,
    shift: 'Plaatsbeschrijving',
    percentage: '50%',
    customer: 'Bedrijf 6',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'In behandeling',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 7,
    shift: 'Blowerdoortest',
    percentage: '70%',
    customer: 'Bedrijf 7',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Afgewerkt',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 8,
    shift: 'Keuringen',
    percentage: '70%',
    customer: 'Bedrijf 8',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Afgewerkt',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 9,
    shift: 'Stabiliteit',
    percentage: '100%',
    customer: 'Bedrijf 9',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Afgewerkt',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 10,
    shift: 'EPB-verslaggeving',
    percentage: '100%',
    customer: 'Bedrijf 10',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Afgewerkt',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 11,
    shift: 'EPB-verslaggeving',
    percentage: '100%',
    customer: 'Bedrijf 10',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Geannuleerd',
    invoiceAllDataDetails: invoiceDataDetails,
  },
  {
    id: 12,
    shift: 'EPB-verslaggeving',
    percentage: '100%',
    customer: 'Bedrijf 10',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Geannuleerd',
    invoiceAllDataDetails: invoiceDataDetails,
  },
]

export const quotationsHeaders = [
  { id: 1, title: 'Dienst' },
  { id: 2, title: 'Superadmin' },
  { id: 3, title: 'Prijs' },
  { id: 4, title: 'Klant' },
  { id: 6, title: 'Laatste update' },
  { id: 7, title: '' },
]
export const quotationsDataDetails = {
  firstCompany: {
    companyName: 'Mathebo Bv. ',
    vtaNumber: 'BE08.2345.324.566',
    streetName: 'Schoorbakkestraat',
    streetNumber: '11',
    postCode: '8600',
    city: 'Pervijze',
  },
  secondCompany: {
    companyName: 'We Invest real estate',
    vtaNumber: 'BE08.2345.324.566',
    streetName: 'Dunzestraat',
    streetNumber: '23',
    postCode: '1440',
    city: 'Tervuren',
  },
  billDetails: {
    billNumber: 2245,
    date: '13/08/2020',
    expireDate: '13/09/2020',
    clientVATNumber: 'BE08.6342.345.734',
    serviceName: 'EPB-verslaggeving',
    number: 1,
    unitPrice: 45000,
    amount: 45000,
    percentage: 50,
    totalExclBTW: 22500,
    btw: 4725,
    totalInclBTW: 27225,
  },
}
export const quotationsData = [
  {
    id: 1,
    shift: 'Stabiliteit',
    prijs: '-',
    customer: 'Bedrijf 1',
    date: '-',
    fileNo: '# 123456',
    status: 'Aanmaken',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 2,
    shift: 'EPB-verslaggeving',
    prijs: '-',
    customer: 'Bedrijf 2',
    date: '-',
    fileNo: '# 123898',
    status: 'Aanmaken',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 3,
    shift: 'Ventilatieverslaggeving',
    prijs: '-',
    customer: 'Bedrijf 3',
    date: '-',
    fileNo: '# 127896',
    status: 'Aanmaken',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 4,
    shift: 'Veiligheidscoördinatie',
    prijs: 'Prijs',
    customer: 'Bedrijf 4',
    date: '13/07/2020',
    fileNo: '# 237964',
    status: 'Draft',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 5,
    shift: 'EPC',
    prijs: 'Prijs',
    customer: 'Bedrijf 5',
    date: '07/10/2020',
    fileNo: '# 478974',
    status: 'Draft',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 6,
    shift: 'Plaatsbeschrijving',
    prijs: 'Prijs',
    customer: 'Bedrijf 6',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Draft',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 7,
    shift: 'Blowerdoortest',
    prijs: 'Prijs',
    customer: 'Bedrijf 7',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Verzonden',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 8,
    shift: 'Keuringen',
    prijs: 'Prijs',
    customer: 'Bedrijf 8',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Verzonden',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 9,
    shift: 'Stabiliteit',
    prijs: 'Prijs',
    customer: 'Bedrijf 9',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Goedgekeurd',
    quotationsAllDataDetails: quotationsDataDetails,
  },
  {
    id: 10,
    shift: 'EPB-verslaggeving',
    prijs: 'Prijs',
    customer: 'Bedrijf 10',
    date: '24/09/2020',
    fileNo: '# 324123',
    status: 'Goedgekeurd',
    quotationsAllDataDetails: quotationsDataDetails,
  },
]
export const ventilationTypes = [
  { id: 1, title: 'Ventilatiesysteem A' },
  { id: 2, title: 'Ventilatiesysteem B' },
  { id: 3, title: 'Ventilatiesysteem C' },
  { id: 4, title: 'Ventilatiesysteem D' },
]

export const calendarData = [
  {
    responsible_id: 1,
    responsible_name: 'Marijn Theunynck',
    assigned_services: [
      {
        user_id: 1,
        service_id: 1, 
        building_id: 1,
        building_name: 'Residentie Amboise',
        service_name: 'EPC certificaat',
        date: '6/11/2020',
        start_time: '09:45',
        end_time: '10:30',
      },
      {
        user_id: 1,
        service_id: 2, 
        building_id: 2,
        building_name: 'Laurins Garden',
        service_name: 'Ventilatieverslaggeving',
        date: '5/11/2020',
        start_time: '11:00',
        end_time: '12:15',
      },
      {
        user_id: 1,
        service_id: 3, 
        building_id: 3,
        building_name: 'Huis de Mol',
        service_name: 'Veiligheidscoördinatie',
        date: '6/11/2020',
        start_time: '13:30',
        end_time: '14:15',
      },
    ]
  },
  {
    responsible_id: 2,
    responsible_name: 'Robbe Smith',
    assigned_services: [
      {
        user_id: 3,
        service_id: 5, 
        building_id: 5,
        building_name: 'Mathebo',
        service_name: 'Foto & Video',
        date: '6/11/2020',
        start_time: '10:30',
        end_time: '11:30',
      },
      {
        user_id: 4,
        service_id: 6, 
        building_id: 3,
        building_name: 'Huis de Mol',
        service_name: 'Blowerdoortest',
        date: '7/11/2020',
        start_time: '15:00',
        end_time: '17:15',
      },
    ]
  },
  {
    responsible_id: 3,
    responsible_name: 'Laurence Mayor',
    assigned_services: [
      {
        user_id: 4,
        service_id: 7, 
        building_id: 2,
        building_name: 'Laurins Garden',
        service_name: 'Foto & Video',
        date: '6/11/2020',
        start_time: '10:30',
        end_time: '11:30',
      },
      {
        user_id: 4,
        service_id: 3, 
        building_id: 3,
        building_name: 'Huis de Mol',
        service_name: 'Veiligheidscoördinatie',
        date: '8/11/2020',
        start_time: '10:30',
        end_time: '11:30',
      },
    ]
  },
]

export const timelineData = [
  {
    building_id: 1,
    building_name: 'Residentie Amboise',
    services: [
      {
        service_id: 1,
        service_name: 'EPC certificaat',
        date: '9/11/2020',
        start_time: '9:30',
        end_time: '12:30',
        color: '#9C5AB4'
      },
      {
        service_id: 2,
        service_name: 'Ventilatieverslaggeving',
        date: '12/11/2020',
        start_time: '14:30',
        end_time: '17:30',
        color: '#3598DC'
      },
      {
        service_id: 3,
        service_name: 'Foto & Video',
        date: '10/11/2020',
        start_time: '10:30',
        end_time: '13:30',
        color: '#334960'
      },
      {
        service_id: 4,
        service_name: 'Blowerdoortest',
        date: '10/11/2020',
        start_time: '14:30',
        end_time: '17:30',
        color: '#E77E22'
      },
      {
        service_id: 5,
        service_name: 'Keuringen',
        date: '14/11/2020',
        start_time: '11:00',
        end_time: '14:00',
        color: '#E84E3E'
      },
      {
        service_id: 6,
        service_name: 'Stabiliteit',
        date: '15/11/2020',
        start_time: '8:00',
        end_time: '12:30',
        color: '#1BBD9D'
      }
    ]
  },
  {
    building_id: 2,
    building_name: 'Laurins Garden',
    services: [
      {
        service_id: 1,
        service_name: 'EPC certificaat',
        date: '9/11/2020',
        start_time: '9:30',
        end_time: '12:30',
        color: '#9C5AB4'
      },
      {
        service_id: 4,
        service_name: 'Blowerdoortest',
        date: '10/11/2020',
        start_time: '14:30',
        end_time: '17:30',
        color: '#E77E22'
      },
      {
        service_id: 5,
        service_name: 'Keuringen',
        date: '14/11/2020',
        start_time: '11:00',
        end_time: '14:00',
        color: '#E84E3E'
      },
      {
        service_id: 6,
        service_name: 'Stabiliteit',
        date: '15/11/2020',
        start_time: '8:00',
        end_time: '12:30',
        color: '#1BBD9D'
      }
    ]
  },
  // {
  //   building_id: 3,
  //   building_name: 'Huis de Mol',
  // },
  // {
  //   building_id: 4,
  //   building_name: 'Seniorie van Laar'
  // },
  // {
  //   building_id: 5,
  //   building_name: 'Mathebo',
  // }
]

export const serviceFilterManifestDefaultStructure = {
  files: [
    {
      id: 0,
      enabled: false,
    },
    {
      id: 1, 
      enabled: false,
    }
  ],
  status: [
    {
      id: 0,
      enabled: false,
    },
    {
      id: 1,
      enabled: false,
    },
    {
      id: 2,
      enabled: false,
    },
    {
      id: 3,
      enabled: false,
    },
    {
      id: 4,
      enabled: false,
    }
  ],
}

export const adminColors = [
  {
    color: '#EF5350',
  },
  {
    color: '#EC407A',
  },
  {
    color: '#AB47BC',
  },
  {
    color: '#673AB7',
  },
  {
    color: '#7986CB',
  },
  {
    color: '#BBDEFB',
  },
  {
    color: '#29B6F6',
  },
  {
    color: '#00BCD4',
  },
  {
    color: '#26A69A',
  },
  {
    color: '#81C784',
  },
  {
    color: '#81C784',
  },
  {
    color: '#D4E157',
  },
  {
    color: '#F9A825',
  },
  {
    color: '#FF6F00',
  },
  {
    color: '#FFB74D',
  },
  {
    color: '#FF5722',
  }
]