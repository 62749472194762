import React, { useEffect, useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import axios from "axios"
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { delay } from "lodash";

import document from "../../../assets/svg/notification/doc.svg";
import message from "../../../assets/svg/notification/message.svg";
import ProfileView from '../profile_view/profile_view'
import {DashboardSidebarModular} from '../dashboard';

import "./_header.scss";

function NotificationDropdown( props ) {
    const [ notificationList, setNotificationList ] = useState([])
    const [ notificationMouseState, setNotificationMouseState ] = useState(false)
    const [ showNotification, setShowNotification ] = useState(false)
    const [ realtime, setRealtime ] = useState(false)

    const { cleanDateTimeString, history } = props;

    useEffect(() => {
        getNotifications()
        delay(() => {
            setRealtime(!realtime);
        }, 3000)
    }, [realtime])

    function getNotifications() {
        const token = localStorage.getItem('authToken')
        axios.post('https://backend.develop.bip.flykube.agifly.cloud/dashboard/unread_notification', {}, {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(response => {
            setNotificationList(response.data)
        }).catch(error => {
        })
    }

    const handleNotificationModal = () => {
        setShowNotification(!showNotification)
    }

    function goToDocument(document) {
        history.push(`/documenten&=${document}`)
    }

    function goToMessages(message) {
        history.push(`/berichten&=${message}`)
    }

    return(
        <div className = {`header-bell-container relative cursor-pointer relative horizontal-center outline-none ${window.screen.width < 600 ? 'margin-right-10' : 'margin-right-50'}`} tabIndex = "1" onBlur = {() => { if(!notificationMouseState) setShowNotification(false) }}>
            <button type = "button" className = "height-auto width-auto cursor-pointer" onClick = {handleNotificationModal}>
                <img src = {require('../../../assets/svg/icon/bell.svg')} alt = "bell" className = "header-bell object-fit-contain" />
                <div className = "header-bell-bubble center-content">
                    <h1 className = "main-font-bold font-size-9 font-color-white">{notificationList.length > 0 ? notificationList.length : 0}</h1>
                </div>
            </button>
            <div className = "header-notification-container animatable-400 ease-in-out-quart" onMouseOver = {() => { setNotificationMouseState(true) }} onMouseLeave = {() => { setNotificationMouseState(false) }} style = {{height: showNotification ? `${notificationList.length > 6 ? 105 * 6 : 105 * notificationList.length}px` : '0px', opacity: showNotification ? 1 : 0}}>
                <div className = "header-notification">
                    {notificationList.length > 0 && notificationList.map(item => (
                        <div className = "header-notification-item-border" onClick = {() => { item.type === "document" ? goToDocument(item.referenceId) : goToMessages(item.referenceId) }}>
                            <div className = "flex-column width-auto margin-top-15">
                                <div className = "flex-row horizontal-end vertical-center margin-right-27">
                                    <img src = {item.type === "document" ? document : message} alt = "bell" className = "header-notification-icon" />
                                    <p className = "main-font-semi-bold font-size-14 font-color-black line-height-20-p">{item.type === "document" ? 'Nieuw document' : 'Nieuw bericht'}</p>
                                    <div className = "header-notification-bubble" />
                                </div>
                                <div className = "flex-row horizontal-end margin-right-40">
                                    <p className = "main-font-regular font-size-14 font-color-black line-height-20-p text-align-end">{item.buildingName}</p>
                                </div>
                                <div className = "flex-row horizontal-end margin-right-40">
                                    <p className = "main-font-regular font-size-14 font-color-black line-height-20-p text-align-end">{item.serviceName}</p>
                                </div>
                            </div>
                            <div className = "flex-row  horizontal-end margin-right-40 margin-top-7 margin-bottom-15" >
                                <p className = "main-font-regular font-size-9 font-color-dark-grey">{cleanDateTimeString(item.date)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default function Header( props ) {
    const [ userDetails, setUserDetails ] = useState()
    const [isProfileClick, setProfileClickState] = useState(false)
    const [ dashboardSidebarState, setDashboardSidebarState ] = useState(false);

    const history = useHistory();
    const { isLogoEnabled, toShowHamburger } = props;

    useEffect(() => {
        const userData = JSON.parse( localStorage.getItem('loggedInUserDetails'));
        setUserDetails(userData)
        
    }, [])

    const handleNavigation = () => {
        history.push('/dashboard');
    }

    const handleSignoutClick=()=>{
        localStorage.clear()
        history.push('/')
    }

    function cleanDateTimeString(date) {
        let newDate = date.split(" ")[0].split("-");
        let newTime = date.split(" ")[1].slice(0, -3)
        return `${newDate[2]}/${newDate[1]}/${newDate[0]} - ${newTime}`
    }

    const handleDashboardSidebar = () => {
        setDashboardSidebarState(!dashboardSidebarState);
    }

    return(
        <Grid container>
            {toShowHamburger && (<DashboardSidebarModular isVisible = {dashboardSidebarState} closeDashboard = {handleDashboardSidebar} />)}
            <div className = "header-container vertical-center">
            <Grid container md={1} sm={1} xs={2} justify='center'>
                {toShowHamburger!==undefined && toShowHamburger && (
                    <div className='center-content margin-left-5 margin-right-10 cursor-pointer'>
                        <img
                            alt = 'menu'
                            className="epb-service-menu-icon"
                            src={require('../../../assets/svg/icon/menu.svg')}
                            onClick={handleDashboardSidebar}
                        />
                    </div>
                )}
            </Grid>
                {isLogoEnabled && (
                    <div className="flex-row width-auto">
                        <div className="dashboard-sidebar-inset vertical-center cursor-pointer" onClick = {handleNavigation}>
                            <Hidden mdUp>
                                <img
                                    src={require('../../../assets/svg/logo_color/7b.svg')}
                                    alt="docubuild"
                                    className="dashboard-sidebar-logo object-fit-contain margin-left-0"
                                />
                                <h1 className="main-font-bold font-size-12 font-color-navy-blue opacity-70 margin-left-5">
                                    Docu
                                </h1>
                                <p className="main-font-light font-size-12 font-color-navy-blue margin-right-10">
                                    Build
                                </p>
                            </Hidden>
                            <Hidden smDown>
                                <img
                                    src={require('../../../assets/svg/logo_color/7b.svg')}
                                    alt="docubuild"
                                    className="dashboard-sidebar-logo object-fit-contain margin-left-50"
                                />
                                <h1 className="main-font-bold font-size-22 font-color-navy-blue opacity-70 margin-left-10">
                                    Docu
                                </h1>
                                <p className="main-font-light font-size-22 font-color-navy-blue">
                                    Build
                                </p>
                            </Hidden>
                        </div>
                    </div>
                )}
                <div className = "width-auto horizontal-end flex-row">
                    <NotificationDropdown cleanDateTimeString = {cleanDateTimeString} history = {history} />
                    <div className = {`flex-row vertical-center ${window.screen.width < 600 ? 'margin-right-5' : 'margin-right-20'}`}>
                        {userDetails !== undefined && userDetails.company !== null ?
                        <img
                            alt="profile"
                            className="header-profile-image object-fit-contain"
                            src={userDetails.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${userDetails.company.profile_picture}` : require('../../../assets/svg/user.svg')}
                            onClick={() => setProfileClickState(true)}
                        />
                        : <div className='header-profile-image background-color-grey' onClick={() => setProfileClickState(true)} />
                        }
                        <ProfileView
                            modalState={isProfileClick}
                            setModalState={setProfileClickState}
                        />
                        <Hidden xsDown>
                            <div className = "flex-column margin-left-10">
                                <p className = "main-font-regular font-size-14 font-color-grey">{userDetails !== undefined && userDetails.firstName}</p>
                                <p className = "main-font-regular font-size-14 font-color-grey">{userDetails !== undefined && userDetails.lastName}</p>
                            </div>
                        </Hidden>
                    </div>
                    <Hidden xsDown>
                        <button
                            type="button"
                            className="header-signout-button-radius background-color-sky-blue center-content margin-right-20 cursor-pointer"
                            onClick={handleSignoutClick}
                        >
                            <span className="flex-row center-content">
                                <p className = "main-font-semi-bold font-color-white font-size-14 margin-left-15 margin-right-15">Sign out</p>
                            </span>
                        </button>
                  </Hidden>
                    <Hidden smUp>
                        <div className='flex-row vertical-center margin-left-10 margin-right-10 '>
                            <img alt="signout" src={require('../../../assets/png/logout.png')} className='header-profile-image object-fit-cover ' onClick={handleSignoutClick}/>
                        </div>
                    </Hidden>
                </div>
            </div>
        </Grid>
    )
}

Header.propTypes = {
    isLogoEnabled: PropTypes.bool,
}

Header.defaultProps = {
    isLogoEnabled: false,
}