import React, { useEffect, useRef, useState, createRef } from 'react'
import { Grid } from '@material-ui/core'
import { StatementDetails } from '../../../epb_forms/epb_form_client/step_three/step_three'
import './_step_3.scss'
import PropTypes from 'prop-types'
import InfoBlueIcon from '../../../../assets/svg/icon/info_circle_blue.svg'
import axios from 'axios'

export default function Step3(props) {
  const { workFile, qualityFile, setWorkFile, setQualityFile, token, projectServiceId, userRole, formId } = props
  const [workFileData,setWorkFileData]=useState(new FormData())
  const [qualityFileData,setQualityFileData]=useState(new FormData())
  const [statementRef, setStatementRef] = useState([])
  
  useEffect(() => {
    let data = []
    const tempArray = []
    Array(2)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setStatementRef(tempArray)

    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/service_document/get`,
        { serviceId: 3, projectServiceId: projectServiceId },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success === true) {
          data = response.data.data
          const tempWorkFileData = new FormData()
          const tempQualityFileData = new FormData()
          let serviceDocumentCategoryId =
            data[2].service_document_categories.length > 0 
                ? data[2].service_document_categories[0].id
              : 0
          let serviceDocumentId =
            data[2].service_document_categories.length > 0
              ? data[2].service_document_categories[0].service_documents
                  .length > 0
                ? data[2].service_document_categories[0].service_documents[0].id
                : 0
              : 0
          tempWorkFileData.append(
            'serviceDocumentCategoryId',
            serviceDocumentCategoryId,
          )
          tempWorkFileData.append('projectServiceId', projectServiceId)
          tempWorkFileData.append('serviceDocumentId', serviceDocumentId)
           
          serviceDocumentCategoryId =
            data[2].service_document_categories.length > 0 
                ? data[2].service_document_categories[1].id
              : 0
           serviceDocumentId =
            data[2].service_document_categories.length > 0
              ? data[2].service_document_categories[1].service_documents
                  .length > 0
                ? data[2].service_document_categories[1].service_documents[0].id
                : 0
              : 0
          tempQualityFileData.append(
            'serviceDocumentCategoryId',
            serviceDocumentCategoryId,
          )
          tempQualityFileData.append('projectServiceId', projectServiceId)
          tempQualityFileData.append('serviceDocumentId', serviceDocumentId)

          setWorkFileData(tempWorkFileData)
          setQualityFileData(tempQualityFileData)
          if(data[2].service_document_categories.length>0 && data[2].service_document_categories[0].service_documents.length>0){
            setWorkFile(data[2].service_document_categories[0].service_documents[0])
          }
          if(data[2].service_document_categories.length>1 && data[2].service_document_categories[1].service_documents.length>0){
            setQualityFile(data[2].service_document_categories[1].service_documents[0])
          }
        }
      })
      .catch((error) => {})

  }, [])

  return (
    <div className="height-auto width-auto animatable-400 fade-in-translate">
      <div className="ventilation-service-blue-strip margin-top-20 vertical-center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1}></Grid>
          <Grid item xs={11} sm={11} md={11}>
            <div className="vertical-center">
              {/* <img
                src={InfoBlueIcon}
                alt=""
                className="ventilation-service-step3-info-icon"
              /> */}
              <span className="main-font-regular font-color-black font-size-16 margin-left-10">
                Ventilatieprestatieverslag
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={10} sm={6} md={6}>
          <StatementDetails
            backendFileName={workFile!==undefined && workFile!==null && workFile.fileName}
            file={workFile}
            setFile={setWorkFile}
            uploadPath={`https://backend.develop.bip.flykube.agifly.cloud/service_document/upload_document`}
            // deletePath="https://backend.develop.bip.flykube.agifly.cloud/ventilation_service_form/ventilatievoorontwerp_document_del"
            buttonText="Upload"
            ref={statementRef[0]}
            token={token}
            formData={workFileData}
            buttonDisableState = {userRole === 'Extern'}
            epbFormId = {formId}
          />
        </Grid>
      </Grid>
      <div className="ventilation-service-blue-strip margin-top-30 vertical-center">
        <Grid container>
          <Grid item xs={1} md={1} sm={1}></Grid>
          <Grid item xs={11} md={11} sm={11}>
            <div className="vertical-center">
              {/* <img
                src={InfoBlueIcon}
                alt=""
                className="ventilation-service-step3-info-icon"
              /> */}
              <span className="main-font-regular font-color-black font-size-16 margin-left-10">
                Attest van het kwaliteitskader
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={10} sm={6} md={6}>
          <StatementDetails
            backendFileName={qualityFile!==undefined && qualityFile!==null && qualityFile.fileName}
            file={qualityFile}
            setFile={setQualityFile}
            uploadPath={`https://backend.develop.bip.flykube.agifly.cloud/service_document/upload_document`}
            // deletePath="https://backend.develop.bip.flykube.agifly.cloud/ventilation_service_form/attest_van_het_kwaliteitskader_document_del"
            buttonText="Upload"
            ref={statementRef[1]}
            token={token}
            formData={qualityFileData}
            buttonDisableState = {userRole === 'Extern'}
            epbFormId = {formId}
          />
        </Grid>
      </Grid>
    </div>
  )
}

Step3.propTypes = {
  workFile: PropTypes.object,
  qualityFile: PropTypes.object,
  setWorkFile: PropTypes.func,
  setQualityFile: PropTypes.func,
}

Step3.defaultProps = {
  workFile: {},
  qualityFile: {},
  setWorkFile: function () {},
  setQualityFile: function () {},
}
